import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { TextField, withTheme } from '@material-ui/core';

import { AVAILABLE_LANGS } from '../../../constants';

function LocalizedTextInput(props) {
    const { label, value, onChange, style, theme, touched = false, error = null, helperText = '' } = props;

    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);

    const [values, setValues] = useState(value || {});

    // organizes the value as a language dictionary on first render only
    useEffect(() => {
        let valuesTemp = {};
        availableLangs.forEach(lang => {
            valuesTemp[lang] = _.get(value, lang, '');
        });

        setValues(valuesTemp);
    }, []);

    const handleChange = e => {
        const valueCopy = _.isNil(values) || _.isEmpty(values) ? {} : _.cloneDeep(values);
        _.set(valueCopy, e.target.name, e.target.value);

        setValues(valueCopy);
        onChange(valueCopy);
    };

    return (
        <div
            style={{
                position: 'relative',
                padding: '18.5px 14px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px 4px 4px 4px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                ...style
                //borderBottom: 'none',
            }}
        >
            <div
                style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: 'absolute',
                    top: -10,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: theme.typography.subtitle2.fontFamily,
                    fontSize: '12px',
                    color: theme.palette.grey[600]
                }}
            >
                {label}
            </div>
            {availableLangs.map(lang => (
                <TextField
                    data-cy={`localized-input-${_.kebabCase(label)}-edit`}
                    key={lang}
                    style={{ marginBottom: theme.spacing.unit }}
                    fullWidth
                    multiline
                    label={lang}
                    value={_.get(values, lang)}
                    name={lang}
                    onChange={handleChange}
                    error={touched && !_.isNil(error)}
                    helperText={touched && !_.isNil(error) ? error : helperText}
                />
            ))}
        </div>
    );
}

export default withTheme()(LocalizedTextInput);
