import React, { useRef, useState } from 'react';
import _ from 'lodash';

import {
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Icon,
    Avatar,
    Divider,
    withTheme,
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    DialogTitle,
    Grow
} from '@material-ui/core';
import { useFormik } from 'formik';

import { AVAILABLE_LANGS } from '../../../constants';

import * as Yup from 'yup';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useContext } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';
import { getCheckbox, getTextInput, getSelect } from 'components/CRUDTables/helperFunctions';

import { Icon as MDIcon } from '@mdi/react';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function SubCommoditiesList({
    allFees,
    allIcons,
    allIconNames,
    commodity,
    commodityFormik,
    subCommodities = [],
    editDisabled,
    onChange,
    theme
}) {
    const [formOpen, setFormOpen] = useState(false);
    const [indexToEdit, setIndexToEdit] = useState(null);
    const [selectedLang, setSelectedLang] = useState('en');

    const { lang } = useContext(LocalizationContext);

    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

    const handleRemove = i => {
        let newSubCommodities = _.cloneDeep(subCommodities);
        newSubCommodities.splice(i, 1);
        onChange(_.orderBy(newSubCommodities, ['name'], ['desc']));
    };

    const handleAddEdit = values => {
        if (_.isNil(indexToEdit)) {
            const newSubCommodities = [...subCommodities, values];
            onChange(_.orderBy(newSubCommodities, ['name'], ['desc']));
        } else {
            let newSubCommodities = _.cloneDeep(subCommodities);
            newSubCommodities[indexToEdit] = values;
            onChange(_.orderBy(newSubCommodities, ['name'], ['desc']));
        }
        setFormOpen(false);
    };

    const handleEditOpen = index => {
        setFormOpen(true);
        setIndexToEdit(index);
    };

    const handleCreateOpen = index => {
        setFormOpen(true);
        setIndexToEdit(null);
    };

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;

        const subCommodities = commodity.subCommodities;
        const subCommodityToMove = _.find(subCommodities, { _id: draggableId });

        const filteredSubCommodities = _.filter(subCommodities, c => c._id != draggableId);

        const updatedSubCommodities = _.cloneDeep(filteredSubCommodities);
        updatedSubCommodities.splice(destination.index, 0, subCommodityToMove);

        commodityFormik.setFieldValue('subCommodities', updatedSubCommodities);
    };

    const languageToDisplay = editDisabled ? lang : selectedLang;

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '60%', display: 'flex' }}>
                    <Typography variant="caption">Icon</Typography>
                    <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 8 }}>
                        Name
                    </Typography>
                </div>

                <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Unit</Typography>
                    {!editDisabled && (
                        <ToggleButtonGroup
                            value={selectedLang}
                            exclusive
                            onChange={(e, lang) => !_.isEmpty(lang) && setSelectedLang(lang)}
                            style={{ marginRight: theme.spacing.unit * 2 }}
                        >
                            {availableLangs.map(lang => (
                                <ToggleButton key={lang} value={lang}>
                                    {lang}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                </div>
            </div>
            <List>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="subCommodityList">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {subCommodities.map((row, i) => (
                                    <Draggable draggableId={row._id} index={i} key={row._id}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                                key={row.name}
                                            >
                                                <Divider />
                                                <ListItem
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <div
                                                        style={{ display: 'flex', alignItems: 'center', width: '60%' }}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                                                <MDIcon
                                                                    path={_.get(
                                                                        allIcons,
                                                                        convertToImportName(row.iconName)
                                                                    )}
                                                                    size={1.5}
                                                                    color={row.color}
                                                                />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            style={{ paddingLeft: theme.spacing.unit * 6 }}
                                                            primary={_.get(row, `name.${languageToDisplay}`, '')}
                                                            primaryTypographyProps={{
                                                                'data-cy': `subcommodity-${i}-name`
                                                            }}
                                                            secondary={`x${_.get(row, 'multiplier', '')}`}
                                                            secondaryTypographyProps={{
                                                                'data-cy': `subcommodity-${i}-multiplier`
                                                            }}
                                                        />
                                                    </div>
                                                    <ListItemSecondaryAction
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '40%'
                                                        }}
                                                    >
                                                        <ListItemText
                                                            style={{ marginLeft: theme.spacing.unit / 2 }}
                                                            primary={_.get(row, `units.${languageToDisplay}`, '')}
                                                            primaryTypographyProps={{
                                                                'data-cy': `subcommodity-${i}-unit`
                                                            }}
                                                        />
                                                        {!editDisabled && (
                                                            <IconButton
                                                                data-cy={`subcommodity-${i}-edit`}
                                                                onClick={() => handleEditOpen(i)}
                                                            >
                                                                <Icon>edit</Icon>
                                                            </IconButton>
                                                        )}

                                                        {!editDisabled && (
                                                            <IconButton
                                                                data-cy={`subcommodity-${i}-delete`}
                                                                onClick={() => handleRemove(i)}
                                                            >
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                <div style={{ width: 0 }}>{provided.placeholder}</div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {!editDisabled && (
                    <>
                        <Divider />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                            onClick={handleCreateOpen}
                        >
                            <Icon>add_circle</Icon> Add
                        </Button>
                    </>
                )}
            </List>
            {formOpen && (
                <SubCommoditiesForm
                    allFees={allFees}
                    allIcons={allIcons}
                    allIconNames={allIconNames}
                    commodity={commodity}
                    values={_.get(subCommodities, `[${indexToEdit}]`, null)}
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                    onSubmit={handleAddEdit}
                    theme={theme}
                />
            )}
        </>
    );
}

function SubCommoditiesForm({ allFees, allIcons, allIconNames, commodity, values, open, onClose, onSubmit, theme }) {
    const commodityIconName = _.get(commodity, 'iconName', 'recycle');
    const commodityColor = _.get(commodity, 'color', '#000000');

    const formik = useFormik({
        initialValues: {
            _id: _.get(values, '_id'),
            name: _.get(values, 'name', ''),
            multiplier: _.get(values, 'multiplier', 1),
            color: _.get(values, 'color', commodityColor),
            iconName: _.get(values, 'iconName', commodityIconName),
            fee: _.get(values, 'fee', null), //pre-applied fee
            feeSingle: _.get(values, 'feeSingle', _.get(values, 'fee', null)), //pre-applied fee
            feeMultiple: _.get(values, 'feeMultiple', _.get(values, 'fee', null)), //pre-applied fee
            showOnMap: _.get(values, 'showOnMap', false),
            units: _.get(values, 'units', ''),
            feeApply: _.get(values, 'feeApply', 'Once') //pre-applied fee -> apply once or per item
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a name for this sub-commodity'),
            multiplier: Yup.number().min(0, 'This must be greater than or equal to 0'),
            color: Yup.string().required('You must enter a color'),
            iconName: Yup.string().oneOf(allIconNames, 'Invalid icon name'),
            units: Yup.string().required('You must enter a unit of measure')
        }),
        onSubmit
    });

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue('color', e.target.value);
            }
        }, 100)
    );

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik);
    };

    const handleFeeChange = e => {
        const fee = e.target.value;

        if (fee === 'none') {
            formik.setFieldValue(e.target.name, null);
            return;
        }

        formik.setFieldValue(e.target.name, fee);
        if (e.target.name === 'fee') {
            formik.setFieldValue('feeSingle', fee);
            formik.setFieldValue('feeMultiple', fee);
        }
        if (e.target.name === 'feeSingle' && _.isNil(formik.values.feeMultiple)) {
            formik.setFieldValue('feeMultiple', fee);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogTitle style={{ padding: 0 }}>Edit Sub-Commodity</DialogTitle>
                <LocalizedTextInput
                    label="Name"
                    value={_.get(formik.values, 'name')}
                    onChange={value => formik.setFieldValue('name', value)}
                    style={{ marginTop: theme.spacing.unit * 2 }}
                />
                <LocalizedTextInput
                    label="Unit of Measure"
                    value={_.get(formik.values, 'units')}
                    onChange={value => formik.setFieldValue('units', value)}
                    style={{ marginTop: theme.spacing.unit * 2 }}
                />
                {getTextInput(
                    theme,
                    'multiplier',
                    'Equivalent Bags',
                    formik,
                    'number',
                    {},
                    {},
                    '3 means 1 unit/item is the same as 3 large garbage bags'
                )}
                <div style={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing.unit }}>
                    <Avatar
                        style={{
                            backgroundColor: 'rgba(0,0,0,0)',
                            marginRight: theme.spacing.unit,
                            marginTop: theme.spacing.unit * 1.5
                        }}
                    >
                        <MDIcon
                            path={_.get(
                                allIcons,
                                convertToImportName(_.get(formik.values, 'iconName', commodityIconName))
                            )}
                            size={1.5}
                            color={_.get(formik.values, 'color', commodityColor)}
                        />
                    </Avatar>
                    {getTextInput(theme, 'iconName', 'Icon', formik)}
                </div>
                <div style={{ textAlign: 'right' }}>
                    <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                </div>
                <TextField
                    type="color"
                    onChange={handleChangeColorWrapper}
                    value={formik.values.color}
                    onBlur={formik.handleBlur}
                    name="color"
                    error={formik.touched.color && formik.errors.color ? true : null}
                    label="Color"
                    margin="normal"
                    variant="outlined"
                    helperText={formik.touched.color && formik.errors.color ? formik.errors.color : null}
                    fullWidth
                />
                <div style={{ display: 'flex' }}>
                    {formik.values.feeApply === 'Once' &&
                        getSelect(
                            theme,
                            'fee',
                            'Fee',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description'
                        )}
                    {formik.values.feeApply === 'Per Item' &&
                        getSelect(
                            theme,
                            'feeSingle',
                            'Fee (Single Unit)',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description'
                        )}
                    {formik.values.feeApply === 'Per Item' &&
                        getSelect(
                            theme,
                            'feeMultiple',
                            'Fee (Multiple Units)',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description',
                            false,
                            false,
                            { marginLeft: theme.spacing.unit }
                        )}
                    {formik.values.feeApply === 'Once' && (
                        <Grow in={!_.isNil(formik.values.fee)} style={{ marginLeft: theme.spacing.unit }}>
                            {getSelect(
                                theme,
                                'feeApply',
                                'Apply',
                                ['Once', 'Per Item'],
                                formik,
                                null,
                                null,
                                null,
                                false,
                                _.isNil(formik.values.fee)
                            )}
                        </Grow>
                    )}
                </div>
                {formik.values.feeApply === 'Per Item' && (
                    <div style={{ display: 'flex' }}>
                        <Grow in={!_.isNil(formik.values.fee)}>
                            {getSelect(
                                theme,
                                'feeApply',
                                'Apply',
                                ['Once', 'Per Item'],
                                formik,
                                null,
                                null,
                                null,
                                false,
                                _.isNil(formik.values.fee)
                            )}
                        </Grow>
                    </div>
                )}
                {getCheckbox(theme, 'showOnMap', 'Show On Map', formik)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button data-cy="edit-sub-commodity-ok" onClick={formik.handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(SubCommoditiesList);

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
