import React, { useState } from 'react';
import _ from 'lodash';

import {
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Icon,
    Avatar,
    Divider,
    colors,
    withTheme,
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    FormGroup,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import { useFormik } from 'formik';

import { AVAILABLE_LANGS } from '../../../constants';

import * as Yup from 'yup';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useContext } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';

function EligibleExamplesList({ eligibleExamples = [], editDisabled, onChange, theme, headerText }) {
    const [formOpen, setFormOpen] = useState(false);
    const [indexToEdit, setIndexToEdit] = useState(null);
    const [selectedLang, setSelectedLang] = useState('en');

    const { lang } = useContext(LocalizationContext);

    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

    const handleRemove = i => {
        let newEligibleExamples = _.cloneDeep(eligibleExamples);
        newEligibleExamples.splice(i, 1);
        onChange(_.orderBy(newEligibleExamples, ['accepted'], ['desc']));
    };

    const handleAddEdit = values => {
        if (_.isNil(indexToEdit)) {
            const newEligibleExamples = [...eligibleExamples, values];
            onChange(_.orderBy(newEligibleExamples, ['accepted'], ['desc']));
        } else {
            let newEligibleExamples = _.cloneDeep(eligibleExamples);
            newEligibleExamples[indexToEdit] = values;
            onChange(_.orderBy(newEligibleExamples, ['accepted'], ['desc']));
        }
        setFormOpen(false);
    };

    const handleEditOpen = index => {
        setFormOpen(true);
        setIndexToEdit(index);
    };

    const handleCreateOpen = index => {
        setFormOpen(true);
        setIndexToEdit(null);
    };

    const languageToDisplay = editDisabled ? lang : selectedLang;

    return (
        <>
            {!_.isNil(headerText) && !_.isEmpty(headerText) && (
                <Typography variant="body2" style={{ marginBottom: theme.spacing.unit }}>
                    {headerText}
                </Typography>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ display: 'inline-flex', marginBottom: theme.spacing.unit }}>
                    <Typography variant="caption">{loc('accepted', lang)}</Typography>
                    <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 4 }}>
                        {loc('description', languageToDisplay)}
                    </Typography>
                </span>
                {!editDisabled && (
                    <span>
                        {' '}
                        <ToggleButtonGroup
                            value={selectedLang}
                            exclusive
                            onChange={(e, lang) => !_.isEmpty(lang) && setSelectedLang(lang)}
                            style={{ marginBottom: theme.spacing.unit, marginRight: theme.spacing.unit }}
                        >
                            {availableLangs.map(lang => (
                                <ToggleButton key={lang} value={lang}>
                                    {lang}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </span>
                )}
            </div>
            <List>
                {eligibleExamples.map((row, index) => (
                    <span key={row.desc}>
                        <Divider />
                        <ListItem style={{ paddingLeft: 0 }}>
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                    {row.accepted ? (
                                        <Icon style={{ color: colors.green[500] }}>check</Icon>
                                    ) : (
                                        <Icon style={{ color: colors.red[500] }}>close</Icon>
                                    )}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                style={{ paddingLeft: theme.spacing.unit * 6 }}
                                primary={_.get(row, `desc.${languageToDisplay}`, '')}
                                secondary={_.get(row, `secondary.${languageToDisplay}`, '')}
                            />
                            {!editDisabled && (
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => handleEditOpen(index)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton onClick={() => handleRemove(index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    </span>
                ))}
                {!editDisabled && (
                    <>
                        <Divider />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                            onClick={handleCreateOpen}
                        >
                            <Icon>add_circle</Icon> Add
                        </Button>
                    </>
                )}
            </List>
            {formOpen && (
                <EligibleExampleForm
                    values={_.get(eligibleExamples, `[${indexToEdit}]`, null)}
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                    onSubmit={handleAddEdit}
                    theme={theme}
                />
            )}
        </>
    );
}

function EligibleExampleForm({ values, open, onClose, onSubmit, theme }) {
    const formik = useFormik({
        initialValues: {
            desc: _.get(values, 'desc', ''),
            secondary: _.get(values, 'secondary', ''),
            accepted: _.get(values, 'accepted', false)
        },
        validationSchema: Yup.object({
            desc: Yup.string().required('You must enter a value'),
            secondary: Yup.string(),
            accepted: Yup.boolean()
        }),
        onSubmit
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <LocalizedTextInput
                    label="Primary text"
                    value={_.get(formik.values, 'desc')}
                    onChange={value => formik.setFieldValue('desc', value)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                />
                <LocalizedTextInput
                    label="Secondary text"
                    value={_.get(formik.values, 'secondary')}
                    onChange={value => formik.setFieldValue('secondary', value)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                name="accepted"
                                color="primary"
                                onChange={formik.handleChange}
                                value={_.get(formik.values, 'accepted', false)}
                                checked={_.get(formik.values, 'accepted', false)}
                                onBlur={formik.handleBlur}
                            />
                        }
                        label="Accepted"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(EligibleExamplesList);
