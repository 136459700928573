import React, { useContext, useEffect, useState, useMemo } from 'react';

import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Icon as MDIcon } from '@mdi/react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { withTheme } from '@material-ui/core/styles';
import { truncateBagtag } from 'utils/misc';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { Typography } from '@material-ui/core';

import ImageScroll from 'components/ImageUploads/ImageScroll';

import { unstable_useMediaQuery } from '@material-ui/core/useMediaQuery';
const TAPS_FOR_EDIT_MODE = 3;

function NumberOfBagsInput(props) {
    const {
        theme,
        startAdornmentIcon,
        startAdornmentIconColor,
        startAdornmentImageSrc,
        maxNumber,
        minNumber,
        commodityAmountRemaining,
        enableClerkAll,
        bulkHasNoBags,
        componentName,
        componentLabel,
        componentValue,
        componentHelperText,
        componentError,
        disableEntireInput,
        warning,
        extraAdornments,
        infoAdornment,
        required,
        minimal,
        commodityUnits,
        hasSubCommodity,
        toggleSubCommodityView,
        subPayloadAmount,
        groupedPayloadString,
        subCommoditiesVisible,
        driverView,
        InputProps,
        onBags,
        setAllSubmitClicked,
        allButtonFirst = false,
        updateRemaining = false,
        updateRemainingAmount,
        totalAmount = 0,
        imagePreviews,
        commodity,
        imageRequired,
        onPhotoButton
    } = props;

    const { lang } = useContext(LocalizationContext);
    const isSmallScreen = unstable_useMediaQuery('(max-width: 475px)');

    const [taps, setTaps] = useState(0);
    const [disableKeyboard, setDisableKeyboard] = useState(false);

    const filteredImages = useMemo(() => {
        return _.filter(imagePreviews, url => {
            const lastSlashIndex = url.lastIndexOf('/');
            const fileName = url.substring(lastSlashIndex, url.length);

            const commodityName = _.get(commodity, 'skuType', '');
            const commodityNameFormatted = commodityName.toLowerCase().replace(/\s/g, '');

            if (fileName.includes(commodityNameFormatted)) return true;
            return false;
        });
    }, [imagePreviews, commodity]);

    const imageIcons = useMemo(() => {
        const icons = [];

        _.forEach(filteredImages, url => {
            icons.push({
                name: _.get(commodity, 'iconName', ''),
                color: _.get(commodity, 'color')
            });
        });

        return icons;
    }, [filteredImages, commodity]);

    const updateBags = num => () => {
        let newValue = Math.floor(!_.isNaN(componentValue) ? _.clone(componentValue) : 0) + num;
        if (newValue >= 0) {
            if (updateRemaining) {
                updateRemainingAmount(totalAmount - newValue);
            }
            onBags({ target: { value: parseFloat(newValue), name: componentName } });
        }
    };

    const updateRemainingBagsAndSubmit = () => {
        if (!_.isNil(onBags) && !_.isNil(setAllSubmitClicked) && !_.isNil(commodityAmountRemaining)) {
            if (updateRemaining) {
                onBags({ target: { value: Number(commodityAmountRemaining) + Number(componentValue) } });
                setAllSubmitClicked(true);
                updateRemainingAmount(0);
            } else {
                onBags({ target: { value: commodityAmountRemaining } });
                setAllSubmitClicked(true);
            }
        } else {
            // do nothing if any prop is missing
        }
    };

    const toggleKeyboard = value => {
        setDisableKeyboard({ disableKeyboard: value });
    };

    const handleAllowEditGestureTaps = e => {
        if (taps <= 0) {
            setTimeout(() => {
                taps = 0;
            }, 1000);
        } else if (taps >= TAPS_FOR_EDIT_MODE) {
            toggleKeyboard(false);
        }
        setTaps(taps => taps + 1);
    };

    const handleChange = e => {
        let name = e.target.name;
        let value = e.target.value;
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) return;

        if (parseFloat(value) < 0) {
            value = 0;
        } else if (parseFloat(value) > 0) {
            value = parseFloat(value);
        }

        if (updateRemaining) {
            updateRemainingAmount(totalAmount - value);
        }
        onBags({
            target: {
                value,
                name
            }
        });
    };

    useEffect(() => {
        setDisableKeyboard(_.get(props, 'disableKeyboard', false));
    }, []);

    const helperText = warning ? (
        <span style={{ color: colors.yellow[900] }}>{componentHelperText}</span>
    ) : (
        componentHelperText
    );

    const helperTextLine = (
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ minWidth: theme.spacing.unit * 20 }}>{helperText}</span>
            {hasSubCommodity && (
                <span
                    data-cy={`toggle-pickup-subcommodity-options-${componentName}`}
                    onClick={() => toggleSubCommodityView(componentName)}
                    style={{
                        cursor: 'pointer',
                        color: theme.palette.linkColor,
                        fontWeight: 600,
                        textDecoration: 'underline'
                    }}
                >
                    {!subCommoditiesVisible && !driverView && loc('pickupDialog28a', lang, { commodityUnits })}
                    {!subCommoditiesVisible && driverView && 'Boxes or Bins? Press here'}
                    {subCommoditiesVisible && loc('pickupDialog28b', lang, { commodityUnits })}
                </span>
            )}
            {!hasSubCommodity && <span>{required && <b>{loc('required', lang)}</b>}</span>}
        </span>
    );
    return (
        <TextField
            data-cy={`number-of-bags-input-${componentName}`}
            style={{ width: '100%', marginLeft: minimal ? '40px' : 0 }}
            name={componentName}
            label={componentLabel + (required ? ` (${loc('required', lang)})` : '')}
            value={componentValue}
            error={componentError}
            type="tel"
            variant="outlined"
            disabled={disableEntireInput}
            onChange={handleChange}
            helperText={minimal ? null : helperTextLine}
            inputProps={
                minimal
                    ? {
                          style: {
                              padding: theme.spacing.unit * 1.25,
                              maxWidth: theme.spacing.unit * 2.5,
                              minWidth: theme.spacing.unit * 2.5
                          }
                      }
                    : {
                          style: {
                              maxWidth: theme.spacing.unit * 2.5,
                              minWidth: theme.spacing.unit * 2.5
                          }
                      }
            }
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                readOnly: disableKeyboard,
                endAdornment: (
                    <InputAdornment
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            {extraAdornments}
                            <span
                                style={{
                                    fontSize: '12px',
                                    marginLeft: theme.spacing.unit / 2,
                                    marginTop: theme.spacing.unit / 3,
                                    color: colors.grey[600],
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {groupedPayloadString
                                    ? groupedPayloadString
                                    : subPayloadAmount > 0
                                    ? `+${subPayloadAmount} other`
                                    : ''}
                            </span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {filteredImages.length > 0 && (componentValue > 0 || subPayloadAmount > 0) ? (
                                <ImageScroll
                                    displayImageIdx={-1}
                                    disableDelete
                                    displayImages={filteredImages}
                                    onImageSelect={() => {}}
                                    onImageDelete={() => {}}
                                    imageIcons={imageIcons}
                                    imageIconSize={0.7}
                                    blockStyles={{
                                        minWidth: 32,
                                        minHeight: 32,
                                        maxWidth: 32,
                                        maxHeight: 32,
                                        border: '1px solid black'
                                    }}
                                />
                            ) : (
                                (componentValue > 0 || subPayloadAmount > 0) &&
                                imageRequired && (
                                    <div
                                        style={{
                                            color: 'white',
                                            display: 'flex',
                                            whiteSpace: isSmallScreen ? null : 'wrap',
                                            alignItems: 'center',
                                            padding: theme.spacing.unit * 0.5,
                                            marginRight: theme.spacing.unit,
                                            backgroundColor: colors.red[500],
                                            borderRadius: theme.shape.borderRadius,
                                            maxWidth: isSmallScreen ? 80 : null
                                        }}
                                    >
                                        <Icon style={{ marginRight: theme.spacing.unit, fontSize: 10 }}>cancel</Icon>
                                        <span style={{ fontSize: 12 }}>Picture Required</span>
                                    </div>
                                )
                            )}
                            {(componentValue > 0 || subPayloadAmount > 0) && imageRequired && (
                                <Button
                                    data-cy={`payload-input-image-button-${componentName}`}
                                    variant="outlined"
                                    onClick={() => onPhotoButton(commodity)}
                                    style={{ padding: 0, minWidth: 36, minHeight: 36, maxWidth: 36, maxHeight: 36 }}
                                >
                                    {' '}
                                    <Icon>photo_camera</Icon>
                                </Button>
                            )}
                        </div>
                    </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                        <Button
                            color="secondary"
                            variant="outlined"
                            disabled={disableEntireInput}
                            onClick={updateBags(-1)}
                            style={{
                                height: theme.spacing.unit * (minimal ? 3.5 : 4),
                                minWidth: theme.spacing.unit * (minimal ? 4 : 6),
                                marginRight: theme.spacing.unit,
                                padding: 0
                            }}
                        >
                            <Icon>remove</Icon>
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={disableEntireInput}
                            onClick={updateBags(+1)}
                            style={{
                                height: theme.spacing.unit * (minimal ? 3.5 : 4),
                                minWidth: theme.spacing.unit * (minimal ? 4 : 6),
                                marginRight: theme.spacing.unit,
                                padding: 0
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                        <MDIcon
                            path={startAdornmentIcon}
                            size={1}
                            color={startAdornmentIconColor}
                            onTouchStart={handleAllowEditGestureTaps}
                        />
                    </InputAdornment>
                ),
                ...InputProps
            }}
        />
    );
}

NumberOfBagsInput.defaultProps = {
    enableClerkAll: false
};
export default withTheme()(NumberOfBagsInput);
