import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { TAX_TYPES } from 'constants.js';

import { _commodity } from 'std';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { getSwitch, getTextInput, getSelect } from 'components/CRUDTables/helperFunctions';
import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';

import {
    Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    Typography,
    Checkbox,
    ListItemText,
    colors
} from '@material-ui/core';

function ContainerRateForm(props) {
    const {
        allRates,
        taxGroups,
        ratesPageConfig,
        containerRate, //NOTE: will be null/undefined if creating a new one
        commodities,
        containerSizes,
        open,
        onSubmitRate,
        onClose,
        theme,
        fullScreen
    } = props;

    //only used when creating a new containerRate
    const defaultRates = _.filter(allRates || [], rate => {
        return _.some(rate.rates, { sku: _.get(containerRate, 'sku', 'invalid') });
    });
    const [ratesIdsToAddTo, setRatesIdsToAddTo] = useState(defaultRates.map(rate => rate._id));

    const allSkuTypes = commodities
        .filter(commodity => !_.get(commodity, 'disabled', false))
        .map(commodity => _commodity.getSkuType(commodity));

    const editing = !_.isEmpty(containerRate) && containerRate.countsExist;

    const formik = useFormik({
        initialValues: {
            skuType: _.get(containerRate, 'skuType', 'beverage'),
            sku: _.get(containerRate, 'sku', ''),
            material: _.get(containerRate, 'material', ''),
            description: _.get(containerRate, 'description', ''),
            descriptions: _.get(containerRate, 'descriptions', ''),
            label: _.get(containerRate, 'label', ''),
            countable: _.get(containerRate, 'countable', true),
            hideFromRatesPage: _.get(containerRate, 'hideFromRatesPage', false),
            hideFromTotals: _.get(containerRate, 'hideFromTotals', false),
            value: -_.get(containerRate, 'value', 0),
            tax: _.get(containerRate, 'tax'),
            rate: {
                handling: _.get(containerRate, 'rate.handling', 0)
            },
            weight: _.get(containerRate, 'weight', 0)
        },
        validationSchema: Yup.object({
            skuType: Yup.string().oneOf(allSkuTypes),
            sku: Yup.number()
                .integer('This must be a whole number')
                .required('You must enter a value'),
            material: Yup.string().required('You must enter a value'),
            // description: Yup.string().required('You must enter a value'),
            descriptions: Yup.string().required('You must enter a description.'),
            countable: Yup.boolean(),
            hideFromRatesPage: Yup.boolean(),
            hideFromTotals: Yup.boolean(),
            value: Yup.number().min(0, 'This must be a positive number'),
            tax: Yup.string()
                .nullable()
                .required('You must select a tax group'),
            rate: Yup.object({
                handling: Yup.number().min(0, 'This must be a positive number')
            }),
            weight: Yup.number().min(0, 'This must be a positive number')
        }),
        onSubmit: values => {
            let valuesCopy = _.cloneDeep(values);

            valuesCopy.value = -values.value; //flip to negative
            valuesCopy.description = _.get(
                valuesCopy.descriptions,
                process.env.REACT_APP_REGION_LANGUAGE,
                valuesCopy.description || ''
            );

            onSubmitRate({ ratesIdsToAddTo, containerRate: valuesCopy });
        }
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle>{!_.isNil(containerRate) ? 'Update' : 'Create'}</DialogTitle>
            <DialogContent>
                {editing && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing.unit * 2 }}>
                        <Icon style={{ marginRight: theme.spacing.unit, color: colors.red[500] }}>warning</Icon>
                        <Typography style={{ color: 'red' }}>
                            Some fields are not editable because it's in use
                        </Typography>
                    </div>
                )}
                {!editing && (
                    <FormControl fullWidth margin="normal" error={_.isEmpty(ratesIdsToAddTo)}>
                        <InputLabel variant="outlined">Add sku to</InputLabel>
                        <Select
                            input={<OutlinedInput labelWidth={'Add sku to'.length * theme.spacing.unit} />}
                            multiple
                            onChange={e => setRatesIdsToAddTo(e.target.value)}
                            value={ratesIdsToAddTo}
                            renderValue={selectedRatesIds => {
                                const selectedRates = _.filter(allRates, rates => selectedRatesIds.includes(rates._id));
                                return selectedRates.map(rates => rates.name).join(', ');
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        setRatesIdsToAddTo(allRates.map(rate => rate._id));
                                    }}
                                >
                                    All
                                </Button>
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        setRatesIdsToAddTo([]);
                                    }}
                                >
                                    None
                                </Button>
                            </div>
                            {allRates.map(({ _id, name }) => (
                                <MenuItem key={_id} value={_id}>
                                    <Checkbox checked={ratesIdsToAddTo.includes(_id)} />
                                    <ListItemText>{name}</ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        {_.isEmpty(ratesIdsToAddTo) && <FormHelperText>You must select at least one</FormHelperText>}
                    </FormControl>
                )}
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        <FormControl
                            fullWidth
                            error={
                                _.get(formik.touched, 'skuType', false) && _.get(formik.errors, 'skuType', false)
                                    ? true
                                    : null
                            }
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            <InputLabel variant="outlined">Commodity</InputLabel>

                            <Select
                                {...formik.getFieldProps('skuType')}
                                fullWidth
                                input={<OutlinedInput labelWidth={'Commodity'.length * theme.spacing.unit} />}
                            >
                                {allSkuTypes.map(skuType => (
                                    <MenuItem value={skuType} key={skuType}>
                                        {skuType}
                                    </MenuItem>
                                ))}
                            </Select>

                            {_.get(formik.touched, 'skuType', false) && _.get(formik.errors, 'skuType', false) && (
                                <FormHelperText>{_.get(formik.errors, 'skuType', '')}</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            {...formik.getFieldProps('sku')}
                            error={formik.touched.sku && formik.errors.sku ? true : null}
                            label="sku"
                            margin="normal"
                            variant="outlined"
                            helperText={formik.touched.sku && formik.errors.sku ? formik.errors.sku : null}
                            fullWidth
                            type="number"
                            disabled={editing}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        {getTextInput(theme, 'value', 'Value(¢)', formik, 'number', {}, {}, null, editing)}
                    </div>
                    <div style={{ flex: 1 }}>{getTextInput(theme, 'material', 'Material', formik)}</div>
                </div>
                {/* {getTextInput(theme, 'description', 'Description', formik)} */}
                <LocalizedTextInput
                    label="Description Translations"
                    value={_.get(formik, 'values.descriptions')}
                    onChange={value => formik.setFieldValue('descriptions', value)}
                    style={{ marginTop: theme.spacing.unit * 3 }}
                    touched={_.get(formik, 'touched.descriptions')}
                    error={_.get(formik, 'errors.descriptions')}
                />
                <div style={{ display: 'flex', marginTop: theme.spacing.unit * 2 }}>
                    <FormControl
                        error={
                            _.get(formik.touched, 'label', false) && _.get(formik.errors, 'label', false) ? true : null
                        }
                        style={{ flex: 1, marginRight: theme.spacing.unit }}
                    >
                        <InputLabel variant="outlined">Size</InputLabel>

                        <Select
                            {...formik.getFieldProps('label')}
                            fullWidth
                            input={<OutlinedInput labelWidth={'label'.length * theme.spacing.unit} />}
                        >
                            <MenuItem value={''} key={'none'}>
                                <i>None</i>
                            </MenuItem>
                            {containerSizes.map(label => (
                                <MenuItem value={label} key={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>

                        {_.get(formik.touched, 'label', false) && _.get(formik.errors, 'label', false) && (
                            <FormHelperText>{_.get(formik.errors, 'label', '')}</FormHelperText>
                        )}
                    </FormControl>
                    <TextField
                        {...formik.getFieldProps('weight')}
                        style={{ flex: 1 }}
                        error={formik.touched.weight && formik.errors.weight ? true : null}
                        label="Weight (kg)"
                        margin="none"
                        variant="outlined"
                        helperText={formik.touched.weight && formik.errors.weight ? formik.errors.weight : null}
                        fullWidth
                        type="number"
                    />
                </div>
                <div style={{ display: 'flex', marginTop: theme.spacing.unit * 2 }}>
                    {getSwitch(theme, 'countable', 'Show on POR screen', formik, {
                        flex: 1,
                        marginRight: theme.spacing.unit
                    })}
                    {getSwitch(theme, 'hideFromRatesPage', 'Hide from rates page', formik, { flex: 1 })}
                </div>
                {getSwitch(theme, 'hideFromTotals', 'Do Not Include In Total Containers', formik)}
                {getSelect(theme, 'tax', 'Tax Group', taxGroups, formik, null, '_id', 'name', false, editing)}
                {getTextInput(theme, 'rate.handling', 'Handling Fee(¢)', formik, 'number', {}, {}, null, editing)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={!editing && _.isEmpty(ratesIdsToAddTo)}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(ContainerRateForm));
