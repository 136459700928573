import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { _user, _time } from 'std';

import { deviceHelper, getAppBarHeight } from 'utils/misc';
import { TIMEZONES } from 'constants.js';

import ReceiversSelectDropdown from 'components/DropDownSelects/Receivers';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import TripHistoryLists from './TripHistoryLists';
import PickupInfoDialog from 'components/Dialogs/Pickup/InfoDialog';

import {
    Paper,
    Typography,
    InputAdornment,
    Icon,
    IconButton,
    LinearProgress,
    Select,
    MenuItem,
    OutlinedInput,
    FormControl,
    InputLabel,
    Menu
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import OperatorContext from 'utils/contexts/OperatorContext';
import HttpContext from 'utils/contexts/HttpContext';
// hooks
import useDriverComplete from './hooks/useDriverComplete';
import useDateFilteredTrips from './hooks/useDateFilteredTrips';
import useGetMetaData from './hooks/useGetMetaData';
import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';
import useReceiverDropDown from 'components/DropDownSelects/Receivers/hooks/useReceiverDropDown';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';

function DriverComplete(props) {
    const { theme, admin, collectors, processors, receivers, reloadOperator, onSnackbar, collector } = props;

    const history = useHistory();
    const http = useContext(HttpContext);
    const operator = useContext(OperatorContext);

    const [filtersOpen, setFiltersOpen] = useState(null);
    const collapseFilters = useMediaQuery(theme.breakpoints.down('sm'));

    const { commoditiesAvailable } = useGetMetaData({ http });

    const [debug, setDebug] = useState(false);
    const [driverConfig, setDriverConfig] = useState({});

    let hideCustomerIdentification = false;
    if (operator.accountType === 'Collector Administrator' && !operator.adminView) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForAdmin`, false);
    } else if (
        operator.accountType === 'Collector Employee' &&
        !operator.adminView &&
        !operator.accountPermissions.includes('Driver')
    ) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForClerk`, false);
    }

    const handleDebug = e => {
        setDebug(e.target.value);
    };

    const { date: dateFilter, timezone, handleChangeDate, handleChangeTimezone } = useDatePicker({
        saveStateInURL: true,
        timezones: TIMEZONES
    });

    const { dateFilteredTrips, dateFilteredBulks, loading } = useDateFilteredTrips({
        dateFilter,
        operator,
        timezone
    });

    const filteredCollectorsByTrip = filterCollectorsByTrip(
        _user.isSystemAdmin(operator) || _user.isInternalRole(operator) ? collectors : [collector],
        dateFilteredTrips
    );

    const {
        showAll,
        collector: collectorFilter,
        collectorObj,
        handleChange: handleChangeCollector
    } = useCollectorDropDown({
        collectors: filteredCollectorsByTrip,
        stateURLKey: 'collector',
        showAll: _user.isSystemAdmin(operator) || _user.isInternalRole(operator),
        saveStateInURL: true,
        initialCollector: _user.isCollectorAdmin(operator) ? collector : null
    });
    const tripsByCollector = filterTripsByCollector(collectorFilter, dateFilteredTrips);
    const filteredProcessorsByTrip = filterProcessorsByTrip(processors, tripsByCollector);
    const { collector: processorFilter, handleChange: handleChangeProcessor } = useCollectorDropDown({
        collectors: filteredProcessorsByTrip,
        stateURLKey: 'processor',
        showAll: true,
        saveStateInURL: true,
        initialCollector: 'all'
    });
    const tripsByCollectorAndProcessor = filterTripsByProcessor(processorFilter, tripsByCollector);
    const filteredReceiversByTrip = filterReceiversByTrip(receivers, tripsByCollectorAndProcessor);

    const { receiver: receiverFilter, handleChange: handleChangeReceiverFilter } = useReceiverDropDown({
        receivers: filteredReceiversByTrip,
        saveStateInURL: true,
        loading: loading
    });

    const filteredTripsByReceiver = filterTripsByReceiver(receiverFilter, tripsByCollectorAndProcessor);

    // only allow undoing if the date selected is today
    const dateSelectedIsToday = _time.isToday(collector.timezone, dateFilter);

    const {
        pickupSelected,
        pickupDialogOpen,
        pickupsByTrips, // pickupsByDrivers,
        numberOfScheduledPickups,
        handlePickupDialogOpen,
        handleOpenPickupDialog
    } = useDriverComplete({
        dateFilteredBulks,
        dateFilteredTrips: filteredTripsByReceiver,
        receiverFilter
    });

    const handleViewOnMaps = (tripId = null) => {
        const driverURL = `/operators/${_user.getId(operator)}/pickups?trip_id=${tripId}&back=true`;
        const collectorURL = `/operators/${_user.getId(
            operator
        )}/pickups-manager?date=${dateFilter.toISOString()}&timezone=${_.get(
            collectorObj,
            'timezone',
            _.get(operator, 'collector.timezone')
        )}&collector=${collectorFilter}`;

        let URL = _user.isDriver(operator) ? driverURL : collectorURL;

        if (!_.isEmpty(tripId) && !_user.isDriver(operator)) {
            URL += `&trip=${tripId}`;
        }

        if (deviceHelper.isNativeApp()) {
            history.push(URL);
        } else {
            window.open(URL, '_blank');
        }
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const renderFilters = children => {
        if (collapseFilters) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getDriversConfig');
            if (res.ok) {
                setDriverConfig(res.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (timezone === _.get(collectorObj, 'timezone', _.get(operator, 'collector.timezone'))) return;
        handleChangeTimezone({
            target: { value: _.get(collectorObj, 'timezone', _.get(operator, 'collector.timezone')) }
        });
    }, [collectorObj]);

    const isTodayOrFutureDate =
        dateFilter.diff(
            _time.getStartOfToday(_.get(collectorObj, 'timezone', _.get(operator, 'collector.timezone')))
        ) >= 0;
    const dropdownStyle = { marginBottom: theme.spacing.unit, marginRight: theme.spacing.unit, maxWidth: '50%' };

    return (
        <Paper
            style={{
                padding: theme.spacing.unit * 2,
                margin: theme.spacing.unit * 2,
                display: 'flex',
                flexFlow: 'column',
                height: `calc(100% - ${getAppBarHeight(theme.breakpoints) - theme.spacing.unit * 3}px)`
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="h6" gutterBottom>
                    Completed Trips
                </Typography>

                {renderFilters(
                    <>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                            {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                <FormControl>
                                    <InputLabel variant="outlined" htmlFor="receiver-filter">
                                        DEBUG
                                    </InputLabel>
                                    <Select
                                        value={debug}
                                        onChange={handleDebug}
                                        name="showClusterIds"
                                        input={<OutlinedInput labelWidth={54} />}
                                        style={{ ...dropdownStyle, minWidth: 132 }}
                                    >
                                        <MenuItem value={true}>Show</MenuItem>
                                        <MenuItem value={false}>Hide</MenuItem>
                                    </Select>
                                </FormControl>
                            )}

                            {!_user.isCollectorAdmin(operator) && !_user.isDriver(operator) && (
                                <CollectorsSelectDropdown
                                    showAll={showAll}
                                    id="collector-dropdown"
                                    label="Transporter"
                                    value={collectorFilter || ''}
                                    collectors={filteredCollectorsByTrip}
                                    cypress_id="trip-list-collector-dropdown"
                                    onChange={handleChangeCollector}
                                    style={dropdownStyle}
                                />
                            )}
                            <CollectorsSelectDropdown
                                showAll={true}
                                id="processor-dropdown"
                                label="Processor"
                                value={processorFilter || ''}
                                collectors={filteredProcessorsByTrip}
                                cypress_id="trip-list-collector-dropdown"
                                onChange={handleChangeProcessor}
                                style={dropdownStyle}
                            />
                            {!_user.isDriver(operator) && (
                                <ReceiversSelectDropdown
                                    id="receiver-dropdown"
                                    value={receiverFilter || ''}
                                    receivers={filteredReceiversByTrip}
                                    cypress_id="trip-list-receiver-dropdown"
                                    onChange={handleChangeReceiverFilter}
                                    style={dropdownStyle}
                                />
                            )}
                            <DatePicker
                                autoOk
                                disableFuture
                                timezone={_.get(collectorObj, 'timezone', _.get(operator, 'collector.timezone'))}
                                value={dateFilter}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>date_range</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            title="View on maps"
                                            position="end"
                                            style={{ color: theme.palette.envColor[500] }}
                                        >
                                            <IconButton
                                                color="primary"
                                                style={{
                                                    marginLeft: theme.spacing.unit,
                                                    marginTop: theme.spacing.unit / 2
                                                }}
                                                disabled={
                                                    !isTodayOrFutureDate ||
                                                    _.isEmpty(pickupsByTrips) ||
                                                    _user.isDriver(operator)
                                                }
                                                onClick={handleViewOnMaps}
                                            >
                                                <Icon>map</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleChangeDate}
                            />
                        </div>
                    </>
                )}
                {collapseFilters && (
                    <div style={{ textAlign: 'right' }}>
                        <IconButton onClick={handleFiltersOpen()}>
                            <Icon>filter_list</Icon>
                        </IconButton>
                    </div>
                )}
            </div>

            {_.isEmpty(pickupsByTrips) && !loading && (
                <Typography style={{ marginTop: theme.spacing.unit * 3 }} variant="body2">
                    No pickups on this date.
                </Typography>
            )}
            {loading && <LinearProgress />}
            <TripHistoryLists
                debug={debug}
                operator={operator}
                driverConfig={driverConfig}
                numberOfScheduledPickups={numberOfScheduledPickups}
                isTodayOrFutureDate={isTodayOrFutureDate}
                onViewOnMaps={handleViewOnMaps}
                pickupsByTrips={pickupsByTrips}
                trips={dateFilteredTrips}
                commoditiesAvailable={commoditiesAvailable}
                onOpenPickupDialog={handleOpenPickupDialog}
                hideCustomerIdentification={hideCustomerIdentification}
            />
            <PickupInfoDialog
                debug={debug}
                open={pickupDialogOpen}
                driverConfig={driverConfig}
                allowUndoPickup={dateSelectedIsToday}
                pickup={pickupSelected}
                trip={_.find(dateFilteredTrips, { _id: _.get(pickupSelected, 'trip') })}
                commoditiesAvailable={commoditiesAvailable}
                onClose={() => handlePickupDialogOpen(false)}
                reloadOperator={reloadOperator}
                onSnackbar={onSnackbar}
                hideCustomerIdentification={hideCustomerIdentification}
                http={http}
            />
        </Paper>
    );
}

export default withTheme()(DriverComplete);

function filterReceiversByTrip(receivers, trips) {
    return receivers.filter(function(receiver) {
        for (const trip of trips) if (_.get(trip, 'transporter._id') === _.get(receiver, '_id')) return true;
        return false;
    });
}

function filterCollectorsByTrip(collectors, trips) {
    return collectors.filter(function(collector) {
        for (const trip of trips) if (_.get(trip, 'transporterCollector._id') === _.get(collector, '_id')) return true;
        return false;
    });
}
function filterProcessorsByTrip(collectors, trips) {
    return collectors.filter(function(collector) {
        for (const trip of trips) if (_.get(trip, 'collector._id') === _.get(collector, '_id')) return true;
        return false;
    });
}

function filterTripsByCollector(collector, trips) {
    if (_.isNil(collector) || collector === 'all') return trips;

    return trips.filter(function(trip) {
        if (_.get(trip, 'transporterCollector._id', _.get(trip, 'collector._id')) === collector) return true;
        return false;
    });
}

function filterTripsByProcessor(collector, trips) {
    if (_.isNil(collector) || collector === 'all') return trips;

    return trips.filter(function(trip) {
        if (_.get(trip, 'collector._id') === collector) return true;
        return false;
    });
}

function filterTripsByReceiver(receiver, trips) {
    if (_.isNil(receiver) || receiver === 'all') return trips;

    return trips.filter(function(trip) {
        if (_.get(trip, 'transporter._id') === receiver) return true;
        return false;
    });
}
