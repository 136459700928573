import React, { Component } from 'react';
import SwipeableViews from 'resources/temp_packages/SwipeableViews';
import { autoPlay } from 'react-swipeable-views-utils';

import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Chip, Icon } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class FeaturedCharityWidget extends Component {
    static contextType = LocalizationContext;
    state = {
        charityIndex: 0
    };

    handleCharityIndex = charityIndex => {
        this.setState({ charityIndex });
    };

    render() {
        const { theme, elevated, charities, customer, onCharityShareDialogOpen, shareDialogEnabled } = this.props;
        const { charityIndex } = this.state;

        const orgs = charities.map(charity => (
            <List key={charity._id}>
                <ListItem
                    disableGutters
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                >
                    <img
                        src={charity.logo}
                        alt="Charity logo"
                        style={{ width: 32 * 2, maxHeight: 32, objectFit: 'contain' }}
                    />
                    <ListItemText
                        primary={charity.name}
                        secondary={`Featured ${terms.ORGANIZATION_NAME.toLowerCase()}`}
                        primaryTypographyProps={{ noWrap: true, style: { lineHeight: 1.25 } }}
                        secondaryTypographyProps={{ noWrap: true, style: { lineHeight: 1.25 } }}
                        style={{ whiteSpace: 'normal' }}
                    />
                </ListItem>
            </List>
        ));

        if (_.isEmpty(charities)) {
            return null;
        }

        return (
            <Paper
                elevation={elevated ? 4 : 2}
                style={{ margin: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit / 2 }}
            >
                {!_.isEmpty(charities) && (
                    <>
                        <AutoPlaySwipeableViews
                            index={charityIndex}
                            ignoreNativeScroll
                            interval={5000}
                            style={{
                                paddingLeft: theme.spacing.unit * 2,
                                paddingRight: 0,
                                paddingTop: theme.spacing.unit,
                                paddingBottom: 0
                            }}
                            onChangeIndex={this.handleCharityIndex}
                        >
                            {orgs}
                        </AutoPlaySwipeableViews>
                        <div style={{ display: 'flex' }}>
                            <Button
                                data-cy="featured-charity-widget-donate-button"
                                variant="text"
                                color="primary"
                                style={{ marginLeft: 'auto', marginRight: theme.spacing.unit }}
                                onClick={this.props.onCharityInfoDialog(true, charities[charityIndex])}
                            >
                                {loc('dashboardUpdate1', this.context.lang)}
                            </Button>
                        </div>{' '}
                    </>
                )}
                {!_.isNil(customer.charities) &&
                    !_.isEmpty(customer.charities) &&
                    shareDialogEnabled &&
                    customer.charities.map(charity => {
                        return (
                            <Chip
                                label={
                                    <span style={{ color: 'white' }}>
                                        {loc('labels6', this.context.lang, { charity: charity.name })}
                                    </span>
                                }
                                icon={<Icon style={{ color: 'white' }}>share</Icon>}
                                style={{ backgroundColor: colors.lightBlue[500], margin: theme.spacing.unit }}
                                onClick={onCharityShareDialogOpen(charity.name, charity.url)}
                            />
                        );
                    })}
            </Paper>
        );
    }
}

export default withTheme()(FeaturedCharityWidget);
