import React, { useContext } from 'react';
import _ from 'lodash';

import { validate } from 'utils/validate';

import GPSSelectMap from 'components/GPSSelectMap/GPSSelectMap';

import { Hidden, Paper, Icon, TextField, Dialog, Button, Typography, DialogTitle, IconButton } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { loc } from 'localizations/localizationHandler';

function SelectLocation(props) {
    const {
        theme,
        admin,
        pickupForm,
        manualGPSEdit,
        mapLocationSelected,
        defaultCoordinates,
        setManualGPSEdit,
        setMapLocationSelected,
        setMapLocationSelection,
        handleConfirm,
        handleManualGPSOverride,
        handleMapCoordinateChange
    } = props;
    const { lang } = useContext(LocalizationContext);
    const invalidCoordinates =
        mapLocationSelected.lat == '' ||
        mapLocationSelected.lng == '' ||
        validate(['latCoordinate'], mapLocationSelected.lat, lang).fail ||
        validate(['lngCoordinate'], mapLocationSelected.lng, lang).fail;

    const handleEndMapSelection = nextStep => {
        if (!nextStep) {
            setMapLocationSelection(false);
            return;
        }

        if (admin) {
            handleManualGPSOverride(mapLocationSelected);
            setMapLocationSelection(false);
        } else {
            handleConfirm(mapLocationSelected);
        }
    };

    return (
        <Dialog id="map-address-selection" fullScreen open>
            <DialogTitle
                disableTypography
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: `max(${theme.spacing.unit * 1.5}px, env(safe-area-inset-top, ${theme.spacing.unit *
                        1.5}px))`,
                    paddingBottom: theme.spacing.unit * 1.5,
                    background: theme.palette.primary.main
                }}
            >
                <div>
                    <Typography variant="body1" style={{ maxWidth: '100%', color: theme.palette.primary.contrastText }}>
                        {loc('locationSelection1', lang)}
                    </Typography>
                    <Typography variant="body2" style={{ maxWidth: '100%', color: theme.palette.primary.contrastText }}>
                        {loc('locationSelection2', lang)}
                    </Typography>
                </div>
                <IconButton
                    style={{ color: theme.palette.primary.contrastText }}
                    onClick={() => setMapLocationSelection(false)}
                    data-cy="map-address-selection-close-button"
                >
                    <Icon>close</Icon>
                </IconButton>
            </DialogTitle>

            <div
                data-cy="gps-select-map-overlay"
                style={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 'calc(50% - 21px)',
                        left: '50%',
                        transform: 'translate(-50%, -50% )',
                        zIndex: 1
                    }}
                >
                    <img
                        alt="select-pickup-location"
                        src="https://app.skipthedepot.com/img/overridered-pin.png"
                        style={{
                            height: 42,
                            width: 32
                        }}
                    />
                </div>
                <GPSSelectMap
                    mapCenter={{
                        lat: parseFloat(_.get(pickupForm, 'location.lat')),
                        lng: parseFloat(_.get(pickupForm, 'location.lng'))
                    }}
                    manualGPSEdit={manualGPSEdit}
                    mapLocationSelected={mapLocationSelected}
                    defaultCoordinates={defaultCoordinates}
                    setManualGPSEdit={setManualGPSEdit}
                    setMapLocationSelected={setMapLocationSelected}
                />
            </div>

            <Hidden smUp>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Paper
                            style={{
                                padding: theme.spacing.unit * 2,
                                paddingTop: theme.spacing.unit * 1.5
                            }}
                        >
                            <TextField
                                disabled
                                fullWidth
                                variant="outlined"
                                name="address"
                                label={loc('locationSelection3', lang)}
                                value={_.get(pickupForm, 'location.description') || loc('locationSelection4', lang)}
                                style={{ marginTop: theme.spacing.unit, paddingBottom: theme.spacing.unit }}
                            />
                            {admin && (
                                <div style={{ display: 'flex', paddingTop: theme.spacing.unit }}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        name="lat"
                                        label={'Latitude'}
                                        value={mapLocationSelected.lat || ''}
                                        onChange={handleMapCoordinateChange}
                                        style={{ paddingRight: theme.spacing.unit * 0.75 }}
                                        error={validate(['latCoordinate'], mapLocationSelected.lat, lang).fail}
                                        helperText={validate(['latCoordinate'], mapLocationSelected.lat, lang).reason}
                                    />
                                    <TextField
                                        type="number"
                                        fullWidth
                                        label={'Longitude'}
                                        variant="outlined"
                                        value={mapLocationSelected.lng || ''}
                                        name="lng"
                                        onChange={handleMapCoordinateChange}
                                        style={{ paddingleft: theme.spacing.unit * 0.75 }}
                                        error={validate(['lngCoordinate'], mapLocationSelected.lng, lang).fail}
                                        helperText={validate(['lngCoordinate'], mapLocationSelected.lng, lang).reason}
                                    />
                                </div>
                            )}

                            <Button
                                data-cy="manual-address-select-accept"
                                variant="contained"
                                fullWidth
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginTop: theme.spacing.unit * 1,
                                    background: theme.palette.common.black,
                                    color: theme.palette.common.white,
                                    opacity: invalidCoordinates ? 0.5 : 1
                                }}
                                onClick={() => handleEndMapSelection(true)}
                                disabled={invalidCoordinates}
                            >
                                {loc('confirm', lang)}
                            </Button>

                            <Button
                                data-cy="manual-address-select-decline"
                                variant="outlined"
                                fullWidth
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginTop: theme.spacing.unit * 1
                                }}
                                onClick={() => handleEndMapSelection(false)}
                            >
                                {loc('cancel', lang)}
                            </Button>
                        </Paper>
                    </div>
                </div>
            </Hidden>
            <Hidden xsDown>
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        bottom: theme.spacing.unit,
                        zIndex: 1,
                        maxWidth: '450px'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: theme.spacing.unit * 1.5 }}>
                        <Paper
                            style={{
                                padding: theme.spacing.unit * 2,
                                paddingTop: theme.spacing.unit * 1.5
                            }}
                        >
                            <TextField
                                disabled
                                fullWidth
                                variant="outlined"
                                name="address"
                                label={loc('locationSelection3', lang)}
                                value={_.get(pickupForm, 'location.description') || 'None Selected'}
                                style={{ marginTop: theme.spacing.unit, paddingBottom: theme.spacing.unit }}
                            />
                            {admin && (
                                <div style={{ display: 'flex', paddingTop: theme.spacing.unit }}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        name="lat"
                                        label={'Latitude'}
                                        value={mapLocationSelected.lat || ''}
                                        onChange={handleMapCoordinateChange}
                                        style={{ paddingRight: theme.spacing.unit * 0.75 }}
                                        error={validate(['latCoordinate'], mapLocationSelected.lat, lang).fail}
                                        helperText={validate(['latCoordinate'], mapLocationSelected.lat, lang).reason}
                                    />
                                    <TextField
                                        type="number"
                                        fullWidth
                                        label={'Longitude'}
                                        variant="outlined"
                                        value={mapLocationSelected.lng || ''}
                                        name="lng"
                                        onChange={handleMapCoordinateChange}
                                        style={{ paddingleft: theme.spacing.unit * 0.75 }}
                                        error={validate(['lngCoordinate'], mapLocationSelected.lng, lang).fail}
                                        helperText={validate(['lngCoordinate'], mapLocationSelected.lng, lang).reason}
                                    />
                                </div>
                            )}
                            <Button
                                data-cy="manual-address-select-accept"
                                variant="contained"
                                fullWidth
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginTop: theme.spacing.unit * 1,
                                    background: theme.palette.common.black,
                                    color: theme.palette.common.white,
                                    opacity: invalidCoordinates ? 0.5 : 1
                                }}
                                onClick={() => handleEndMapSelection(true)}
                                disabled={invalidCoordinates}
                            >
                                {loc('confirm', lang)}
                            </Button>

                            <Button
                                data-cy="manual-address-select-decline"
                                variant="outlined"
                                fullWidth
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginTop: theme.spacing.unit * 1
                                }}
                                onClick={() => handleEndMapSelection(false)}
                            >
                                {loc('cancel', lang)}
                            </Button>
                        </Paper>
                    </div>
                </div>
            </Hidden>
        </Dialog>
    );
}

export default withTheme()(SelectLocation);
