import React, { memo } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withTheme } from '@material-ui/core';

function TimezonesDropDownSelect({
    theme,
    fullWidth,
    helperText = 'Timezone',
    id = 'timezone-drop-down-select',
    name = 'timezone',
    disabled = false,
    timezones = [],
    value,
    onChange,
    labelCustomWidth = 72,
    cypressId,
    style,
    showAll = true
}) {
    return (
        <FormControl fullWidth={fullWidth} style={style}>
            <InputLabel htmlFor={id}>{helperText}</InputLabel>
            <Select
                id="timezone-filter"
                data-cy={cypressId}
                value={value}
                onChange={onChange}
                disabled={disabled || !(timezones.length > 1)}
                inputProps={{
                    name: name,
                    id: id
                }}
                // TODO: make possible to have both variabts
                // input={<OutlinedInput labelWidth={labelCustomWidth} name={name} id={id} />}
                style={{
                    minWidth: 132
                }}
            >
                {/* {showAll && timezones.length !== 1 && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )} */}
                {timezones.map(tz => {
                    return (
                        <MenuItem value={tz} key={tz}>
                            <em>{tz}</em>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default memo(withTheme()(TimezonesDropDownSelect));
