import React, { useState } from 'react';

import _ from 'lodash';

import { Chip, Typography, Icon, colors, withTheme } from '@material-ui/core';

import { loc, locDate } from 'localizations/localizationHandler';

import { truncateBagtag } from 'utils/misc';

import MDIcon from '@mdi/react';
import { mdiTagArrowDown, mdiLabel } from '@mdi/js';
import { getCustomerName } from '../../../utils/misc';

function Bagtags({ customer, bagtags, sessions, collector, lang, theme, showEmptyBagTag = true }) {
    const customerId = customer.uniqueID;
    const sessionsWithValidLabels = _.filter(sessions, session => session.bagtag && session.bagtag != customerId);

    const allUniqueBagTags = _.uniqBy(bagtags, 'bagtag');

    if (!_.isEmpty(sessionsWithValidLabels)) {
        return (
            <ShowHideBagTagList theme={theme} lang={lang} bagTagObj={sessionsWithValidLabels} collector={collector} />
        );
    } else {
        return (
            <ShowHideBagTagList
                theme={theme}
                lang={lang}
                bagTagObj={allUniqueBagTags}
                customerId={customerId}
                showEmptyBagTag={showEmptyBagTag}
                collector={collector}
            />
        );
    }
}

export default withTheme()(Bagtags);

function ShowHideBagTagList({ theme, lang, bagTagObj, customerId, showEmptyBagTag, collector }) {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            {bagTagObj.map((obj, i) => {
                if (!showMore && i > 0) {
                    return <></>;
                }

                if (!showEmptyBagTag) {
                    if (_.isEmpty(obj.bagtag)) {
                        return <></>;
                    }
                }

                var isCustomerLabel = truncateBagtag(obj.bagtag) === customerId;

                if (isCustomerLabel) {
                    return (
                        <Chip
                            icon={<MDIcon path={mdiLabel} size={1} color={theme.palette.text.secondary} />}
                            style={{
                                height: 'auto',
                                padding: theme.spacing.unit / 2,
                                margin: theme.spacing.unit / 2
                            }}
                            label={truncateBagtag(obj.bagtag)}
                        />
                    );
                }

                return (
                    <Chip
                        icon={<MDIcon path={mdiTagArrowDown} size={1} color={theme.palette.text.secondary} />}
                        style={{
                            height: 'auto',
                            padding: theme.spacing.unit / 2,
                            marginBottom: theme.spacing.unit / 2,
                            marginRight: 3
                        }}
                        label={
                            <BagTagWithDate
                                theme={theme}
                                lang={lang}
                                bagTagObj={obj}
                                isCustomerLabel={isCustomerLabel}
                                collector={collector}
                            />
                        }
                    />
                );
            })}

            {bagTagObj.length > 1 && !showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(true)}
                >
                    {loc('showAll', lang)}
                </Typography>
            )}
            {bagTagObj.length > 1 && showMore && (
                <Typography
                    style={{
                        textAlign: 'right',
                        display: 'block',
                        color: theme.palette.primary.main,
                        cursor: 'pointer'
                    }}
                    onClick={() => setShowMore(false)}
                >
                    {loc('showLess', lang)}
                </Typography>
            )}
        </>
    );
}

function BagTagWithDate({ theme, lang, bagTagObj, collector }) {
    const { bagtag, dateCounted, operator } = bagTagObj;
    const counterName = getCustomerName(operator);
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <span style={{ margin: theme.spacing.unit * 0.25 }}>{truncateBagtag(bagtag)}</span>
                {dateCounted && (
                    <span style={{ margin: theme.spacing.unit * 0.25, color: colors.green[500] }}>
                        <Icon style={{ fontSize: '15px' }}>check</Icon>
                    </span>
                )}

                {/*dateCounted && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: theme.spacing.unit / 2
                    }}
                >
                    <MDIcon path={mdiTagArrowDown} size={0.8} color={theme.palette.text.secondary} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span
                            style={{
                                display: 'block',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                marginRight: theme.spacing.unit / 4
                            }}
                        >
                            {loc('processedOn', lang)}:
                        </span>
                        <span style={{ display: 'block', fontSize: '12px' }}>
                            {locDate(dateCounted, 'MMMM D, YYYY hh:mm A', lang)}
                        </span>
                        {!_.isNil(collector) && <span style={{ fontSize: '11px' }}>{_.get(collector, 'name')}</span>}
                        {!_.isEmpty(counterName) && <span style={{ fontSize: '11px' }}>{counterName}</span>}
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '11px'
                }}
            >
                <span style={{ display: 'block' }}>{truncateBagtag(bagtag)}</span>
            </div>*/}
            </div>
            {dateCounted && (
                <span style={{ margin: theme.spacing.unit * 0.25 }}>
                    {locDate(dateCounted, 'MMM D, YYYY hh:mm A', lang)}
                </span>
            )}
        </div>
    );
}
