import React, { useState, useEffect, useMemo, useContext } from 'react';

import _ from 'lodash';
import { _user, _pickup } from 'std';
import moment from 'moment-timezone';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import * as colors from '@material-ui/core/colors';
import {
    Divider,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Avatar,
    Checkbox,
    CircularProgress,
    Button,
    Tooltip,
    IconButton,
    Icon,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Grid,
    Switch,
    FormControlLabel,
    Badge
} from '@material-ui/core';
import {
    mdiRefresh,
    mdiClose,
    mdiFilterVariant,
    mdiMapMarker,
    mdiMagnify,
    mdiContentSave,
    mdiUndo,
    mdiCrosshairsQuestion,
    mdiMapMarkerOff,
    mdiMapMarkerQuestion
} from '@mdi/js';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { withTheme } from '@material-ui/core/styles';

import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';

import HttpContext from 'utils/contexts/HttpContext';
import { getReceiverName, getPickupDisplayedInfo, formatAsAddress, convertSecondsToMinutes } from 'utils/misc';
import { MODIFIERS } from './constants';
import { RESIDENTIAL, COMMERCIAL, CONDO, BOTTLE_DRIVE } from 'constants.js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { useRef } from 'react';

function DrawerContent(props) {
    const {
        theme,
        isSystemAdmin,
        isCollectorAdmin,
        trips,
        pickups,
        pickupsHidden,
        triplessPickups,
        pickupSelected,
        pickupIdsChecked,
        commoditiesAvailable,
        onPickupClick,
        onCheckPickup,
        onCheckPickups,
        setAssignDialogOpen,
        onBulkRescheduleDialog,
        onCreateStopDialog,
        onScheduleUnservicedDialog,
        onTripToCenter,
        handlePinClick,
        selectedCollector,
        showAllPickups,
        recalculatingTrips,
        handleRecalc,
        setPickupDrawerOpen,
        filters,
        handleFilters,
        handleFiltersCheckbox,
        onSnackbar,
        onForceRecalculation,
        sendETANotifications,
        unselectEverything,
        draggablePickupsSelected
    } = props;
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [groupedPickups, setGroupedPickups] = useState([]); // Allows for indexing of drag/drop pickups
    const [pendingSave, setPendingSave] = useState({});
    const [manualOrdering, setManualOrdering] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);

    const ctrlPressedRef = useRef(false);
    const shiftPressedRef = useRef(false);
    const colorKeys = ['pink', 'deepOrange', 'amber', 'lightGreen', 'teal', 'blue', 'indigo', 'purple'];
    const uniqueMasterClusterIds = _.uniq(
        _.filter(pickups, p => !_.isNil(p.master_zone_vid)).map(pickup => pickup.master_zone_vid)
    ).sort();
    const generateAddressForPickup = pickup => {
        let address = formatAsAddress(pickup.location);

        if (pickup.timeSlotBooking || pickup.manualTimeOverride) {
            const formattedTime = moment(pickup.date)
                .tz(_.get(pickup, 'collector.timezone', process.env.REACT_APP_REGION_TIMEZONE))
                .format('h:mm a');

            address += ` (${formattedTime})`;
        }

        return address;
    };
    const handleKeyDown = event => {
        if (event.code === 'ControlLeft' || event.code === 'ControlRight') {
            ctrlPressedRef.current = true;
        } else if (event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
            shiftPressedRef.current = true;
        }
    };

    const handleKeyUp = event => {
        if (event.code === 'ControlLeft' || event.code === 'ControlRight') {
            ctrlPressedRef.current = false;
        } else if (event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
            shiftPressedRef.current = false;
        }
    };

    const handlePickupClick = pickup => props => {
        if (_.isNil(onPickupClick)) {
            return;
        }

        onPickupClick(
            pickup,
            shiftPressedRef.current,
            manualOrdering[_.get(pickup, 'trip._id', pickup.trip)] && ctrlPressedRef.current
        )(props);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
    }, []);

    const stopIdsChecked = _.uniq(
        pickupIdsChecked.filter(
            id => !_.isNil(_.find(pickups, p => p._id.toString() === id && _pickup.isOutOfSystemStop(p)))
        )
    );

    const renderLunch = lunchBreakDuration => {
        return (
            <div
                style={{
                    margin: theme.spacing.unit * 2,
                    textAlign: 'center'
                }}
            >
                <Divider
                    style={{
                        background: colors.grey[500]
                    }}
                />
                <Typography variant="caption" style={{ color: colors.grey[500] }}>
                    {convertSecondsToMinutes(lunchBreakDuration, 0)} minute lunch
                </Typography>
                <Divider
                    style={{
                        background: colors.grey[500]
                    }}
                />
            </div>
        );
    };

    const renderPickup = (pickup, isDragging, isDraggingOver) => {
        const numberOfPickupsDragged =
            draggablePickupsSelected.length + (draggablePickupsSelected.includes(pickup._id) ? 0 : 1);
        const dragSelect =
            !_.isEmpty(draggablePickupsSelected) && (isDragging || draggablePickupsSelected.includes(pickup._id));

        let color = colors.lightGreen[500];
        if (!pickup.confirmed) {
            color = colors.orange[500];
        }
        if (pickup.skipped) {
            color = colors.yellow[600];
        }
        if (_.isNil(pickup.collector)) {
            color = colors.red[500];
        }
        if (_.isNil(pickup.customer)) {
            if (_.isNil(pickup.stopIconColor) || _.isEmpty(pickup.stopIconColor)) {
                color = colors.grey[700];
            } else if (pickup.stopIconColor === 'black') {
                color = colors.grey[900];
            } else {
                color = colors[pickup.stopIconColor][600];
            }
        }
        if (pickup.wrongDate) {
            color = colors.red[500];
        }

        let zone_vid =
            filters.showClusterIds === 'Master'
                ? _.get(pickup, 'master_zone_vid', null)
                : _.get(pickup, 'zone_vid', null);
        if (typeof zone_vid !== 'string') zone_vid = null;

        return (
            <ListItem
                data-cy={`pickups-admin-trips-${_.get(pickup, 'location.description', '')
                    .replace(/[, ]+/g, '-')
                    .trim()}`}
                key={pickup._id}
                button
                style={{
                    paddingLeft: theme.spacing.unit,
                    paddingRight: 0,
                    paddingTop: 2,
                    paddingBottom: 2,
                    backgroundColor: dragSelect
                        ? colors['blue'][100]
                        : filters.showClusterIds === 'None'
                        ? ''
                        : colors[_.get(pickup, 'clusterColor', 'grey')][100]
                }}
                onClick={handlePickupClick(pickup)}
                disabled={dragSelect && isDraggingOver}
            >
                <ListItemAvatar>
                    {_pickup.isOutOfSystemStop(pickup) ? (
                        <ListItemAvatar>
                            <Avatar
                                style={{
                                    backgroundColor:
                                        filters.showClusterIds === 'None'
                                            ? color
                                            : colors[_.get(pickup, 'clusterColor', 'grey')][500]
                                }}
                            >
                                {pickup.wrongDate ? (
                                    'E'
                                ) : _.isNil(pickup.stopIcon) || _.isEmpty(pickup.stopIcon) ? (
                                    'S'
                                ) : (
                                    <MDIcon
                                        path={_.get(allIcons, convertToImportName(_.get(pickup, 'stopIcon', 'home')))}
                                        color="white"
                                    />
                                )}
                            </Avatar>
                        </ListItemAvatar>
                    ) : (
                        <Avatar
                            style={{
                                backgroundColor:
                                    filters.showClusterIds === 'None'
                                        ? color
                                        : colors[_.get(pickup, 'clusterColor', 'grey')][500]
                            }}
                        >
                            {pickup.wrongDate ? 'E' : _pickup.numberOfPayloadBags(pickup, commoditiesAvailable)}
                        </Avatar>
                    )}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {pickup.numberOfBins > 0 && (
                                <Tooltip title="Bins">
                                    <MDIcon
                                        path={_.get(allIcons, 'mdiDelete')}
                                        size={0.6}
                                        color="grey"
                                        data-cy="admin-ui-bin-indicator"
                                    />
                                </Tooltip>
                            )}
                            <span style={pickup.wrongDate ? { color: colors.red[500] } : {}}>
                                {generateAddressForPickup(pickup)}
                            </span>
                        </div>
                    }
                    secondary={
                        <>
                            <span style={pickup.wrongDate ? { color: colors.red[500] } : {}}>
                                {getPickupDisplayedInfo(pickup, 'Driver')}
                            </span>{' '}
                            <span style={pickup.wrongDate || !pickup.trip ? { color: colors.red[500] } : {}}>
                                {filters.showClusterIds === 'Master' || filters.showClusterIds === 'Trip'
                                    ? `(${zone_vid})`
                                    : pickup.trip
                                    ? `(${_.get(pickup, 'collector.code', 'UNASSIGNED')} · 
                                ${getReceiverName(pickup.receiver)})`
                                    : 'UNROUTED'}

                                {pickup.unrouted && (
                                    <span style={{ color: colors.red[500], marginLeft: theme.spacing.unit }}>
                                        UNROUTED
                                    </span>
                                )}
                            </span>
                        </>
                    }
                    primaryTypographyProps={{ noWrap: true }}
                    style={{
                        paddingLeft: theme.spacing.unit,
                        maxWidth: `calc(100% - ${
                            isSystemAdmin && _pickup.isStreetLocation(pickup) && !_pickup.gpsOverrided(pickup)
                                ? '100px'
                                : '65px'
                        }`
                    }}
                />
                <ListItemSecondaryAction>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {isDragging && numberOfPickupsDragged > 1 && (
                            <Badge
                                badgeContent={draggablePickupsSelected.length + (dragSelect ? 0 : 1)}
                                color="primary"
                            />
                        )}
                        {isSystemAdmin && _pickup.isStreetLocation(pickup) && !_pickup.gpsOverrided(pickup) && (
                            <Tooltip title="Review location">
                                <MDIcon path={mdiMapMarker} color={colors.orange[500]} size={1} />
                            </Tooltip>
                        )}
                        <Checkbox
                            data-cy={`pickups-admin-trips-checkbox-${_.get(pickup, 'location.description', '')
                                .replace(/[, ]+/g, '-')
                                .trim()}`}
                            checked={pickupIdsChecked.includes(pickup._id)}
                            onChange={onCheckPickup(pickup)}
                        />
                    </span>
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    const handleFiltersOpen = open => () => {
        setFiltersOpen(open);
    };

    const handleSearch = trip => () => {
        onTripToCenter(trip);

        const driver = {
            _id: trip.transporter._id,
            name: _user.getNameFull(trip.transporter),
            collector_id: trip.collector._id,
            trip_id: trip._id
        };
        handlePinClick(driver);
    };

    const handleDragEnd = ({ destination, source }) => {
        if (!destination || source.droppableId !== destination.droppableId) return; //Cannot move pickups between trips. Maybe allow this?

        const latestVersion = _.last(groupedPickups);
        const clonedPickups = _.cloneDeep(latestVersion[source.droppableId]);

        if (draggablePickupsSelected.length > 1) {
            let pickupsToMove = _.filter(clonedPickups, p => draggablePickupsSelected.includes(p._id.toString()));
            let pickupDragged = clonedPickups[source.index];
            if (!pickupsToMove.map(pickup => pickup._id.toString()).includes(pickupDragged._id.toString())) {
                pickupsToMove.push(pickupDragged);
            }
            let slicePoint = destination.index > source.index ? destination.index + 1 : destination.index;
            let beforeArray = _.filter(
                clonedPickups.slice(0, slicePoint),
                p => !pickupsToMove.map(pickup => pickup._id.toString()).includes(p._id.toString())
            );
            let afterArray = _.filter(
                clonedPickups.slice(slicePoint, clonedPickups.length),
                p => !pickupsToMove.map(pickup => pickup._id.toString()).includes(p._id.toString())
            );

            setGroupedPickups([
                ...groupedPickups,
                { ...latestVersion, [source.droppableId]: beforeArray.concat(pickupsToMove).concat(afterArray) }
            ]);
            unselectEverything();
        } else {
            const [pickupToMove] = clonedPickups.splice(source.index, 1);
            clonedPickups.splice(destination.index, 0, pickupToMove);

            setGroupedPickups([...groupedPickups, { ...latestVersion, [source.droppableId]: clonedPickups }]);
        }
        if (!pendingSave[source.droppableId]) setPendingSave({ ...pendingSave, [source.droppableId]: true });
    };

    const invalidPickups = useMemo(
        () => triplessPickups.filter(pickup => pickup.complete || _.isNil(pickup.collector)),
        [triplessPickups, showAllPickups, selectedCollector]
    );

    const handleSaveTripChanges = trip_id => async () => {
        const latestVersion = _.last(groupedPickups);
        const pickups = latestVersion[trip_id];
        const res = await http.post('/trips/manualRoute', { trip_id, pickups, sendETANotifications });
        if (res.ok) {
            handleRecalc();
            setPendingSave({ ...pendingSave, [trip_id]: false });
        }
    };

    const handleUndoChange = () => {
        const latestVersion = _.cloneDeep(groupedPickups);
        latestVersion.pop();

        if (latestVersion.length === 1) {
            setPendingSave(_.mapValues(pendingSave, () => false));
        }
        setGroupedPickups(latestVersion);
    };

    const handleToggleManualOrdering = async (checked, trip_id) => {
        const res = await http.post('/trips/toggleManualOrdering', { trip_id, checked });
        if (res.ok) {
            setManualOrdering({ ...manualOrdering, [trip_id]: checked });
            if (!checked) {
                onForceRecalculation(trip_id)();
                handleRecalc();
            }
        } else {
            onSnackbar(`Error ${checked ? 'enabling' : 'disabling'} manual ordering`, 'error');
        }
    };

    const handleBulkDeleteStops = async () => {
        const res = await http.post(`/stops/bulkDelete`, { pickupIds: stopIdsChecked });
        if (res.ok) {
            handleRecalc();
        }
    };

    const unassignedPickups = useMemo(() => {
        const pickups = triplessPickups.filter(pickup => {
            if (_.get(pickup, 'collector._id') !== selectedCollector && !showAllPickups) return false;
            return !_.isNil(pickup.collector);
        });

        return _.groupBy(pickups, 'collector._id');
    }, [triplessPickups, showAllPickups, selectedCollector]);

    const unconfirmedPickups = useMemo(() => pickups.filter(p => !p.confirmed), [pickups]);

    useEffect(() => {
        if (!_.isEmpty(trips)) {
            setGroupedPickups([trips.reduce((obj, trip) => ({ ...obj, [trip._id]: trip.pickups }), {})]);
            setPendingSave(trips.reduce((obj, trip) => ({ ...obj, [trip._id]: false }), {}));
            setManualOrdering(
                trips.reduce((obj, trip) => ({ ...obj, [trip._id]: _.get(trip, 'manualOrdering', false) }), {})
            );
        }
    }, [trips]);

    const pickupInfoStyles = {
        display: 'flex',
        alignItems: 'center'
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div
                    style={{
                        height: isSystemAdmin ? '91vh' : '94vh',
                        padding: theme.spacing.unit,
                        paddingTop: theme.spacing.unit * 2,
                        overflowY: 'scroll'
                    }}
                >
                    {_.isEmpty(pickups) && _.isEmpty(trips) && (
                        <Typography color="textSecondary">No pickups on this date.</Typography>
                    )}
                    {!_.isEmpty(invalidPickups) && (
                        <>
                            <Typography variant="subtitle1" style={{ marginLeft: theme.spacing.unit }}>
                                Invalid Pickups
                            </Typography>
                            <Divider
                                style={{
                                    marginLeft: theme.spacing.unit,
                                    marginRight: theme.spacing.unit,
                                    marginTop: theme.spacing.unit * 0.5,
                                    marginBottom: theme.spacing.unit
                                }}
                            />
                            <List
                                dense
                                disablePadding
                                style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }}
                            >
                                {invalidPickups.map(pickup => {
                                    return (
                                        <ListItem
                                            data-cy={`pickups-admin-trips-${_.get(pickup, 'location.description', '')
                                                .replace(/[, ]+/g, '-')
                                                .trim()}`}
                                            key={pickup._id}
                                            button
                                            style={{
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: 0,
                                                paddingTop: 2,
                                                paddingBottom: 2
                                            }}
                                            onClick={handlePickupClick(pickup)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar style={{ backgroundColor: colors.red[500] }}>
                                                    {_pickup.numberOfPayloadBags(pickup, commoditiesAvailable)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={generateAddressForPickup(pickup)}
                                                secondary={
                                                    <>
                                                        {pickup.complete && (
                                                            <span>Pickup has already been completed</span>
                                                        )}
                                                        {_.isNil(pickup.collector) && (
                                                            <span>Pickup collector value is null</span>
                                                        )}
                                                    </>
                                                    // <>
                                                    //     <span>{getPickupDisplayedInfo(pickup, 'Driver')}</span>{' '}
                                                    //     <span>
                                                    //         ({_.get(pickup, 'collector.code', 'UNASSIGNED')} ·{' '}
                                                    //         {getReceiverName(pickup.receiver)})
                                                    //     </span>
                                                    // </>
                                                }
                                                primaryTypographyProps={{ noWrap: true }}
                                                style={{ paddingLeft: theme.spacing.unit }}
                                            />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    data-cy={`pickups-admin-trips-checkbox-${_.get(
                                                        pickup,
                                                        'location.description',
                                                        ''
                                                    )
                                                        .replace(/[, ]+/g, '-')
                                                        .trim()}`}
                                                    checked={pickupIdsChecked.includes(pickup._id)}
                                                    onChange={onCheckPickup(pickup)}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </>
                    )}
                    {!_.isEmpty(unassignedPickups) &&
                        Object.values(unassignedPickups).map(pickups => {
                            const name = _.first(pickups).collector.code;

                            const allPickupsChecked =
                                !_.isEmpty(pickups) && pickups.every(p => pickupIdsChecked.includes(p._id));

                            return (
                                <React.Fragment key={name}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginLeft: theme.spacing.unit, position: 'relative' }}
                                    >
                                        {name} <span style={{ color: theme.palette.text.secondary }}>(UNASSIGNED)</span>
                                        <Checkbox
                                            data-cy="pickups-admin-trips-checkbox"
                                            checked={allPickupsChecked}
                                            style={{ position: 'absolute', right: 4, top: -10 }}
                                            onChange={onCheckPickups(pickups)}
                                        />
                                    </Typography>
                                    <Divider
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: theme.spacing.unit,
                                            marginTop: theme.spacing.unit * 0.5,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    />
                                    <List dense disablePadding style={{ marginTop: theme.spacing.unit }}>
                                        {pickups.map(pickup => renderPickup(pickup))}
                                    </List>
                                </React.Fragment>
                            );
                        })}

                    <DragDropContext onDragEnd={handleDragEnd}>
                        {trips.map(trip => {
                            if (
                                _.get(
                                    trip,
                                    'transporterCollector._id',
                                    _.get(trip, 'transporter.collector._id', '')
                                ).toString() !== selectedCollector &&
                                !showAllPickups
                            )
                                return null;
                            const timezone = trip.collector.timezone;
                            const latestVersion = _.last(groupedPickups);

                            if (!latestVersion) return null;

                            if (latestVersion[trip._id]) {
                                for (let leg of _.get(trip, 'route.legs', [])) {
                                    if (_.get(leg, 'lunchBreakDuration', 0) > 0) {
                                        let pickup = latestVersion[trip._id].find(
                                            p => p._id.toString() === _.get(leg, 'pickup_id', '').toString()
                                        );
                                        if (!_.isNil(pickup)) {
                                            pickup.lunchBreakDuration = _.get(leg, 'lunchBreakDuration', 0);
                                        }
                                    }
                                }
                                let uniqueClusterIds = _.uniq(latestVersion[trip._id].map(pickup => pickup.zone_vid));
                                latestVersion[trip._id].forEach(pickup => {
                                    pickup.clusterColor =
                                        colorKeys[
                                            _.indexOf(
                                                filters.showClusterIds === 'Master'
                                                    ? uniqueMasterClusterIds
                                                    : uniqueClusterIds,
                                                filters.showClusterIds === 'Master'
                                                    ? pickup.master_zone_vid
                                                    : pickup.zone_vid
                                            ) % 8
                                        ];
                                });
                            }

                            const allPickupsChecked =
                                !_.isEmpty(latestVersion[trip._id]) &&
                                _.every(latestVersion[trip._id], p => pickupIdsChecked.includes(p._id));

                            return (
                                <React.Fragment key={trip._id}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                position: 'relative',
                                                marginLeft: theme.spacing.unit,
                                                flex: 1,
                                                fontSize: '95%'
                                            }}
                                            noWrap
                                        >
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: 'calc(100% - 45px)',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <span data-cy="pickups-admin-trips-transporter-collector-code">
                                                    [
                                                    {_.get(
                                                        trip,
                                                        'transporterCollector.code',
                                                        _.get(trip, 'transporter.collector.code', 'N/A')
                                                    )}
                                                    ]{' '}
                                                </span>
                                                <span data-cy="pickups-admin-trips-reciever-name">
                                                    {getReceiverName(trip.transporter)}{' '}
                                                </span>
                                                <span style={{ color: theme.palette.text.secondary }}>
                                                    {moment(trip.startTime)
                                                        .tz(timezone)
                                                        .format('h:mm A')}
                                                    <CustomTextBadge
                                                        tooltip={
                                                            moment(trip.startTime)
                                                                .tz(timezone)
                                                                .format('h:mm A Z') +
                                                            ' ' +
                                                            timezone
                                                        }
                                                    >
                                                        {moment(trip.startTime)
                                                            .tz(timezone)
                                                            .format('z')}
                                                    </CustomTextBadge>
                                                </span>{' '}
                                                ➡{' '}
                                                <span data-cy="pickups-admin-trips-collector-code">
                                                    {trip.collector.code}
                                                </span>
                                            </div>
                                            <Checkbox
                                                data-cy="pickups-admin-trips-checkbox"
                                                checked={allPickupsChecked}
                                                style={{ position: 'absolute', right: 4, top: -10 }}
                                                onChange={onCheckPickups(latestVersion[trip._id])}
                                            />
                                        </Typography>
                                    </div>
                                    <Divider
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: theme.spacing.unit,
                                            marginTop: theme.spacing.unit * 0.5
                                        }}
                                    />
                                    <List dense disablePadding>
                                        {_.isEmpty(latestVersion[trip._id]) ? (
                                            <Typography
                                                color="textSecondary"
                                                style={{
                                                    paddingLeft: theme.spacing.unit,
                                                    marginTop: theme.spacing.unit * 2
                                                }}
                                            >
                                                No routed pickups.
                                            </Typography>
                                        ) : (
                                            <>
                                                <FormControlLabel
                                                    style={{
                                                        height: 15,
                                                        paddingLeft: theme.spacing.unit,
                                                        marginTop: theme.spacing.unit,
                                                        marginBottom: theme.spacing.unit
                                                    }}
                                                    control={
                                                        <Switch
                                                            data-cy="pickups-admin-trips-manual-ordering"
                                                            checked={manualOrdering[trip._id]}
                                                            onChange={e =>
                                                                handleToggleManualOrdering(e.target.checked, trip._id)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            style={{
                                                                height: 20,
                                                                color: theme.palette.text.secondary
                                                            }}
                                                        >
                                                            Manual Ordering
                                                        </Typography>
                                                    }
                                                    labelPlacement="end"
                                                />

                                                {manualOrdering[trip._id] && (
                                                    <IconButton
                                                        onClick={handleUndoChange}
                                                        style={{
                                                            padding: theme.spacing.unit,
                                                            opacity: pendingSave[trip._id] ? 1 : 0.5
                                                        }}
                                                        disabled={!pendingSave[trip._id]}
                                                    >
                                                        <MDIcon
                                                            path={mdiUndo}
                                                            size={1}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </IconButton>
                                                )}
                                                {manualOrdering[trip._id] && (
                                                    <IconButton
                                                        onClick={handleSaveTripChanges(trip._id)}
                                                        style={{
                                                            padding: theme.spacing.unit,
                                                            opacity: pendingSave[trip._id] ? 1 : 0.5
                                                        }}
                                                        data-cy={`pickups-admin-drawer-save`}
                                                        disabled={!pendingSave[trip._id]}
                                                    >
                                                        <MDIcon
                                                            path={mdiContentSave}
                                                            size={1}
                                                            color={theme.palette.primary.main}
                                                        />
                                                    </IconButton>
                                                )}
                                            </>
                                        )}
                                        {!_.isEmpty(latestVersion[trip._id]) &&
                                            (manualOrdering[trip._id] ? (
                                                <Droppable
                                                    droppableId={trip._id}
                                                    /*Fixes react-dnd not calculating the correct left and top position of the item being dragged due to the Drawer 
                                                    having the transform css property https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md */
                                                    renderClone={(provided, snapshotDroppable, rubric) => {
                                                        const pickup = latestVersion[trip._id][rubric.source.index];
                                                        return (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    listStyleType: 'none',
                                                                    ...provided.draggableProps.style
                                                                }}
                                                            >
                                                                {_.isNil(pickup.lunchBreakDuration)
                                                                    ? ''
                                                                    : renderLunch(pickup.lunchBreakDuration)}
                                                                {renderPickup(pickup, snapshotDroppable.isDragging)}
                                                            </div>
                                                        );
                                                    }}
                                                >
                                                    {(provided, snapshotDroppable) => {
                                                        return (
                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                {latestVersion[trip._id].map((pickup, i) => {
                                                                    if (_.get(pickup, 'complete', false)) {
                                                                        return null;
                                                                    }
                                                                    // Get number of bins from passed down pickups object
                                                                    // and temporarily store it on the trips pickup obj (for visualization)
                                                                    const pickupObj = _.find(pickups, {
                                                                        _id: pickup._id
                                                                    });
                                                                    if (pickupObj)
                                                                        pickup.numberOfBins = pickupObj.numberOfBins;

                                                                    return (
                                                                        <Draggable
                                                                            draggableId={`${pickup._id}`}
                                                                            index={i}
                                                                            key={`${i}${pickup._id.toString()}`}
                                                                        >
                                                                            {(provided, snapshotDraggable) => {
                                                                                return (
                                                                                    <div
                                                                                        data-cy={`draggable-pickup-${i}`}
                                                                                        key={pickup._id}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        style={{
                                                                                            ...provided.draggableProps
                                                                                                .style
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        {_.isNil(
                                                                                            pickup.lunchBreakDuration
                                                                                        )
                                                                                            ? ''
                                                                                            : renderLunch(
                                                                                                  pickup.lunchBreakDuration
                                                                                              )}
                                                                                        {renderPickup(
                                                                                            pickup,
                                                                                            snapshotDraggable.isDragging,
                                                                                            snapshotDroppable.isDraggingOver
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            ) : (
                                                <>
                                                    {latestVersion[trip._id].map((pickup, i) => {
                                                        if (_.get(pickup, 'complete', false)) {
                                                            return null;
                                                        }
                                                        // Get number of bins from passed down pickups object
                                                        // and temporarily store it on the trips pickup obj (for visualization)
                                                        const pickupObj = _.find(pickups, {
                                                            _id: pickup._id
                                                        });
                                                        if (pickupObj) pickup.numberOfBins = pickupObj.numberOfBins;

                                                        return (
                                                            <div data-cy={`draggable-pickup-${i}`} key={pickup._id}>
                                                                {_.isNil(pickup.lunchBreakDuration)
                                                                    ? ''
                                                                    : renderLunch(pickup.lunchBreakDuration)}
                                                                {renderPickup(pickup)}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ))}
                                    </List>
                                </React.Fragment>
                            );
                        })}
                    </DragDropContext>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        padding: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 1.5,
                        paddingRight: theme.spacing.unit * 1.5
                    }}
                >
                    <Button
                        data-cy="admin-ui-assign-button"
                        disabled={pickupSelected.unserviced || _.isEmpty(pickupIdsChecked)}
                        variant="contained"
                        color="primary"
                        style={{
                            flexGrow: 1,
                            margin: theme.spacing.unit * 0.5
                        }}
                        onClick={() => setAssignDialogOpen(true)}
                    >
                        Reassign
                    </Button>
                    {isSystemAdmin && (
                        <>
                            {pickupSelected.unserviced ? (
                                <Button
                                    disabled={_.isEmpty(pickupIdsChecked)}
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                        flexGrow: 1,
                                        margin: theme.spacing.unit * 0.5
                                    }}
                                    onClick={onScheduleUnservicedDialog(true)}
                                >
                                    Schedule Unserviced
                                </Button>
                            ) : (
                                <Button
                                    data-cy="admin-ui-pickup-bulk-reschedule"
                                    disabled={pickupSelected.unserviced || _.isEmpty(pickupIdsChecked)}
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                        flexGrow: 1,
                                        margin: theme.spacing.unit * 0.5
                                    }}
                                    onClick={() => onBulkRescheduleDialog(true)}
                                >
                                    Reschedule
                                </Button>
                            )}
                        </>
                    )}
                    {!(
                        isSystemAdmin &&
                        stopIdsChecked.length > 1 &&
                        _.isNil(
                            _.find(pickups, p => pickupIdsChecked.includes(p._id.toString()) && !_.isNil(p.customer))
                        )
                    ) && (
                        <Button
                            data-cy="admin-ui-create-stop"
                            variant="contained"
                            color="primary"
                            style={{
                                flexGrow: 1,
                                margin: theme.spacing.unit * 0.5
                            }}
                            onClick={onCreateStopDialog}
                        >
                            Create stop
                        </Button>
                    )}

                    {isSystemAdmin &&
                        stopIdsChecked.length > 1 &&
                        _.isNil(
                            _.find(pickups, p => pickupIdsChecked.includes(p._id.toString()) && !_.isNil(p.customer))
                        ) && (
                            <Button
                                data-cy="admin-ui-bulk-delete-stops"
                                variant="contained"
                                color="primary"
                                style={{
                                    flexGrow: 1,
                                    margin: theme.spacing.unit * 0.5
                                }}
                                onClick={() =>
                                    warnAction(
                                        () => handleBulkDeleteStops(),
                                        `Are you sure you want to delete ${stopIdsChecked.length} out of system stops?`
                                    )
                                }
                            >
                                Delete stops
                            </Button>
                        )}

                    <div style={{ flex: '100%', display: 'flex', justifyContent: 'center' }}>
                        {isSystemAdmin && (
                            <Button style={{ flex: '1 1 50%' }} onClick={handleFiltersOpen(true)}>
                                <MDIcon size={1} color={theme.palette.text.secondary} path={mdiFilterVariant} />
                            </Button>
                        )}
                        <Button style={{ flex: '1 1 50%' }} onClick={handleRecalc} data-cy="trips-refresh-button">
                            {recalculatingTrips ? (
                                <CircularProgress size={24} />
                            ) : (
                                <MDIcon size={1} color={theme.palette.text.secondary} path={mdiRefresh} />
                            )}
                        </Button>
                        <Button
                            data-cy="pickups-drawer-close"
                            style={{ flex: '1 1 50%' }}
                            onClick={() => {
                                setPickupDrawerOpen(false);
                            }}
                        >
                            <MDIcon size={1} color={theme.palette.text.secondary} path={mdiClose} />
                        </Button>
                    </div>
                </div>
            </div>

            <Dialog open={filtersOpen} onClose={handleFiltersOpen(false)} fullWidth maxWidth="xs">
                <DialogTitle>
                    Map Filters
                    <div style={{ display: 'flex', gap: theme.spacing.unit }}>
                        <Tooltip title="Total Pickups">
                            <div style={pickupInfoStyles}>
                                <MDIcon path={mdiMapMarker} size={0.85} color={theme.palette.text.secondary} />
                                <Typography color="textSecondary" data-cy="total-pickups-count">
                                    {pickups.length}
                                </Typography>
                            </div>
                        </Tooltip>

                        <Tooltip title="Unconfirmed Pickups">
                            <div style={pickupInfoStyles}>
                                <MDIcon path={mdiMapMarkerQuestion} size={0.85} color={theme.palette.text.secondary} />
                                <Typography color="textSecondary">{unconfirmedPickups.length}</Typography>
                            </div>
                        </Tooltip>

                        <Tooltip title="Hidden Pickups">
                            <div style={pickupInfoStyles}>
                                <MDIcon path={mdiMapMarkerOff} size={0.85} color={theme.palette.text.secondary} />
                                <Typography color="textSecondary">{pickupsHidden}</Typography>
                            </div>
                        </Tooltip>

                        <Tooltip title="Unrouted Pickups">
                            <div style={pickupInfoStyles}>
                                <MDIcon path={mdiCrosshairsQuestion} size={0.85} color={theme.palette.text.secondary} />
                                <Typography color="textSecondary" data-cy="unrouted-pickups-count">
                                    {triplessPickups.length}
                                </Typography>
                            </div>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Pickups</Typography>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Pickup Types</InputLabel>
                                <Select
                                    multiple
                                    value={filters.pickupTypes}
                                    onChange={handleFilters}
                                    name="pickupTypes"
                                >
                                    {[RESIDENTIAL, COMMERCIAL, CONDO, BOTTLE_DRIVE, 'Stop'].map(val => {
                                        return (
                                            <MenuItem value={val} key={val}>
                                                {val}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Commodity Types</InputLabel>
                                <Select
                                    multiple
                                    value={filters.commodityTypes}
                                    onChange={handleFilters}
                                    name="commodityTypes"
                                >
                                    {commoditiesAvailable &&
                                        commoditiesAvailable.map(({ skuType, payloadInputName }) => {
                                            return (
                                                <MenuItem key={skuType} value={skuType}>
                                                    {_.get(payloadInputName, lang, '')}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Modifiers</InputLabel>
                                <Select
                                    multiple
                                    value={filters.modifierTypes}
                                    onChange={handleFilters}
                                    name="modifierTypes"
                                >
                                    {MODIFIERS.map(val => {
                                        return (
                                            <MenuItem key={val} value={val}>
                                                {_.startCase(val)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Routing Cluster ID</InputLabel>
                                <Select value={filters.showClusterIds} onChange={handleFilters} name="showClusterIds">
                                    <MenuItem value={'Master'}>Master</MenuItem>
                                    <MenuItem value={'Trip'}>Trip</MenuItem>
                                    <MenuItem value={'None'}>None</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                fullWidth
                                style={{
                                    height: 15,
                                    opacity: '0.54'
                                }}
                                control={
                                    <Checkbox
                                        name="clusterPickupMarkers"
                                        style={{ paddingLeft: theme.spacing.unit }}
                                        checked={filters.clusterPickupMarkers}
                                        onChange={handleFiltersCheckbox}
                                    />
                                }
                                label={<Typography>Cluster Pickup Markers</Typography>}
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2 }}>
                        Zones
                    </Typography>
                    <Grid container spacing={8}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Zones</InputLabel>
                                <Select value={filters.showZones} onChange={handleFilters} name="showZones">
                                    <MenuItem value={true}>Show</MenuItem>
                                    <MenuItem value={false}>Hide</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Zones Activity</InputLabel>
                                <Select value={filters.onlyTodayZones} onChange={handleFilters} name="onlyTodayZones">
                                    <MenuItem value={false}>All</MenuItem>
                                    <MenuItem value={true}>Active Today</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Collector & Dates</InputLabel>
                                <Select
                                    value={filters.showNextServiceDate}
                                    onChange={handleFilters}
                                    name="showNextServiceDate"
                                >
                                    <MenuItem value={true}>Show</MenuItem>
                                    <MenuItem value={false}>Hide</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Zone Legend</InputLabel>
                                <Select value={filters.showLegend} onChange={handleFilters} name="showLegend">
                                    <MenuItem value={true}>Show</MenuItem>
                                    <MenuItem value={false}>Hide</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFiltersOpen(false)}>{loc('close', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(DrawerContent);

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
