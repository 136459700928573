import React, { useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';

import { Clipboard } from '@capacitor/clipboard';

import { getCustomerName, formatAsCurrency, deviceHelper } from 'utils/misc';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Toolbar as MuiToolbar, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Icon, IconButton, LinearProgress, colors, Avatar } from '@material-ui/core';

import { withTheme, withStyles } from '@material-ui/core/styles';

import CompletedRedemptionTable from './CompletedRedemptionTable';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    IntegratedSelection,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    Grid as TableGrid,
    Table,
    TableHeaderRow,
    TableSelection,
    PagingPanel,
    Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import RedemptionBreakdownDialog from '../Dialogs/RedemptionBreakdownDialog';
import { openWindow } from 'helpers/windowHelper';
import { useEffect } from 'react';

function STDTables(props) {
    const {
        theme,
        redemptions,
        fetchRedemptionsAndBatches,
        processingRedemptions,
        selectedCheque,
        selectedNonCheque,
        selectedProcessing,
        selectedProcessingDriver,
        selectedDriverRedemptions,
        selectedBankTransfer,
        selectedCash,
        onNonChequeSelection,
        onChequeSelection,
        onProcessingSelection,
        onProcessingDriverSelection,
        onBankTransferSelection,
        onCashSelection,
        onDriverRedemptionSelection,
        onSnackbar,
        batches,
        redemptionOptions,
        operator,
        history,
        inProgress
    } = props;

    const { lang } = useContext(LocalizationContext);
    const [view, setView] = useState('eTransferCustomer');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [selectedRedemption, setSelectedRedemption] = useState(null);
    const [adminTrips, setAdminTrips] = useState([]);
    const [tripsLoading, setTripsLoading] = useState(false);

    const disableInAppRedemptionCancel = _.get(redemptionOptions, 'disableInAppRedemptionCancel', false);
    const hideEtransferPassphrase = _.get(redemptionOptions, 'hideEtransferPassphrase', false);

    const getAdminTrips = async () => {
        if (selectedRedemption && _.get(selectedRedemption, 'type') === 'Driver') {
            setTripsLoading(true);
            const res = await props.http.getJSON(`/trips/getTripPayBreakdownsForRedemption/${selectedRedemption._id}`);
            if (res.ok) {
                setAdminTrips(res.data.trips);
            } else {
                setAdminTrips([]);
            }
            setTripsLoading(false);
        }
    };

    useEffect(() => {
        getAdminTrips();
    }, [selectedRedemption]);

    const handleCopy = string => {
        try {
            onSnackbar(`Copied ${string} to your clipboard!`, 'success', 1000);
            //Remove $ from sting
            if (string[0] === '$') {
                string = string.substring(1);
            }
            Clipboard.write({ string });
        } catch (err) {
            onSnackbar('Unable to copy value to clipboard', 'error', 1000);
        }
    };

    const handleInfoDialog = breakdown => {
        setSelectedRedemption(breakdown);
        setInfoDialogOpen(true);
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        setSelectedRedemption(null);
    };

    const addCopyButton = string => (
        <span onClick={() => handleCopy(string)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {string}
        </span>
    );

    if (_.isNil(redemptions) || _.isNil(processingRedemptions) || _.isNil(batches)) {
        return (
            <React.Fragment>
                <LinearProgress />
                <Typography style={{ padding: theme.spacing.unit * 2 }}>
                    Fetching redemptions and batch files. Please wait
                </Typography>
            </React.Fragment>
        );
    }

    const eTransferRows = [];
    const chequeRows = [];
    const bankTransferRows = [];
    const cashRows = [];
    const driverRows = [];

    const eTransferReviewRows = [];
    const bTransferReviewRows = [];
    const cashReviewRows = [];
    const chequeReviewRows = [];
    const driverReviewRows = [];

    redemptions.forEach(redemption => {
        let customerNameText =
            redemption.type === 'Customer' || redemption.type === 'Driver'
                ? getCustomerName(redemption.claimant)
                : redemption.charity.name;
        let redemptionValue = {
            email: addCopyButton(redemption.email),
            name: addCopyButton(customerNameText),
            dateRequested: moment(redemption.createdAt)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            amount: addCopyButton(formatAsCurrency(redemption.amount - redemption.fee)),
            type: redemption.type,
            question: addCopyButton(redemption.question),
            passphrase: addCopyButton(redemption.passphrase),
            accountNumber: addCopyButton(_.get(redemption, 'banking.accountNumber', '')),
            transitNumber: addCopyButton(_.get(redemption, 'banking.transitNumber', '')),
            institutionNumber: addCopyButton(_.get(redemption, 'banking.institutionNumber', ''))
        };
        let underReview = _.get(redemption, 'underReview', false);
        if (underReview) {
            let infractions = _.get(redemption, 'infractions', null);

            let disable = _.isEmpty(infractions) ? false : true;

            if (!_.isEmpty(infractions)) {
                let unApprovedInfractions = _.filter(infractions, i => i.resolved !== true);

                if (!_.isEmpty(unApprovedInfractions)) {
                    disable = true;
                } else {
                    disable = false;
                }
            }

            redemptionValue.actions = (
                <>
                    <Tooltip title="View Customer">
                        <IconButton
                            onClick={() => {
                                const URL = `/customers/${_.get(redemption, 'claimant._id')}`;
                                openWindow(history, !deviceHelper.isNativeApp(), URL);
                            }}
                        >
                            <Icon>visibility</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Infraction">
                        <IconButton
                            onClick={() => {
                                const URL = `/operators/${_user.getId(
                                    operator
                                )}/infractions?searchTerm=${customerNameText}`;
                                openWindow(history, !deviceHelper.isNativeApp(), URL);
                            }}
                        >
                            <Icon>assignment_late</Icon>
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        switch (redemption.paymentModel) {
            case 'INTERAC_ETRANSFERS':
                redemptionValue.method = (
                    <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>e-Transfer</span>
                );
                if (
                    _.get(redemption, 'type', 'Customer') === 'Customer' ||
                    _.get(redemption, 'type', 'Customer') === 'Charity'
                ) {
                    if (underReview) {
                        eTransferReviewRows.push(redemptionValue);
                    } else {
                        eTransferRows.push(redemptionValue);
                    }
                } else {
                    if (underReview) {
                        driverReviewRows.push(redemptionValue);
                    } else {
                        driverRows.push(redemptionValue);
                    }
                }
                break;
            case 'CHEQUE':
                redemptionValue.method = (
                    <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                        {loc('redemption5', lang)}
                    </span>
                );
                if (underReview) {
                    chequeReviewRows.push(redemptionValue);
                } else {
                    chequeRows.push(redemptionValue);
                }
                break;
            case 'BANK_TRANSFER':
                redemptionValue.method = (
                    <span style={{ color: colors.orange[500], fontWeight: 500 }}>{loc('redemption6', lang)}</span>
                );
                if (underReview) {
                    bTransferReviewRows.push(redemptionValue);
                } else {
                    bankTransferRows.push(redemptionValue);
                }
                break;
            case 'CASH':
                redemptionValue.method = (
                    <span style={{ color: colors.green[500], fontWeight: 500 }}>{loc('redemption7', lang)}</span>
                );
                if (underReview) {
                    cashReviewRows.push(redemptionValue);
                } else {
                    cashRows.push(redemptionValue);
                }
                break;
            default:
                break;
        }
    });

    const processingRedemptionsRows = processingRedemptions.map(redemption => {
        let method;
        switch (redemption.paymentModel) {
            case 'INTERAC_ETRANSFERS':
                method = <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>e-Transfer</span>;
                break;
            case 'CHEQUE':
                method = (
                    <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                        {loc('redemption5', lang)}
                    </span>
                );
                break;
            default:
                break;
        }

        return {
            email: addCopyButton(redemption.email),
            name: addCopyButton(
                redemption.type === 'Customer' || redemption.type === 'Driver'
                    ? getCustomerName(redemption.claimant)
                    : redemption.charity.name
            ),
            amount: addCopyButton(formatAsCurrency(redemption.amount - redemption.fee)),
            type: redemption.type,
            dateRequested: moment(redemption.createdAt)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            dateProcessed: moment(_.get(redemption, 'processedDate', 'N/A'))
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            method: method,
            question: addCopyButton(redemption.question),
            passphrase: addCopyButton(redemption.passphrase)
        };
    });

    /*const completedRedempRows = completedRedemptions.map(redemption => {
        let method;
        let action = redemption.type === 'Driver' ? addInfoButton(redemption) : '';
        switch (redemption.paymentModel) {
            case 'INTERAC_ETRANSFERS':
                method = (
                    <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                        {loc('redemption4', lang)}
                    </span>
                );
                break;
            case 'CHEQUE':
                method = (
                    <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                        {loc('redemption5', lang)}
                    </span>
                );
                break;
            case 'CASH':
                method = <span style={{ color: colors.green[500], fontWeight: 500 }}>{loc('redemption7', lang)}</span>;
                break;
            case 'BANK_TRANSFER':
                method = <span style={{ color: colors.orange[500], fontWeight: 500 }}>{loc('redemption6', lang)}</span>;
                break;
            default:
                break;
        }

        return {
            info: action,
            email: addCopyButton(_.get(redemption, 'email', 'N/A')),
            name: addCopyButton(
                redemption.type === 'Customer' || redemption.type === 'Driver'
                    ? getCustomerName(redemption.claimant)
                    : redemption.charity.name
            ),
            dateRequested: moment(redemption.createdAt)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            amount: addCopyButton(formatAsCurrency(redemption.amount - redemption.fee)),
            type: redemption.type,
            dateCompleted: moment(_.get(redemption, 'updatedAt', 'N/A'))
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            method: method,
            question: addCopyButton(_.get(redemption, 'question', 'N/A')),
            passphrase: addCopyButton(_.get(redemption, 'passphrase', 'N/A'))
        };
    });*/

    const batchesRows = batches.map((batch, index) => {
        return {
            batch: _.get(batch, 'batchNumber', 'N/A'),
            firstTripDate: !_.isNil(_.get(batch, 'firstTripDate'))
                ? moment(_.get(batch, 'firstTripDate', 'N/A'))
                      .tz(process.env.REACT_APP_REGION_TIMEZONE)
                      .format('LT MMM Do, YYYY')
                : 'N/A',
            lastTripDate: !_.isNil(_.get(batch, 'lastTripDate'))
                ? moment(_.get(batch, 'lastTripDate', 'N/A'))
                      .tz(process.env.REACT_APP_REGION_TIMEZONE)
                      .format('LT MMM Do, YYYY')
                : 'N/A',
            dateCreated: moment(_.get(batch, 'createdAt', 'N/A'))
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('LT MMM Do, YYYY'),
            operatorName: getCustomerName(batch.operator),
            redemptions: batch.redemptions.length,
            totalValue: formatAsCurrency(_.get(batch, 'totalValue', '0')),
            actions: (
                <React.Fragment>
                    <IconButton
                        color="primary"
                        onClick={async () => {
                            let res = await props.http.download(
                                `/redemptions/batch/${batch.batchNumber}/download`,
                                `${getFilename(batch)}.csv`
                            );
                            if (!res.ok) {
                                props.onSnackbar('Error downloading batch', 'error');
                            }
                        }}
                    >
                        <Icon>save_alt</Icon>
                    </IconButton>
                </React.Fragment>
            ),
            type: batch.type
        };
    });

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, view) => {
                        if (!_.isEmpty(view)) setView(view);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} value="eTransferCustomer" data-cy="redemptions-etransfer-tab">
                        E-Transfers
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor:
                                    eTransferRows.length > 0 ? theme.palette.primary.main : theme.palette.text.disabled,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {eTransferRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="cheque" data-cy="redemptions-cheque-tab">
                        Cheques
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor:
                                    chequeRows.length > 0 ? theme.palette.primary.main : theme.palette.text.disabled,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {chequeRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="bankTransfer" data-cy="redemptions-banktransfer-tab">
                        {loc('redemption6', lang)}
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor:
                                    bankTransferRows.length > 0
                                        ? theme.palette.primary.main
                                        : theme.palette.text.disabled,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {bankTransferRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="cash" data-cy="redemptions-cash-tab">
                        Cash
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor:
                                    cashRows.length > 0 ? theme.palette.primary.main : theme.palette.text.disabled,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {cashRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton
                        style={{ flexGrow: 1 }}
                        value="eTransferDriver"
                        data-cy="redemptions-etransferdriver-tab"
                    >
                        Drivers
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor:
                                    driverRows.length > 0 ? theme.palette.primary.main : theme.palette.text.disabled,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {driverRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="completed" data-cy="redemptions-completed-tab">
                        Completed
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view !== 'completed' && (
                <Grid container spacing={0} style={{ padding: theme.spacing.unit * 2 }}>
                    {view === 'cheque' && (
                        <>
                            {chequeReviewRows.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Paper id="redemptions-under-review-table" data-cy="redemptions-under-review-table">
                                        <MuiToolbar>
                                            <Typography variant="h6">Review Pending Cheques</Typography>
                                        </MuiToolbar>
                                        <TableGrid
                                            rows={chequeReviewRows}
                                            columns={[
                                                { title: 'Actions', name: 'actions' },
                                                { title: 'E-mail', name: 'email' },
                                                { title: 'Name', name: 'name' },
                                                { title: 'Date Requested', name: 'dateRequested' },
                                                { title: 'Amount', name: 'amount' },
                                                { title: 'Type', name: 'type' },
                                                { title: 'Method', name: 'method' },
                                                { title: 'Question', name: 'question' },
                                                !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                            ]}
                                        >
                                            <PagingState defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Paper id="redemptions-table-cheque" data-cy="redemptions-table-cheque">
                                    <MuiToolbar>
                                        <Typography variant="h6">Pending Cheques</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={chequeRows}
                                        columns={[
                                            { title: 'E-mail', name: 'email' },
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Question', name: 'question' },
                                            !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onChequeSelection}
                                            selection={selectedCheque}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                <Button
                                    data-cy="redemptions-complete-cheque"
                                    variant="contained"
                                    color="secondary"
                                    disabled={selectedCheque.length === 0}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    onClick={props.onConfirmationDialog(true, 'CHEQUE', ['Customer', 'Charity'])}
                                >
                                    Complete
                                </Button>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedCheque.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'CHEQUE',
                                            ['Customer', 'Charity'],
                                            false,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}

                    {view === 'bankTransfer' && (
                        <>
                            {bTransferReviewRows.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Paper id="redemptions-under-review-table" data-cy="redemptions-under-review-table">
                                        <MuiToolbar>
                                            <Typography variant="h6">Review Pending Bank Transfers</Typography>
                                        </MuiToolbar>
                                        <TableGrid
                                            rows={bTransferReviewRows}
                                            columns={[
                                                { title: 'Actions', name: 'actions' },
                                                { title: 'Name', name: 'name' },
                                                { title: 'Date Requested', name: 'dateRequested' },
                                                { title: 'Amount', name: 'amount' },
                                                { title: 'Type', name: 'type' },
                                                { title: 'Method', name: 'method' },
                                                { title: 'Account Number', name: 'accountNumber' },
                                                { title: 'Transit Number', name: 'transitNumber' },
                                                { title: loc('redemption45', lang), name: 'institutionNumber' }
                                            ]}
                                        >
                                            <PagingState defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12} style={{}}>
                                <Paper id="redemptions-table-bank-transfer" data-cy="redemptions-table-bank-transfer">
                                    <MuiToolbar>
                                        <Typography variant="h6">Pending Bank Transfers</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={bankTransferRows}
                                        columns={[
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Account Number', name: 'accountNumber' },
                                            { title: 'Transit Number', name: 'transitNumber' },
                                            { title: loc('redemption45', lang), name: 'institutionNumber' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onBankTransferSelection}
                                            selection={selectedBankTransfer}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                <Button
                                    data-cy="redemptions-complete-bank-transfer"
                                    variant="contained"
                                    color="secondary"
                                    disabled={selectedBankTransfer.length === 0}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    onClick={props.onConfirmationDialog(true, 'BANK_TRANSFER', ['Customer', 'Charity'])}
                                >
                                    Complete
                                </Button>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedBankTransfer.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'BANK_TRANSFER',
                                            ['Customer', 'Charity'],
                                            false,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}

                    {view === 'cash' && (
                        <>
                            {cashReviewRows.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Paper id="redemptions-under-review-table" data-cy="redemptions-under-review-table">
                                        <MuiToolbar>
                                            <Typography variant="h6">Review Pending Cash</Typography>
                                        </MuiToolbar>
                                        <TableGrid
                                            rows={cashReviewRows}
                                            columns={[
                                                { title: 'Actions', name: 'actions' },
                                                { title: 'Name', name: 'name' },
                                                { title: 'Date Requested', name: 'dateRequested' },
                                                { title: 'Amount', name: 'amount' },
                                                { title: 'Type', name: 'type' },
                                                { title: 'Method', name: 'method' }
                                            ]}
                                        >
                                            <PagingState defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12} style={{}}>
                                <Paper id="redemptions-table-cash" data-cy="redemptions-table-cash">
                                    <MuiToolbar>
                                        <Typography variant="h6">Cash</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={cashRows}
                                        columns={[
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState onSelectionChange={onCashSelection} selection={selectedCash} />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                <Button
                                    data-cy="redemptions-complete-cash"
                                    variant="contained"
                                    color="secondary"
                                    disabled={selectedCash.length === 0}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    onClick={props.onConfirmationDialog(true, 'CASH', ['Customer', 'Charity'])}
                                >
                                    Complete
                                </Button>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedCash.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'CASH',
                                            ['Customer', 'Charity'],
                                            false,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}

                    {view === 'eTransferCustomer' && (
                        <>
                            {eTransferReviewRows.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Paper id="redemptions-under-review-table" data-cy="redemptions-under-review-table">
                                        <MuiToolbar>
                                            <Typography variant="h6">Review Pending e-Transfers</Typography>
                                        </MuiToolbar>
                                        <TableGrid
                                            rows={eTransferReviewRows}
                                            columns={[
                                                { title: 'Actions', name: 'actions' },
                                                { title: 'E-mail', name: 'email' },
                                                { title: 'Name', name: 'name' },
                                                { title: 'Date Requested', name: 'dateRequested' },
                                                { title: 'Amount', name: 'amount' },
                                                { title: 'Type', name: 'type' },
                                                { title: 'Method', name: 'method' },
                                                { title: 'Question', name: 'question' },
                                                !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                            ]}
                                        >
                                            <PagingState defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </Paper>
                                </Grid>
                            )}

                            <Grid item xs={12} style={{}}>
                                <Paper id="redemptions-table" data-cy="redemptions-table">
                                    <MuiToolbar>
                                        <Typography variant="h6">Pending e-Transfers</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={eTransferRows}
                                        columns={[
                                            { title: 'E-mail', name: 'email' },
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Question', name: 'question' },
                                            !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onNonChequeSelection}
                                            selection={selectedNonCheque}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                <Button
                                    data-cy="redemptions-complete"
                                    variant="contained"
                                    color="secondary"
                                    disabled={selectedNonCheque.length === 0}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    onClick={props.onConfirmationDialog(true, 'INTERAC_ETRANSFERS', [
                                        'Customer',
                                        'Charity'
                                    ])}
                                >
                                    Complete
                                </Button>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedNonCheque.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'INTERAC_ETRANSFERS',
                                            ['Customer', 'Charity'],
                                            false,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <Paper id="processing-redemptions-table" data-cy="processing-redemptions-table">
                                    <MuiToolbar>
                                        <Typography variant="h6">Processing</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={_.filter(
                                            processingRedemptionsRows,
                                            redemptionRow =>
                                                redemptionRow.type === 'Customer' || redemptionRow.type === 'Charity'
                                        )}
                                        columns={[
                                            { title: 'E-mail', name: 'email' },
                                            { title: 'Name', name: 'name' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Date Processed', name: 'dateProcessed' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Question', name: 'question' },
                                            !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onProcessingSelection}
                                            selection={selectedProcessing}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-pending-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedProcessing.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'INTERAC_ETRANSFERS',
                                            ['Customer', 'Charity'],
                                            false,
                                            true,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <Paper id="redemptions-csvs-table" data-cy="redemptions-csvs-table">
                                    <MuiToolbar style={{ minHeight: 48, alignItems: 'flex-end' }}>
                                        <Typography variant="h6">CSVs</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={_.filter(batchesRows, { type: 'Customer' })
                                            .slice()
                                            .reverse()}
                                        columns={[
                                            { title: 'File Name', name: 'batch' },
                                            { title: 'Date Created', name: 'dateCreated' },
                                            { title: 'Operator', name: 'operatorName' },
                                            { title: 'Redemptions', name: 'redemptions' },
                                            { title: 'Amount', name: 'totalValue' },
                                            { title: 'Actions', name: 'actions' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <IntegratedFiltering />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <Toolbar />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {view === 'eTransferDriver' && (
                        <>
                            {driverReviewRows.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    <Paper id="redemptions-under-review-table" data-cy="redemptions-under-review-table">
                                        <MuiToolbar>
                                            <Typography variant="h6">Review Pending e-Transfers</Typography>
                                        </MuiToolbar>
                                        <TableGrid
                                            rows={driverReviewRows}
                                            columns={[
                                                { title: 'Actions', name: 'actions' },
                                                { title: 'E-mail', name: 'email' },
                                                { title: 'Name', name: 'name' },
                                                { title: 'Date Requested', name: 'dateRequested' },
                                                { title: 'Amount', name: 'amount' },
                                                { title: 'Type', name: 'type' },
                                                { title: 'Method', name: 'method' },
                                                { title: 'Question', name: 'question' },
                                                !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                            ]}
                                        >
                                            <PagingState defaultCurrentPage={0} />
                                            <IntegratedPaging />
                                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                            <TableHeaderRow />
                                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                        </TableGrid>
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12} style={{}}>
                                <Paper id="redemptions-table" data-cy="redemptions-table">
                                    <MuiToolbar>
                                        <Typography variant="h6">Pending e-Transfers</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={driverRows}
                                        columns={[
                                            { title: 'E-mail', name: 'email' },
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Question', name: 'question' },
                                            !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onDriverRedemptionSelection}
                                            selection={selectedDriverRedemptions}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                <Button
                                    data-cy="redemptions-complete"
                                    variant="contained"
                                    color="secondary"
                                    disabled={selectedDriverRedemptions.length === 0}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    onClick={props.onConfirmationDialog(true, 'INTERAC_ETRANSFERS', ['Driver'])}
                                >
                                    Complete
                                </Button>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedDriverRedemptions.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'INTERAC_ETRANSFERS',
                                            ['Driver'],
                                            false,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <Paper id="processing-redemptions-table" data-cy="processing-redemptions-table">
                                    <MuiToolbar>
                                        <Typography variant="h6">Processing</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={_.filter(processingRedemptionsRows, { type: 'Driver' })}
                                        columns={[
                                            { title: 'E-mail', name: 'email' },
                                            { title: 'Name', name: 'name' },
                                            { title: 'Date Requested', name: 'dateRequested' },
                                            { title: 'Amount', name: 'amount' },
                                            { title: 'Date Processed', name: 'dateProcessed' },
                                            { title: 'Type', name: 'type' },
                                            { title: 'Method', name: 'method' },
                                            { title: 'Question', name: 'question' },
                                            !hideEtransferPassphrase && { title: 'Passphrase', name: 'passphrase' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <SelectionState
                                            onSelectionChange={onProcessingDriverSelection}
                                            selection={selectedProcessingDriver}
                                        />
                                        <IntegratedSelection />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <TableSelection showSelectAll />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                                {!disableInAppRedemptionCancel && (
                                    <Button
                                        data-cy="redemptions-pending-reject"
                                        variant="contained"
                                        color="secondary"
                                        disabled={selectedProcessingDriver.length === 0}
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        onClick={props.onConfirmationDialog(
                                            true,
                                            'INTERAC_ETRANSFERS',
                                            ['Driver'],
                                            false,
                                            true,
                                            true
                                        )}
                                    >
                                        Reject
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <Paper id="redemptions-csvs-table" data-cy="redemptions-csvs-table">
                                    <MuiToolbar style={{ minHeight: 48, alignItems: 'flex-end' }}>
                                        <Typography variant="h6">CSVs</Typography>
                                    </MuiToolbar>
                                    <TableGrid
                                        rows={_.filter(batchesRows, { type: 'Driver' })
                                            .slice()
                                            .reverse()}
                                        columns={[
                                            { title: 'File Name', name: 'batch' },
                                            { title: 'First Trip Date', name: 'firstTripDate' },
                                            { title: 'Last Trip Date', name: 'lastTripDate' },
                                            { title: 'Date Created', name: 'dateCreated' },
                                            { title: 'Operator', name: 'operatorName' },
                                            { title: 'Redemptions', name: 'redemptions' },
                                            { title: 'Amount', name: 'totalValue' },
                                            { title: 'Actions', name: 'actions' }
                                        ]}
                                    >
                                        <PagingState defaultCurrentPage={0} />
                                        <IntegratedFiltering />
                                        <IntegratedPaging />
                                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                        <TableHeaderRow />
                                        <Toolbar />
                                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                    </TableGrid>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            {view === 'completed' && (
                <CompletedRedemptionTable onInfoDialog={handleInfoDialog} redemptionOptions={redemptionOptions} />
            )}
            {selectedRedemption && (
                <RedemptionBreakdownDialog
                    onClose={handleInfoDialogClose}
                    open={infoDialogOpen}
                    selectedRedemption={selectedRedemption}
                    theme={theme}
                    onSnackbar={onSnackbar}
                    trips={adminTrips}
                    tripsLoading={tripsLoading}
                />
            )}
        </>
    );
}

const styles = theme => ({
    tableStyles: {
        tableLayout: 'auto'
    }
});
const TableCell = ({ ...props }) => <Table.Cell {...props} style={{ overflow: 'visible' }} />;
const TableComponentBase = ({ classes, ...props }) => <Table.Table {...props} className={classes.tableStyles} />;
const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

function getFilename(batch) {
    return `${moment(batch.createdAt).format('YYYY-MM-DD')}-STD${
        _.get(batch, 'type', '') === 'Driver' ? '-Driver' : ''
    }-eTransfers`;
}

export default withTheme()(STDTables);
