import React, { useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _user, _time, _commodity } from 'std';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import InlineChip from 'components/Chips/InlineChip';

import GenericDialog from 'components/Dialogs/GenericDialog';

import {
    withTheme,
    TableRow,
    TableCell,
    colors,
    IconButton,
    Icon,
    Tooltip,
    Grid,
    Paper,
    Menu,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Button,
    MenuItem,
    TextField,
    Checkbox,
    ListItemText,
    Switch,
    Typography,
    Avatar,
    LinearProgress,
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core';
import OperatorContext from 'utils/contexts/OperatorContext';
import { uppercaseFirstLetter, formatAsCurrency } from 'utils/misc';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import CommodityPickupDialog from 'components/CommodityPickupDialog';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import useCommodityPickupFilters from './hooks/useCommodityPickupFilters';

import ImageViewer from 'components/ImageUploads/ImageViewer';

import TrailerLabelDialog from './TrailerLabelDialog';
import useTrailerLabelDialog from './TrailerLabelDialog/hooks/useTrailerLabelDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CommodityPickups(props) {
    const { lang } = useContext(LocalizationContext);
    const { theme, collector } = props;

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);

    const [stats, setStats] = useState(null);

    const [payloadDialogOpen, setPayloadDialogOpen] = useState(false);
    const [commodityPickupSelected, setCommodityPickupSelected] = useState(null);
    const [idsChecked, setIdsChecked] = useState([]);
    const [commodityToComplete, setCommodityToComplete] = useState(null);
    const tzCommodityPickupSelected = _.get(
        commodityPickupSelected,
        'collection.timezone',
        process.env.REACT_APP_REGION_TIMEZONE
    );

    const [loading, setLoading] = useState(false);
    const [statsLoading, setStatsLoading] = useState(true);

    const operator = useContext(OperatorContext);
    const warnAction = useContext(ConfirmDialogContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [commodityPickupDialogOpen, setCommodityPickupDialogOpen] = useState(false);
    const [editingCommodityPickup, setEditingCommodityPickup] = useState(false);

    const [images, setImages] = useState([]);
    const [imageDialogOpen, setImageDialogOpen] = useState(false);

    const handleViewImage = imageURLs => {
        setImages(imageURLs);
        setImageDialogOpen(true);
    };

    let headers = [];
    if (_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) {
        headers = [
            { name: 'Actions', key: 'Actions', locKey: null },
            { name: 'Status', key: 'Status', locKey: null },
            { name: 'Trailer Number', key: 'Trailer Number', locKey: null },
            { name: 'Bottle Depot', key: 'Bottle Depot', locKey: null },
            { name: 'Reference Number', key: 'Reference Number', locKey: null },
            { name: 'Date Requested^', key: 'Date Requested', locKey: null },
            { name: 'Collection Period^', key: 'Collection Period', locKey: null },
            { name: 'Commodity', key: 'Commodity', locKey: null },
            { name: 'Total Items', key: 'Total Items', locKey: null },
            { name: 'Associated Orders', key: 'Associated Orders', locKey: null },
            { name: 'Associated Bags/Items', key: 'Associated Bags/Items', locKey: null },
            { name: 'Payment Total', key: 'Payment Total', locKey: null },
            { name: 'Processor Payment', key: 'Processor Payment', locKey: null },
            { name: 'Driver Payment', key: 'Driver Payment', locKey: null },
            { name: 'Customer Payment', key: 'Customer Payment', locKey: null },
            { name: 'Charity Payment', key: 'Charity Payment', locKey: null },
            { name: 'Payload', key: 'Payload', locKey: null }
        ];
    } else {
        headers = [
            { name: 'Actions', key: 'Actions', locKey: null },
            { name: 'Status', key: 'Status', locKey: null },
            { name: 'Trailer Number', key: 'Trailer Number', locKey: null },
            { name: 'Reference Number', key: 'Reference Number', locKey: null },
            { name: 'Date Requested^', key: 'Date Requested', locKey: null },
            { name: 'Collection Period^', key: 'Collection Period', locKey: null },
            { name: 'Commodity', key: 'Commodity', locKey: null },
            { name: 'Total Items', key: 'Total Items', locKey: null },
            { name: 'Associated Orders', key: 'Associated Orders', locKey: null },
            { name: 'Associated Bags/Items', key: 'Associated Bags/Items', locKey: null },
            { name: 'Payload', key: 'Payload', locKey: null }
        ];
    }
    const {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allCommodities,
        commoditiesSelected,
        setCommoditiesSelected,
        filtersLoading,
        handleAllFilterCollectors,
        handleAllFilterCommodities
    } = useCommodityPickupFilters(_user.isCollectorAdmin(operator) ? collector : null);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards
        // handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .subtract(30, 'd')
            .startOf('d'),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisMonth'
    });

    const {
        loading: tableLoading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/commodityPickups?collectors=${!_user.isSystemAdmin(operator) &&
            !_user.isInternalRole(operator) &&
            _.get(operator, 'collectors', []).length <= 1
            ? collector._id
            : collectorsSelected
            }&commodities=${commoditiesSelected}`,
        key: 'commodityPickups',
        headers: headers.map(h => h.name)
    });

    const {
        open: trailerLabelDialogOpen,
        trailerLabel,
        handleOpen: handleOpenTrailerLabelDialog,
        handleClose: handleCloseTrailerLabelDialog,
        handleLabelChange: handleChangeLabel
    } = useTrailerLabelDialog();

    const handleOpenCompleteDialog = () => {
        setCommodityPickupSelected(null);
        setCommodityPickupDialogOpen(true);
        setEditingCommodityPickup(false);
    };

    const handleLoadStats = async () => {
        setStatsLoading(true);
        const res = await http.getJSON(
            `/commodityPickups/stats?collectors=${!_user.isSystemAdmin(operator) &&
                !_user.isInternalRole(operator) &&
                _.get(operator, 'collectors', []).length <= 1
                ? collector._id
                : collectorsSelected
            }&commodities=${commoditiesSelected}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        );
        if (res.ok) {
            setStats(res.data);
        }
        setStatsLoading(false);
    };

    const handlePayloadDialogOpen = commodityPickup => {
        setPayloadDialogOpen(true);
        setCommodityPickupSelected(commodityPickup);
    };

    const handleUpdateCommodityPickupDialog = commodityPickup => {
        setCommodityPickupSelected(commodityPickup);
        setCommodityPickupDialogOpen(true);
        setEditingCommodityPickup(true);
    };

    const handleUpdateCommodityPickup = async (fieldValues, trailerLabel) => {
        setLoading(true);
        const res = await http.post(
            `/commodityPickups/${commodityPickupSelected._id}/update`,
            { fieldValues, trailerLabel },
            true
        );
        if (res.ok) {
            onSnackbar('Successfully updated commodity pickup');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleDeleteCommodityPickup = async commodityPickup => {
        setLoading(true);
        const res = await http.post(`/commodityPickups/${commodityPickup._id}/delete`, {}, true);
        if (res.ok) {
            onSnackbar('Requested pickup successfully deleted');
            getData();
            handleLoadStats();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleCompleteCommodityPickups = async (fieldValues, trailerLabel) => {
        setLoading(true);
        const res = await http.post(
            `/commodityPickups/processCommodityPickups`,
            { _ids: idsChecked, fieldValues, trailerLabel },
            true
        );
        if (res.ok) {
            onSnackbar('Successfully completed commodity pickups');
            setCommodityPickupDialogOpen(false);
            getData();
            handleLoadStats();
            setIdsChecked([]);
            setCommodityToComplete(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleCommodityPickupChecked = (e, commodityPickup) => {
        const idsCheckedCopy = _.cloneDeep(idsChecked);
        if (e.target.checked) {
            idsCheckedCopy.push(commodityPickup._id);
        } else {
            const i = idsCheckedCopy.indexOf(commodityPickup._id);
            if (i >= 0) {
                idsCheckedCopy.splice(i, 1);
            }
        }
        setIdsChecked(idsCheckedCopy);

        if (_.isEmpty(idsCheckedCopy)) {
            setCommodityToComplete(null);
        } else if (_.get(commodityToComplete, '_id') !== _.get(commodityPickup, 'commodity._id')) {
            setCommodityToComplete(commodityPickup.commodity);
        }
    };

    const handleSubmitTrailerLabel = async () => {
        setLoading(true);
        const res = await http.post(`/commodityPickups/updateTrailerLabels`, { _ids: idsChecked, trailerLabel }, true);
        if (res.ok) {
            onSnackbar('Successfully updated commodity pickups');
            handleCloseTrailerLabelDialog();
            getData();
            handleLoadStats();
            setIdsChecked([]);
            setCommodityToComplete(null);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const renderRow = (commodityPickup, filteredHeaders, i) => {
        const timezone = _.get(commodityPickup, 'collection.timezone', process.env.REACT_APP_REGION_TIMEZONE);
        const startDateFormatted = formatDate(_.get(commodityPickup, 'collectionPeriod.startDate'), timezone);
        const endDateFormatted = formatDate(_.get(commodityPickup, 'collectionPeriod.endDate'), timezone);
        const createdAtFormatted = formatDate(_.get(commodityPickup, 'createdAt'), timezone);

        const olderThan4Weeks = moment(_.get(commodityPickup, 'createdAt'))
            .tz(timezone)
            .isBefore(
                moment()
                    .tz(timezone)
                    .subtract(4, 'weeks')
            );

        const payloadFormatted = _(commodityPickup.payload)
            .filter(p => p.quantity > 0)
            .map((p, i) => (
                <InlineChip
                    key={i}
                    style={{ margin: theme.spacing.unit * 0.5 }}
                    color={colors.grey[500]}
                    textColor={'white'}
                    text={
                        _.isEmpty(p.unit)
                            ? `${p.description}: ${p.quantity}`
                            : `${p.description}: ${p.quantity} ${p.unit}`
                    }
                />
            ))
            .value();

        const bulks = _.get(commodityPickup, 'bulks', []);
        const allBulksInvoiced = _.every(bulks, bulk => {
            const invoice = _.get(bulk, 'invoice');
            return !_.isNil(invoice) && !_.isEmpty(invoice);
        });
        const outOfSystemCollectorPaymentInvoiced =
            _.get(commodityPickup, 'outOfSystemCollectorPaymentTotal', 0) <= 0 ||
            !_.isNil(_.get(commodityPickup, 'outOfSystemCollectorPaymentInvoice'));

        const invoiced = allBulksInvoiced && outOfSystemCollectorPaymentInvoiced;

        const payloadImages = _.get(commodityPickup, 'images');

        const commodityMatchesCommodityToComplete =
            _.isNil(commodityToComplete) ||
            (!_.isNil(commodityToComplete) &&
                _.get(commodityPickup, 'commodity._id') === _.get(commodityToComplete, '_id'));

        const multipleSelectionAllowed =
            _.isNil(commodityToComplete) || _commodity.isSplitCompleted(commodityToComplete);

        return (
            <TableRow key={commodityPickup._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                            <Checkbox
                                disabled={
                                    _.get(commodityPickup, 'status', '') !== 'Collected' ||
                                    !commodityMatchesCommodityToComplete ||
                                    (!multipleSelectionAllowed && _.first(idsChecked) !== commodityPickup._id)
                                }
                                checked={idsChecked.includes(commodityPickup._id)}
                                onClick={e => handleCommodityPickupChecked(e, commodityPickup)}
                                data-cy={`commodity-pickup-selection-box-${i}`}
                            />
                        )}
                        <Tooltip title="Edit" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => handleUpdateCommodityPickupDialog(commodityPickup)}
                                disabled={
                                    invoiced ||
                                    !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) ||
                                    _.get(commodityPickup, 'status', '') !== 'Processed' ||
                                    olderThan4Weeks
                                }
                                data-cy={`commodity-pickup-edit-btn-${i}`}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" disableFocusListener disableTouchListener disabled={invoiced}>
                            <IconButton
                                data-cy={`commodity-pickup-delete-btn-${i}`}
                                disabled={
                                    invoiced || !(_user.isSystemAdmin(operator) || _user.isInternalRole(operator))
                                }
                                onClick={() => {
                                    warnAction(
                                        () => handleDeleteCommodityPickup(commodityPickup),
                                        `Are you sure you want to delete this?`
                                    );
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => handlePayloadDialogOpen(commodityPickup)}
                                data-cy={`commodity-pickup-collector-payload-btn-${i}`}
                            >
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Status') && (
                    <TableCell>
                        <InlineChip
                            style={{ margin: theme.spacing.unit * 0.5 }}
                            color={
                                _.get(commodityPickup, 'status', '') === 'Requested'
                                    ? colors.blueGrey[500]
                                    : _.get(commodityPickup, 'status', '') === 'Collected'
                                        ? colors.blue[500]
                                        : colors.green[500]
                            }
                            textColor={'white'}
                            text={_.get(commodityPickup, 'status', '')}
                        />
                    </TableCell>
                )}

                {filteredHeaders.includes('Trailer Number') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>{_.get(commodityPickup, 'trailerLabel', '')}</TableCell>
                )}
                {headers.map(h => h.name).includes('Bottle Depot') && filteredHeaders.includes('Bottle Depot') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {_.get(commodityPickup, 'collector.name', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Reference Number') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {_.get(commodityPickup, 'collectionId')} - {_.get(commodityPickup, 'collectionLabel')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Requested^') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>{createdAtFormatted}</TableCell>
                )}
                {filteredHeaders.includes('Collection Period^') && (
                    <TableCell style={{ whiteSpace: 'noWrap' }}>
                        {startDateFormatted} - {endDateFormatted}
                    </TableCell>
                )}
                {filteredHeaders.includes('Commodity') && (
                    <TableCell>{_.get(commodityPickup, 'commodity.skuType', '')}</TableCell>
                )}
                {filteredHeaders.includes('Total Items') &&
                    (_.get(commodityPickup, 'status', '') !== 'Processed' ? (
                        <TableCell />
                    ) : (
                        <TableCell>
                            {_.sumBy(_.get(commodityPickup, 'payload', []), 'quantity')}{' '}
                            {_.get(commodityPickup, 'commodity.skuType', '') === 'clothing' ? 'lbs' : 'items'}
                        </TableCell>
                    ))}
                {filteredHeaders.includes('Associated Orders') && (
                    <TableCell>
                        {_.get(commodityPickup, 'status', '') !== 'Requested' &&
                            !_.isNil(_.get(commodityPickup, 'bulks'))
                            ? _.get(commodityPickup, 'bulks', []).length
                            : ''}
                    </TableCell>
                )}
                {filteredHeaders.includes('Associated Bags/Items') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'commodityAmountTotal')) &&
                            `${_.get(commodityPickup, 'commodityAmountTotal', '')} ${_.get(
                                commodityPickup,
                                `commodity.${lang}.unit`,
                                ''
                            )}`}
                    </TableCell>
                )}

                {filteredHeaders.includes('Payment Total') && (
                    <TableCell data-cy={`commodity-pickup-payment-total-${i}`}>
                        {!_.isNil(_.get(commodityPickup, 'paymentTotal')) &&
                            formatAsCurrency(_.get(commodityPickup, 'paymentTotal'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Processor Payment') && (
                    <TableCell data-cy={`commodity-pickup-collector-payment-total-${i}`}>
                        {!_.isNil(_.get(commodityPickup, 'collectorPaymentTotal')) &&
                            formatAsCurrency(_.get(commodityPickup, 'collectorPaymentTotal'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Driver Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'driverSplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'driverSplit'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Customer Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'customerSplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'customerSplit'))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Charity Payment') && (
                    <TableCell>
                        {!_.isNil(_.get(commodityPickup, 'charitySplit')) &&
                            formatAsCurrency(_.get(commodityPickup, 'charitySplit'))}
                    </TableCell>
                )}

                {filteredHeaders.includes('Payload') && (
                    <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    width: '350px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'noWrap'
                                }}
                            >
                                {payloadFormatted}
                            </div>
                        </div>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleSubmit = e => {
        handleLoadStats();
        getData();

        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    useEffect(() => {
        if (!filtersLoading) {
            handleLoadStats();
        }
    }, [collectorsSelected, commoditiesSelected, filtersLoading]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {' '}
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            {(_user.isSystemAdmin(operator) ||
                                                _user.isInternalRole(operator) ||
                                                _.get(operator, 'collectors', []).length > 1) && (
                                                    <FormControl>
                                                        <Select
                                                            multiple
                                                            disabled={loading}
                                                            onChange={e => setCollectorsSelected(e.target.value)}
                                                            style={{
                                                                maxWidth: '250px',
                                                                margin: theme.spacing.unit
                                                            }}
                                                            value={collectorsSelected}
                                                            renderValue={s => `${s.length} Processors`}
                                                            data-cy="commodity-pickups-collector-select"
                                                        >
                                                            <div
                                                                style={{ display: 'flex', justifyContent: 'space-around' }}
                                                            >
                                                                <Button
                                                                    disabled={loading}
                                                                    onClick={handleAllFilterCollectors(true)}
                                                                >
                                                                    All
                                                                </Button>
                                                                <Button
                                                                    disabled={loading}
                                                                    onClick={handleAllFilterCollectors(false)}
                                                                    data-cy="commodity-pickups-collector-select-none"
                                                                >
                                                                    None
                                                                </Button>
                                                            </div>
                                                            {allCollectors.map(({ _id, name }) => (
                                                                <MenuItem
                                                                    disabled={loading}
                                                                    key={_id}
                                                                    value={_id}
                                                                    data-cy={`commodity-pickups-collector-select-${_.kebabCase(
                                                                        name
                                                                    )}`}
                                                                >
                                                                    <Checkbox checked={collectorsSelected.includes(_id)} />
                                                                    <ListItemText>{name}</ListItemText>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setCommoditiesSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={commoditiesSelected}
                                                    renderValue={s => `${s.length} Commodities`}
                                                    data-cy="commodity-pickups-commodity-select"
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCommodities(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCommodities(false)}
                                                            data-cy="commodity-pickups-commodity-select-none"
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allCommodities.map(({ _id, skuType }) => (
                                                        <MenuItem
                                                            disabled={loading}
                                                            key={_id}
                                                            value={_id}
                                                            data-cy={`commodity-pickups-commodity-select-${_.kebabCase(skuType)}`}
                                                        >
                                                            <Checkbox checked={commoditiesSelected.includes(_id)} />
                                                            <ListItemText style={{ textTransform: 'capitalize' }}>
                                                                {skuType}
                                                            </ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headers.map(({ name, locKey }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                        data-cy="commodity-pickups-search-btn"
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton
                                                onClick={handleFiltersOpen()}
                                                data-cy="commodity-pickups-filters-btn"
                                            >
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <div
                style={{
                    padding: theme.spacing.unit,
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2
                }}
            >
                {statsLoading && <LinearProgress />}
                <Paper
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        padding: theme.spacing.unit * 2,
                        height: '100%',
                        flexWrap: 'wrap'
                    }}
                >
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>fitness_center</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Items/Lbs: {_.get(stats, 'items', 0)}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>ballot</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Orders: {_.get(stats, 'orders', 0)}
                        </Typography>
                    </span>
                    {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                        <>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>attach_money</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Payment Total: {formatAsCurrency(_.get(stats, 'paymentTotal', 0))}
                                </Typography>
                            </span>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>local_shipping</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Processor Total: {formatAsCurrency(_.get(stats, 'collectorPayment', 0))}
                                </Typography>
                            </span>
                        </>
                    )}
                </Paper>
            </div>
            <DashBoardTable
                title={'Requested Pickups'}
                loading={loading || tableLoading || filtersLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={
                    (_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                        <Button
                            color="secondary"
                            disabled={loading || _.isEmpty(idsChecked)}
                            variant="outlined"
                            onClick={handleOpenCompleteDialog}
                            data-cy="commodity-pickup-completion-btn"
                        >
                            Complete
                        </Button>
                    )
                }
                customActionAlign="left"
            >
                {filteredData &&
                    filteredData.map((commodityPickup, i) => {
                        return renderRow(commodityPickup, filteredHeaders, i);
                    })}
            </DashBoardTable>
            <GenericDialog
                open={payloadDialogOpen}
                onClose={() => setPayloadDialogOpen(false)}
                title={`${uppercaseFirstLetter(_.get(commodityPickupSelected, 'commodity.skuType', ''))} Pickup`}
                message={
                    <>
                        <div>
                            <b>Reference Number:</b> {_.get(commodityPickupSelected, 'collectionId', '')} -{' '}
                            {_.get(commodityPickupSelected, 'collectionLabel', '')}
                        </div>
                        <div style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <b>Collection Period:</b>{' '}
                            {formatDate(
                                _.get(commodityPickupSelected, 'collectionPeriod.startDate'),
                                tzCommodityPickupSelected
                            )}{' '}
                            -{' '}
                            {formatDate(
                                _.get(commodityPickupSelected, 'collectionPeriod.endDate'),
                                tzCommodityPickupSelected
                            )}
                        </div>

                        <ImageViewer
                            displayImages={_.get(commodityPickupSelected, 'imagesOnRequest', [])}
                            disableDelete={true}
                            scrollerStyles={{
                                maxWidth: 420,
                                overflowX: 'auto',
                                marginBottom: theme.spacing.unit
                            }}
                        />

                        <div style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <b>Comments:</b>
                            <br /> <Typography>{_.get(commodityPickupSelected, 'commentsOnRequest', '')}</Typography>
                            <Typography>{_.get(commodityPickupSelected, 'commentsOnCollection', '')}</Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>
                                <b>Payload:</b>
                            </span>
                            {_(_.get(commodityPickupSelected, 'payload', []))
                                .filter(p => p.quantity > 0)
                                .map((p, i) => (
                                    <span key={i} data-cy={`commodity-pickup-payload-${_.kebabCase(p.description)}`}>
                                        {p.description}: {p.quantity} {p.unit || ''}
                                    </span>
                                ))
                                .value()}
                        </div>
                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                            Total Items: {_.sumBy(_.get(commodityPickupSelected, 'payload', []), 'quantity')}
                        </div>
                    </>
                }
            />
            <CommodityPickupDialog
                open={commodityPickupDialogOpen}
                commodity={editingCommodityPickup ? _.get(commodityPickupSelected, 'commodity') : commodityToComplete}
                commodityPickup={editingCommodityPickup ? commodityPickupSelected : null}
                loading={loading || tableLoading}
                onClose={() => setCommodityPickupDialogOpen(false)}
                onSubmit={editingCommodityPickup ? handleUpdateCommodityPickup : handleCompleteCommodityPickups}
            />

            <Dialog open={imageDialogOpen} fullWidth onClose={() => setImageDialogOpen(false)}>
                <ImageViewer
                    displayImages={images}
                    disableDelete={true}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
            <TrailerLabelDialog
                open={trailerLabelDialogOpen}
                trailerLabel={trailerLabel}
                onClose={handleCloseTrailerLabelDialog}
                onLabelChange={handleChangeLabel}
                onSubmit={handleSubmitTrailerLabel}
            />
        </>
    );
}

export default withTheme()(CommodityPickups);

function formatDate(date, timezone) {
    return !_.isNil(date)
        ? moment(date)
            .tz(timezone)
            .format('MMM D, YYYY')
        : 'N/A';
}
