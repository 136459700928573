import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

import {
    Icon,
    colors,
    withTheme,
    Typography,
    Avatar,
    Link,
    Dialog,
    CircularProgress,
    Tooltip,
    IconButton
} from '@material-ui/core';

import { List, ListItem, ListItemText } from '@material-ui/core';

import { mdiCounter } from '@mdi/js';

import { Icon as MDIcon } from '@mdi/react';

import { deviceHelper } from '../../utils/misc.js';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function ComplaintsWidget(props) {
    const {
        operator,
        pickupsWithIssues,
        bulksWithIssues,
        systemComplaints,
        theme,
        history,
        loading,
        isDriver = false,
        height,
        noPadding,
        unviewedComplaintIdsState,
        unviewedComplaintBulkIdsState
    } = props;

    const [complaintImages, setComplaintImages] = useState(null);
    const [allComplaints, setAllComplaints] = useState([]);

    const http = useContext(HttpContext);

    useEffect(() => {
        const complaintsList = buildComplaintsList(
            pickupsWithIssues,
            bulksWithIssues,
            systemComplaints,
            unviewedComplaintIdsState,
            unviewedComplaintBulkIdsState
        );

        setAllComplaints(complaintsList);
    }, [
        pickupsWithIssues,
        bulksWithIssues,
        systemComplaints,
        unviewedComplaintIdsState,
        unviewedComplaintBulkIdsState
    ]);

    const openComplaintInBulks = async (operatorId, complaint) => {
        let { complaintId } = complaint;

        if (complaint.complaintItemType !== 'bulk') {
            const res = await http.getJSON(`/bulk?pickup_id=${complaintId}`);
            if (res.ok) complaintId = res.data.bulk._id;
        }

        try {
            const URL = `/operators/${operatorId}/bulks/${complaintId}`;
            if (!deviceHelper.isNativeApp()) {
                window.open(URL, '_system');
            } else {
                history.push(URL);
            }
        } catch (err) {
            console.error('Error opening complaint');
        }
    };

    if (loading) {
        return (
            <div style={{ padding: theme.spacing.unit }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            </div>
        );
    }

    if (_.isEmpty(allComplaints)) {
        return (
            <div style={{ padding: theme.spacing.unit, height: height ? height + 16 : undefined }}>
                <Typography style={{ margin: theme.spacing.unit * 2 }}>No Complaints</Typography>
            </div>
        );
    }
    return (
        <>
            <div style={{ padding: noPadding ? 0 : theme.spacing.unit, width: '100%' }}>
                <List
                    dense
                    style={{
                        maxHeight: height ? height : 200,
                        height: height ? height : undefined,
                        overflowY: 'auto'
                    }}
                >
                    {allComplaints.map(complaint => {
                        const issueImages = complaint.issueImages;
                        let secondary = 'Sorting complaint';
                        if (complaint.complaintItemType === 'pickup') secondary = 'Pickup complaint';
                        if (complaint.complaintItemType === 'system') secondary = 'System complaint';
                        let icon = <MDIcon path={mdiCounter} size={1} color={'white'} />;
                        if (complaint.complaintItemType === 'pickup') icon = <Icon>local_shipping</Icon>;
                        if (complaint.complaintItemType === 'system') icon = <Icon>settings</Icon>;

                        return (
                            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Avatar style={{ backgroundColor: theme.palette.primary.main }}>{icon}</Avatar>
                                <ListItemText
                                    style={{ fontSize: '0.875rem' }}
                                    primary={
                                        <>
                                            {!_.isNil(complaint.unresolved) && complaint.complaintItemType === 'bulk' && (
                                                <span
                                                    style={{
                                                        background: complaint.unresolved
                                                            ? colors.red[500]
                                                            : colors.blue[100],
                                                        color: theme.palette.getContrastText(
                                                            complaint.unresolved ? colors.red[500] : colors.blue[100]
                                                        ),
                                                        fontSize: theme.typography.fontSize * 0.875,
                                                        marginRight: theme.spacing.unit,
                                                        padding: '2px 4px',
                                                        borderRadius: 4
                                                    }}
                                                >
                                                    {complaint.unresolved ? 'UNRESOLVED' : 'RESOLVED'}
                                                </span>
                                            )}
                                            {(operator.accountType === 'Collector Employee' &&
                                                !operator.accountPermissions.includes('Clerk')) ||
                                            (complaint.complaintItemType === 'system' ||
                                                complaint.complaintItemType === 'System') ? (
                                                <span>"{complaint.issueDescription}"</span>
                                            ) : (
                                                <Link
                                                    color="inherit"
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        if (!isDriver) openComplaintInBulks(operator._id, complaint);
                                                    }}
                                                >
                                                    "{complaint.issueDescription}"
                                                </Link>
                                            )}
                                        </>
                                    }
                                    secondary={
                                        <>
                                            {secondary} - {moment(complaint.date).format('MMM DD, YYYY')}
                                        </>
                                    }
                                />
                                {!_.isEmpty(issueImages) && (
                                    <Tooltip title="View Complaint Images">
                                        <IconButton
                                            onClick={() => {
                                                setComplaintImages(issueImages);
                                            }}
                                        >
                                            <Icon>image</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            </div>
            <Dialog open={!_.isNil(complaintImages)} fullWidth onClose={() => setComplaintImages(null)}>
                <ImageViewer
                    displayImages={complaintImages}
                    disableDelete={true}
                    innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
        </>
    );
}

export default withTheme()(ComplaintsWidget);

const buildComplaintsList = (
    pickupsWithIssues = [],
    bulksWithIssues = [],
    systemComplaints = [],
    unviewedComplaintIdsState = [],
    unviewedComplaintBulkIdsState = []
) => {
    const complaintsList = [];

    pickupsWithIssues.forEach(pickup => {
        if (!unviewedComplaintIdsState.includes(pickup._id)) {
            return;
        }
        complaintsList.push({
            complaintId: pickup._id,
            complaintItemType: 'pickup',
            date: pickup.completionDate,
            unresolved: false, // No way to resolve atm
            issueDescription: pickup.customerIssues.issueDescription
        });
    });

    bulksWithIssues.forEach(bulk => {
        if (!unviewedComplaintBulkIdsState.includes(bulk._id)) {
            return;
        }
        const customerIssues = _.get(bulk, 'customerIssues', []);
        if (!_.isEmpty(customerIssues)) {
            complaintsList.push({
                complaintId: bulk._id,
                complaintItemType: 'bulk',
                date: bulk.dateCompleted,
                unresolved: _.isNil(bulk.customerIssues.dateResolved),
                issueDescription: bulk.customerIssues.issueDescription
            });
        }

        const counterIssues = _.get(bulk, 'driverIssues.issues', []);
        if (!_.isEmpty(counterIssues)) {
            let issueDescription = bulk.driverIssues.issues.join(', ');
            if (!_.isNil(bulk.driverIssues.description)) {
                issueDescription += ', ' + bulk.driverIssues.description;
            }
            if (
                !_.isNil(_.get(bulk, 'driverIssues.reporter.name.first', null)) &&
                !_.isNil(_.get(bulk, 'driverIssues.reporter.name.last', null))
            ) {
                issueDescription +=
                    ' - ' + bulk.driverIssues.reporter.name.first + ' ' + bulk.driverIssues.reporter.name.last;
            }
            complaintsList.push({
                complaintId: bulk._id,
                complaintItemType: 'bulk',
                date: bulk.datePickedUp,
                unresolved: null, // No way to resolve atm
                issueDescription: issueDescription
                // issueImages: bulk.driverIssues.images
            });
        }
    });

    systemComplaints.forEach(complaint => {
        if (!unviewedComplaintIdsState.includes(complaint._id)) {
            return;
        }
        let issueDescription = complaint.description;
        if (
            !_.isNil(_.get(complaint, 'offender.name.first', null)) &&
            !_.isNil(_.get(complaint, 'offender.name.last', null))
        ) {
            issueDescription += ' - ' + complaint.offender.name.first + ' ' + complaint.offender.name.last;
        }
        complaintsList.push({
            complaintItemType: 'system',
            date: complaint.createdAt,
            unresolved: null, // No way to resolve atm
            issueDescription: issueDescription
        });
    });

    sortComplaints(complaintsList);

    return complaintsList;
};

const sortComplaints = complaintsList => {
    //sort by unresolved/resolved then by date

    complaintsList.sort((a, b) => {
        if (a.unresolved && !b.unresolved) {
            return -1;
        } else if (!a.unresolved && b.unresolved) {
            return 1;
        } else {
            if (a.date > b.date) {
                return -1;
            } else if (a.date < b.date) {
                return 1;
            } else {
                return 0;
            }
        }
    });
};
