import React, { useContext, useState } from 'react';

import _ from 'lodash';
import { _bulk, _user } from 'std';

import { Grid } from '@material-ui/core';

import BulkSorterCard from './BulkSorterCard';

import {
    CardContent,
    TextField,
    CardActionArea,
    Card,
    Divider,
    Button,
    Hidden,
    colors,
    withTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { Icon as MDIcon } from '@mdi/react';
import { mdiExclamationThick, mdiCheckBold } from '@mdi/js';

import { deviceHelper } from 'utils/misc';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import OperatorContext from 'utils/contexts/OperatorContext';

const numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3'];
const presets = ['12', '24', '36', '48'];
const PAYMENT_TYPES = ['credit', 'debit', 'cash'];
const keySizeMultiplier = { Normal: 1, Large: 1.25, Small: 0.75 };

function Keypad({
    theme,
    bulkStationConfig,
    skus,
    bulkSkuType,
    disableEditing,
    amount,
    display,
    bulkDonating,
    handleAddCountsAndUpdateBulkSorter,
    onCustomFeeDialog,
    handleClear,
    grandTotal,
    hasOperator,
    handlePresetButtonClick,
    handleNumButtonClick,
    // handleAppendNumber,
    // handleRemoveNumber,
    // handleResetAndUpdateBulkSorter,
    handleAddOperator,
    handleCalculateResult,
    handleApplyCustomFee,
    handleNumInputChange,
    setIdleCountdown,
    resetTimeoutCount,
    inProgress,
    bulk,
    onOpenReportDialog,
    onOpenComplaintDialog,
    hideIssue = false,
    opaque = false,
    reportType,
    hidePreset = false,
    hideKeypad = false,
    keySize = 'Normal',
    onOpenAICountDialog,
    currentCountingSessionId,
    onSnackbar
}) {
    const operator = useContext(OperatorContext);
    const [customerPaymentDialog, setCustomerPaymentDialog] = useState(false);
    const [customerPaymentForm, setCustomerPaymentForm] = useState({
        amount: null,
        type: PAYMENT_TYPES[0],
        note: '',
        lastFourDigits: ''
    });

    const handleCustomerPaymentDialog = open => () => {
        if (open && (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId))) {
            onSnackbar('Please select or create a counting session first', 'error');
            return;
        }
        if (open) {
            setIdleCountdown(0);
        }
        resetTimeoutCount();
        setCustomerPaymentDialog(open);
    };

    const handleCustomerPaymentForm = (e, type) => {
        resetTimeoutCount();
        const { name, value } = e.target;
        if (type) {
            setCustomerPaymentForm({ ...customerPaymentForm, type });
        } else {
            setCustomerPaymentForm({ ...customerPaymentForm, [name]: value });
        }
    };

    const handleCustomerPayment = () => {
        resetTimeoutCount();

        let description = `paid with card ending in ${customerPaymentForm.lastFourDigits}`;
        handleApplyCustomFee({
            description,
            amount: -customerPaymentForm.amount * 100,
            contractorShare: 1,
            note: customerPaymentForm.note
        });

        setCustomerPaymentForm({
            amount: null,
            type: PAYMENT_TYPES[0],
            note: '',
            lastFourDigits: ''
        });
        setCustomerPaymentDialog(false);
    };

    bulkStationConfig.sort((a, b) => {
        return a.index - b.index;
    });
    const bulkSorterCards = bulkStationConfig.map(config => {
        const tempSku = _(skus[bulkSkuType])
            .filter(sku => sku.sku === config.sku)
            .value();
        return { ..._.first(tempSku), ...config };
    });

    // const showNums = !deviceHelper.isNativeApp();

    const isLargeScreen = useMediaQuery('(min-height: 750px)');

    const keypadButtonHeight = theme.spacing.unit * (isLargeScreen ? 6 : 4) * keySizeMultiplier[keySize];

    const itemStyle = {
        height: keypadButtonHeight,
        marginBottom: theme.spacing.unit
    };

    const buttonStyle = {
        height: keypadButtonHeight,
        fontSize: 20
    };

    return (
        <>
            <div
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
            >
                <Grid container spacing={theme.spacing.unit}>
                    {bulkSorterCards.map((sku, index) => {
                        return (
                            (_.get(sku, 'countable', false) || sku.special) && (
                                <BulkSorterCard
                                    key={sku.sku}
                                    sku={sku}
                                    disableEditing={disableEditing}
                                    amount={amount}
                                    onAddCountsAndUpdateBulkSorter={handleAddCountsAndUpdateBulkSorter}
                                    setIdleCountdown={setIdleCountdown}
                                    resetTimeoutCount={resetTimeoutCount}
                                    operator={operator}
                                    bulkDonating={bulkDonating}
                                    onCustomFeeDialog={onCustomFeeDialog}
                                    opaque={opaque}
                                    bulk={bulk}
                                    onOpenReportDialog={onOpenReportDialog}
                                    reportType={reportType}
                                    onOpenAICountDialog={onOpenAICountDialog}
                                />
                            )
                        );
                    })}

                    {_bulk.isAdjustment(bulk) &&
                        (_user.isSystemAdmin(operator) ||
                            _user.isInternalRole(operator) ||
                            _.get(
                                operator,
                                'collector.configuration.bulkPermissions.customerPayment.create',
                                false
                            )) && (
                            <Grid item xs={12}>
                                <Card
                                    style={{
                                        backgroundColor: colors.grey[300]
                                    }}
                                    elevation={0}
                                >
                                    <CardActionArea
                                        onClick={handleCustomerPaymentDialog(true)}
                                        disabled={disableEditing}
                                    >
                                        <CardContent style={{ padding: theme.spacing.unit + 4 }}>
                                            <table
                                                style={{
                                                    tableLayout: 'fixed',
                                                    width: '100%'
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span className="scalingFont">Customer Payment</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>{' '}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )}
                    {_bulk.hasCustomerIssue(bulk) &&
                        (operator.accountType === 'System Administrator' ||
                            operator.accountType === 'Collector Administrator' ||
                            _user.isInternalRole(operator)) && (
                            <Grid item xs={12}>
                                <ListItem
                                    style={{
                                        backgroundColor: _bulk.hasUnresolvedCustomerIssue(bulk)
                                            ? colors.red[100]
                                            : colors.green[100],
                                        borderRadius: 2
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                            <MDIcon
                                                path={
                                                    _bulk.hasUnresolvedCustomerIssue(bulk)
                                                        ? mdiExclamationThick
                                                        : mdiCheckBold
                                                }
                                                size={1}
                                                color={
                                                    _bulk.hasUnresolvedCustomerIssue(bulk)
                                                        ? colors.red[300]
                                                        : colors.green[300]
                                                }
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                            style: { marginRight: theme.spacing.unit * 11 }
                                        }}
                                        primary={
                                            _bulk.hasUnresolvedCustomerIssue(bulk)
                                                ? 'Unresolved Complaint'
                                                : 'Resolved Complaint'
                                        }
                                    />
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        disabled={inProgress}
                                        size="small"
                                        onClick={() => {
                                            onOpenComplaintDialog();
                                            resetTimeoutCount();
                                        }}
                                        style={{ width: '120px' }}
                                    >
                                        {_bulk.hasUnresolvedCustomerIssue(bulk) ? 'resolve' : 'edit'}
                                    </Button>
                                </ListItem>
                            </Grid>
                        )}
                </Grid>
            </div>
            <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
            <Grid
                container
                spacing={8}
                style={{
                    justifyContent: 'center'
                }}
            >
                {!hasOperator && (
                    <Grid item xs={8} style={{ ...itemStyle }}>
                        <TextField
                            data-cy="por-screen-count-input"
                            type="tel"
                            variant="outlined"
                            fullWidth
                            disabled={disableEditing}
                            InputProps={{
                                min: 0,
                                style: { height: keypadButtonHeight, fontSize: 20 },
                                readOnly: !deviceHelper.isNativeApp() ? false : !hideKeypad
                            }}
                            value={amount.toString()}
                            onChange={handleNumInputChange}
                        />
                    </Grid>
                )}
                {hasOperator && (
                    <Grid item xs={8} style={{ ...itemStyle }}>
                        <TextField
                            data-cy="por-screen-count-input-display"
                            variant="outlined"
                            fullWidth
                            disabled={disableEditing}
                            InputProps={{
                                min: 0,
                                style: { height: keypadButtonHeight, fontSize: 20 },
                                readOnly: !deviceHelper.isNativeApp() ? false : !hideKeypad
                            }}
                            value={display}
                            onChange={handleNumInputChange}
                            onKeyDown={e => (e.keyCode === 13 ? handleCalculateResult() : null)}
                        />
                    </Grid>
                )}

                {!hideKeypad && (
                    <Grid item xs={4} style={itemStyle}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={disableEditing || hasOperator}
                            style={buttonStyle}
                            fullWidth
                            onClick={() => {
                                handleAddOperator('X');
                            }}
                        >
                            Times
                        </Button>
                    </Grid>
                )}

                {/* <Grid item xs={4} style={{ ...itemStyle }}>
                    <Button
                        data-cy="bulk-counter-return-value-0"
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={disableEditing}
                        style={buttonStyle}
                        fullWidth
                        onClick={() => {
                            warnAction(() => {
                                handleResetAndUpdateBulkSorter();
                            }, 'You are about to reset all sku totals. Are you sure you want to do this?');
                        }}
                    >
                        RESET
                    </Button>
                </Grid> */}
                {!hidePreset &&
                    presets.map((num, index) => {
                        return (
                            <Grid item xs={6} key={`${num}-${index}-0`} style={{ ...itemStyle }}>
                                <Button
                                    data-cy="bulk-counter-return-value-0"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={disableEditing}
                                    style={buttonStyle}
                                    fullWidth
                                    onClick={() => {
                                        handlePresetButtonClick(num);
                                    }}
                                >
                                    {num}
                                </Button>
                            </Grid>
                        );
                    })}
                <>
                    {!hideKeypad &&
                        numbers.map((num, index) => {
                            return (
                                <Grid item xs={4} key={`${num}-${index}-1`} style={itemStyle}>
                                    <Button
                                        data-cy="bulk-counter-return-value-0"
                                        variant="contained"
                                        color="default"
                                        size="large"
                                        disabled={disableEditing}
                                        style={buttonStyle}
                                        fullWidth
                                        onClick={() => {
                                            handleNumButtonClick(num);
                                        }}
                                    >
                                        {num}
                                    </Button>
                                </Grid>
                            );
                        })}
                    {!hideKeypad && (
                        <Grid item xs={8} style={itemStyle}>
                            <Button
                                data-cy="bulk-counter-return-value-0"
                                variant="contained"
                                color="default"
                                size="large"
                                disabled={disableEditing}
                                style={buttonStyle}
                                fullWidth
                                onClick={() => {
                                    handleNumButtonClick(0);
                                }}
                            >
                                0
                            </Button>
                        </Grid>
                    )}

                    <Grid item xs={4} style={{ ...itemStyle }}>
                        <Button
                            data-cy="bulk-counter-return-value-0"
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={disableEditing}
                            style={buttonStyle}
                            fullWidth
                            onClick={handleClear}
                        >
                            CLEAR
                        </Button>
                    </Grid>
                </>
            </Grid>

            <Dialog open={customerPaymentDialog} onClose={handleCustomerPaymentDialog(false)}>
                <DialogTitle>Customer Payment Amount</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="amount"
                        label="Amount"
                        name="amount"
                        fullWidth
                        variant="outlined"
                        value={customerPaymentForm.amount || ''}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        onChange={handleCustomerPaymentForm}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="lastFourDigits"
                        label="Last Four Digits of Card"
                        name="lastFourDigits"
                        fullWidth
                        variant="outlined"
                        value={customerPaymentForm.lastFourDigits || ''}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    style={{ width: 120, color: theme.palette.text.disabled }}
                                    position="start"
                                >
                                    **** **** ****{' '}
                                </InputAdornment>
                            )
                        }}
                        onChange={handleCustomerPaymentForm}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="note"
                        label="Note"
                        name="note"
                        fullWidth
                        variant="outlined"
                        value={customerPaymentForm.note || ''}
                        onChange={handleCustomerPaymentForm}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCustomerPaymentDialog(false)}>Cancel</Button>
                    <Button onClick={handleCustomerPayment} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(Keypad);
