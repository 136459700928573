import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { formatAsCurrency, getCustomerFirstNameAndLastInitial } from 'utils/misc';

import { _bulk, _rates, _user } from 'std';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import {
    Typography,
    Divider,
    Button,
    IconButton,
    colors,
    withTheme,
    Card,
    CardActionArea,
    Icon,
    Tooltip
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiAccount, mdiClock, mdiClose, mdiTagArrowDown, mdiPrinter, mdiAccountHardHat } from '@mdi/js';
import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';

const CountsScreenV2 = React.forwardRef((props, ref) => {
    const scrollPanelRef = useRef();
    const {
        theme,
        bulk = {},
        disableEditing,
        inProgress,
        customFeesForm,
        skus,
        countTotal,
        grandTotal,
        totalCountFormAmount,
        hideCounter = false,
        countFontSize = null,
        totalsFontSize = null,
        headerFooterColor = null,
        headerFooterTextColor = props.theme.palette.text.primary,
        padding = 2,
        onRemoveCounts,
        onRemoveCustomFee,
        onClearCountsAndFees,
        setIdleCountdown,
        resetTimeoutCount,
        printingReceipt,
        commodityColors,
        setCurrentCountingSessionId,
        commodities,
        lang,
        currentCountingSessionId,
        operator,
        collector,
        promoCodes,
        setCreateEditNumberOfCommoditiesDialogOpen,
        handleRemoveCountingSession,
        handleRemoveSessionlessCounts,
        handleRemoveCustomFeeFromSessionAndBulk,
        onImageDialog,
        setImages,
        handleScrollBarWidth,
        setCreatEditNumberOfCommoditiesMode
    } = props;
    const warnAction = useContext(ConfirmDialogContext);

    // const aggregatedCounts = useMemo(() => _bulk.getCountsItemsWithAmount(bulk, skus[bulk.skuType] || []), [
    //     bulk,
    //     skus
    // ]);

    const serviceFeesForSkuType = useMemo(() => {
        const collectorServiceFees = _.get(collector, 'configuration.serviceFees', []);
        return _.find(collectorServiceFees, { skuType: _.get(bulk, 'skuType') });
    }, [collector, bulk]);
    const breakdowns = useMemo(() => getBreakdowns(bulk, skus[bulk.skuType] || [], grandTotal + totalCountFormAmount), [
        bulk,
        grandTotal,
        skus
    ]);

    const [sessionlessCounts, setSessionlessCounts] = useState([]);
    const [sessionlessFees, setSessionlessFees] = useState([]);
    const [sessionlessCountsItemsWithAmount, setSessionlessCountsItemsWithAmount] = useState([]);
    const [countImageUrlMap, setCountImageUrlMap] = useState({});
    useEffect(() => {
        setSessionlessCounts(getSessionlessCounts());
        setSessionlessFees(getSessionlessFees());
        setCountImageUrlMap(getCountImageUrlMap());
    }, [bulk]);
    function getSessionlessCounts() {
        let sessionItemIds = {};
        let newSessionCounts = [];
        _.get(bulk, 'countingSessions', []).forEach(session => {
            _.get(session, 'items', []).forEach(item => {
                let itemId = _.get(item, '_id', null);
                if (!_.isNil(itemId)) {
                    sessionItemIds[itemId] = itemId;
                }
            });
        });
        let sessionItemIdsArray = Object.values(sessionItemIds);
        _.get(bulk, 'counts', []).forEach(count => {
            let itemId = _.get(_.first(_.get(count, 'items', [])), '_id', null);
            if (!_.isNil(itemId) && _.isArray(sessionItemIdsArray) && !sessionItemIdsArray.includes(itemId)) {
                newSessionCounts.push(count);
            }
        });
        setSessionlessCountsItemsWithAmount(
            _bulk.getCountsItemsWithAmount({ counts: newSessionCounts }, skus[bulk.skuType] || [])
        );
        return newSessionCounts;
    }
    function getSessionlessFees() {
        let sessionFeeIds = {};
        let newSessionlessFees = [];
        _.get(bulk, 'countingSessions', []).forEach(session => {
            _.get(session, 'customFees', []).forEach(fee => {
                let feeId = _.get(fee, '_id', null);
                if (!_.isNil(feeId)) {
                    sessionFeeIds[feeId] = feeId;
                }
            });
        });
        _.get(bulk, 'customFees', []).forEach((fee, index) => {
            let feeId = _.get(fee, '_id', null);

            //Hide generated fees(e.g. min pickup fee) to avoid confusing collectors/counters
            const hideFee = _.get(fee, 'generatedOnBulkCompletion', false) && !isAdminOrCSR;

            if (!_.isNil(feeId) && sessionFeeIds[feeId] === undefined && !hideFee) {
                newSessionlessFees.push({ ...fee, index: index });
            }
        });
        return newSessionlessFees;
    }
    function getCountImageUrlMap() {
        if (_.isNil(bulk)) return {};
        let newCountImageMap = {};
        _.get(bulk, 'counts', []).forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                if (!_.isNil(_.get(item, 'countImageUrl', null))) {
                    newCountImageMap[item._id] = item.countImageUrl;
                }
            });
        });
        return newCountImageMap;
    }

    const headerFooterStyle = {
        fontSize: totalsFontSize,
        color: headerFooterTextColor
    };
    const countsStyle = { fontSize: countFontSize, paddingLeft: padding };
    const isAdminOrCSR = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);

    useEffect(() => {
        const checkForScrollbar = () => {
            if (scrollPanelRef.current) {
                const scrollBarWidth = scrollPanelRef.current.offsetWidth - scrollPanelRef.current.clientWidth;
                handleScrollBarWidth(scrollBarWidth);
            }
        };
        checkForScrollbar();

        window.addEventListener('resize', checkForScrollbar);
        return () => {
            window.removeEventListener('resize', checkForScrollbar);
        };
    }, []);
    return (
        <div
            // ref={ref}
            style={{
                overflowY: 'auto',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                margin: !printingReceipt ? 0 : 20
            }}
        >
            <div
                ref={scrollPanelRef}
                style={{ overflowY: 'auto', flex: `1 1 auto`, minHeight: !printingReceipt ? '150px' : '0px' }}
                id="custom-scroller"
            >
                {_.get(bulk, 'countingSessions', []).map((session, idx) => {
                    let sessionCounts = _bulk.getCountsItemsWithAmount({ counts: [session] }, skus[bulk.skuType] || []);

                    let commodityCounted = _.get(session, 'commodityCounted', []);
                    let renderList = [];
                    const currentSkuCom = _.find(commodities, {
                        skuType: _.get(bulk, 'skuType', '')
                    });
                    let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                    let bagtag = session.bagtag;
                    let dateBagtagPrinted = session.dateBagtagPrinted;

                    let tomraVariables = session.tomraVariables;
                    let machineCounter = '';
                    if (tomraVariables) {
                        const machine = tomraVariables.rvmName;
                        const serialNumber = tomraVariables.rvmSerialNo;
                        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;
                    }

                    const showHeader = session.tomraVariables || bulk.tomraVariables; // Update to true for any and all API submission

                    commodityCounted.forEach(commodityCount => {
                        let amount = _.get(commodityCount, 'amount', 0);
                        if (amount === 0) return;
                        let isSubCommodity = _.get(commodityCount, 'isSubCommodity', true);
                        let subCommodityId = _.get(commodityCount, 'subCommodity', 0);
                        if (!isSubCommodity) {
                            renderList.push({
                                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                                amount: amount,
                                name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), amount),
                                bagtag,
                                dateBagtagPrinted
                            });
                        } else {
                            let subCommodity = _.find(subCommodityLookup, subComLookupItem => {
                                return subComLookupItem._id === subCommodityId;
                            });
                            let name = _.get(subCommodity, `name.${lang}`, '');
                            renderList.push({
                                color: _.get(subCommodity, 'color', colors.blue[300]),
                                icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                                amount: amount,
                                name: shortenSubComName(name, amount)
                            });
                        }
                    });
                    let hasEditPerm =
                        operator._id === _.get(session, 'operator._id', '') || _user.isCollectorAdmin(operator);

                    return (
                        <Card
                            data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                            elevation={0}
                            style={{
                                marginTop: theme.spacing.unit,
                                border: '1px solid',
                                borderColor:
                                    currentCountingSessionId === _.get(session, '_id', '')
                                        ? theme.palette.primary.main
                                        : theme.palette.grey[300],
                                maxHeight: '100%',
                                position: 'relative',
                                padding: '4px 0'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: theme.spacing.unit / 4,
                                    paddingLeft: theme.spacing.unit / 2,
                                    paddingRight: theme.spacing.unit * 2,
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    {currentCountingSessionId === _.get(session, '_id', '') &&
                                        (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                            <IconButton
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    setCreatEditNumberOfCommoditiesMode('edit');
                                                    setCreateEditNumberOfCommoditiesDialogOpen(true);
                                                }}
                                                data-cy={`count-session-edit-${idx}`}
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        )}
                                    {currentCountingSessionId === _.get(session, '_id', '') &&
                                        _.get(bulk, 'countingSessions', []).length > 1 &&
                                        (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                            <IconButton
                                                data-cy={`count-session-delete-${idx}`}
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    if (_.get(bulk, 'countingSessions', []).length < 2) return;
                                                    warnAction(() => {
                                                        handleRemoveCountingSession(currentCountingSessionId);
                                                    }, 'You are about to delete this session. Do you wish to continue?');
                                                }}
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        )}
                                    {renderList.length === 0 && (
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                alignSelf: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            No commodities
                                        </Typography>
                                    )}
                                    <div
                                        style={{
                                            flexWrap: 'wrap',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        {(renderList || []).map(renderItem => {
                                            return (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: 'max-content',
                                                        flexDirection: 'row',
                                                        marginRight: 5
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignSelf: 'center',
                                                            justifyContent: 'center',
                                                            height: 16
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={_.get(renderItem, 'icon', '')}
                                                            size={0.7}
                                                            color={_.get(renderItem, 'color', '')}
                                                        />
                                                    </div>
                                                    <Typography
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            alignSelf: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {_.get(renderItem, 'amount', '')}{' '}
                                                        {_.get(renderItem, 'name', '')}
                                                    </Typography>

                                                    {/* {renderItem.bagtag && (
                                                        <Tooltip title={renderItem.bagtag}>
                                                            <span style={{ alignSelf: 'center' }}>
                                                                <MDIcon
                                                                    path={mdiTagArrowDown}
                                                                    size={0.7}
                                                                    style={{
                                                                        marginLeft: theme.spacing.unit / 2
                                                                    }}
                                                                    color={colors.grey[500]}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    )} */}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {currentCountingSessionId === _.get(session, '_id', '') && (
                                    <Typography
                                        style={{
                                            whiteSpace: 'nowrap',
                                            alignSelf: 'center',
                                            justifyContent: 'center',
                                            color: theme.palette.grey[500],
                                            fontSize: 13
                                        }}
                                    >
                                        sorting
                                    </Typography>
                                )}
                                {currentCountingSessionId !== _.get(session, '_id', '') &&
                                    (isAdminOrCSR || hasEditPerm) &&
                                    (!_bulk.isCompleted(bulk) && !_bulk.isInvoiced(bulk)) && (
                                        <div
                                            style={{
                                                alignSelf: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Button
                                                data-cy={`count-session-select-${idx}`}
                                                color="primary"
                                                style={{
                                                    fontSize: 12,
                                                    minWidth: '36px',
                                                    maxWidth: '96px',
                                                    minHeight: '26px',
                                                    maxHeight: '26px',
                                                    padding: theme.spacing.unit
                                                }}
                                                variant="contained"
                                                size="small"
                                                onClick={e => {
                                                    setCurrentCountingSessionId(session._id);
                                                }}
                                            >
                                                edit
                                            </Button>
                                        </div>
                                    )}
                            </div>
                            {showHeader && (
                                <>
                                    <Divider
                                        style={{
                                            margin: theme.spacing.unit,
                                            marginRight: theme.spacing.unit * 2
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginLeft: theme.spacing.unit * 0.75,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {bagtag && (
                                            <Tooltip title={'Bagtag associated to this counting session'}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <MDIcon
                                                        path={mdiTagArrowDown}
                                                        size={0.6}
                                                        style={{
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                        color={colors.grey[500]}
                                                    />

                                                    <Typography style={{ fontSize: '12px' }}>{bagtag}</Typography>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {dateBagtagPrinted && (
                                            <Tooltip title={'Date when the bagtag for this session was printed'}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <MDIcon
                                                        path={mdiPrinter}
                                                        size={0.6}
                                                        style={{
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                        color={colors.grey[500]}
                                                    />

                                                    <Typography style={{ fontSize: '12px' }}>
                                                        {moment(dateBagtagPrinted).format('MMM D, YYYY h:mm A')}
                                                    </Typography>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {machineCounter && (
                                            <Tooltip title={'Machine that processed the counts for this session'}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <MDIcon
                                                        path={mdiAccountHardHat}
                                                        size={0.6}
                                                        style={{
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                        color={colors.grey[500]}
                                                    />

                                                    <Typography style={{ fontSize: '12px' }}>
                                                        {machineCounter}
                                                    </Typography>
                                                </div>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={'Date when this session was processed'}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <MDIcon
                                                    path={mdiAccountHardHat}
                                                    size={0.6}
                                                    style={{
                                                        marginTop: theme.spacing.unit / 4,
                                                        marginLeft: theme.spacing.unit / 2,
                                                        marginRight: theme.spacing.unit / 2
                                                    }}
                                                    color={colors.grey[500]}
                                                />

                                                <Typography style={{ fontSize: '12px' }}>
                                                    {moment(session.dateCounted).format('MMM D, YYYY h:mm A')}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                            <Divider style={{ marginTop: theme.spacing.unit }} />
                            <table
                                style={{
                                    width: '100%',
                                    padding: theme.spacing.unit / 2
                                }}
                            >
                                <tbody style={{ backgroundColor: headerFooterColor }}>
                                    {sessionCounts.map((sessionCount, i) => {
                                        // TODO: find was in UI to show bulk counts versus singular counts
                                        if (sessionCount.items.length === 0) {
                                            return (
                                                <Typography
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        alignSelf: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    No counts
                                                </Typography>
                                            );
                                        }
                                        return sessionCount.items.map((item, j) => {
                                            const epicStyle = {};
                                            let countImageUrl = countImageUrlMap[item._id];
                                            return (
                                                <tr
                                                    key={`${item.description}-${j}`}
                                                    style={{
                                                        marginBottom: 5,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    {!disableEditing &&
                                                        !printingReceipt &&
                                                        currentCountingSessionId === _.get(session, '_id', '') && (
                                                            <td
                                                                style={{
                                                                    width: !_.isNil(countImageUrl) ? 52 : 24,
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                {!disableEditing && (
                                                                    <IconButton
                                                                        data-cy="delete-session-count"
                                                                        disabled={disableEditing || inProgress}
                                                                        style={{ padding: 0 }}
                                                                        onClick={() => {
                                                                            warnAction(() => {
                                                                                onRemoveCounts(
                                                                                    sessionCount._id,
                                                                                    item._id
                                                                                );
                                                                                resetTimeoutCount();
                                                                            }, `Are you sure you want to delete [${item.description} ${item.size} x ${item.quantity}] from the counts?`);
                                                                            setIdleCountdown(0);
                                                                            resetTimeoutCount();
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={mdiClose}
                                                                            size={0.8}
                                                                            color={colors.red[500]}
                                                                        />
                                                                    </IconButton>
                                                                )}
                                                                {!_.isNil(countImageUrl) && (
                                                                    <Tooltip
                                                                        title={'View AI count image'}
                                                                        disableFocusListener
                                                                        disableTouchListener
                                                                    >
                                                                        <IconButton
                                                                            style={{ marginLeft: 4, padding: 0 }}
                                                                            onClick={() => {
                                                                                if (!_.isEmpty(countImageUrl)) {
                                                                                    setImages([countImageUrl]);
                                                                                    onImageDialog(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <MDIcon path={allIcons.mdiEye} size={0.8} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </td>
                                                        )}
                                                    <td
                                                        style={{ width: 'auto' }}
                                                        data-cy={`counts-panel-item-${j}-description`}
                                                    >
                                                        <Typography noWrap style={countsStyle}>
                                                            {item.description} {item.size}
                                                        </Typography>
                                                    </td>

                                                    <td
                                                        style={{ width: 'auto' }}
                                                        data-cy={`counts-panel-item-${j}-quantity`}
                                                    >
                                                        <Typography style={countsStyle}>
                                                            {Math.floor(_.get(item, 'quantity', 0))}
                                                        </Typography>
                                                    </td>
                                                    <td
                                                        style={{ width: 'auto' }}
                                                        data-cy={`counts-panel-item-${j}-value`}
                                                    >
                                                        <Typography style={countsStyle}>
                                                            {formatAsCurrency(item.value || 0)}
                                                        </Typography>
                                                    </td>

                                                    {!hideCounter && !showHeader && (
                                                        <td
                                                            style={{ width: 'auto' }}
                                                            data-cy={`counts-panel-item-${j}-counter`}
                                                        >
                                                            <Typography style={{ ...countsStyle, fontSize: '70%' }}>
                                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                                    {getCustomerFirstNameAndLastInitial(
                                                                        sessionCount.operator
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {moment(item.dateCounted).format('DD/MM/YY h:mma')}
                                                                </span>
                                                            </Typography>
                                                            {/* <Typography
                                                                noWrap
                                                                style={{
                                                                    ...countsStyle,
                                                                    overflow: 'hidden',
                                                                    fontSize: '70%'
                                                                }}
                                                            >
                                                                {moment(item.dateCounted).format('DD/MM/YY h:mma')}
                                                            </Typography> */}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        });
                                    })}
                                    {(_.get(session, 'customFees', []) || []).map((fee, index) => {
                                        return (
                                            <tr key={`${fee.amount}-${index}`}>
                                                {!printingReceipt &&
                                                    currentCountingSessionId === _.get(session, '_id', '') && (
                                                        <DeleteColumn
                                                            style={countsStyle}
                                                            show={!disableEditing}
                                                            disabled={disableEditing || inProgress}
                                                            onFunction={() => {
                                                                handleRemoveCustomFeeFromSessionAndBulk(
                                                                    session._id,
                                                                    fee._id
                                                                );
                                                                setIdleCountdown(0);
                                                                resetTimeoutCount();
                                                            }}
                                                        />
                                                    )}
                                                <td>
                                                    <Typography style={countsStyle}>{fee.description}</Typography>
                                                </td>
                                                <td>
                                                    <Typography style={countsStyle}>
                                                        {_.get(fee, 'quantity', 1)}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography
                                                        style={{
                                                            color:
                                                                fee.amount > 0
                                                                    ? theme.palette.error.main
                                                                    : colors.green[400],
                                                            ...countsStyle
                                                        }}
                                                    >
                                                        {formatAsCurrency(-fee.amount)}
                                                    </Typography>
                                                </td>
                                                {!hideCounter && (
                                                    <td>
                                                        <Typography style={{ ...countsStyle, fontSize: '70%' }}>
                                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                                {!_.isNil(_.get(fee, 'appliedByName'))
                                                                    ? fee.appliedByName
                                                                    : !_.isNil(_.get(fee, 'appliedBy'))
                                                                    ? getCustomerFirstNameAndLastInitial(
                                                                          _.get(fee, 'appliedBy')
                                                                      )
                                                                    : fee.appliedAutomatically ||
                                                                      fee.generatedOnBulkCompletion
                                                                    ? 'Applied by System'
                                                                    : ''}
                                                            </span>
                                                            <span>
                                                                {!_.isNil(_.get(fee, 'dateApplied'))
                                                                    ? moment(fee.dateApplied).format('DD/MM/YY h:mma')
                                                                    : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                    </td>
                                                )}
                                                <td />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                style={{
                                    backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                                    position: 'absolute',
                                    height: '100%',
                                    width: 8,
                                    right: 0,
                                    top: 0
                                }}
                            />
                        </Card>
                    );
                })}
                {(sessionlessCounts.length > 0 || sessionlessFees.length > 0) && (
                    <Card
                        data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                        elevation={0}
                        style={{
                            margin: theme.spacing.unit,
                            border: '1px solid',
                            borderColor: theme.palette.grey[300],
                            maxHeight: '100%',
                            position: 'relative',
                            padding: '4px 0'
                        }}
                    >
                        {/* <div
                            style={{
                                // display: 'flex',
                                // flexDirection: 'row',
                                paddingLeft: theme.spacing.unit / 2,
                                paddingRight: theme.spacing.unit * 2
                                // justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={
                                    {
                                        // display: 'flex',
                                        // flexDirection: 'row'
                                    }
                                }
                            >
                                <Typography
                                    style={{
                                        whiteSpace: 'nowrap',
                                        alignSelf: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Session-less items
                                </Typography>
                            </div>
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit }} /> */}
                        <table
                            style={{
                                width: '100%',
                                padding: theme.spacing.unit / 2
                            }}
                        >
                            <tbody style={{ backgroundColor: headerFooterColor }}>
                                {sessionlessCountsItemsWithAmount.map((count, i) => {
                                    if (count.items.length === 0) {
                                        return (
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                No counts
                                            </Typography>
                                        );
                                    }
                                    return count.items.map((item, j) => {
                                        const epicStyle = {};

                                        return (
                                            <tr
                                                key={`${item.description}-${j}`}
                                                style={{
                                                    marginBottom: 5,
                                                    paddingBottom: 5
                                                }}
                                            >
                                                {!disableEditing && !printingReceipt && (
                                                    <DeleteColumn
                                                        style={countsStyle}
                                                        show={!disableEditing} // show del for all items in bulk count
                                                        disabled={disableEditing || inProgress}
                                                        epicStyle={epicStyle}
                                                        onFunction={() => {
                                                            warnAction(() => {
                                                                handleRemoveSessionlessCounts(count._id, item._id);
                                                                resetTimeoutCount();
                                                            }, `Are you sure you want to delete [${item.description} ${item.size} x ${item.quantity}] from the counts?`);
                                                            setIdleCountdown(0);
                                                            resetTimeoutCount();
                                                        }}
                                                    />
                                                )}
                                                <td
                                                    style={{ width: 'auto' }}
                                                    data-cy={`counts-panel-item-${j}-description`}
                                                >
                                                    <Typography noWrap style={countsStyle}>
                                                        {item.description} {item.size}
                                                    </Typography>
                                                </td>

                                                <td
                                                    style={{ width: 'auto' }}
                                                    data-cy={`counts-panel-item-${j}-quantity`}
                                                >
                                                    <Typography style={countsStyle}>
                                                        {Math.floor(_.get(item, 'quantity', 0))}
                                                    </Typography>
                                                </td>
                                                <td style={{ width: 'auto' }} data-cy={`counts-panel-item-${j}-value`}>
                                                    <Typography style={countsStyle}>
                                                        {formatAsCurrency(item.value || 0)}
                                                    </Typography>
                                                </td>
                                                {!hideCounter && (
                                                    <td
                                                        style={{ width: 'auto' }}
                                                        data-cy={`counts-panel-item-${j}-counter`}
                                                    >
                                                        <Typography style={{ ...countsStyle, fontSize: '70%' }}>
                                                            <span style={{ marginRight: theme.spacing.unit }}>
                                                                {getCustomerFirstNameAndLastInitial(count.operator)}
                                                            </span>
                                                            <span>
                                                                {moment(item.dateCounted).format('DD/MM/YY h:mma')}
                                                            </span>
                                                        </Typography>
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    });
                                })}
                                {(sessionlessFees || []).map(fee => {
                                    let index = _.get(fee, 'index', null);
                                    return (
                                        <tr key={`${fee.amount}-${index}`}>
                                            {!printingReceipt && (
                                                <DeleteColumn
                                                    style={countsStyle}
                                                    show={!disableEditing}
                                                    disabled={disableEditing || inProgress}
                                                    onFunction={() => {
                                                        onRemoveCustomFee(index);
                                                        setIdleCountdown(0);
                                                        resetTimeoutCount();
                                                    }}
                                                />
                                            )}
                                            <td>
                                                <Typography style={countsStyle}>{fee.description}</Typography>
                                            </td>
                                            <td>
                                                <Typography style={countsStyle}>{_.get(fee, 'quantity', 1)}</Typography>
                                            </td>
                                            <td>
                                                <Typography
                                                    style={{
                                                        color:
                                                            fee.amount > 0
                                                                ? theme.palette.error.main
                                                                : colors.green[400],
                                                        ...countsStyle
                                                    }}
                                                >
                                                    {formatAsCurrency(-fee.amount)}
                                                </Typography>
                                            </td>
                                            {!hideCounter && (
                                                <td>
                                                    <Typography style={{ ...countsStyle, fontSize: '70%' }}>
                                                        <span style={{ marginRight: theme.spacing.unit }}>
                                                            {!_.isNil(_.get(fee, 'appliedByName'))
                                                                ? fee.appliedByName
                                                                : !_.isNil(_.get(fee, 'appliedBy'))
                                                                ? getCustomerFirstNameAndLastInitial(
                                                                      _.get(fee, 'appliedBy')
                                                                  )
                                                                : fee.appliedAutomatically ||
                                                                  fee.generatedOnBulkCompletion
                                                                ? 'Applied by System'
                                                                : ''}
                                                        </span>
                                                        <span>
                                                            {!_.isNil(_.get(fee, 'dateApplied'))
                                                                ? moment(fee.dateApplied).format('DD/MM/YY h:mma')
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                </td>
                                            )}
                                            <td />
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div
                            style={{
                                backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                                position: 'absolute',
                                height: '100%',
                                width: 8,
                                right: 0,
                                top: 0
                            }}
                        />
                    </Card>
                )}
            </div>
            <Divider style={{ marginTop: theme.spacing.unit }} />
            <table
                style={{
                    tableLayout: 'fixed',
                    width: '100%',
                    backgroundColor: headerFooterColor,
                    padding: padding,
                    paddingRight: 0
                }}
            >
                <tbody>
                    {breakdowns.map((breakdown, index) => {
                        return (
                            <tr key={breakdown.title}>
                                <td>
                                    <Typography style={headerFooterStyle}>{breakdown.title}</Typography>
                                </td>
                                <td>
                                    <Typography style={headerFooterStyle}>{Math.floor(breakdown.quantity)}</Typography>
                                </td>
                                <td>
                                    <Typography
                                        style={{
                                            color:
                                                breakdown.value < 0
                                                    ? theme.palette.error.main
                                                    : theme.palette.text.primary,
                                            ...headerFooterStyle
                                        }}
                                        data-cy={`counts-panel-breakdown-value-${breakdown.title}`}
                                    >
                                        {formatAsCurrency(breakdown.value)}
                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td>
                            <Typography style={headerFooterStyle}>Rates</Typography>
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} />
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} noWrap>
                                {_.get(bulk, 'rates.name', '')}
                            </Typography>
                        </td>
                    </tr>

                    {!printingReceipt && (
                        <tr>
                            <td>
                                <Typography style={headerFooterStyle}>Avg</Typography>
                            </td>
                            <td>
                                <Typography style={headerFooterStyle} />
                            </td>
                            <td>
                                <Typography style={headerFooterStyle} noWrap>
                                    {getValuePerBag(countTotal, bulk, commodities)} /{' '}
                                    {_bulk.getBulkUnitOfMeasurement(bulk)}
                                </Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {isAdminOrCSR && (
                <AdminBreakdownSection
                    bulk={bulk}
                    skus={skus}
                    promoCodes={promoCodes}
                    serviceFeesForSkuType={serviceFeesForSkuType}
                    headerFooterColor={headerFooterColor}
                    padding={padding}
                />
            )}
        </div>
    );
});

const DeleteColumn = ({ show, disabled, onFunction }) => {
    return (
        <td style={{ width: 24 }}>
            {show && (
                <IconButton
                    disabled={disabled}
                    style={{ padding: 0 }}
                    data-cy="delete-session-count"
                    onClick={() => onFunction()}
                >
                    <MDIcon path={mdiClose} size={0.8} color={colors.red[500]} />
                </IconButton>
            )}
        </td>
    );
};

const AdminBreakdownSection = function({
    bulk,
    skus,
    promoCodes,
    serviceFeesForSkuType,
    headerFooterColor,
    padding,
    headerFooterStyle
}) {
    const generatedFees = _.get(bulk, 'customFees', []).filter(fee => {
        return _.get(fee, 'generatedOnBulkCompletion', false);
    });

    const rates = _.get(bulk, 'rates.rates', []);
    const depositTotal = useMemo(() => {
        let depositTotal = 0;
        _.get(bulk, 'counts', []).forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                const rate = _.find(rates, { sku: _.get(item, 'sku') });
                if (rate) {
                    depositTotal += _.get(rate, 'value') * -1 * _.get(item, 'quantity', 0);
                }
            });
        });
        return depositTotal;
    }, [bulk, rates]);

    const promo = _.first(promoCodes);
    const serviceFeeAmount = calculateServiceFeeAmount(
        serviceFeesForSkuType,
        depositTotal,
        _.get(bulk, 'counts', []),
        rates,
        !_.isNil(bulk.charity),
        promo
    );

    let discount = 0;
    if (!_.isNil(promo)) {
        const discountRatio = 1.0 - _.get(promo, 'variables.customerSplit', 0);
        discount = Math.round(discountRatio * 100);
    }

    return (
        <>
            <Divider />
            <table
                style={{
                    tableLayout: 'fixed',
                    width: '100%',
                    backgroundColor: headerFooterColor,
                    padding: padding,
                    paddingRight: 0
                }}
            >
                <tbody>
                    <tr>
                        <td>
                            <Typography style={headerFooterStyle}>Admin Info</Typography>
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} />
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} />
                        </td>
                    </tr>
                    {generatedFees.map((fee, i) => (
                        <tr key={i}>
                            <td>
                                <Typography style={headerFooterStyle}>{_.get(fee, 'description', '')}</Typography>
                            </td>
                            <td>
                                <Typography style={headerFooterStyle} />
                            </td>
                            <td>
                                <Typography style={headerFooterStyle}>
                                    {formatAsCurrency(-_.get(fee, 'amount'))}
                                </Typography>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <Typography style={headerFooterStyle}>
                                Service Fee
                                {!_.isNil(promo) && <> (promocode {discount}%)</>}
                            </Typography>
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} />
                        </td>
                        <td>
                            <Typography style={headerFooterStyle}>{formatAsCurrency(-serviceFeeAmount)}</Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

function getBreakdowns(bulk, skus, grandTotal) {
    const nonGeneratedFees = _.get(bulk, 'customFees', []).filter(fee => {
        return !_.get(fee, 'generatedOnBulkCompletion', false);
    });

    const [feesAmount, paymentsAmount] = nonGeneratedFees.reduce(
        (arr, { amount }) => {
            if (amount > 0) {
                arr[0] = arr[0] + amount;
            } else if (amount < 0) {
                arr[1] = arr[1] + amount;
            }

            return arr;
        },

        [0, 0]
    );

    const feesBreakdown = {
        title: 'Fees',
        quantity: nonGeneratedFees.filter(({ amount }) => amount > 0).length,
        value: -feesAmount
    };

    const paymentsBreakdown = {
        title: 'Payments',
        quantity: nonGeneratedFees.filter(({ amount }) => amount < 0).length,
        value: -paymentsAmount
    };

    const rates = _.get(bulk, 'rates.rates', []);
    const skusToIgnoreForTotal = _rates.getIgnoredSkusForTotals(rates);
    const totalBreakdown = {
        title: 'Total',
        quantity: _bulk.getCountsTotalItemCount(bulk, skusToIgnoreForTotal),
        value: grandTotal
    };

    let breakdowns = [totalBreakdown];

    if (!_.isEmpty(skus)) {
        const allSkuLabels = _(skus)
            .groupBy('label')
            .keys()
            .value();

        // only show more lines if theres more than 1 label types
        if (allSkuLabels.length > 1) {
            const flattenedAndGroupedCountsObj = _.groupBy(
                _.get(bulk, 'counts', []).reduce((array, count) => (array = array.concat(count.items)), []),
                'size'
            );

            for (let label of allSkuLabels) {
                if (!_.isEmpty(label)) {
                    let quantity = 0;
                    let value = 0;

                    const flattenedAndGroupedCountsArr = flattenedAndGroupedCountsObj[label];
                    if (!_.isEmpty(flattenedAndGroupedCountsArr)) {
                        for (let flatCounts of flattenedAndGroupedCountsArr) {
                            quantity += flatCounts.quantity;

                            const sku = _(skus)
                                .filter(sku => sku.sku === flatCounts.sku)
                                .value();

                            value += flatCounts.quantity * _.first(sku).value * -1; // skus values are inverted
                        }
                    }

                    const newBreakdown = {
                        title: label,
                        quantity,
                        value
                    };

                    breakdowns.push(newBreakdown);
                }
            }
        }
    }

    if (!_.isEmpty(nonGeneratedFees)) {
        breakdowns.push(feesBreakdown);
        breakdowns.push(paymentsBreakdown);
    }

    return breakdowns;
}

function getValuePerBag(countTotal, bulk, commodities) {
    const currentSkuCom = _.find(commodities, {
        skuType: _.get(bulk, 'skuType', '')
    });
    let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
    let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
    let subComToBagEquiv = 0;
    processedSubComList.forEach(subCom => {
        let subComId = _.get(subCom, 'subCommodity', '');
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let subCommodity = _.find(subCommodityLookup, { _id: subComId });
        subComToBagEquiv += Math.ceil(_.get(subCom, 'amount', 0) * _.get(subCommodity, 'multiplier', 0));
    });
    if (sortedBags + subComToBagEquiv > 0) {
        return formatAsCurrency(countTotal / (sortedBags + subComToBagEquiv));
    } else {
        return '?';
    }
}

export default withTheme()(CountsScreenV2);

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

function calculateServiceFeeAmount(serviceFee, totalValue, counts, countRates, donated, promo) {
    if (_.get(serviceFee, 'type', '') === 'Count Split') {
        let totalApplicableQuantity = 0;
        counts.forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                const rate = _.find(countRates, { sku: count.sku });
                if (!_.isNil(rate) && rate.skuType === serviceFee.skuType && count.sku === serviceFee.sku) {
                    totalApplicableQuantity += count.quantity;
                }
            });
        });
        let customerReturns;
        if (!_.isNil(promo)) {
            const rate = _.find(countRates, { sku: serviceFee.sku });
            const newSplit = Math.abs(rate.value) * _.get(promo, 'variables.customerSplit', 0);
            customerReturns = totalApplicableQuantity * newSplit;
        } else {
            customerReturns =
                totalApplicableQuantity *
                (donated ? _.get(serviceFee, 'split.charity', 0) : _.get(serviceFee, 'split.customer', 0));
        }
        return _.round(Math.abs(totalValue) - customerReturns);
    } else {
        if (!_.isNil(promo)) {
            return _.round((1.0 - _.get(promo, 'variables.customerSplit', 0)) * Math.abs(totalValue)) || 0;
        } else {
            return _.round(_.get(serviceFee, 'value', 0) * Math.abs(totalValue)) || 0;
        }
    }
}
