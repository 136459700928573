import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    withTheme,
    withMobileDialog,
    Typography,
    Switch,
    TextField,
    FormControl,
    FormControlLabel,
    MenuItem,
    Icon,
    FormHelperText
} from '@material-ui/core';

import { Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import TimePicker from 'material-ui-pickers/TimePicker';
import TimePickerTz from 'components/DateTimePickersTz/TimePickerTz';

import CharitySelect from 'components/CharitySelect/CharitySelect';

import * as terms from 'localizations/terms';
import { useContext } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import TestSendComponent from './TestSendComponent';
import SendOutListComponent from './SendOutListComponent';
import SendOutListDialog from './SendOutListDialog';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { useEffect } from 'react';

import ExternalLink from 'components/ExternalLink/ExternalLink';
import HttpContext from 'utils/contexts/HttpContext';

import { downloadObjectAsCSV } from 'utils/misc';

const baseTemplateVariables = [
    {
        name: 'first_name',
        description: 'First Name'
    },
    {
        name: 'last_name',
        description: 'Last Name'
    },
    {
        name: 'address',
        description: 'Address'
    },
    {
        name: 'charity_name',
        description: 'Associated Charity Name'
    },
    {
        name: 'charity_email',
        description: 'Associated Charity Email'
    },
    {
        name: 'balance',
        description: 'Current Balance'
    },
    {
        name: 'containers',
        description: 'Total Containers'
    },
    {
        name: 'co2_emissions',
        description: 'Total Co2 Emissions'
    },
    {
        name: 'energy_saved',
        description: 'Total Energy Saved'
    },
    {
        name: 'kg_spared',
        description: 'Total Kg spared from Landfill'
    },
    {
        name: 'account_code',
        description: 'Account Code'
    },
    {
        name: 'rating_link',
        description: 'Rating Links'
    },
    {
        name: 'last_location_used',
        description: 'Last Location Used'
    }
];

function SendOutEditDialog(props) {
    const {
        open,
        sendOut,
        onClose,
        fullScreen,
        zones = [],
        regions = [],
        charities = [],
        services = [],
        ratingPlatforms = [],
        sendOutLists = [],
        onSubmit,
        onReloadSendOutLists,
        theme,
        smsConfigData
    } = props;

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const mobilePickupSubServices = useMemo(
        () => _.get(_.find(services, service => service.isMobilePickupService && !service.disabled), 'subServices', []),
        [services]
    );
    const [templateVariables, setTemplateVariables] = useState([]);
    const [sendOutListDialogOpen, setSendOutListDialogOpen] = useState(false);

    const warnAction = useContext(ConfirmDialogContext);
    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);

    const defaultDate = moment()
        .tz(timezone)
        .add(1, 'day')
        .add(1, 'hour')
        .startOf('hour')
        .toDate();
    const formik = useFormik({
        initialValues: {
            name: _.get(sendOut, 'name', ''),
            date: _.get(sendOut, 'sendDate', defaultDate), //date and time are merged into sendDate when submitting the values
            time: _.get(sendOut, 'sendDate', defaultDate),
            sendMode: _.get(sendOut, 'sendMode', ''),
            emailNotification: {
                sendEmailNotification: _.get(sendOut, 'emailNotification.sendEmailNotification', false),
                templateId: _.get(sendOut, 'emailNotification.templateId', ''),
                unsubscribeGroup: !_.isNil(_.get(sendOut, 'emailNotification.unsubscribeGroup'))
                    ? _.get(sendOut, 'emailNotification.unsubscribeGroup', '')
                    : ''
            },
            smsNotification: {
                sendSms: _.get(sendOut, 'smsNotification.sendSms', false),
                text: _.get(sendOut, 'smsNotification.text', '')
            },
            pushNotification: {
                sendPushNotification: _.get(sendOut, 'pushNotification.sendPushNotification', false),
                title: _.get(sendOut, 'pushNotification.title', ''),
                text: _.get(sendOut, 'pushNotification.text', '')
            },
            sendOutLists: _.get(sendOut, 'sendOutLists', []),
            enrichDataFromDB: _.get(sendOut, 'enrichDataFromDB', false),
            startDate: _.get(sendOut, 'startDate', defaultDate),
            endDate: _.get(sendOut, 'endDate', defaultDate),
            testRestrictData: _.get(sendOut, 'testRestrictData', false),
            testStartDate: _.get(sendOut, 'testStartDate', defaultDate),
            testEndDate: _.get(sendOut, 'testEndDate', defaultDate)
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a name'),
            emailNotification: Yup.object({
                sendEmailNotification: Yup.boolean(),
                templateId: Yup.string().when('sendEmailNotification', {
                    is: true,
                    then: Yup.string().required('You must enter a template id')
                }),
                unsubscribeGroup: Yup.number().when('sendEmailNotification', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a unsubscribe group')
                        .integer('You must enter a whole number')
                })
            }),
            smsNotification: Yup.object({
                sendSms: Yup.boolean(),
                text: Yup.string().when('sendSms', {
                    is: true,
                    then: Yup.string()
                        .required('You must enter a sms message')
                        .max(1600, 'This must be below 1600 characters')
                })
            }),
            pushNotification: Yup.object({
                sendPushNotification: Yup.boolean(),
                title: Yup.string().when('sendPushNotification', {
                    is: true,
                    then: Yup.string().required('You must enter a push notification title')
                }),
                text: Yup.string().when('sendPushNotification', {
                    is: true,
                    then: Yup.string().required('You must enter a push notification message')
                })
            }),
            sendMode: Yup.string().required('You must select a value'),
            sendOutLists: Yup.array().min(1, 'You must select at least 1')
        }),
        onSubmit: values => {
            if (
                !_.get(values, 'emailNotification.sendEmailNotification', false) &&
                !_.get(values, 'smsNotification.sendSms', false) &&
                !_.get(values, 'pushNotification.sendPushNotification', false)
            ) {
                onSnackbar('You must add either an email, sms or push notification', 'info');
                return;
            }
            const valuesCopy = _.cloneDeep(values);

            const date = moment(valuesCopy.date).tz(timezone);
            const time = moment(valuesCopy.time).tz(timezone);
            valuesCopy.sendDate = moment()
                .tz(timezone)
                .set({
                    year: date.get('year'),
                    month: date.get('month'),
                    date: date.get('date'),
                    hour: time.get('hour'),
                    minute: time.get('minute'),
                    second: 0,
                    millisecond: 0
                });

            if (values.sendMode === 'Immediately') {
                warnAction(() => {
                    onSubmit(valuesCopy);
                }, 'Are you sure you want to send notifications to all specified customers immediately');
            } else {
                onSubmit(valuesCopy);
            }
        }
    });

    useEffect(() => {
        setTemplateVariables([
            ...baseTemplateVariables,
            ...ratingPlatforms.map(r => {
                return {
                    name: _.snakeCase(_.get(r, 'name', '')),
                    description: `${_.get(r, 'name', '')} Rating Link`
                };
            })
        ]);
    }, [ratingPlatforms]);

    const smsHelperTxt = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                Messages above 160 characters or 70 with emojis will have higher billing{' '}
                <ExternalLink
                    text="More Info"
                    url="https://www.twilio.com/docs/glossary/what-sms-character-limit"
                    textInline
                />
            </span>
            <span style={{ marginLeft: theme.spacing.unit, whiteSpace: 'nowrap', color: theme.palette.text.primary }}>
                {_.get(formik.values, 'smsNotification.text', '').length} Total
            </span>
        </div>
    );
    const handlePaste = event => {
        event.preventDefault();
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');
        const textWithoutSpaces = pastedText.replace(/\s/g, '');
        document.execCommand('insertText', false, textWithoutSpaces);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth fullScreen={fullScreen}>
                <DialogContent>
                    <Grid container>
                        <Grid
                            container
                            xs={12}
                            md={7}
                            spacing={theme.spacing.unit * 2}
                            style={{ paddingRight: theme.spacing.unit * 2, borderRight: '3px solid #ccc' }}
                        >
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6">General</Typography>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: theme.spacing.unit,
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <TextField
                                            {...formik.getFieldProps('name')}
                                            fullWidth
                                            variant="outlined"
                                            label="Name"
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                            helperText={
                                                _.get(formik.touched, 'name', false) && _.get(formik.errors, 'name', '')
                                            }
                                            error={
                                                _.get(formik.touched, 'name', false) &&
                                                _.get(formik.errors, 'name', false)
                                            }
                                        />
                                        <TextField
                                            {...formik.getFieldProps('sendOutLists')}
                                            fullWidth
                                            select
                                            SelectProps={{
                                                multiple: true
                                            }}
                                            variant="outlined"
                                            label="User Lists"
                                            helperText={
                                                (_.get(formik.touched, 'sendOutLists', false) &&
                                                    _.get(formik.errors, 'sendOutLists', '')) ||
                                                ' '
                                            }
                                            error={
                                                _.get(formik.touched, 'sendOutLists', false) &&
                                                _.get(formik.errors, 'sendOutLists', false)
                                            }
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                        >
                                            {sendOutLists.map(sendOutList => (
                                                <MenuItem key={sendOutList._id} value={sendOutList._id}>
                                                    {sendOutList.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: theme.spacing.unit
                                        }}
                                    >
                                        <TextField
                                            {...formik.getFieldProps('sendMode')}
                                            fullWidth
                                            select
                                            variant="outlined"
                                            label="Schedule"
                                            helperText={
                                                (_.get(formik.touched, 'sendMode', false) &&
                                                    _.get(formik.errors, 'sendMode', '')) ||
                                                ' '
                                            }
                                            error={
                                                _.get(formik.touched, 'sendMode', false) &&
                                                _.get(formik.errors, 'sendMode', false)
                                            }
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                        >
                                            <MenuItem value={'Immediately'}>Send Immediately</MenuItem>
                                            <MenuItem value={'Date&Time'}>Select Date And Time</MenuItem>
                                        </TextField>
                                        {_.get(formik, 'values.sendMode') === 'Date&Time' && (
                                            <>
                                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                                    <DatePicker
                                                        timezone={timezone}
                                                        type="end"
                                                        label="Date"
                                                        format="DD MMM YYYY"
                                                        value={moment(_.get(formik, 'values.date'))}
                                                        //style={{ margin: theme.spacing.unit*2 }}
                                                        variant="outlined"
                                                        onChange={d => formik.setFieldValue('date', d.toDate())}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                                    <TimePickerTz
                                                        timezone={timezone}
                                                        disablePast
                                                        autoOk={false}
                                                        label="Time"
                                                        variant="outlined"
                                                        value={_.get(formik, 'values.time')}
                                                        //style={{ marginTop: theme.spacing.unit*2 }}
                                                        // minutesStep={5}
                                                        emptyLabel="Please select a time"
                                                        format="h:mm A"
                                                        onChange={time => formik.setFieldValue('time', time)}
                                                        error={
                                                            _.get(formik.touched, 'time', false) &&
                                                            _.get(formik.errors, 'time', false)
                                                                ? true
                                                                : null
                                                        }
                                                        helperText={
                                                            (_.get(formik.touched, 'time', false) &&
                                                                _.get(formik.errors, 'time', false) &&
                                                                _.get(formik.errors, 'time', false)) ||
                                                            ' '
                                                        }
                                                    />
                                                </FormControl>
                                            </>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: theme.spacing.unit * 2
                                        }}
                                    >
                                        <Typography variant="h6">Enrich Data From Database</Typography>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    {...formik.getFieldProps('enrichDataFromDB')}
                                                    checked={_.get(formik.values, 'enrichDataFromDB')}
                                                />
                                            }
                                        />
                                    </div>
                                    {_.get(formik, 'values.enrichDataFromDB') && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                gap: theme.spacing.unit,
                                                marginTop: theme.spacing.unit
                                            }}
                                        >
                                            <Typography variant="subtitle2">Use Date Between</Typography>
                                            <FormControl fullWidth style={{ flex: 1 }}>
                                                <DatePicker
                                                    timezone={timezone}
                                                    type="start"
                                                    label="Start Date"
                                                    format="DD MMM YYYY"
                                                    value={moment(_.get(formik, 'values.startDate'))}
                                                    variant="outlined"
                                                    onChange={d => formik.setFieldValue('startDate', d.toDate())}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth style={{ flex: 1 }}>
                                                <DatePicker
                                                    timezone={timezone}
                                                    type="end"
                                                    label="End Date"
                                                    format="DD MMM YYYY"
                                                    value={moment(_.get(formik, 'values.endDate'))}
                                                    variant="outlined"
                                                    onChange={d => formik.setFieldValue('endDate', d.toDate())}
                                                />
                                            </FormControl>
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: theme.spacing.unit
                                        }}
                                    >
                                        <Typography variant="h6">Email Notification</Typography>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    {...formik.getFieldProps('emailNotification.sendEmailNotification')}
                                                    checked={_.get(
                                                        formik.values,
                                                        'emailNotification.sendEmailNotification'
                                                    )}
                                                />
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: theme.spacing.unit
                                        }}
                                    >
                                        {_.get(formik, 'values.emailNotification.sendEmailNotification') && (
                                            <TextField
                                                {...formik.getFieldProps('emailNotification.templateId')}
                                                fullWidth
                                                variant="outlined"
                                                label="Template ID"
                                                margin="dense"
                                                helperText={
                                                    (_.get(formik.touched, 'emailNotification.templateId', false) &&
                                                        _.get(formik.errors, 'emailNotification.templateId', ' ')) ||
                                                    ' '
                                                }
                                                error={
                                                    _.get(formik.touched, 'emailNotification.templateId', false) &&
                                                    _.get(formik.errors, 'emailNotification.templateId', false)
                                                }
                                                onPaste={handlePaste}
                                            />
                                        )}
                                        {_.get(formik, 'values.emailNotification.sendEmailNotification') && (
                                            <TextField
                                                {...formik.getFieldProps('emailNotification.unsubscribeGroup')}
                                                type="number"
                                                fullWidth
                                                variant="outlined"
                                                label="Unsubscribe Group"
                                                margin="dense"
                                                helperText={
                                                    (_.get(
                                                        formik.touched,
                                                        'emailNotification.unsubscribeGroup',
                                                        false
                                                    ) &&
                                                        _.get(
                                                            formik.errors,
                                                            'emailNotification.unsubscribeGroup',
                                                            ' '
                                                        )) ||
                                                    ' '
                                                }
                                                error={
                                                    _.get(
                                                        formik.touched,
                                                        'emailNotification.unsubscribeGroup',
                                                        false
                                                    ) &&
                                                    _.get(formik.errors, 'emailNotification.unsubscribeGroup', false)
                                                }
                                            />
                                        )}
                                    </div>

                                    {smsConfigData.config.isEnabled && (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginTop: theme.spacing.unit
                                                }}
                                            >
                                                <Typography variant="h6">SMS Notification</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            {...formik.getFieldProps('smsNotification.sendSms')}
                                                            checked={_.get(formik.values, 'smsNotification.sendSms')}
                                                        />
                                                    }
                                                />
                                            </div>

                                            {_.get(formik, 'values.smsNotification.sendSms') && (
                                                <>
                                                    <TextField
                                                        {...formik.getFieldProps('smsNotification.text')}
                                                        multiline
                                                        fullWidth
                                                        variant="outlined"
                                                        label="SMS Message"
                                                        disabled={!_.get(formik, 'values.smsNotification.sendSms')}
                                                        margin="dense"
                                                        helperText={
                                                            _.get(formik.touched, 'smsNotification.text', false) &&
                                                            _.get(formik.errors, 'smsNotification.text', false)
                                                                ? _.get(formik.errors, 'smsNotification.text', '')
                                                                : smsHelperTxt || ' '
                                                        }
                                                        error={
                                                            _.get(formik.touched, 'smsNotification.text', false) &&
                                                            _.get(formik.errors, 'smsNotification.text', false)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: theme.spacing.unit
                                        }}
                                    >
                                        <Typography variant="h6">Push Notification</Typography>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    {...formik.getFieldProps('pushNotification.sendPushNotification')}
                                                    checked={_.get(
                                                        formik.values,
                                                        'pushNotification.sendPushNotification'
                                                    )}
                                                />
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            gap: theme.spacing.unit
                                        }}
                                    >
                                        {_.get(formik, 'values.pushNotification.sendPushNotification') && (
                                            <TextField
                                                {...formik.getFieldProps('pushNotification.title')}
                                                multiline
                                                fullWidth
                                                variant="outlined"
                                                label="Push Title"
                                                disabled={
                                                    !_.get(formik, 'values.pushNotification.sendPushNotification')
                                                }
                                                margin="dense"
                                                helperText={
                                                    (_.get(formik.touched, 'pushNotification.title', false) &&
                                                        _.get(formik.errors, 'pushNotification.title', ' ')) ||
                                                    ' '
                                                }
                                                error={
                                                    _.get(formik.touched, 'pushNotification.title', false) &&
                                                    _.get(formik.errors, 'pushNotification.title', false)
                                                }
                                            />
                                        )}
                                        {_.get(formik, 'values.pushNotification.sendPushNotification') && (
                                            <TextField
                                                {...formik.getFieldProps('pushNotification.text')}
                                                multiline
                                                fullWidth
                                                variant="outlined"
                                                label="Push Message"
                                                disabled={
                                                    !_.get(formik, 'values.pushNotification.sendPushNotification')
                                                }
                                                margin="dense"
                                                helperText={
                                                    (_.get(formik.touched, 'pushNotification.text', false) &&
                                                        _.get(formik.errors, 'pushNotification.text', ' ')) ||
                                                    ' '
                                                }
                                                error={
                                                    _.get(formik.touched, 'pushNotification.text', false) &&
                                                    _.get(formik.errors, 'pushNotification.text', false)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" style={{ marginBottom: theme.spacing.unit }}>
                                    Set User List
                                </Typography>
                                <Button
                                    style={{ margin: theme.spacing.unit }}
                                    variant="outlined"
                                    onClick={() => setSendOutListDialogOpen(true)}
                                    color="primary"
                                >
                                    <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                    Add New
                                </Button>
                            </div>
                            <SendOutListComponent
                                selectedLists={_.get(formik.values, 'sendOutLists', [])}
                                sendOutLists={sendOutLists}
                                error={
                                    _.get(formik.touched, 'sendOutLists', false) &&
                                    _.get(formik.errors, 'sendOutLists', false)
                                }
                                onChange={selectedLists => formik.setFieldValue('sendOutLists', selectedLists)}
                                onReloadSendOutLists={onReloadSendOutLists}
                            />
                            {_.get(formik.touched, 'sendOutLists', false) &&
                                _.get(formik.errors, 'sendOutLists', false) && (
                                    <FormHelperText error>{_.get(formik.errors, 'sendOutLists', false)}</FormHelperText>
                                )}
                                </Grid>*/}
                        <Grid
                            container
                            xs={12}
                            sm={6}
                            md={5}
                            spacing={theme.spacing.unit * 2}
                            style={{ paddingLeft: theme.spacing.unit * 4 }}
                        >
                            <Grid item xs={12} md={12}>
                                <Typography variant="h6">Test Send</Typography>
                                <TestSendComponent formik={formik} templateVariables={templateVariables} smsConfigData={smsConfigData} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={formik.handleSubmit}
                        color="primary"
                        disabled={
                            !_.get(formik, 'values.emailNotification.sendEmailNotification') &&
                            !_.get(formik, 'values.smsNotification.sendSms') &&
                            !_.get(formik, 'values.pushNotification.sendPushNotification')
                        }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <SendOutListDialog
                open={sendOutListDialogOpen}
                onClose={() => setSendOutListDialogOpen(false)}
                //onReloadSendOutLists={onReloadSendOutLists}
                regions={regions}
                zones={zones}
                charities={charities}
                mobilePickupSubServices={mobilePickupSubServices}
                http={http}
            />
        </>
    );
}

export default withMobileDialog()(withTheme()(SendOutEditDialog));
