import React, { useState, useContext } from 'react';

import _ from 'lodash';
import * as colors from '@material-ui/core/colors';
import bulkHelper from 'helpers/bulkHelper';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Icon,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Grid,
    InputLabel,
    Collapse
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import TipSelect from 'components/TipSelect/TipSelect';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function getReturnAmountFromBulk(bulk) {
    let amountRecycled = 0;
    let isDonation = false;

    let depositValue = 0;
    let serviceFee = 0;
    let returnTotal = 0;
    if (!_.isNil(bulk)) {
        const { customerAmount, collectorAmount, totalValue } = bulkHelper.getAmounts(bulk);

        returnTotal += customerAmount;
        depositValue += totalValue;

        serviceFee += collectorAmount;
        isDonation = !_.isNil(bulk.charity);
    }

    switch (process.env.REACT_APP_PAYMENT_MODEL) {
        case 'AUS_DIRECT_BANKING': {
            amountRecycled = (depositValue - serviceFee) / 100;
            break;
        }
        case 'INTERAC_ETRANSFERS':
            amountRecycled = returnTotal / 100;
            break;
        default:
            throw new Error('REACT_APP_PAYMENT_MODEL not set properly');
    }

    return {
        amountRecycled,
        isDonation
    };
}

function TipScreen(props) {
    const {
        onNextStep,
        problemWithPickup,
        theme,
        bulk,
        hasTip,
        enableTipping,
        onBack,
        onClose,
        isAdmin,
        submissionValues
    } = props;

    const { lang } = useContext(LocalizationContext);

    const { amountRecycled, isDonation } = getReturnAmountFromBulk(bulk);
    let standardTipAmounts = [];
    if (amountRecycled <= 0.5) {
        standardTipAmounts = [0.5];
    } else if (amountRecycled <= 1) {
        standardTipAmounts = [0.5, 1];
    } else if (amountRecycled <= 2) {
        standardTipAmounts = [0.5, 1, 2];
    } else if (amountRecycled <= 5) {
        standardTipAmounts = [1, 2, 5];
    } else {
        standardTipAmounts = [2, 5];
    }

    const [feedbackType, setFeedbackType] = useState(
        _.get(submissionValues, 'feedbackType', problemWithPickup ? 'complaint' : '')
    );
    const [feedback, setFeedback] = useState(_.get(submissionValues, 'feedback', ''));
    const [tipAmount, setTipAmount] = useState(_.get(submissionValues, 'tipAmount', 0));
    const [tipAmountOther, setTipAmountOther] = useState(!standardTipAmounts.includes(tipAmount));
    const [driverWasOnTime, setDriverWasOnTime] = useState(_.get(submissionValues, 'driverWasOnTime'));
    const [driverLeftBags, setDriverLeftBags] = useState(_.get(submissionValues, 'driverLeftBags'));
    const [driverWasProfessional, setDriverWasProfessional] = useState(
        _.get(submissionValues, 'driverWasProfessional')
    );
    const [reason, setReason] = useState(_.get(submissionValues, 'reason', ''));

    const listItemStyles = { width: 175, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 };

    return (
        <>
            <DialogTitle disableTypography style={{ textAlign: 'center', justifyContent: 'center' }}>
                <Typography variant="h5">
                    <b>{loc('feedback1', lang)}</b>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit / 2}>
                    <Grid item sm="4" xs="12" style={{ marginBottom: theme.spacing.unit }}>
                        <Grid container>
                            <Grid item sm="12" xs="6">
                                <div
                                    style={{
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        marginRight: 2,
                                        marginLeft: 2
                                    }}
                                >
                                    <ListItemText primary={loc('feedback2', lang)} style={{ padding: 0 }} />
                                </div>
                            </Grid>
                            <Grid item sm="12" xs="6">
                                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <IconButton
                                        data-cy="driver-rating-on-time-yes-button"
                                        onClick={() => setDriverWasOnTime(true)}
                                        style={{
                                            color: colors.green[500]
                                        }}
                                    >
                                        <Icon>{!driverWasOnTime ? 'thumb_up_off_alt' : 'thumb_up_alt'}</Icon>
                                    </IconButton>
                                    <IconButton
                                        data-cy="driver-rating-on-time-no-button"
                                        onClick={() => setDriverWasOnTime(false)}
                                        color="secondary"
                                    >
                                        <Icon>
                                            {_.isNil(driverWasOnTime) || driverWasOnTime
                                                ? 'thumb_down_off_alt'
                                                : 'thumb_down_alt'}
                                        </Icon>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm="4" xs="12" style={{ marginBottom: theme.spacing.unit }}>
                        <Grid container>
                            <Grid item sm="12" xs="6">
                                <div
                                    style={{
                                        textAlign: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            process.env.REACT_APP_ENV === 'TEST'
                                                ? loc('feedback3a', lang)
                                                : loc('feedback3', lang)
                                        }
                                        style={{ padding: 0 }}
                                    />
                                </div>
                            </Grid>
                            <Grid item sm="12" xs="6">
                                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <IconButton
                                        data-cy="driver-rating-bags-yes-button"
                                        onClick={() => setDriverLeftBags(true)}
                                        style={{
                                            color: colors.green[500]
                                        }}
                                    >
                                        <Icon>{!driverLeftBags ? 'thumb_up_off_alt' : 'thumb_up_alt'}</Icon>
                                    </IconButton>
                                    <IconButton
                                        data-cy="driver-rating-bags-no-button"
                                        onClick={() => setDriverLeftBags(false)}
                                        color="secondary"
                                    >
                                        <Icon>
                                            {_.isNil(driverLeftBags) || driverLeftBags
                                                ? 'thumb_down_off_alt'
                                                : 'thumb_down_alt'}
                                        </Icon>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm="4" xs="12" style={{ marginBottom: theme.spacing.unit }}>
                        <Grid container>
                            <Grid item sm="12" xs="6">
                                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <ListItemText primary={loc('feedback4', lang)} style={{ padding: 0 }} />
                                </div>
                            </Grid>
                            <Grid item sm="12" xs="6">
                                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <IconButton
                                        data-cy="driver-rating-professional-yes-button"
                                        onClick={() => setDriverWasProfessional(true)}
                                        style={{
                                            color: colors.green[500]
                                        }}
                                    >
                                        <Icon>{!driverWasProfessional ? 'thumb_up_off_alt' : 'thumb_up_alt'}</Icon>
                                    </IconButton>
                                    <IconButton
                                        data-cy="driver-rating-professional-no-button"
                                        onClick={() => setDriverWasProfessional(false)}
                                        color="secondary"
                                    >
                                        <Icon>
                                            {_.isNil(driverWasProfessional) || driverWasProfessional
                                                ? 'thumb_down_off_alt'
                                                : 'thumb_down_alt'}
                                        </Icon>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs="12">
                        <Button
                            variant={feedbackType === 'compliment' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setFeedbackType('compliment')}
                            fullWidth
                            data-cy="driver-rating-feedback-compliment"
                        >
                            {loc('feedback15', lang)}
                        </Button>
                    </Grid>
                    <Grid item xs="12">
                        <Button
                            variant={feedbackType === 'complaint' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => setFeedbackType('complaint')}
                            fullWidth
                            data-cy="driver-rating-feedback-complaint"
                        >
                            {loc('feedback16', lang)}
                        </Button>
                    </Grid>
                </Grid>
                <Collapse in={feedbackType === 'complaint'}>
                    <FormControl style={{ textAlign: 'left', marginTop: theme.spacing.unit / 2 }} fullWidth>
                        <InputLabel>{loc('complaints3', lang)}</InputLabel>
                        <Select
                            data-cy="driver-rating-feedback-problem-type"
                            value={reason}
                            onChange={event => setReason(event.target.value)}
                            fullWidth
                        >
                            <MenuItem value="pickup">{loc('complaints4', lang)}</MenuItem>
                            <MenuItem value="bulk" data-cy="share-and-feedback-dialog-complaint-reason-refund-problem">
                                {loc('complaints5', lang)}
                            </MenuItem>
                            <MenuItem value="generic">{loc('complaints6', lang)}</MenuItem>
                        </Select>
                    </FormControl>
                </Collapse>
                <Collapse in={!_.isEmpty(feedbackType)}>
                    <FormControl style={{ marginTop: theme.spacing.unit / 2 }} fullWidth>
                        <TextField
                            data-cy="driver-rating-feedback"
                            label={loc('complaints7', lang)}
                            value={feedback}
                            name="feedback"
                            fullWidth
                            multiline
                            onChange={event => setFeedback(event.target.value)}
                        />
                    </FormControl>
                </Collapse>
            </DialogContent>
            {enableTipping && !hasTip && !isDonation && amountRecycled > 0 && (
                <>
                    <DialogTitle disableTypography style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Typography variant="h5">
                            <b>{loc('feedback9', lang)}</b>
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <TipSelect
                            tipAmount={tipAmount}
                            setTipAmount={setTipAmount}
                            tipAmountOther={tipAmountOther}
                            setTipAmountOther={setTipAmountOther}
                            standardTipAmounts={standardTipAmounts}
                            maxAmount={amountRecycled}
                        />
                    </DialogContent>
                </>
            )}

            <DialogActions
                style={{
                    justifyContent: 'space-between',
                    marginTop: theme.spacing.unit,
                    marginBottom: theme.spacing.unit
                }}
            >
                <Button onClick={onBack}>{loc('back', lang)}</Button>

                {isAdmin && <Button onClick={onClose}>{loc('close', lang)}</Button>}
                <Button
                    data-cy="driver-rating-feedback-next"
                    onClick={() =>
                        onNextStep(
                            tipAmount,
                            feedback,
                            feedbackType,
                            reason,
                            driverWasOnTime,
                            driverLeftBags,
                            driverWasProfessional
                        )
                    }
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
}

export default withTheme()(TipScreen);
