import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { RESIDENTIAL, CONDO, COMMERCIAL, BOTTLE_DRIVE, ZONES_COLORS } from 'constants.js';

import { withTheme } from '@material-ui/core/styles';
import { mdiSale } from '@mdi/js';
import * as allIcons from '@mdi/js';
import { getPickupFrequency, formatAsAddress, formatAsPhoneNumber, getWindowWidth } from 'utils/misc';
import * as colors from '@material-ui/core/colors';
import { Icon as MDIcon } from '@mdi/react';
import {
    DialogContentText,
    Button,
    Icon,
    Typography,
    Tooltip,
    IconButton,
    Chip,
    Card,
    CardContent,
    CardActionArea,
    Fab,
    withStyles,
    ButtonBase,
    ClickAwayListener
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Badge from 'components/Badge/Badge';
import ExpandableInfoPanel from './ExpandableInfoPanel';
import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';

// contexts
import DebugContext from 'components/CustomDebugDialog/DebugContext';

import { _time } from 'std';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

import { Clipboard } from '@capacitor/clipboard';

const styles = theme => ({
    outlinedFAB: {
        border: '1px solid',
        backgroundColor: 'rgba(0,0,0,0)',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    }
});

const PickupInfo = ({
    customer,
    charities,
    theme,
    handleCancelPickup,
    handleEditPickup,
    handleToggleFlag,
    pickup,
    count,
    classes,
    width
}) => {
    const onDebug = useContext(DebugContext);
    const onSnackbar = useContext(SnackbarContext);
    const [info, setInfo] = useState(pickup);
    const [isExpanded, setIsExpanded] = useState(false);

    const divRef = useRef(null);
    const [stackSections, setStackSections] = useState(false);

    const { lang } = useContext(LocalizationContext);

    const handleClicks = (pickup_id, path, value) => () => {
        handleToggleFlag(pickup_id, path, value);
        setInfo(prevState => ({ ...prevState, [path]: value }));
    };

    const handleCopy = string => {
        Clipboard.write({
            string
        });
        onSnackbar(`Copied ${string} to clipboard.`);
    };

    const iconStyle = { marginRight: theme.spacing.unit * 0.5, fontSize: 18 };
    const textStyle = {
        fontSize: 12
    };
    const itemStyle = { display: 'flex', flex: '1 1 auto' };
    const payload = _.get(info, 'payload');
    let payloadNonZero = {};
    Object.keys(_.get(info, 'payload', {})).forEach(skuType => {
        if (payload[skuType] !== 0) {
            payloadNonZero[skuType] = payload[skuType];
        }
    });
    const charityFromPickup = _.find(charities, charity => charity._id === info.charity);
    const charityName = !_.isNil(charityFromPickup) ? charityFromPickup.name : 'N/A';
    const alwaysConfirmed = _.get(info, 'alwaysConfirmed', false);
    const bypassZoneChecks = _.get(info, 'bypassZoneChecks', false);
    const alwaysBypassZoneChecks = _.get(info, 'alwaysBypassZoneChecks', false);
    const highPriority = _.get(info, 'postponedBySystem', 0);
    const hasClothingBin = _.get(info, 'hasClothingBin', false);

    const handleResize = () => {
        if (!divRef || !divRef.current) return;
        if (divRef.current.offsetWidth < 449) {
            setStackSections(true);
        } else {
            setStackSections(false);
        }
    };

    useEffect(() => {
        setInfo(pickup);
    }, [pickup]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const backgroundColor =
        ZONES_COLORS[
            moment(info.date)
                .tz(_.get(info, 'zone.timezone', _time.getTimezone()))
                .isoWeekday() - 1
        ];
    const backgroundColorDarkness = backgroundColor === colors.lime ? 700 : 500; //darken the lime color
    const chipBackgroundColor = backgroundColor[backgroundColorDarkness];

    //*********************** */
    const isSmScreen = isWidthDown('xs', width);

    const tileSize = 54;
    const iconOffset = 12;

    const squareIcon = useMemo(
        () => (
            <div
                style={{
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: tileSize,
                    width: tileSize
                }}
            >
                <Typography variant="button" style={{ color: 'inherit' }} textAlign="center">
                    {locDate(info.date, 'D', lang, _.get(info, 'zone.timezone', _time.getTimezone()))}
                </Typography>
                <Typography
                    variant="button"
                    textAlign="center"
                    style={{
                        fontSize: theme.typography.caption.fontSize,
                        color: 'inherit'
                    }}
                >
                    {locDate(info.date, 'MMM', lang, _.get(info, 'zone.timezone', _time.getTimezone()))}
                </Typography>
            </div>
        ),
        [pickup, theme]
    );

    return (
        <div style={{ maxWidth: '100%' }}>
            <Card
                elevation={0}
                style={{
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    maxHeight: '100%',
                    marginTop: theme.spacing.unit,
                    marginBottom: theme.spacing.unit,
                    maxWidth: '100%'
                }}
            >
                <CardContent style={{ display: 'block', position: 'relative', padding: 0 }}>
                    <CardActionArea
                        data-cy="pickup-info-expand-info"
                        disableRipple={!customer.adminView}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: theme.spacing.unit,
                            //paddingBottom: 0,
                            alignItems: 'center',
                            maxWidth: '100%',
                            cursor: customer.adminView && 'pointer'
                        }}
                        onClick={() => customer.adminView && setIsExpanded(!isExpanded)}
                    >
                        <div
                            style={{
                                border: '1px solid',
                                borderColor: chipBackgroundColor || colors.grey[500],
                                backgroundColor: chipBackgroundColor || colors.grey[500],
                                borderRadius: 4,
                                paddingTop: 0,
                                height: tileSize,
                                width: tileSize,
                                textAlign: 'center'
                            }}
                        >
                            {squareIcon}
                        </div>
                        <div
                            style={{
                                marginLeft: theme.spacing.unit,
                                flex: 1,
                                overflow: 'hidden',
                                //overflowX: 'auto',
                                width: 0
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!isSmScreen && (
                                    <Typography variant="caption" noWrap style={{ marginRight: theme.spacing.unit }}>
                                        {locDate(
                                            info.date,
                                            'dddd',
                                            lang,
                                            _.get(info, 'zone.timezone', _time.getTimezone())
                                        )}
                                    </Typography>
                                )}
                                <Typography variant="caption" style={{ marginRight: theme.spacing.unit }}>
                                    {!info.unserviced && (
                                        <>
                                            {!_.isNil(info.nextDate) ? (
                                                <span
                                                    data-cy="pickup-info-recurrence"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        color: theme.palette.primary.main,
                                                        marginLeft: theme.spacing.unit / 2
                                                    }}
                                                >
                                                    <Icon
                                                        title={loc('pickupDialog16', lang)}
                                                        style={{ verticalAlign: 'text-bottom', fontSize: 18 }}
                                                    >
                                                        sync
                                                    </Icon>{' '}
                                                    {getPickupFrequency(info) / 7}{' '}
                                                    {!isSmScreen && (
                                                        <>
                                                            {' '}
                                                            week
                                                            {getPickupFrequency(info) / 7 > 1 ? 's' : ''}
                                                        </>
                                                    )}
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        color: theme.palette.primary.main,
                                                        marginLeft: theme.spacing.unit / 2
                                                    }}
                                                >
                                                    <Icon
                                                        title={loc('pickupDialog17', lang)}
                                                        style={{ verticalAlign: 'text-bottom', fontSize: 18 }}
                                                    >
                                                        sync_disabled
                                                    </Icon>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Typography>
                                <DetailedPayloadIndicators
                                    showUnits
                                    commodities={_.get(info, 'zone.payloadAccepted')}
                                    indicatorSize={0.7}
                                    pickups={[pickup]}
                                    labelStyle={{
                                        fontSize: '0.75rem'
                                    }}
                                    style={{ flexWrap: 'nowrap' }}
                                />
                                {highPriority > 0 && (
                                    <Badge
                                        style={{
                                            display: 'flex',
                                            alignItem: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, .54)',
                                            marginLeft: theme.spacing.unit
                                        }}
                                    >
                                        <Icon color="action" style={{ ...iconStyle, color: '#fff' }}>
                                            star
                                        </Icon>{' '}
                                        <Typography style={{ ...textStyle, color: '#fff' }}>
                                            {loc('high', lang)}
                                        </Typography>
                                    </Badge>
                                )}
                                {hasClothingBin && (
                                    <Badge
                                        style={{
                                            display: 'flex',
                                            alignItem: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, .54)',
                                            marginLeft: theme.spacing.unit
                                        }}
                                    >
                                        <Icon color="action" style={{ ...iconStyle, color: '#fff' }}>
                                            delete
                                        </Icon>
                                        <Typography style={{ ...textStyle, color: '#fff' }}>
                                            {'Clothing Bins'}
                                        </Typography>
                                    </Badge>
                                )}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <div
                                    style={{
                                        ...itemStyle,
                                        display: 'flex',
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon color="action" style={iconStyle}>
                                        phone
                                    </Icon>

                                    <Typography
                                        style={{
                                            ...textStyle,
                                            flex: 1,
                                            whiteSpace: 'nowrap'
                                        }}
                                        noWrap
                                        data-cy="pickup-info-phone"
                                    >
                                        {_.isNil(info.phone)
                                            ? formatAsPhoneNumber(customer.phone)
                                            : formatAsPhoneNumber(info.phone)}
                                    </Typography>
                                </div>
                                <div
                                    style={
                                        !_.isEmpty(info.promos)
                                            ? { ...itemStyle, marginRight: theme.spacing.unit }
                                            : { ...itemStyle, opacity: '0.7', marginRight: theme.spacing.unit }
                                    }
                                >
                                    <span style={{ ...iconStyle, height: iconStyle.fontSize }}>
                                        <MDIcon
                                            path={mdiSale}
                                            size={`${iconStyle.fontSize}px`}
                                            color={colors.grey[600]}
                                        />
                                    </span>

                                    <Typography style={textStyle} noWrap>
                                        {_.isEmpty(info.promos)
                                            ? loc('managePickups2', lang)
                                            : `${_.last(info.promos).code}`}
                                    </Typography>
                                </div>
                                <div style={!_.isEmpty(info.charity) ? itemStyle : { ...itemStyle, opacity: '0.7' }}>
                                    <>
                                        <Icon color="action" style={iconStyle}>
                                            favorite
                                        </Icon>

                                        <Typography style={textStyle} noWrap>
                                            {_.isEmpty(info.charity)
                                                ? loc('managePickups1', lang)
                                                : _.isNil(info.subdivision)
                                                ? `${charityName}`
                                                : `${charityName} (${info.subdivision})`}
                                        </Typography>
                                    </>
                                </div>
                            </div>

                            <div
                                style={{
                                    ...itemStyle,
                                    flex: '0 0 100%',
                                    marginBottom: theme.spacing.unit,
                                    maxWidth: '100%',
                                    overflow: 'hidden'
                                }}
                            >
                                <Icon color="action" style={iconStyle}>
                                    comment
                                </Icon>

                                <Typography style={textStyle} noWrap>
                                    {info.comment}
                                </Typography>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                //flexDirection: isSmScreen ? 'column' : 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Fab
                                data-cy={`${count}-edit-or-cancel-dialog-edit-button`}
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleEditPickup(info._id)();
                                }}
                                size={isSmScreen ? 'small' : 'medium'}
                                style={{
                                    marginRight: theme.spacing.unit,
                                    boxShadow: 'none',
                                    flexShrink: 0
                                }}
                                className={classes.outlinedFAB}
                            >
                                <Icon>edit</Icon>
                            </Fab>
                            <Fab
                                data-cy={`${count}-edit-or-cancel-dialog-cancel-button`}
                                color="secondary"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleCancelPickup(info._id)();
                                }}
                                size={isSmScreen ? 'small' : 'medium'}
                                style={{
                                    boxShadow: 'none',
                                    marginRight: theme.spacing.unit,
                                    flexShrink: 0
                                }}
                            >
                                <Icon style={{ color: 'white' }}>delete</Icon>
                            </Fab>
                        </div>
                    </CardActionArea>
                    <div
                        style={{
                            backgroundColor: chipBackgroundColor || colors.grey[500],
                            position: 'absolute',
                            height: '100%',
                            width: 8,
                            right: 0,
                            top: 0
                        }}
                    />
                    {customer.adminView && (
                        <div style={{ padding: theme.spacing.unit, paddingTop: 0 }}>
                            {/*customer.adminView && (
                                <CardActionArea
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    data-cy="pickup-info-expand-info"
                                >
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontSize: 12, color: theme.palette.text.primary }}
                                    >
                                        Info
                                    </Typography>
                                    <Icon>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                                </CardActionArea>
                                )*/}
                            {customer.adminView && (
                                <ExpandableInfoPanel
                                    info={info}
                                    alwaysConfirmed={alwaysConfirmed}
                                    bypassZoneChecks={bypassZoneChecks}
                                    alwaysBypassZoneChecks={alwaysBypassZoneChecks}
                                    highPriority={highPriority}
                                    hasClothingBin={hasClothingBin}
                                    theme={theme}
                                    isInfoExpanded={isExpanded}
                                    handleCopy={handleCopy}
                                    handleClicks={handleClicks}
                                />
                            )}
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default withWidth()(withStyles(styles)(withTheme()(PickupInfo)));

const getIcon = pickupType => {
    switch (pickupType) {
        case CONDO:
            return 'location_city';
        case COMMERCIAL:
            return 'store';
        case BOTTLE_DRIVE:
            return 'favorite';
        case RESIDENTIAL:
        default:
            return 'home';
    }
};

const getPickupType = pickupType => {
    switch (pickupType) {
        case CONDO:
            return 'Condo';
        case COMMERCIAL:
            return 'Commercial';
        case BOTTLE_DRIVE:
            return 'Bottle drive';
        case RESIDENTIAL:
        default:
            return 'Residential';
    }
};
