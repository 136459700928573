import React, { useState, useEffect } from 'react';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user, _time } from 'std';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';

import {
    FinancialWidget,
    TripsWidget,
    DriverCodeWidget,
    StatsWidget,
    CompletedTripsWidget
} from 'components/DriverWidgets';
//import OperatorRatingWidgets from 'components/OperatorRatingWidgets/OperatorRatingWidgets';
import PickupRatingWidget from 'components/OperatorRatingWidgets/PickupRatingWidget';
import CountingRatingWidget from 'components/OperatorRatingWidgets/CountingRatingWidget';
import ComplaintsWidget from 'components/OperatorRatingWidgets/ComplaintsWidget';
import MonthDropDownSelect from 'components/DropDownSelects/MonthDropDown';

import Grid from '@material-ui/core/Grid';
import { withTheme } from '@material-ui/core/styles';
import {
    Hidden,
    CircularProgress,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    FormControlLabel,
    Checkbox,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Menu,
    Icon,
    Badge,
    withStyles
} from '@material-ui/core';

import useGetJSON from 'utils/hooks/useGetJSON';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import AssortedTimeIntervalDropdown from 'components/DropDownSelects/AssortedTimeIntervalDropdown';
import ComplimentsWidget from 'components/OperatorRatingWidgets/ComplimentsWidget';
import CustomerFeedbackWidget from 'components/OperatorRatingWidgets/CustomerFeedbackWidget';
import TipsWidget from 'components/OperatorRatingWidgets/TipsWidget';

import CustomerReviewWrapper from 'components/DriverWidgets/CustomerReviewWrapper';

const styles = theme => ({
    tabBadgeRoot: {
        color: 'white',
        '& > .tab-text': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper
        }
    },
    tabBadgeShifted: {
        right: -theme.spacing.unit
    },
    tabBadgeDefault: {
        right: 0
    }
});

function Dashboard(props) {
    const {
        theme,
        classes,
        http,
        history,
        operator,
        pickups,
        redemptions,
        redemptionFee,
        redemptionPending,
        minRedemption,
        onSnackbar,
        reloadOperator
    } = props;

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [tab, setTab] = useState(0); // toggle between complaints and compliments widget

    const shiftBadge = useMediaQuery(theme.breakpoints.up('sm'));

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeBothDates,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .subtract(30, 'days')
            .startOf('day'),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const [completedTrips, setCompletedTrips] = useState([]);
    const [driverFinancialInfo, setDriverFinancialInfo] = useState({});
    const [commodities, setCommodities] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [redemptionDialogOpen, setRedemptionDialogOpen] = useState(false);
    const [locationDialogOpen, setLocationDialogOpen] = useState(
        JSON.parse(localStorage.getItem('locationAccessDialog') || 'true')
    );

    const [compTripsWithStatsLoading, setCompTripsWithStatsLoading] = useState(false);

    const [intervalValue, setIntervalValue] = useState(
        [moment(startDate).format('MM-DD-YYYY'), moment(endDate).format('MM-DD-YYYY')].toString()
    );

    const { data: firstTrip } = useGetJSON(`/users/${operator._id}/getFirstTrip`, 'firstTrip');

    const { loading: tripsLoading, data: trips } = useGetJSON(`/users/operators/${operator._id}/getTrips`, 'trips', []);

    const { loading: tipStatsLoading, data: tips } = useGetJSON(
        `/tips/operators/${operator._id}/getDriverTipStats?startDate=${intervalValue.split(',')[0]}&endDate=${
            intervalValue.split(',')[1]
        }`
    );

    const { loading: driverRatingLoading, error: driverRatingError, data: driverRatingObj } = useGetJSON(
        `/users/${operator._id}/operatorRatingStatistics?startDate=${intervalValue.split(',')[0]}&endDate=${
            intervalValue.split(',')[1]
        }`
    );

    const [driverRanking, setDriverRanking] = useState({});
    const [pickedUpCommodities, setPickedUpCommodities] = useState([]);
    const [locationDialogCheckbox, setLocationDialogCheckbox] = useState(false);

    const upcomingTrips = _.filter(
        trips,
        trip =>
            trip.upcoming ||
            (moment(trip.startTime)
                .tz(operator.collector.timezone)
                .startOf('day')
                .isSame(
                    moment()
                        .tz(operator.collector.timezone)
                        .startOf('day')
                ) &&
                _.isNil(trip.offloadTime))
    );

    const handleIntervalChange = e => {
        const date = e.target.value;
        const dateArr = date.split(',');

        handleChangeBothDates(
            moment.tz(dateArr[0], 'MM-DD-YYYY', timezone),
            moment.tz(dateArr[1], 'MM-DD-YYYY', timezone)
        );
    };

    const handleLocationDialog = () => {
        setLocationDialogOpen(false);
        if (locationDialogCheckbox) {
            localStorage.setItem('locationAccessDialog', false);
        }
    };

    const handleLocationDialogCheckbox = () => {
        setLocationDialogCheckbox(!locationDialogCheckbox);
    };

    const handleChangeTab = (e, value) => {
        setCurrentTab(value);
    };

    const handleRedemptionDialog = state => () => {
        setRedemptionDialogOpen(state);
    };

    const handleRedeemSuccess = async () => {
        setRedemptionDialogOpen(false);
        onSnackbar('You have requested a redemption!');
        await reloadOperator();
    };

    const handleDonateSuccess = async charityName => {
        setRedemptionDialogOpen(false);
        onSnackbar(`You have donated your returns to ${charityName}!`);
        await reloadOperator();
    };

    useEffect(() => {
        if (filtersOpen) {
            setFiltersOpen(false);
            setAnchor(null);
        }

        setCompTripsWithStatsLoading(true);
        http.getJSON(
            '/users/operators/' +
                operator._id +
                `/getCompletedTripsStatistics?startDate=${intervalValue.split(',')[0]}&endDate=${
                    intervalValue.split(',')[1]
                }`
        ).then(res1 => {
            try {
                setCompletedTrips(_.get(res1, 'data.completedTripsWithStats.completedTrips', []));
                setDriverFinancialInfo(_.get(res1, 'data.completedTripsWithStats.driverFinancialInfo', {}));
                setDriverRanking(_.get(res1, 'data.driverRankingPickedUpCmdties.driverRanking', {}));
                setPickedUpCommodities(_.get(res1, 'data.driverRankingPickedUpCmdties.pickedUpCommodities', []));

                setCompTripsWithStatsLoading(false);
            } catch (err) {
                console.error('Trip confirm error');
            }
        });

        //get all commodities
        (async function() {
            const res = await http.getJSON(`/commodities/getAllCommodities`);
            if (res.ok) {
                setCommodities(_.get(res, 'data.collectionData', []));
            }
        })();
    }, [intervalValue]);

    useEffect(() => {
        if (filtersOpen) {
            setFiltersOpen(false);
            setAnchor(null);
        }
        setIntervalValue([moment(startDate).format('MM-DD-YYYY'), moment(endDate).format('MM-DD-YYYY')].toString());
    }, [startDate, endDate]);

    const [complimentsViewed, setComplimentsViewed] = useState(localStorage.getItem('complimentsViewed') === 'true');
    const [complaintsViewed, setComplaintsViewed] = useState(localStorage.getItem('complaintsViewed') === 'true');
    const [unviewedComplimentsCount, setUnviewedComplimentsCount] = useState(0);
    const [unviewedComplaintsCount, setUnviewedComplaintsCount] = useState(0);
    const [unviewedComplaintIdsState, setUnviewedComplaintIdsState] = useState([]);
    const [unviewedComplaintBulkIdsState, setUnviewedComplaintBulkIdsState] = useState([]);

    useEffect(() => {
        let complimentIds = _.get(driverRatingObj, 'pickupsWithCompliments', []).map(c => c._id);
        let viewedComplimentIds = JSON.parse(localStorage.getItem('viewedComplimentIds') || '[]');
        let viewedComplimentIdsSet = new Set(viewedComplimentIds);
        let unviewedComplimentIds = [];
        complimentIds.forEach(id => {
            if (!viewedComplimentIdsSet.has(id)) {
                unviewedComplimentIds.push(id);
            }
        });
        let complimentCount = unviewedComplimentIds.length;
        setUnviewedComplimentsCount(complimentCount);
        let complaintIds = [
            ..._.get(driverRatingObj, 'pickupsWithIssues', []),
            ..._.get(driverRatingObj, 'systemComplaints', [])
        ].map(c => c._id);
        let complaintBulks = _.get(driverRatingObj, 'bulksWithIssues', []);
        let viewedComplaintBulks = JSON.parse(localStorage.getItem('viewedComplaintBulks') || '{}');
        let unviewedComplaintBulks = [];
        // viewedComplaintBulks contains key value pair of complaint bulks id and their complaint description
        // for each of compalaint bulks currently in view, check their id and check if the description match
        // if match consider it viewed
        complaintBulks.forEach(b => {
            let viewedComplaintDesc = _.get(viewedComplaintBulks, `${b._id}`, null);
            let unviewedComplaintDesc = null;
            if (!_.isEmpty(_.get(b, 'driverIssues.issues', []))) {
                unviewedComplaintDesc = _.get(b, `driverIssues.description`, '');
            }
            if (!_.isNil(viewedComplaintDesc) && viewedComplaintDesc === unviewedComplaintDesc) {
                return;
            }
            unviewedComplaintBulks.push(b);
        });
        let complaintBulkCount = unviewedComplaintBulks.length;
        setUnviewedComplaintBulkIdsState(unviewedComplaintBulks.map(b => b._id));

        let viewedComplaintIds = JSON.parse(localStorage.getItem('viewedComplaintIds') || '[]');
        let viewedComplaintIdsSet = new Set(viewedComplaintIds);
        let unviewedComplaintIds = [];
        complaintIds.forEach(id => {
            if (!viewedComplaintIdsSet.has(id)) {
                unviewedComplaintIds.push(id);
            }
        });
        setUnviewedComplaintIdsState(unviewedComplaintIds);
        let complaintCount = unviewedComplaintIds.length;
        setUnviewedComplaintsCount(complaintCount + complaintBulkCount);
        if (complimentCount !== 0) {
            localStorage.setItem('complimentsViewed', false);
            setComplimentsViewed(false);
        }
        if (complaintCount + complaintBulkCount > 0) {
            localStorage.setItem('complaintsViewed', false);
            setComplaintsViewed(false);
        }
    }, [driverRatingObj]);

    const handleViewCompliments = driverRatingObj => {
        let complementIds = _.get(driverRatingObj, 'pickupsWithCompliments', []).map(c => c._id);
        let viewedComplimentIds = JSON.parse(localStorage.getItem('viewedComplimentIds') || '[]');
        let newViewedComplimentIds = [...new Set([...complementIds, ...viewedComplimentIds])];
        localStorage.setItem('viewedComplimentIds', JSON.stringify(newViewedComplimentIds));
        localStorage.setItem('complimentsViewed', true);
        setComplimentsViewed(true);
    };

    const handleViewComplaints = driverRatingObj => {
        let complaintIds = [
            ..._.get(driverRatingObj, 'pickupsWithIssues', []),
            ..._.get(driverRatingObj, 'systemComplaints', [])
        ].map(c => c._id);
        let complaintBulks = _.get(driverRatingObj, 'bulksWithIssues', []);
        let viewedComplaintBulks = JSON.parse(localStorage.getItem('viewedComplaintBulks') || '{}');
        complaintBulks.forEach(b => {
            viewedComplaintBulks[b._id] = _.get(b, 'driverIssues.description', '');
        });
        let viewedComplaintIds = JSON.parse(localStorage.getItem('viewedComplaintIds') || '[]');
        let newViewedComplaintIds = [...new Set([...complaintIds, ...viewedComplaintIds])];
        localStorage.setItem('viewedComplaintIds', JSON.stringify(newViewedComplaintIds));
        localStorage.setItem('viewedComplaintBulks', JSON.stringify(viewedComplaintBulks));
        localStorage.setItem('complaintsViewed', true);
        setComplaintsViewed(true);
    };

    const isDriver = _user.isDriver(operator);
    const isClerk = _user.isClerk(operator);

    const firstTripDate = _.get(firstTrip, 'startDateInteger');
    const dateFloor = _.isNil(firstTripDate) ? firstTripDate : firstTripDate.toString();
    return (
        <>
            <Hidden xsDown>
                <Grid
                    container
                    style={{ padding: theme.spacing.unit * 1, width: '100%' }}
                    spacing={theme.spacing.unit * 2}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={theme.spacing.unit * 2}>
                            {driverRatingLoading || compTripsWithStatsLoading ? (
                                <Grid item xs={12}>
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        <CircularProgress />
                                    </Paper>
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <Paper
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                padding: theme.spacing.unit,
                                                marginTop: theme.spacing.unit,
                                                gap: theme.spacing.unit
                                            }}
                                        >
                                            <DateRangePicker
                                                timezone={timezone}
                                                startDate={startDate}
                                                endDate={endDate}
                                                window={dateWindow}
                                                handlePrevious={handleGoBackwards}
                                                handleNext={handleGoForwards}
                                                handleChangeStartDate={handleChangeStartDate}
                                                handleChangeEndDate={handleChangeEndDate}
                                            />
                                            <AssortedTimeIntervalDropdown
                                                value={intervalValue}
                                                onChange={handleIntervalChange}
                                                style={{ width: '132px' }}
                                                dateFloor={dateFloor}
                                            />
                                        </Paper>
                                    </Grid>
                                    {isDriver && (
                                        <Grid item sm={6} md>
                                            <PickupRatingWidget
                                                pickupRating={driverRatingObj.pickupRating}
                                                operator={operator}
                                                driverFinancialInfo={driverFinancialInfo}
                                                driverRanking={driverRanking}
                                                isDriver={isDriver}
                                                pickedUpCommodities={pickedUpCommodities}
                                                commodities={commodities}
                                                tipBalance={tips.tipBalance}
                                            />
                                        </Grid>
                                    )}

                                    {isClerk && (
                                        <Grid item sm={6} md>
                                            <CountingRatingWidget
                                                countRating={driverRatingObj.countRating}
                                                isClerk={isClerk}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item sm={12} md>
                                        {_.get(operator, 'permissions.payThroughSystem', false) && tips.tipsAreEnabled && (
                                            <TipsWidget
                                                style={{
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                                tipBalance={tips.tipBalance}
                                                theme={theme}
                                                loading={tipStatsLoading}
                                            />
                                        )}
                                        <CustomerReviewWrapper
                                            type="compliments"
                                            count={unviewedComplimentsCount}
                                            viewed={complimentsViewed}
                                            handleExpand={handleViewCompliments}
                                            driverRatingObj={driverRatingObj}
                                        >
                                            <ComplimentsWidget
                                                style={{
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                                noPadding={true}
                                                operator={operator}
                                                pickupsWithCompliments={driverRatingObj.pickupsWithCompliments}
                                                loading={driverRatingLoading}
                                            />
                                        </CustomerReviewWrapper>
                                        <CustomerReviewWrapper
                                            viewed={complaintsViewed}
                                            count={unviewedComplaintsCount}
                                            handleExpand={handleViewComplaints}
                                            driverRatingObj={driverRatingObj}
                                        >
                                            <ComplaintsWidget
                                                noPadding={true}
                                                operator={operator}
                                                history={history}
                                                pickupsWithIssues={driverRatingObj.pickupsWithIssues}
                                                bulksWithIssues={driverRatingObj.bulksWithIssues}
                                                systemComplaints={driverRatingObj.systemComplaints}
                                                loading={driverRatingLoading}
                                                isDriver={isDriver}
                                                unviewedComplaintIdsState={unviewedComplaintIdsState}
                                                unviewedComplaintBulkIdsState={unviewedComplaintBulkIdsState}
                                            />
                                        </CustomerReviewWrapper>

                                        {driverRatingObj.customerFeedback && (
                                            <CustomerFeedbackWidget
                                                style={{
                                                    marginTop: theme.spacing.unit * 2
                                                }}
                                                customerFeedback={driverRatingObj.customerFeedback}
                                                theme={theme}
                                                loading={tripsLoading}
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {isDriver && (
                        <>
                            <Grid item xs={6}>
                                <div style={{ marginBottom: theme.spacing.unit }}>
                                    <DriverCodeWidget driverCode={operator.driverCode} />
                                </div>
                                <TripsWidget
                                    history={props.history}
                                    operator={operator}
                                    upcomingTrips={upcomingTrips}
                                    onCustomHeader={props.onCustomHeader}
                                    commodities={commodities}
                                    loading={tripsLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CompletedTripsWidget
                                    http={http}
                                    completedTrips={completedTrips}
                                    operator={operator}
                                    commodities={commodities}
                                    tipsGroupedByTrip={tips.tipsGroupedByTrip}
                                    tipsAreEstimated={tips.tipsAreEstimated}
                                    tipsAreEnabled={tips.tipsAreEnabled}
                                    loading={compTripsWithStatsLoading || tipStatsLoading}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Hidden>
            <Hidden smUp>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingBottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing.unit * 7}px)`
                    }}
                >
                    {(isDriver || isClerk) && (
                        <Grid item xs={12}>
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: theme.spacing.unit,
                                    marginBottom: theme.spacing.unit
                                }}
                            >
                                <Menu
                                    open={filtersOpen}
                                    anchorEl={anchor}
                                    onClose={e => {
                                        setFiltersOpen(false);
                                        setAnchor(null);
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: theme.spacing.unit * 2
                                        }}
                                    >
                                        <DateRangePicker
                                            timezone={timezone}
                                            startDate={startDate}
                                            endDate={endDate}
                                            window={dateWindow}
                                            handlePrevious={handleGoBackwards}
                                            handleNext={handleGoForwards}
                                            handleChangeStartDate={handleChangeStartDate}
                                            handleChangeEndDate={handleChangeEndDate}
                                        />
                                        <AssortedTimeIntervalDropdown
                                            value={intervalValue}
                                            onChange={handleIntervalChange}
                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                            dateFloor={dateFloor}
                                        />
                                    </div>
                                </Menu>
                                <Typography
                                    style={{ display: 'inline', margin: `0px ${theme.spacing.unit}px`, flex: 1 }}
                                    variant="subtitle1"
                                    data-cy="driver-dashboard-date-label"
                                >
                                    Filtered from {moment(startDate).format('MMM DD YYYY')} -{' '}
                                    {moment(endDate).format('MMM DD YYYY')}
                                </Typography>
                                <div style={{ textAlign: 'right' }}>
                                    <Button
                                        data-cy="driver-dashboard-filters"
                                        disabled={driverRatingLoading || compTripsWithStatsLoading}
                                        onClick={e => {
                                            setFiltersOpen(true);
                                            setAnchor(e.currentTarget);
                                        }}
                                    >
                                        {driverRatingLoading || compTripsWithStatsLoading ? (
                                            <CircularProgress size={24} color={theme.palette.text.disabled} />
                                        ) : (
                                            <>
                                                <Typography
                                                    style={{ display: 'inline', marginRight: theme.spacing.unit }}
                                                    variant="button"
                                                    data-cy="driver-dashboard-filter-button"
                                                >
                                                    Filters
                                                </Typography>
                                                <Icon>filter_list</Icon>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    )}
                    {currentTab === 0 && (
                        <>
                            {isDriver && (
                                <div style={{ marginBottom: theme.spacing.unit }}>
                                    <DriverCodeWidget driverCode={operator.driverCode} />
                                </div>
                            )}

                            <CustomerReviewWrapper
                                type="compliments"
                                count={complimentsViewed}
                                viewed={complimentsViewed}
                                handleExpand={handleViewCompliments}
                                driverRatingObj={driverRatingObj}
                            >
                                <ComplimentsWidget
                                    style={{
                                        marginBottom: theme.spacing.unit * 2
                                    }}
                                    noPadding={true}
                                    operator={operator}
                                    pickupsWithCompliments={driverRatingObj.pickupsWithCompliments}
                                    loading={driverRatingLoading}
                                />
                            </CustomerReviewWrapper>
                            <CustomerReviewWrapper
                                viewed={complaintsViewed}
                                count={complaintsViewed}
                                handleExpand={handleViewComplaints}
                                driverRatingObj={driverRatingObj}
                            >
                                <ComplaintsWidget
                                    noPadding={true}
                                    operator={operator}
                                    history={history}
                                    pickupsWithIssues={driverRatingObj.pickupsWithIssues}
                                    bulksWithIssues={driverRatingObj.bulksWithIssues}
                                    systemComplaints={driverRatingObj.systemComplaints}
                                    loading={driverRatingLoading}
                                    isDriver={isDriver}
                                />
                            </CustomerReviewWrapper>
                            {isDriver && (
                                <TripsWidget
                                    history={props.history}
                                    operator={operator}
                                    upcomingTrips={upcomingTrips}
                                    onCustomHeader={props.onCustomHeader}
                                    commodities={commodities}
                                    loading={tripsLoading}
                                />
                            )}
                            {isClerk && !isDriver && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant="h6">Driver Permission Required</Typography>
                                    <Typography>Sign in to an driver account to gain access to this tab</Typography>
                                </div>
                            )}
                        </>
                    )}

                    {currentTab === 1 && (
                        <>
                            {driverRatingLoading || compTripsWithStatsLoading ? (
                                <Grid item xs={12}>
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: theme.spacing.unit,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        <CircularProgress />
                                    </Paper>
                                </Grid>
                            ) : (
                                <>
                                    <CompletedTripsWidget
                                        http={http}
                                        completedTrips={completedTrips}
                                        operator={operator}
                                        commodities={commodities}
                                        tipsGroupedByTrip={tips.tipsGroupedByTrip}
                                        tipsAreEstimated={tips.tipsAreEstimated}
                                        tipsAreEnabled={tips.tipsAreEnabled}
                                        loading={compTripsWithStatsLoading || tipStatsLoading}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 2 && (
                        <>
                            {driverRatingLoading || compTripsWithStatsLoading ? (
                                <Paper
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: theme.spacing.unit,
                                        marginBottom: theme.spacing.unit
                                    }}
                                >
                                    <CircularProgress />
                                </Paper>
                            ) : (
                                <>
                                    <div style={{ marginBottom: theme.spacing.unit }}>
                                        {_.get(operator, 'permissions.payThroughSystem', false) &&
                                            tips.tipsAreEnabled && (
                                                <TipsWidget
                                                    tipBalance={tips.tipBalance}
                                                    theme={theme}
                                                    loading={tipStatsLoading}
                                                />
                                            )}
                                    </div>
                                    {isDriver && (
                                        <div style={{ marginBottom: theme.spacing.unit }}>
                                            <PickupRatingWidget
                                                pickupRating={driverRatingObj.pickupRating}
                                                operator={operator}
                                                driverFinancialInfo={driverFinancialInfo}
                                                driverRanking={driverRanking}
                                                isDriver={isDriver}
                                                pickedUpCommodities={pickedUpCommodities}
                                                commodities={commodities}
                                                tipBalance={tips.tipBalance}
                                            />
                                        </div>
                                    )}

                                    {isClerk && (
                                        <div style={{ marginBottom: theme.spacing.unit }}>
                                            <CountingRatingWidget
                                                countRating={driverRatingObj.countRating}
                                                isClerk={isClerk}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>

                <AppBar
                    position="fixed"
                    color="default"
                    elevation={0}
                    style={{
                        top: 'auto',
                        bottom: 0,
                        backgroundColor: theme.palette.background.paper,
                        paddingBottom: 'env(safe-area-inset-bottom)',
                        borderTop: `2px solid ${theme.palette.primary.main}`
                    }}
                >
                    <Tabs
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        value={currentTab}
                        onChange={handleChangeTab}
                    >
                        <Tab data-cy="operator-dashboard-tab-upcoming" label="Upcoming" style={{ flex: 1 }} />
                        <Tab data-cy="operator-dashboard-tab-completed" label="Completed" style={{ flex: 1 }} />
                        <Tab data-cy="operator-dashboard-tab-stats" label="Stats" style={{ flex: 1 }} />
                    </Tabs>
                </AppBar>
            </Hidden>
            <Dialog
                open={locationDialogOpen && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)}
                handleClose={handleLocationDialog}
            >
                <DialogTitle>Location Access</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This app collects location data to enable pickup routing, driver location updates, and pickups
                        statistics even when the app is closed or not in use.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={locationDialogCheckbox}
                                onChange={handleLocationDialogCheckbox}
                                value={locationDialogCheckbox}
                                data-cy="location-dialog-checkbox-do-not-show-again"
                            />
                        }
                        label="Do not show this again"
                    />
                    <Button onClick={handleLocationDialog} data-cy="location-dialog-checkbox-dismiss">
                        Dismiss
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withStyles(styles)(withTheme()(Dashboard));
