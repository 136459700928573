import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { clusterAndSortReturnSitesByDistance } from 'utils/latlngFunctions';
import SearchIcon from '@material-ui/icons/Search';
import { getGPSLocation, getOpenStatusFromAvailability } from 'utils/misc';

import {
    CircularProgress,
    withMobileDialog,
    withTheme,
    withWidth,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    Avatar,
    Typography,
    TextField,
    InputAdornment,
    Chip,
    colors,
    ListItemSecondaryAction,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    LinearProgress
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { isWidthUp } from '@material-ui/core/withWidth';
import { DROP_OFF_TYPES } from '../../../../../constants';

const BAG_DROP_TYPES = ['App Only', 'Kiosk', 'Kiosk & Scanner', 'Access Door'];

const defaultAvailability = [
    { day: 'Monday', hours: '8am–6pm' },
    { day: 'Tuesday', hours: '8am–6pm' },
    { day: 'Wednesday', hours: '8am–6pm' },
    { day: 'Thursday', hours: '8am–6pm' },
    { day: 'Friday', hours: '8am–6pm' },
    { day: 'Saturday', hours: '8am–6pm' },
    { day: 'Sunday', hours: '8am–6pm' }
];

function SearchDialog(props) {
    const {
        onClose,
        dropLocations,
        collectors,
        customer,
        theme,
        handleUpdateCurrentLocation,
        onSelectReturnSite,
        width,
        location,
        open
    } = props;
    const { lang } = useContext(LocalizationContext);

    const isSmUp = isWidthUp('sm', width);

    const [GPSLoading, setGPSLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [sortedReturnSites, setSortedReturnSites] = useState([]);
    const [minReturnSiteDistance, setMinReturnSiteDistance] = useState(Number.MAX_SAFE_INTEGER);
    const [searchTextFilter, setSearchTextFilter] = useState('');
    const [searchTechnologyFilter, setSearchTechnologyFilter] = useState([loc('bagDrop', lang), loc('openNow', lang)]);
    const [filteredReturnSites, setFilteredReturnSites] = useState([]);

    const getDropLocation = async () => {
        setLoading(true);
        let coordinates = { lat: 0, lng: 0 };
        if (_.isNil(location)) {
            setGPSLoading(true);
            coordinates = await getGPSLocation();
            setGPSLoading(false);

            let lat, lng;
            if (_.isNil(coordinates)) {
                lat = _.get(customer, 'location.lat');
                lng = _.get(customer, 'location.lng');

                if (_.isNil(lat) || _.isNil(lng)) {
                    setLoading(false);
                    return;
                }
            } else {
                lat = coordinates.coords.latitude;
                lng = coordinates.coords.longitude;
                handleUpdateCurrentLocation({ lat, lng });
            }
        } else {
            coordinates = location;
        }
        let sortedSites = clusterAndSortReturnSitesByDistance(
            {
                lat: _.get(coordinates, 'lat', _.get(customer, 'location.lat')),
                lng: _.get(coordinates, 'lat', _.get(customer, 'location.lng'))
            },
            dropLocations,
            collectors
        );
        let minDistance = minReturnSiteDistance;

        const preferredReturnSite = _.get(customer, 'preferredReturnSite');
        sortedSites.forEach(site => {
            const openStatus = getOpenStatusFromAvailability(
                _.get(
                    site.collector,
                    'availability',
                    _.get(_.first(site.dropLocations), 'availability', defaultAvailability)
                ),
                _.get(
                    site.collector,
                    'timezone',
                    _.get(
                        _.first(site.dropLocations),
                        'collector.timezone',
                        _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                    )
                ),
                lang
            );
            site.openStatusText = _.get(openStatus, 'openStatusText', '');
            site.isOpen = _.get(openStatus, 'open', false);
            if (site.distance <= minDistance) {
                minDistance = site.distance;
            }
            if (
                !_.isNil(preferredReturnSite) &&
                (_.get(preferredReturnSite, 'description', '') === _.get(site.collector, 'location.description') ||
                    _.some(
                        site.dropLocations,
                        location =>
                            _.get(preferredReturnSite, 'description', '') === _.get(location, 'location.description')
                    ))
            ) {
                site.isPreferred = true;
            }
        });
        setMinReturnSiteDistance(minDistance);
        setSortedReturnSites(sortedSites);
        setFilteredReturnSites(sortedSites);

        setLoading(false);
    };

    useEffect(() => {
        getDropLocation();
    }, []);

    useEffect(() => {
        setFilteredReturnSites(
            _.filter(sortedReturnSites, rs => {
                if (
                    !(
                        _.get(rs, 'collector.name', '')
                            .toLowerCase()
                            .includes(searchTextFilter.toLowerCase()) ||
                        _.get(_.first(rs.dropLocations), 'name', '')
                            .toLowerCase()
                            .includes(searchTextFilter.toLowerCase()) ||
                        _.get(rs, 'address', '')
                            .toLowerCase()
                            .includes(searchTextFilter.toLowerCase())
                    )
                ) {
                    return false;
                }
                for (let filterTechnology of searchTechnologyFilter) {
                    // if (filterTechnology === loc('noTechnology', lang) && _.isEmpty(rs.dropLocations)) {
                    //     return true;
                    // } else
                    if (filterTechnology === loc('openNow', lang) && rs.isOpen) {
                        return true;
                    } else if (filterTechnology === loc('bagDrop', lang)) {
                        if (_.some(rs.dropLocations, d => BAG_DROP_TYPES.includes(d.dropOffType))) {
                            return true;
                        }
                    }
                    // else if (filterTechnology === loc('rvm', lang)) {
                    //     if (_.some(rs.dropLocations, d => d.dropOffType === 'Reverse Vending Machine')) {
                    //         return true;
                    //     }
                    // }
                }
                return false;
            })
        );
    }, [searchTextFilter, searchTechnologyFilter]);

    return loading || GPSLoading ? (
        <LinearProgress />
    ) : (
        <Dialog open={open} fullWidth fullScreen={!isSmUp}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {loc('returnSiteLookup', lang)}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2, paddingBottom: 0, height: '100%' }}>
                <TextField
                    name="search"
                    label={loc('search', lang)}
                    fullWidth
                    variant="outlined"
                    value={searchTextFilter || ''}
                    onChange={e => setSearchTextFilter(e.target.value)}
                    margin="dense"
                    autoComplete="false"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <InputLabel variant="outlined" htmlFor="collector-filter">
                        {loc('technologyFilter', lang)}
                    </InputLabel>
                    <Select
                        data-cy="bulk-type-selection-dropdown"
                        value={searchTechnologyFilter}
                        onChange={e => setSearchTechnologyFilter(e.target.value)}
                        multiple
                        input={
                            <OutlinedInput
                                labelWidth={loc('technologyFilter', lang).length * 7 || 36}
                                name="collector"
                                id="outlined-age-simple"
                            />
                        }
                        style={{
                            minWidth: 125
                        }}
                    >
                        {/* <MenuItem value={loc('noTechnology', lang)} key={loc('noTechnology', lang)}>
                            {loc('noTechnology', lang)}
                        </MenuItem>
                        <MenuItem value={loc('rvm', lang)} key={loc('rvm', lang)}>
                            {loc('rvm', lang)}
                        </MenuItem> */}
                        {/* {DROP_OFF_TYPES.map(technology => {
                            return (
                                <MenuItem value={technology.value} key={technology.value}>
                                    {loc(technology.localizationName, lang)}
                                </MenuItem>
                            );
                        })} */}
                        <MenuItem value={loc('bagDrop', lang)} key={loc('bagDrop', lang)}>
                            {loc('bagDrop', lang)}
                        </MenuItem>
                        <MenuItem value={loc('openNow', lang)} key={loc('openNow', lang)}>
                            {loc('openNow', lang)}
                        </MenuItem>
                    </Select>
                </FormControl>
                <List style={{ paddingTop: theme.spacing.unit }}>
                    {filteredReturnSites
                        .filter(site => _.isNil(site.collector) || _.get(site, 'collector.openToPublic', false))
                        .map((returnSite, idx) => {
                            return (
                                <ListItem
                                    key={idx}
                                    button
                                    dense
                                    disableGutters
                                    onClick={onSelectReturnSite(returnSite)}
                                    style={{
                                        paddingTop: theme.spacing.unit,
                                        paddingBottom: theme.spacing.unit,
                                        borderBottom: '1px solid lightgrey'
                                    }}
                                >
                                    <Avatar
                                        style={{
                                            borderRadius: 6,
                                            width: 64,
                                            height: 64,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            backgroundColor: theme.palette.primary.main
                                        }}
                                    >
                                        <div
                                            data-cy={`return-site-${idx}-distance`}
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                fontSize: 14
                                            }}
                                        >
                                            {_.round(_.get(returnSite, 'distance', 0), 2)} km
                                        </div>
                                    </Avatar>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: '1 1 auto',
                                            padding: `0px ${theme.spacing.unit * 2}px`,
                                            minWidth: 0,
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <Typography variant="body2" color="primary" noWrap>
                                                {_.get(
                                                    returnSite,
                                                    'collector.name',
                                                    _.get(
                                                        _.first(_.get(returnSite, 'dropLocations', [])),
                                                        'name',
                                                        'N/A'
                                                    )
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="default"
                                                style={{
                                                    fontSize: '0.75rem',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {_.get(returnSite, 'address', 'N/A')}
                                            </Typography>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Chip
                                                    data-cy={`depot-ui-search-open-status-${idx}`}
                                                    label={_.startCase(returnSite.openStatusText)}
                                                    style={{
                                                        margin: theme.spacing.unit / 2,
                                                        backgroundColor: returnSite.isOpen
                                                            ? colors.green[500]
                                                            : colors.red[500],
                                                        color: 'white',
                                                        height: '1.5em'
                                                    }}
                                                />
                                                {returnSite.distance <= minReturnSiteDistance && (
                                                    <Chip
                                                        data-cy={`depot-ui-search-closest-location-${idx}`}
                                                        label={loc('pickupDialogDrop24b', lang)}
                                                        style={{
                                                            margin: theme.spacing.unit / 2,
                                                            backgroundColor: colors.green[500],
                                                            color: 'white',
                                                            height: '1.5em'
                                                        }}
                                                    />
                                                )}
                                                {/* <Chip
                                                        data-cy={`depot-ui-search-closest-location-${idx}`}
                                                        label={loc('pickupDialogDrop25b', lang)}
                                                        style={{
                                                            margin: theme.spacing.unit / 2,
                                                            backgroundColor: colors.amber[700],
                                                            color: 'white',
                                                            height: '1.5em'
                                                        }}
                                                    /> */}
                                                {returnSite.isPreferred && (
                                                    <Chip
                                                        data-cy={`depot-ui-search-closest-location-${idx}`}
                                                        label={_.startCase(loc('pickupDialogDrop51', lang))}
                                                        style={{
                                                            margin: theme.spacing.unit / 2,
                                                            backgroundColor: colors.green[500],
                                                            color: 'white',
                                                            height: '1.5em'
                                                        }}
                                                    />
                                                )}
                                                {_.get(returnSite, 'dropLocations', []).map(dropLocation => {
                                                    let technology = _.find(
                                                        DROP_OFF_TYPES,
                                                        d => d.value === dropLocation.dropOffType
                                                    );
                                                    return (
                                                        <Chip
                                                            data-cy={`depot-ui-search-closest-location-${idx}`}
                                                            label={loc(technology.localizationName, lang)}
                                                            style={{
                                                                margin: theme.spacing.unit / 2,
                                                                backgroundColor: colors.grey[500],
                                                                color: 'white',
                                                                height: '1.5em'
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <ListItemSecondaryAction>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {returnSite.commodities.map(commodity => {
                                                    return (
                                                        <MDIcon
                                                            key={commodity.iconName}
                                                            path={allIcons[commodity.iconName]}
                                                            color={commodity.color}
                                                            size={1}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {returnSite.technologies.map(technology => {
                                                    const technologyInfo =
                                                        technology === 'Primary Return Site'
                                                            ? { icon: 'business' }
                                                            : _.find(DROP_OFF_TYPES, type => type.value === technology);
                                                    return <Icon color="primary">{technologyInfo.icon}</Icon>;
                                                })}
                                            </div>
                                        </div>
                                    </ListItemSecondaryAction> */}
                                </ListItem>
                            );
                        })}
                </List>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={() => onClose()}>
                    {loc('cancel', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withWidth()(withMobileDialog()(withTheme()(SearchDialog)));
