import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress
} from '@material-ui/core';

import CurrentTime from 'components/Misc/CurrentTime';
import CRONLogList from 'components/Lists/CRONLog';

import useErrorLogList from 'components/Lists/ErrorLog/useErrorLogList';

import useSystemSocket from 'utils/hooks/useSystemSocket';

import SystemTasksTable from 'components/SystemTasksTable/SystemTasksTable';

function Debug({ theme, http, onSnackbar }) {
    const { logs, loading: loadingLogs } = useErrorLogList();
    const {
        infoDialogOpen,
        infoDialogTask,
        handleViewMoreInfo,
        handleCloseMoreInfo,
        handleTriggerSystemTask,
        handleToggleSystemTask,
        systemTasks,
        systemTaskLogs
    } = useSystemSocket();

    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState({});

    const encryptpassphrases = async item => {
        setIsLoading(true);
        var res = await http.post(`/redemptions/encryptPassphrases`);
        if (res.ok) {
            onSnackbar('Success');
        } else {
            onSnackbar('Fail', 'error');
        }
        setIsLoading(false);
    };

    const decryptpassphrases = async item => {
        setIsLoading(true);
        var res = await http.post(`/redemptions/decryptPassphrases`);
        if (res.ok) {
            onSnackbar('Success');
        } else {
            onSnackbar('Fail', 'error');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/system/allConfigs');
            if (res.ok) {
                setConfig(res.data.config);
            }
        })();
    }, []);

    return (
        <Grid container spacing={16} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={12} sm={4} style={{ height: '100%' }}>
                <Paper style={{ padding: theme.spacing.unit * 2 }}>
                    <Typography variant="h5" style={{ marginBottom: theme.spacing.unit }}>
                        System Task Logs
                    </Typography>
                    <div style={{ height: 400 }}>
                        <CRONLogList logs={systemTaskLogs} systemTasks={systemTasks} loading={false} />{' '}
                    </div>
                </Paper>
                {/* <Grid container style={{ width: '100%', marginTop: theme.spacing.unit }}>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    America/Edmonton
                                </Typography>
                                <CurrentTime timezone="America/Edmonton" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    America/Toronto
                                </Typography>
                                <CurrentTime timezone="America/Toronto" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Australia/Brisbane
                                </Typography>
                                <CurrentTime timezone="Australia/Brisbane" />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> */}
                {_.get(config, 'system.showManualPassPhraseEncryption', false) && (
                    <Paper style={{ marginTop: 10, padding: 16 }}>
                        <Typography variant="h6" style={{ marginBottom: theme.spacing.unit }}>
                            Other{' '}
                            {isLoading && (
                                <>
                                    <CircularProgress
                                        size={18}
                                        thickness={4.8}
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            marginLeft: theme.spacing.unit
                                        }}
                                    />
                                </>
                            )}
                        </Typography>{' '}
                        <Table padding="dense">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Manually Trigger</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ whiteSpace: 'nowrap' }}>
                                <TableRow>
                                    <TableCell>Encrypt Redemption Passphrase's</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => {
                                                encryptpassphrases();
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            disabled={isLoading}
                                        >
                                            RUN NOW
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Decrypt Redemption Passphrase's</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => {
                                                decryptpassphrases();
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            disabled={isLoading}
                                        >
                                            RUN NOW
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                )}
            </Grid>

            <Grid item xs={12} sm={8}>
                <SystemTasksTable
                    infoDialogOpen={infoDialogOpen}
                    infoDialogTask={infoDialogTask}
                    systemTasks={systemTasks}
                    onToggleSystemTask={handleToggleSystemTask}
                    onClose={handleCloseMoreInfo}
                    onViewMoreInfo={handleViewMoreInfo}
                    onTriggerSystemTask={handleTriggerSystemTask}
                />
            </Grid>
        </Grid>
    );
}

export default withTheme()(Debug);
