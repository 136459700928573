import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';

import {
    Icon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme,
    Link
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import { NONCONFORMANTISSUE, LOWVOLUMEISSUE } from '../../../constants';

function CustomerReportDialog(props) {
    const { open, onClose, bulk, pickup, theme, http } = props;
    const { lang } = useContext(LocalizationContext);

    const [reportOptions, setReportOptions] = useState([]);
    const [issueOptions, setIssueOptions] = useState([]);
    const [lowVolumeContainerThreshold, setLowVolumeContainerThreshold] = useState(0);
    const loadOptions = async () => {
        const res = await http.getJSON(`/system/configuration/clerkComplaintOptions/${lang}`);
        const res2 = await http.getJSON(`/system/configuration/driverComplaintOptions/${lang}`);
        const res3 = await http.getJSON(`/pickups/minVolumeFee`);
        if (res.ok && res2.ok && res3.ok) {
            setReportOptions(res.data.options);
            setIssueOptions(res2.data.options);
            setLowVolumeContainerThreshold(res3.data.lowVolumeContainerThreshold);
        }
    };
    useEffect(() => {
        loadOptions();
    }, []);

    const isEXP = process.env.REACT_APP_REGION_EXT === 'EXP';
    let reportingIssues = [];
    reportingIssues = _.get(bulk, 'payloadIssues.issues', [])
        .map(issue => reportOptions.find(option => option.code === issue)) // change to check code only next release
        .filter(issue => !_.isNil(issue) && issue.emailToCustomer === true);
    const reportingIssuesImages = _.get(bulk, 'payloadIssues.images', []);

    const counterIssuesText = [];
    for (let issue of reportingIssues) {
        // if (isEXP) {
        counterIssuesText.push({ title: issue.label, details: issue.description });
        // } else {
        //     counterIssuesText.push({ title: issue.suggestions[0], details: issue.suggestions[1] });
        // }
    }

    const driverIssues = _.get(pickup, 'driverIssues', [])
        .map(issue => issueOptions.find(option => option.code === issue)) // change to check code only next release
        .filter(issue => !_.isNil(issue) && issue.emailToCustomer === true);

    const driverIssuesText = [];
    for (let issue of driverIssues) {
        driverIssuesText.push({ title: issue.suggestions[0], details: issue.suggestions[1] });
    }

    let nonConformantLink = 'https://skipthedepot.com/recycling/beverages/';
    switch (process.env.REACT_APP_REGION_EXT) {
        case 'AUS':
            nonConformantLink = 'https://re-collect.com.au/recycling/beverages/';
            break;
        case 'EXP':
            nonConformantLink = 'https://www.return-it.ca/beverage/products/';
            break;
        case 'CON':
            if (lang === 'fr') {
                nonConformantLink = 'https://consignaction.ca/liste-des-contenants-consignes/';
            } else {
                nonConformantLink = 'https://consignaction.ca/en/returnable-containers/';
            }
            break;
        case 'STD':
        default:
            nonConformantLink = 'https://skipthedepot.com/recycling/beverages/';
    }
    const nonConformantIssue = (
        // TODO: add EXP
        <>
            {process.env.REACT_APP_REGION_EXT === 'STD'
                ? 'Only beverage containers registered with the Alberta Brand Registry are eligible for a refund. For more information please follow the link '
                : loc('pickupWidgets30', lang)}
            <Link
                href={nonConformantLink}
                style={{
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                onClick={e => {
                    e.preventDefault();
                    window.open(nonConformantLink);
                }}
            >
                here
            </Link>
        </>
    );

    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>{isEXP ? 'Details for your last pickup' : loc('pickupWidgets6', lang)}</DialogTitle>
            <DialogContent>
                {driverIssues && !_.isEmpty(driverIssues) && (
                    <DialogContentText data-cy="driver-issues-text">
                        {!_.isEmpty(driverIssuesText) && (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ marginRight: theme.spacing.unit }}>error</Icon>
                                    {loc('pickupWidgets31', lang)}
                                </div>
                                {driverIssuesText.map(issue => (
                                    <div style={{ marginTop: theme.spacing.unit }}>
                                        <b>{issue.title}</b> {issue.details}
                                    </div>
                                ))}
                            </>
                        )}
                    </DialogContentText>
                )}
                {reportingIssues && !_.isEmpty(reportingIssues) && (
                    <>
                        <DialogContentText data-cy="counter-issues-text" style={{ marginTop: theme.spacing.unit }}>
                            {!_.isEmpty(counterIssuesText) && (
                                <>
                                    {!isEXP && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Icon style={{ marginRight: theme.spacing.unit }}>error</Icon>
                                            {loc('pickupWidgets31', lang)}
                                        </div>
                                    )}
                                    {counterIssuesText.map(issue => (
                                        <div style={{ marginTop: theme.spacing.unit }}>
                                            <b>{issue.title}</b>{' '}
                                            {issue.details === NONCONFORMANTISSUE
                                                ? nonConformantIssue
                                                : issue.details === LOWVOLUMEISSUE
                                                ? loc('lowVolumeFeeWarning', lang, { lowVolumeContainerThreshold })
                                                : issue.details}
                                        </div>
                                    ))}
                                </>
                            )}
                        </DialogContentText>
                        {!_.isEmpty(reportingIssuesImages) && (
                            <ImageViewer
                                displayImages={reportingIssuesImages}
                                disableDelete={true}
                                scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button data-cy="customer-report-dialog-close" color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CustomerReportDialog);
