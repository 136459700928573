import React, { useState, useMemo, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import EditOrCancelDialog from './EditOrCancelDialog';
import HelpDialog from 'components/HelpDialog/HelpDialog';
import CancelPickupDialog from 'components/Dialogs/PickupBooking/CancelPickupDialog';

import SelectLocation from 'components/SelectLocation/SelectLocation';

import generateHelperTextArray from './misc/helperTextGenerator';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { CircularProgress, Paper } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

// custom components for the steps
import Address from './screens/Address';
import ServiceTypeSelection from './screens/ServiceTypeSelection';
import Date from './screens/Date';
import Donations from './screens/Donations';
import Payload from './screens/Payload';
import Comments from './screens/Comments';
import PictureConfirmation from './screens/PictureConfirmation';
import BottleDrive from './screens/BottleDrive';

import QuickdropSteps from './QuickdropSteps/QuickdropSteps';

// custom hooks
import usePickupDialog from './hooks/usePickupDialog';
import useGetJSON from 'utils/hooks/useGetJSON';

import { BOTTLE_DRIVE, DEPOT_SEARCH, DROPANDGO, EXTERNAL_LINK, INSTRUCTIONS, LABELS, QR_SCAN } from 'constants.js';
import { Dialog, MobileStepper, withMobileDialog } from '@material-ui/core';
import { useContext } from 'react';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import { _pickup } from 'std';
import AdminOptions from './screens/AdminOptions';
import SearchDialog from './QuickdropSteps/screens/SearchDialog';
import Instructions from './QuickdropSteps/screens/Instructions';
import { formatAsPhoneNumber } from 'utils/misc';

// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE); // TODO: remove

// ALL OF THESE SHOULD BE PULLED INTO CONFIGURABLE PLACES, NOT HARDCODED
/*const servicesConfiguration = require('./config/temp_config_' +
    process.env.REACT_APP_REGION_EXT.toLowerCase() +
    '.json');*/

export const WEEKLY_FREQUENCIES = [7 * 1, 7 * 2, 7 * 4, 7 * 10];
export const MONTHLY_FREQUENCIES = [28 * 1, 28 * 2, 28 * 3];

const IN_HOUSE = ['Drop&Go', 'Express', 'Walk-In'];

function PickupDialog(props) {
    const {
        auth,
        theme,
        google,
        history,
        http,
        open,
        customer,
        charities,
        charitySelected,
        openDropAndGo,
        dropAndGoCollector,
        dropAndGoTechnologies,
        handleResetDropAndGo,
        defaultCoordinates,
        fullScreen,
        pickups,
        pickupPending,
        incompletePickups,
        dropLocations,
        collectors,
        onClose,
        onCancel,
        onSubmitSuccess,
        onGenericError,
        onSnackbar,
        isSystemAdmin,
        onOpenQuickdropDialog,
        onReloadCustomer,
        bulks,
        subdivisionPreferred,
        regions,
        enableTipping,
        commoditiesAvailable,
        multiBagDrops,
        lockDonationPreference,
        onQRDialog,
        // allowRequestLabels,
        charityEnabled,
        handleUpdateCurrentLocation,
        promosEnabled,
        enableCustomerPickupImageUpload,
        markerLocation,
        overrideTechnologyFilter,
        overrideTechnologyTypeForInstructions
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [manualGPSEdit, setManualGPSEdit] = useState(false);

    const [mapLocationSelection, setMapLocationSelection] = useState(false);
    const [mapLocationSelected, setMapLocationSelected] = useState({ lat: null, lng: null });

    const [selectedPickup, setSelectedPickup] = useState(incompletePickups ? incompletePickups[0] : null);
    const [dispatch, setDispatch] = useState('');

    const [returnSiteLookupOpen, setReturnSiteLookupOpen] = useState(false);

    const [kantechPhoneNumber, setkantechPhoneNumber] = useState('');
    const [quickdropInstructions, setQuickdropInstructions] = useState({});
    const [quickdropInstructionsLoading, setQuickdropInstructionsLoading] = useState({});

    const [collector, setCollector] = useState(dropAndGoCollector);
    const [technologies, setTechnologies] = useState(dropAndGoTechnologies);

    const [defaultToSingleService, setDefaultToSingleService] = useState(false);

    const {
        loading: servicesLoading,
        error: errorLoadingServices,
        data: servicesConfiguration = { services: [] },
        refresh: handleRefreshServices
    } = useGetJSON(`/services`, null, {}, true);

    const fallbackServices = {
        services: [
            {
                pickupType: 'Residential',
                text: {
                    en: {
                        title: 'Mobile Pickup (70%-90% Refund)',
                        secondaryTitle: ''
                    },
                    fr: {
                        title: 'Ramassage mobile (70%-90% remboursement)',
                        secondaryTitle: ''
                    }
                },
                title: 'Ramassage mobile',
                secondaryTitle: '',
                mdiIcon: 'mdiTruck',
                icon: 'local_shipping',
                helpDialogKey: 'residential',
                hideHelpButton: true,
                isMobilePickupService: true,
                subServices: [
                    {
                        pickupType: 'Residential',
                        text: {
                            en: {
                                title: 'Residential',
                                secondaryTitle: ''
                            },
                            fr: {
                                title: 'Résidentiel',
                                secondaryTitle: ''
                            }
                        },
                        title: 'Ramassage résidentiel',
                        secondaryTitle: '',
                        icon: 'home',
                        mdiIcon: 'mdiHome',
                        helpDialogKey: 'residential'
                    },
                    {
                        pickupType: 'Condo',
                        text: {
                            en: {
                                title: 'Condo',
                                secondaryTitle: ''
                            },
                            fr: {
                                title: 'Condo',
                                secondaryTitle: ''
                            }
                        },
                        title: 'Ramassage en condo',
                        secondaryTitle: '',
                        icon: 'location_city',
                        mdiIcon: 'mdiCity',
                        helpDialogKey: 'condo'
                    },
                    {
                        pickupType: 'Commercial',
                        text: {
                            en: {
                                title: 'Commercial',
                                secondaryTitle: ''
                            },
                            fr: {
                                title: 'Commerciale',
                                secondaryTitle: ''
                            }
                        },
                        title: 'Collecte commerciale',
                        secondaryTitle: '',
                        icon: 'store',
                        mdiIcon: 'mdiStore',
                        helpDialogKey: 'commercial'
                    }
                ]
            }
        ]
    };

    //HACK: Since useState is asycnronous, and callbacks cannot be used, the dispatch function is delayed
    //by Xms prior to firing to ensure the selectedPickup has been properly updated. If the timeout is
    //removed, the buttons need to be clicked twice. A less hacky way to implement this is have clicking the
    //pickup 'select' that pickup (which will change the selectedPickup), then have a common edit/cancel button.
    //fuckin weird man...
    const handleAdditionalPickup = () => {
        setSelectedPickup();
        setTimeout(() => setDispatch('CREATE'), 100);
    };

    const handleGPSOverride = () => {
        const location = _.get(pickupForm, 'location');
        handleManualGPSOverride(location);
    };

    const handleEditPickup = pickup_id => () => {
        setSelectedPickup(_.find(pickups, { _id: pickup_id }));
        setTimeout(() => setDispatch('ACTION'), 100);
    };

    const handleCancelPickup = pickup_id => () => {
        setSelectedPickup(_.find(pickups, { _id: pickup_id }));
        setTimeout(() => setDispatch('CANCEL'), 100);
    };

    const handleOpenQuickdropDialog = () => {
        onOpenQuickdropDialog();
        onClose();
    };

    const handleMapCoordinateChange = e => {
        const coordType = e.target.name;
        const newValue = parseFloat(e.target.value);

        setManualGPSEdit(true);
        setMapLocationSelected({ ...mapLocationSelected, [coordType]: newValue });
    };

    const handleGPSVerify = pickupForm => {
        const location = _.get(pickupForm, 'location');
        if (!isSystemAdmin && !pickupIsUnchangeable && _.get(location, 'isStreet')) {
            setMapLocationSelection(true);
        } else {
            handleAddressNext();
        }
    };

    useEffect(() => {
        if (dispatch) {
            switch (dispatch) {
                case 'CREATE':
                    handleActionDialog(true);
                    break;
                case 'ACTION':
                    handleActionDialog(false);
                    break;
                case 'CANCEL':
                    handleCancelConfirmationDialog(true);
                    break;
                default:
                    break;
            }
            setDispatch();
        }
    }, [dispatch]);

    useEffect(() => {
        const getQuickdropInstructions = async () => {
            setQuickdropInstructionsLoading(true);
            const quickdropInstructionsRes = await http.getJSON('/config/quickdropInstructions', false, true);
            if (quickdropInstructionsRes.ok) {
                setQuickdropInstructions(quickdropInstructionsRes.data.config);
            }
            setQuickdropInstructionsLoading(false);
        };
        const getKantechPhoneNumber = async () => {
            const res = await http.getJSON('/system/configuration/kantechPhoneNumber', false, true);
            if (res.ok) {
                setkantechPhoneNumber(formatAsPhoneNumber(_.get(res.data, 'kantechPhoneNumber', '')));
            }
        };
        getKantechPhoneNumber();
        getQuickdropInstructions();
    }, []);

    const pickupsPending = _.filter(pickups, p => !p.complete && !p.aborted);
    const unserviceable = !_.isNil(selectedPickup) ? selectedPickup.unserviced : false;

    // hook
    const {
        mode,
        setMode,
        pickupForm,
        formErrors,
        helpDialogTopic,
        helpDialogOpen,
        activeStep,
        prevStep,
        inProgress,
        cancelReason,
        timeOverride,
        imageURLs,
        imagePreviews,
        uploadingImage,
        handleUploadImage,
        handleDeleteImage,
        noDatesAvailableDialogOpen,
        pickupMessage,
        showBypassZoneOptions,
        availableBookings,
        availableTimeSlots,
        showTimeSlots,
        driversAvailable,
        collectorsAvailable,
        minFrequency,
        minFrequencyPickupTypes,
        minPickupFeeEnabled,
        lowVolumeContainerThreshold,
        handleBack,
        handleNext,
        handleGPSReset,
        handleInHouse,
        handleSelectPickupType,
        handleApplyToBeACharity, // not being used since disabling bottle drives
        handleReceiverChange,
        handleAddressNext,
        handleDefaultTrip,
        handleClose,
        handleCancel,
        handleChange,
        handlePastLocationData,
        handlePayload,
        handleSubPayloads,
        handleApplyPromo,
        handleRemovePromo,
        handleSuggestionSelected,
        handleDateChange,
        handleTimePreferenceChange,
        handleOverrideAlwaysConfirmChange,
        handleEfficiencyModifierChange,
        handleOverrideProcessorChange,
        handleOverrideTransporterChange,
        handleShowTimeOverride,
        handleActionDialog,
        handleCharityPreferred,
        handleSetDonation,
        handleCancelConfirmationDialog,
        handleToggleFlag,
        handleSubmit,
        handleCancelChange,
        toggleHelpDialog,
        toggleDonate,
        // toggleTaxReceiptRequested,
        togglePhoneOverride,
        handlePromoInputSetDonation,
        handleCustomTime,
        handleRemoveCustomTime,
        handleTogglePromoRecurring,
        handleManualGPSOverride,
        handleBypassChange,
        handleAvailableTimeSlots,
        toggleBottleDrive,
        togglePlaceAtStartOfTrip,
        handleNoDatesAvailableDialog,
        handlePickupMessage,
        handleSendPickupRequestEmail,
        handleTipAmountChange,
        handleRequestBinChange,
        handleBinRequestMessageChange,
        handleChangeCoordinates,
        handleDonateBottleDrive,
        technologyFilter,
        defaultToInstructions,
        defaultToActionStep,
        technologyTypeForInstructions
    } = usePickupDialog({
        auth,
        http,
        history,
        pickups,
        selectedPickup,
        setSelectedPickup,
        pickupPending,
        incompletePickups,
        google,
        charities,
        charitySelected,
        subdivisionPreferred,
        commoditiesAvailable,
        customer,
        servicesConfiguration,
        onClose,
        onCancel,
        onSubmitSuccess,
        onSnackbar,
        mapLocationSelection,
        setMapLocationSelection,
        setMapLocationSelected,
        handleResetDropAndGo,
        onQRDialog
    });

    const { pickupType } = pickupForm;

    useEffect(() => {
        //if a pickup is canceled through the pickup confirmation dialog(or any other place not handled by the pickup dialog)
        //and there if no incomplete pickups set mode to CREATE
        if (_.isEmpty(incompletePickups) && mode !== 'CREATE') {
            handleAdditionalPickup();
        }

        if (openDropAndGo) {
            setMode('CREATE');
            handleInHouse(
                _.isNil(pickupType) || _.isEmpty(pickupType) || !IN_HOUSE.includes(pickupType) ? 'Drop&Go' : pickupType
            );
        } else if (!_.isNil(dropAndGoCollector)) {
            handleResetDropAndGo();
        }

        if (open) {
            handleRefreshServices();
            setCollector(dropAndGoCollector);
            setTechnologies(dropAndGoTechnologies);
        } else {
            setCollector(null);
            setTechnologies([]);
        }
    }, [open]);

    useEffect(() => {
        let servicesDisplayed = _.filter(
            !errorLoadingServices ? servicesConfiguration.services : fallbackServices.services,
            s => !(s.pickupType === LABELS) && !s.disabled && !s.hidden /*&& !allowRequestLabels*/
        );
        if (open && activeStep === 0 && servicesDisplayed.length === 1) {
            setDefaultToSingleService(true);
            const singleService = _.first(servicesDisplayed);
            switch (singleService.pickupType) {
                case LABELS:
                case QR_SCAN:
                    onQRDialog(true)();
                    break;
                case DEPOT_SEARCH:
                    setReturnSiteLookupOpen(true);
                    break;
                case EXTERNAL_LINK:
                    window.open(singleService.externalLink);
                    break;
                default:
                    handleSelectPickupType(singleService.pickupType, singleService)();
            }
        }
    }, [servicesConfiguration]);

    const formControlStyle = {
        marginTop: theme.spacing.unit + theme.spacing.unit / 2
    };

    const pickupIsUnchangeable = useMemo(
        () =>
            !customer.adminView &&
            !_.isNil(selectedPickup) &&
            !selectedPickup.unserviced &&
            pickupPending &&
            !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE) && // allow customers to change address on same day pickup for automated tester mode
            moment().isSameOrAfter(
                moment(pickupForm.date)
                    .startOf('day')
                    .hours(7)
            ),
        [customer.adminView, selectedPickup, pickupForm.date, pickupPending]
    );

    const serviceStep = useMemo(
        () => (
            <>
                {servicesLoading ? (
                    <Paper
                        elevation={0}
                        style={{
                            minHeight: '80px',
                            padding: theme.spacing.unit * 2,
                            margin: theme.spacing.unit * 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress size={32} />
                    </Paper>
                ) : (
                    <ServiceTypeSelection
                        theme={theme}
                        http={http}
                        inProgress={inProgress}
                        servicesLoading={servicesLoading}
                        customer={customer}
                        pickupForm={pickupForm}
                        serviceSelections={
                            !errorLoadingServices ? servicesConfiguration.services : fallbackServices.services
                        }
                        onSelectPickupType={handleSelectPickupType}
                        onApplyPromo={handleApplyPromo}
                        onRemovePromo={handleRemovePromo}
                        toggleHelpDialog={toggleHelpDialog}
                        onClose={handleClose}
                        onOpenQuickdropDialog={handleOpenQuickdropDialog}
                        onPromoInputSetDonation={handlePromoInputSetDonation}
                        onQRDialog={onQRDialog}
                        onSearchDialog={() => setReturnSiteLookupOpen(true)}
                        promosEnabled={promosEnabled}
                        // allowRequestLabels={allowRequestLabels}
                    />
                )}
            </>
        ),
        [
            customer,
            handleRemovePromo,
            handleApplyPromo,
            handleClose,
            handleSelectPickupType,
            http,
            inProgress,
            pickupForm,
            theme,
            toggleHelpDialog
        ]
    );

    const addressStep = useMemo(() => {
        if (IN_HOUSE.includes(pickupType)) {
            return (
                <QuickdropSteps
                    dropLocations={dropLocations}
                    collectors={collectors}
                    customer={customer}
                    charities={charities}
                    onClose={handleClose}
                    onBackPickupScreen={defaultToSingleService ? null : handleBack}
                    toggleHelpDialog={toggleHelpDialog}
                    google={google}
                    onSnackbar={onSnackbar}
                    onReloadCustomer={onReloadCustomer}
                    bulks={bulks}
                    lastPickup={_.last(pickups)}
                    collectorOverride={collector}
                    regions={regions}
                    multiBagDrops={multiBagDrops}
                    commoditiesAvailable={commoditiesAvailable}
                    onQRDialog={onQRDialog}
                    quickdropInstructions={quickdropInstructions}
                    // allowRequestLabels={allowRequestLabels}
                    charityEnabled={charityEnabled}
                    handleUpdateCurrentLocation={handleUpdateCurrentLocation}
                    dropAndGoCollector={collector}
                    dropAndGoTechnologies={technologies}
                    pickupType={pickupType}
                    kantechPhoneNumber={kantechPhoneNumber}
                    technologyFilter={!_.isNil(overrideTechnologyFilter) ? overrideTechnologyFilter : technologyFilter}
                    defaultToInstructions={defaultToInstructions}
                    defaultToActionStep={!_.isNil(overrideTechnologyFilter) ? true : defaultToActionStep}
                    technologyTypeForInstructions={
                        !_.isNil(overrideTechnologyTypeForInstructions)
                            ? overrideTechnologyTypeForInstructions
                            : technologyTypeForInstructions
                    }
                />
            );
        } else if (pickupType === BOTTLE_DRIVE) {
            return (
                <BottleDrive
                    onBack={handleBack}
                    charities={charities}
                    customer={customer}
                    commodities={commoditiesAvailable}
                />
            );
        } else {
            if (mapLocationSelection) {
                return (
                    <SelectLocation
                        admin={isSystemAdmin}
                        pickupForm={pickupForm}
                        manualGPSEdit={manualGPSEdit}
                        mapLocationSelected={mapLocationSelected}
                        setManualGPSEdit={setManualGPSEdit}
                        setMapLocationSelected={setMapLocationSelected}
                        setMapLocationSelection={setMapLocationSelection}
                        defaultCoordinates={defaultCoordinates}
                        handleConfirm={handleAddressNext}
                        handleManualGPSOverride={handleManualGPSOverride}
                        handleMapCoordinateChange={handleMapCoordinateChange}
                    />
                );
            } else {
                const pendingPickupsByLocation = _.keyBy(pickupsPending || [], 'location.description');
                return (
                    <Address
                        http={http}
                        google={google}
                        customer={customer}
                        formErrors={formErrors}
                        admin={isSystemAdmin}
                        pickupIsUnchangeable={pickupIsUnchangeable}
                        inProgress={inProgress}
                        services={!errorLoadingServices ? servicesConfiguration.services : fallbackServices.services}
                        handleGPSReset={handleGPSReset}
                        handleGPSOverride={handleGPSOverride}
                        handleChangeCoordinates={handleChangeCoordinates}
                        onOverrideAlwaysConfirmChange={handleOverrideAlwaysConfirmChange}
                        toggleHelpDialog={toggleHelpDialog}
                        onChange={handleChange}
                        onPastLocationChange={handlePastLocationData}
                        onBack={handleBack}
                        onAddressNext={() => handleGPSVerify(pickupForm)}
                        onSuggestionSelected={handleSuggestionSelected}
                        pickupForm={pickupForm}
                        formControlStyle={formControlStyle}
                        setMapLocationSelection={setMapLocationSelection}
                        pendingPickupsByLocation={pendingPickupsByLocation}
                        onSelectPickupType={handleSelectPickupType}
                    />
                );
            }
        }
    }, [
        pickupType,
        handleBack,
        inProgress,
        customer,
        http,
        google,
        pickupIsUnchangeable,
        toggleHelpDialog,
        handleChange,
        handleAddressNext,
        handleSuggestionSelected,
        pickupForm,
        formControlStyle,
        commoditiesAvailable,
        charities
    ]);

    const dateStep = useMemo(
        () => (
            <Date
                http={http}
                theme={theme}
                unserviceable={unserviceable}
                inProgress={inProgress}
                pickupForm={pickupForm}
                showTimeOverride={timeOverride}
                minFrequency={minFrequency}
                minFrequencyPickupTypes={minFrequencyPickupTypes}
                onBack={handleBack}
                onNext={handleNext}
                onChange={handleChange}
                onDateChange={handleDateChange}
                onTimePreferenceChange={handleTimePreferenceChange}
                onEfficiencyModifierChange={handleEfficiencyModifierChange}
                onTimeOverride={handleShowTimeOverride}
                onOverrideProcessorChange={handleOverrideProcessorChange}
                onOverrideTransporterChange={handleOverrideTransporterChange}
                onGenericError={() => onGenericError(handleClose)}
                customer={customer}
                isSystemAdmin={isSystemAdmin}
                showBypassZoneOptions={showBypassZoneOptions}
                handleDefaultTrip={handleDefaultTrip}
                onReceiverChange={handleReceiverChange}
                onCustomTime={handleCustomTime}
                onRemoveCustomTime={handleRemoveCustomTime}
                onBypassChange={handleBypassChange}
                availableBookings={availableBookings}
                availableTimeSlots={availableTimeSlots}
                showTimeSlots={showTimeSlots}
                handleAvailableTimeSlots={handleAvailableTimeSlots}
                toggleBottleDrive={toggleBottleDrive}
                togglePlaceAtStartOfTrip={togglePlaceAtStartOfTrip}
                driversAvailable={driversAvailable}
                collectorsAvailable={collectorsAvailable}
            />
        ),
        [handleBack, handleChange, handleDateChange, handleNext, inProgress, pickupForm, theme, unserviceable]
    );

    const adminStep = useMemo(
        () => (
            <AdminOptions
                http={http}
                theme={theme}
                unserviceable={unserviceable}
                inProgress={inProgress}
                pickupForm={pickupForm}
                showTimeOverride={timeOverride}
                onBack={handleBack}
                onNext={handleNext}
                onChange={handleChange}
                onDateChange={handleDateChange}
                onTimePreferenceChange={handleTimePreferenceChange}
                onOverrideAlwaysConfirmChange={handleOverrideAlwaysConfirmChange}
                onEfficiencyModifierChange={handleEfficiencyModifierChange}
                onTimeOverride={handleShowTimeOverride}
                onOverrideProcessorChange={handleOverrideProcessorChange}
                onOverrideTransporterChange={handleOverrideTransporterChange}
                onGenericError={() => onGenericError(handleClose)}
                customer={customer}
                isSystemAdmin={isSystemAdmin}
                showBypassZoneOptions={showBypassZoneOptions}
                handleDefaultTrip={handleDefaultTrip}
                onReceiverChange={handleReceiverChange}
                onCustomTime={handleCustomTime}
                onRemoveCustomTime={handleRemoveCustomTime}
                onBypassChange={handleBypassChange}
                availableBookings={availableBookings}
                availableTimeSlots={availableTimeSlots}
                showTimeSlots={showTimeSlots}
                handleAvailableTimeSlots={handleAvailableTimeSlots}
                toggleBottleDrive={toggleBottleDrive}
                togglePlaceAtStartOfTrip={togglePlaceAtStartOfTrip}
                driversAvailable={driversAvailable}
                collectorsAvailable={collectorsAvailable}
            />
        ),
        [handleBack, handleChange, handleDateChange, handleNext, inProgress, pickupForm, theme, unserviceable]
    );

    const payloadStep = useMemo(
        () => (
            <Payload
                theme={theme}
                pickupForm={pickupForm}
                formErrors={formErrors}
                inProgress={inProgress}
                customer={customer}
                commoditiesAvailable={commoditiesAvailable}
                toggleHelpDialog={toggleHelpDialog}
                onNext={handleNext}
                onBack={handleBack}
                onPayload={handlePayload}
                onSubPayloads={handleSubPayloads}
                handleRequestBinChange={handleRequestBinChange}
                handleBinRequestMessageChange={handleBinRequestMessageChange}
                lowVolumeContainerThreshold={lowVolumeContainerThreshold}
                minPickupFeeEnabled={minPickupFeeEnabled}
            />
        ),
        [
            customer,
            commoditiesAvailable,
            formErrors,
            handleBack,
            handleNext,
            handlePayload,
            handleSubPayloads,
            inProgress,
            pickupForm,
            theme,
            toggleHelpDialog,
            lowVolumeContainerThreshold,
            minPickupFeeEnabled
        ]
    );

    const confirmationStep = useMemo(
        () => (
            <PictureConfirmation
                onBack={handleBack}
                onNext={handleNext}
                pickupForm={pickupForm}
                imageURLs={imageURLs}
                imagePreviews={imagePreviews}
                uploadingImage={uploadingImage}
                handleUploadImage={handleUploadImage}
                handleDeleteImage={handleDeleteImage}
                activeStep={activeStep}
                prevStep={prevStep}
            />
        ),
        [
            handleBack,
            handleNext,
            pickupForm,
            imageURLs,
            imagePreviews,
            uploadingImage,
            handleUploadImage,
            handleDeleteImage,
            activeStep,
            prevStep
        ]
    );

    const commentsStep = useMemo(
        () => (
            <Comments
                http={http}
                theme={theme}
                pickupForm={pickupForm}
                formErrors={formErrors}
                inProgress={inProgress}
                customer={customer}
                toggleHelpDialog={toggleHelpDialog}
                onChange={handleChange}
                onNext={handleNext}
                onBack={handleBack}
                togglePhoneOverride={togglePhoneOverride}
                enableCustomerPickupImageUpload={enableCustomerPickupImageUpload}
                isSystemAdmin={isSystemAdmin}
            />
        ),
        [formErrors, handleBack, handleChange, handleNext, inProgress, pickupForm, theme, toggleHelpDialog]
    );

    const donationStep = useMemo(
        () => (
            <Donations
                isSystemAdmin={isSystemAdmin}
                customer={customer}
                theme={theme}
                charities={charities}
                pickupForm={pickupForm}
                inProgress={inProgress}
                onCharityPreferred={handleCharityPreferred}
                onChange={handleChange}
                toggleDonate={toggleDonate}
                // toggleTaxReceiptRequested={toggleTaxReceiptRequested}
                onSetDonation={handleSetDonation}
                onSubmit={handleSubmit}
                onApplyPromo={handleApplyPromo}
                onRemovePromo={handleRemovePromo}
                onBack={handleBack}
                onPromoInputSetDonation={handlePromoInputSetDonation}
                onTogglePromoRecurring={handleTogglePromoRecurring}
                regions={regions}
                onTipAmountChange={handleTipAmountChange}
                enableTipping={enableTipping}
                lockDonationPreference={lockDonationPreference}
            />
        ),
        [
            charities,
            customer,
            handleApplyPromo,
            handleBack,
            handleChange,
            handleCharityPreferred,
            handlePromoInputSetDonation,
            handleRemovePromo,
            handleSetDonation,
            handleSubmit,
            handleTogglePromoRecurring,
            inProgress,
            isSystemAdmin,
            pickupForm,
            theme,
            toggleDonate
            // toggleTaxReceiptRequested
        ]
    );

    let steps = isSystemAdmin
        ? [serviceStep, addressStep, dateStep, adminStep, payloadStep, confirmationStep, commentsStep, donationStep]
        : [serviceStep, addressStep, dateStep, payloadStep, confirmationStep, commentsStep, donationStep];

    const STEPS_TITLE_FIELDS = isSystemAdmin
        ? [
              'pickupDialog1',
              'pickupDialog8',
              'date',
              'adminOptions',
              'recyclables',
              'confirmation',
              'directions',
              'Donations'
          ]
        : ['pickupDialog1', 'pickupDialog8', 'date', 'recyclables', 'confirmation', 'directions', 'Donations'];

    if (!enableCustomerPickupImageUpload) {
        _.remove(steps, confirmationStep);
        _.remove(STEPS_TITLE_FIELDS, 'confirmation');
    }

    const dialogTitlePrefix = activeStep > 0 ? `${pickupType} ` : '';
    const extraTitle =
        (IN_HOUSE.includes(pickupType) || pickupType === 'Bottle Drive') && activeStep !== 0
            ? ''
            : loc(STEPS_TITLE_FIELDS[activeStep], lang);

    const HELP_DIALOGS = useMemo(
        () => generateHelperTextArray({ data: { uniqueID: props.customer.uniqueID }, theme: props.theme, lang }),
        [props.customer.uniqueID, props.theme, lang]
    );

    const helpDialog = useMemo(() => _.find(HELP_DIALOGS, dialog => dialog.topic === helpDialogTopic), [
        HELP_DIALOGS,
        helpDialogTopic
    ]);

    const handleSelectReturnSite = returnSite => () => {
        setCollector(returnSite.collector);
        setTechnologies(returnSite.dropLocations);
        setReturnSiteLookupOpen(false);
        handleSelectPickupType(DROPANDGO)();
    };

    if (!open) {
        return null;
    }

    return (
        <React.Fragment>
            <Dialog
                id="multi-step-dialog"
                data-cy="multi-step-dialog"
                fullScreen={fullScreen}
                fullWidth
                open={open && mode === 'CREATE'}
            >
                {!((IN_HOUSE.includes(pickupType) || pickupType === BOTTLE_DRIVE) && activeStep === 1) && (
                    <DialogTitlePrimary
                        closeButtonShown
                        textVariant={activeStep === 0 ? null : 'body1'}
                        onClose={handleClose}
                    >
                        {extraTitle}
                    </DialogTitlePrimary>
                )}
                {!((IN_HOUSE.includes(pickupType) || pickupType === BOTTLE_DRIVE) && activeStep === 1) && (
                    <MobileStepper
                        variant="dots"
                        steps={steps.length}
                        position="static"
                        activeStep={activeStep}
                        nextButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        backButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 0 }}
                    />
                )}
                {steps[activeStep]}

                {returnSiteLookupOpen && (
                    <SearchDialog
                        open={returnSiteLookupOpen}
                        onClose={() => setReturnSiteLookupOpen(false)}
                        dropLocations={dropLocations}
                        collectors={collectors}
                        customer={customer}
                        theme={theme}
                        handleUpdateCurrentLocation={handleUpdateCurrentLocation}
                        onSelectReturnSite={handleSelectReturnSite}
                    />
                )}
            </Dialog>
            {!_.isNil(helpDialog) && (
                <HelpDialog
                    open={helpDialogOpen}
                    title={helpDialog.title}
                    body={helpDialog.body}
                    onClose={toggleHelpDialog(helpDialog.topic)}
                />
            )}
            {!_.isNil(pickupsPending) && (
                <EditOrCancelDialog
                    open={open && mode === 'ACTION'}
                    fullScreen={fullScreen}
                    customer={customer}
                    pickups={pickupsPending}
                    markerLocation={markerLocation}
                    charities={charities}
                    onClose={handleClose}
                    handleEditPickup={handleEditPickup}
                    handleCancelPickup={handleCancelPickup}
                    setSelectedPickup={setSelectedPickup}
                    handleAdditionalPickup={handleAdditionalPickup}
                    handleToggleFlag={handleToggleFlag}
                    onReloadCustomer={onReloadCustomer}
                    adminView={customer.adminView}
                    multiplePickups={customer.multiplePickups}
                    toggleHelpDialog={toggleHelpDialog}
                />
            )}

            <CancelPickupDialog
                open={open && mode === 'CANCEL'}
                fullScreen={fullScreen}
                cancelReason={cancelReason}
                handleCancelConfirmationDialog={handleCancelConfirmationDialog}
                handleCancelChange={handleCancelChange}
                handleCancel={handleCancel}
                unserviceable={unserviceable}
                customer={customer}
                http={http}
                selectedPickup={selectedPickup}
                onSnackbar={onSnackbar}
                onReloadCustomer={onReloadCustomer}
                payload={_pickup.getPayload(selectedPickup)}
                subPayloads={_.get(selectedPickup, 'subPayloads', [])}
            />
        </React.Fragment>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(PickupDialog));
