import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme, Typography, Paper, Chip, Icon, colors } from '@material-ui/core';
import CharityGrowthTasksList from '../CharityGrowthPlan/CharityGrowthTasksList';
//import CharityTaskCompletionChip from './CharityTaskCompletionChip';
import CharityActionChips from './CharityActionChips';
import charityTaskHelper from '../../helpers/charityTaskHelper';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityTasksCompletionWidget(props) {
    const {
        charity,
        elevated,
        onTaskSelected,
        onCharityGrowthPlan,
        customersDonating,
        socialMediaShares,
        onShareDialogOpen,
        onRedirectToPage,
        shareDialogEnabled,
        theme,
        bulks
    } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Paper elevated={elevated ? 4 : 2} style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
            <CharityActionChips
                charity={charity}
                customersDonating={customersDonating}
                shares={socialMediaShares}
                onShareDialogOpen={onShareDialogOpen}
                onRedirectToPage={onRedirectToPage}
                onCharityGrowthPlan={onCharityGrowthPlan}
                shareDialogEnabled={shareDialogEnabled}
                theme={theme}
                bulks={bulks}
            />
            <div
                style={{
                    marginTop: theme.spacing.unit * 2,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h5">{loc('dashboardGrowthPlan1', lang)}</Typography>
            </div>
            {charity.approved ? (
                <CharityGrowthTasksList
                    tasks={charity.charityTasks}
                    onTaskSelected={onTaskSelected}
                    showRightArrows={true}
                />
            ) : (
                <Typography
                    variant="subheading"
                    style={{ margin: theme.spacing.unit * 2, color: theme.palette.text.secondary }}
                >
                    {loc('dashboardGrowthPlan2', lang)}
                </Typography>
            )}
        </Paper>
    );
}

export default withTheme()(CharityTasksCompletionWidget);
