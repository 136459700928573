import React, { useContext, useState } from 'react';

import _ from 'lodash';

import CharitySelect from 'components/CharitySelect/CharitySelect';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Fade from '@material-ui/core/Fade';

import { getClosestRegion } from 'utils/latlngFunctions';

import {
    LinearProgress,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    Grid,
    InputAdornment
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import TipSelect from 'components/TipSelect/TipSelect';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Donations = props => {
    const {
        customer,
        theme,
        charities,
        pickupForm,
        formContainsErrors,
        inProgress,
        onCharityPreferred,
        onChange,
        toggleDonate,
        // toggleTaxReceiptRequested,
        isSystemAdmin,
        onSetDonation,
        onSubmit,
        onApplyPromo,
        onRemovePromo,
        onBack,
        onPromoInputSetDonation,
        onTogglePromoRecurring,
        regions,
        onTipAmountChange,
        enableTipping,
        lockDonationPreference
    } = props;

    const { lang } = useContext(LocalizationContext);

    const {
        charityPreferred,
        subdivisionPreferred,
        taxReceiptRequested,
        pickupType,
        promos,
        donate,
        recurringPromo,
        location,
        tipAmount
    } = pickupForm;

    const standardTipAmounts = [1, 2, 5];
    const [tipAmountOther, setTipAmountOther] = useState(tipAmount > 0 && !standardTipAmounts.includes(tipAmount));

    const pickupRegion = getClosestRegion(location, regions);
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog49', lang)} iconName="loyalty" />
                <CharitySelect
                    style={{ marginBottom: theme.spacing.unit * 2 }}
                    donate={donate}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    subdivisionPreferred={subdivisionPreferred}
                    taxReceiptRequested={taxReceiptRequested}
                    pickupType={pickupType}
                    onCharityPreferred={onCharityPreferred}
                    onChange={onChange}
                    onSwitch={toggleDonate}
                    onSetDonation={onSetDonation}
                    // onToggleTaxReceiptRequested={toggleTaxReceiptRequested}
                    regionToFilterFeatured={pickupRegion}
                    lockDonationPreference={lockDonationPreference}
                />
                {enableTipping && (
                    <>
                        <CustomFormTitle titleText="Leave a tip?" iconName="star" />
                        <TipSelect
                            tipAmount={tipAmount}
                            setTipAmount={onTipAmountChange}
                            tipAmountOther={tipAmountOther}
                            setTipAmountOther={setTipAmountOther}
                            standardTipAmounts={standardTipAmounts}
                            maxAmount={100}
                        />
                    </>
                )}

                <CustomFormTitle titleText={loc('pickupDialog58', lang)} iconName="local_atm" />
                <FormControl fullWidth data-cy="multistep-dialog-promo-codes-input">
                    <PromoCodesInput
                        customer={customer}
                        promos={promos}
                        onApplyPromo={onApplyPromo}
                        onRemovePromo={onRemovePromo}
                        charityPreferred={charityPreferred}
                        subdivisionPreferred={subdivisionPreferred}
                        onCharityPreferred={onPromoInputSetDonation}
                        donate={donate}
                        isLastPickupStep={true}
                        hideSearch={true}
                    />
                </FormControl>
                {_.get(promos, '[0].recurring', false) && (
                    <Typography variant="caption" style={{ marginTop: theme.spacing.unit }}>
                        This promo is recurring
                    </Typography>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" disabled={inProgress} onClick={onBack}>
                    Back
                </Button>
                <Button
                    color="primary"
                    disabled={formContainsErrors || inProgress}
                    onClick={onSubmit}
                    data-cy="pickupdialog-submit-button"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
};

export default withTheme()(Donations);
