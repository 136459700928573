import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import * as terms from 'localizations/terms';

import GMapsAutocomplete from 'components/GMapsAutocomplete';

import LogoUpload from 'components/LogoUpload/LogoUpload';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTheme } from '@material-ui/core/styles';

import StringListInput from 'components/CustomInputs/StringListInput';

import GoogleContext from 'utils/contexts/GoogleContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import { Collapse, Icon, InputAdornment } from '@material-ui/core';
import moment from 'moment-timezone';
import { isEXPRegion } from 'utils/misc';

function CharityInformationForm(props) {
    const {
        handleAddressChange,
        formControlStyle,
        textFieldStyle,
        form,
        formErrors,
        file,
        taxReceiptsIssued,
        differentTeams,
        oAuthRegistration,
        handleFormChange,
        handleFormCloseDateChange,
        handleSuggestionSelected,
        handleDrop,
        handleToggleTaxReceiptsIssued,
        handleToggleDifferentTeams,
        handleSubdivisionsChange,
        toggleGPSOverride,
        handleCoordinateChange,
        theme
    } = props;

    const { lang } = useContext(LocalizationContext);
    const { google } = useContext(GoogleContext);

    return (
        <React.Fragment>
            <Typography variant="h6">{loc('registrationOrg18', lang)}</Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                {loc('registrationOrg6', lang)}
            </Typography>

            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    data-cy="registration-charityName-input"
                    value={form.charityName}
                    helperText={formErrors['charityName'].reason}
                    error={formErrors['charityName'].fail}
                    label={`${loc('registrationOrg16', lang)}`}
                    name="charityName"
                    InputProps={{ style: textFieldStyle }}
                    onChange={handleFormChange}
                    FormHelperTextProps={{ 'data-cy': `registration-charityName-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    data-cy="registration-registeredCharityName-input"
                    value={form.registeredCharityName}
                    helperText={formErrors['registeredCharityName'].reason}
                    error={formErrors['registeredCharityName'].fail}
                    label={`${loc('registrationOrg19', lang)} (${loc('ifDifferent', lang)})`}
                    name="registeredCharityName"
                    InputProps={{ style: textFieldStyle }}
                    onChange={handleFormChange}
                    FormHelperTextProps={{ 'data-cy': `registration-registeredCharityName-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    data-cy="registration-redemptionEmail-input"
                    value={form.redemptionEmail}
                    helperText={formErrors['redemptionEmail'].reason}
                    error={formErrors['redemptionEmail'].fail}
                    label={`${loc('registrationOrg17', lang)} `}
                    placeholder={loc('registrationOrg7', lang)}
                    name="redemptionEmail"
                    InputProps={{ style: textFieldStyle }}
                    onChange={handleFormChange}
                    FormHelperTextProps={{ 'data-cy': `registration-redemptionEmail-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    data-cy="registration-mission-input"
                    multiline
                    value={form.mission}
                    helperText={formErrors['mission'].reason}
                    error={formErrors['mission'].fail}
                    label={loc('registrationOrg8', lang)}
                    name="mission"
                    InputProps={{ style: textFieldStyle }}
                    onChange={handleFormChange}
                    FormHelperTextProps={{ 'data-cy': `registration-mission-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    data-cy="registration-website-input"
                    value={form.website}
                    helperText={formErrors['website'].reason}
                    error={formErrors['website'].fail}
                    label={`${loc('registrationOrg15', lang)}`}
                    name="website"
                    InputProps={{ style: textFieldStyle }}
                    onChange={handleFormChange}
                    FormHelperTextProps={{ 'data-cy': `registration-website-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            onChange={handleFormChange}
                            value={form.hasCloseDate}
                            checked={form.hasCloseDate}
                            name="hasCloseDate"
                        />
                    }
                    label={loc('registrationOrg20', lang)}
                />
            </FormControl>
            <Collapse in={form.hasCloseDate}>
                <FormControl fullWidth style={formControlStyle}>
                    <DatePicker
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        value={form.closeDate}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            ),
                            style: textFieldStyle
                        }}
                        name="closeDate"
                        onChange={handleFormCloseDateChange}
                        disablePast
                        minDate={moment().tz(process.env.REACT_APP_REGION_TIMEZONE)}
                    />
                </FormControl>
            </Collapse>
            {oAuthRegistration && (
                <>
                    <GMapsAutocomplete
                        data-cy="registration-address-input"
                        google={google}
                        location={form.location}
                        label={loc('address', lang)}
                        placeholder={loc('registrationOrg9', lang)}
                        selectedValue={form.location.description}
                        types={['address']}
                        style={{ ...formControlStyle, ...textFieldStyle }}
                        error={formErrors.location['description']}
                        onChange={handleAddressChange}
                        onSuggestionSelected={handleSuggestionSelected}
                        suggestionsOccupyVerticalSpace={false}
                        variant=""
                    />
                </>
            )}
            <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                <LogoUpload
                    theme={theme}
                    file={file}
                    handleDrop={handleDrop}
                    name={loc('organizationName', lang, { organizationName: terms.ORGANIZATION_NAME })}
                />
            </FormControl>
            {!isEXPRegion() && (
                <FormGroup row style={formControlStyle}>
                    <FormControlLabel
                        data-cy={`registration-taxReceipts-slider`}
                        control={<Switch checked={taxReceiptsIssued} onChange={handleToggleTaxReceiptsIssued} />}
                        label={loc('registrationOrg12', lang)}
                    />
                </FormGroup>
            )}
            {taxReceiptsIssued && (
                <FormControl fullWidth>
                    <TextField
                        data-cy={`registration-crn-input`}
                        // variant="outlined"
                        value={form.charityRegistrationNumber}
                        helperText={formErrors['charityRegistrationNumber'].reason}
                        error={formErrors['charityRegistrationNumber'].fail}
                        label={loc('charityNumName', lang)}
                        placeholder={process.env.REACT_APP_CHARITY_NUMBER_PLACEHOLDER}
                        name="charityRegistrationNumber"
                        InputProps={{ style: textFieldStyle }}
                        onChange={handleFormChange}
                        FormHelperTextProps={{ 'data-cy': `registration-crn-input-helper-txt` }}
                    />
                </FormControl>
            )}

            <FormGroup row style={formControlStyle}>
                <FormControlLabel
                    data-cy={`registration-subdivisions-slider`}
                    control={<Switch checked={differentTeams} onChange={handleToggleDifferentTeams} />}
                    label={loc('registrationOrg13', lang)}
                />
            </FormGroup>
            {differentTeams && (
                <StringListInput
                    error={inputValue => {}}
                    errorMessage=""
                    values={_.get(form, 'subdivisions', [])}
                    onChange={handleSubdivisionsChange}
                    label={loc('registrationOrg14', lang)}
                    InputProps={{
                        style: textFieldStyle,
                        variant: 'outlined',
                        'data-cy': 'registration-subdivisions-input'
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default withTheme()(CharityInformationForm);
