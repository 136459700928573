import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

function useErrorLogList(props) {
    const { numberOfLogs = 100 } = props || {};

    const http = useContext(HttpContext);

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        http.getJSON(`/debug/logs/${numberOfLogs}`)
            .then(res => {
                const logs = _.get(res, 'data.logs', []);
                setLogs(logs);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    }, []);

    return {
        logs,
        loading
    };
}

export default useErrorLogList;
