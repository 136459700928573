import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import CommodityTable from 'components/CRUDTables/CommodityTable';

function Commodities(props) {
    return <CommodityTable {...props} />;
}

export default withTheme()(Commodities);
