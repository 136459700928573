import React from 'react';
import _ from 'lodash';
import {
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    InputLabel,
    Select,
    OutlinedInput,
    FormControl,
    MenuItem,
    FormHelperText,
    Checkbox,
    Typography,
    Icon,
    Tooltip
} from '@material-ui/core';

import TimePicker from 'material-ui-pickers/TimePicker';

export const getSwitch = (
    theme,
    name,
    label,
    formik,
    style = {},
    labelStyle = {},
    onChange,
    infoText = '',
    disabled = false
) => (
    <FormGroup style={style}>
        <FormControlLabel
            control={
                <Switch
                    id={name}
                    name={name}
                    color="primary"
                    onChange={
                        onChange !== undefined
                            ? e => {
                                  onChange(e);
                                  formik.handleChange(e);
                              }
                            : e => {
                                  formik.handleChange(e);
                              }
                    }
                    value={_.get(formik.values, name, false)}
                    checked={_.get(formik.values, name, false)}
                    onBlur={formik.handleBlur}
                    data-cy={`${_.kebabCase(label)}-toggle`}
                    disabled={disabled}
                />
            }
            label={
                <Typography variant="body2" style={labelStyle}>
                    {label}
                    {!_.isEmpty(infoText) && (
                        <Tooltip
                            title={infoText}
                            data-cy={`config-${_.kebabCase(name)}-info-text`}
                            style={{
                                color: theme.palette.text.disabled,
                                fontSize: 18
                            }}
                        >
                            <Icon data-cy={`config-${_.kebabCase(name)}-info-icon`}>info</Icon>
                        </Tooltip>
                    )}
                </Typography>
            }
        />
    </FormGroup>
);

export const getCheckbox = (theme, name, label, formik) => (
    <FormGroup>
        <FormControlLabel
            control={
                <Checkbox
                    id={name}
                    name={name}
                    color="primary"
                    onChange={formik.handleChange}
                    value={_.get(formik.values, name, false)}
                    checked={_.get(formik.values, name, false)}
                    onBlur={formik.handleBlur}
                    data-cy={`${name}-checkbox`}
                />
            }
            label={label}
        />
    </FormGroup>
);

export const getTextInput = (
    theme,
    name,
    label,
    formik,
    type = 'text',
    inputProps = {},
    inputLabelProps = {},
    helperText = null,
    disabled = false,
    onChange
) => {
    return (
        <TextField
            {...formik.getFieldProps(name)}
            multiline={type === 'text'}
            type={type}
            error={_.get(formik.touched, name, false) && _.get(formik.errors, name, false) ? true : null}
            label={label}
            style={{ marginTop: theme.spacing.unit * 2 }}
            variant="outlined"
            helperText={
                _.get(formik.touched, name, false) && _.get(formik.errors, name, false)
                    ? _.get(formik.errors, name, false)
                    : helperText
            }
            fullWidth
            InputProps={inputProps}
            InputLabelProps={inputLabelProps}
            disabled={disabled}
            data-cy={`${_.kebabCase(name)}-input`}
            onChange={e => {
                formik.getFieldProps(name).onChange(e);
                if (onChange !== undefined) {
                    onChange(e);
                }
            }}
        />
    );
};

export const getSelect = (
    theme,
    name,
    label,
    selectableValues,
    formik,
    itemChangeAction = null,
    menuItemValue = null,
    menuItemName = null,
    multiple = false,
    disabled = false,
    style = {}
) => {
    return (
        <FormControl
            fullWidth
            error={_.get(formik.touched, name, false) && _.get(formik.errors, name, false) ? true : null}
            style={{ ...style, marginTop: theme.spacing.unit * 2 }}
        >
            <InputLabel variant="outlined">{label}</InputLabel>
            {itemChangeAction ? (
                <Select
                    {...formik.getFieldProps(name)}
                    fullWidth
                    input={
                        <OutlinedInput
                            labelWidth={label.length * theme.spacing.unit}
                            data-cy={`${_.kebabCase(name)}-input`}
                        />
                    }
                    data-cy={`${_.kebabCase(name)}-select`}
                    onChange={itemChangeAction}
                    multiple={multiple}
                    disabled={disabled}
                >
                    {selectableValues.map((selectableValue, idx) => (
                        <MenuItem
                            data-cy={`${_.kebabCase(name)}-select-${idx}`}
                            value={_.get(selectableValue, menuItemValue) || selectableValue.toString()}
                            key={idx}
                        >
                            {_.get(selectableValue, menuItemName) || selectableValue.toString()}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Select
                    {...formik.getFieldProps(name)}
                    fullWidth
                    multiple={multiple}
                    disabled={disabled}
                    data-cy={`${_.kebabCase(name)}-select`}
                    input={
                        <OutlinedInput
                            labelWidth={label.length * theme.spacing.unit}
                            data-cy={`${_.kebabCase(name)}-input`}
                        />
                    }
                >
                    {selectableValues.map((selectableValue, idx) => (
                        <MenuItem
                            data-cy={`${_.kebabCase(name)}-select-${idx}`}
                            value={_.get(selectableValue, menuItemValue) || selectableValue.toString()}
                            key={idx}
                        >
                            {_.get(selectableValue, menuItemName) || selectableValue.toString()}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {_.get(formik.touched, name, false) && _.get(formik.errors, name, false) && (
                <FormHelperText>{_.get(formik.errors, name, '')}</FormHelperText>
            )}
        </FormControl>
    );
};

export const getTimePicker = (theme, name, label, formik, value, changeAction, disabled = false) => (
    <TimePicker
        disabled={disabled}
        label={label}
        name={name}
        variant="outlined"
        value={value}
        emptyLabel="Please select a time"
        format="h:mm A"
        onChange={changeAction}
        style={{ marginTop: theme.spacing.unit * 2 }}
        helperText={
            _.get(formik.touched, name, false) && _.get(formik.errors, name, false)
                ? _.get(formik.errors, name, false)
                : null
        }
        error={_.get(formik.touched, name, false) && _.get(formik.errors, name, false) ? true : null}
        fullWidth
    />
);
