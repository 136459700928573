import _ from 'lodash';
import { wait } from 'utils/misc';
import { _time } from 'std';

//try to only use these ones as the other ones aren't free
export const GOOGLE_PLACES_FREE_TIER_FIELDS = [
    'address_component',
    'adr_address',
    'business_status',
    'formatted_address',
    'geometry',
    'icon',
    'name',
    'permanently_closed',
    'photo',
    'place_id',
    'plus_code',
    'type',
    'url',
    'utc_offset',
    'vicinity'
];

export function getPlaceDetails(
    service,
    place_id,
    okStatus,
    http,
    sessionToken = null,
    fields = GOOGLE_PLACES_FREE_TIER_FIELDS
) {
    return new Promise((resolve, reject) => {
        //NOTE: if fields is not specified in the object bellow it defaults to all fields which means we get billed for them.
        service.getDetails({ placeId: place_id, fields, sessionToken }, (place, status) => {
            http.post('/externalCallLogs/logPlacesUsage', {});
            if (status === okStatus) {
                place.timezone = determineTimezoneBasedOnUTCOffset(place.utc_offset);
                return resolve(place);
            } else {
                //console.log(status);
                return reject();
            }
        });
    }).catch(err => {});
}
// TODO: this is not an amazing solution
function determineTimezoneBasedOnUTCOffset(utc_offset) {
    const offset = utc_offset / 60;
    switch (offset) {
        case -4:
            return 'America/Toronto';
        case -5:
            return 'America/Winnipeg';
        case -6:
            return 'America/Edmonton';
        default:
            return _time.getTimezone();
    }
}

/**
 * Description.
 * Converts an address into an object containing data as stated
 * in the fields array. More information can be found here:
 * https://developers.google.com/maps/documentation/geocoding/start
 *
 * @param {Object} google
 * @param {String} address
 * @param {Array} fields
 *
 * @returns {Promise} Promise resolves to results from Google query
 *
 */

export async function getDataFromAddress(google, address, fields) {
    const tempPlace = google.maps.places;
    const service = new tempPlace.PlacesService(document.createElement('div'));

    const request = {
        query: address,
        fields: fields
    };

    return new Promise((resolve, reject) => {
        service.findPlaceFromQuery(request, (results, status) => {
            if (status === tempPlace.PlacesServiceStatus.OK) {
                // Resolve's to closest match for given address
                resolve(results[0]);
            } else {
                // Resolve's to undefined if no results are found
                // Handle wherever function is called
                resolve(undefined);
            }
        });
    });
}

export function getUnifiedRoute(
    directionsService,
    pickups,
    currentLocationOrig,
    endLocationOrig,
    counterValue,
    cancellationRequired,
    onSnackbar
) {
    console.log('counterValue', counterValue);
    // Sanitize inputs:
    const currentLocation = {
        location: {
            lat: parseFloat(currentLocationOrig.lat),
            lng: parseFloat(currentLocationOrig.lng)
        }
    };
    const endLocation = {
        location: {
            lat: parseFloat(endLocationOrig.lat),
            lng: parseFloat(endLocationOrig.lng)
        }
    };

    return new Promise(async (resolve, reject) => {
        let responses = [];
        let pickupsByZone = _.groupBy(_.filter(pickups, pickup => pickup.zone), pickup => pickup.zone.name); // do not include pickups with no zone
        let zones = _.keys(pickupsByZone);
        // console.log('zones', zones);
        let zonesResponse;
        let zonesWaypoints;
        let i = 0;
        while (_.isNil(zonesResponse)) {
            if (cancellationRequired(counterValue)) {
                console.error('CANCELLATION OCCURRED');
                return;
            }

            let strategy = {
                type: 'USE_GIVEN_INDEX',
                payload: {
                    index: i
                }
            };
            zonesWaypoints = getZonesWaypoints(zones, pickupsByZone, strategy);
            zonesResponse = await getDirectionsPoll(
                directionsService,
                currentLocation,
                endLocation,
                zonesWaypoints,
                counterValue,
                cancellationRequired
            );
            i++;
            // if (_.isNil(zonesResponse)) {
            //     console.log('retrying');
            // }
        }
        let zonesWaypointsOrder = zonesResponse.routes[0].waypoint_order;
        let zonesSorted = zonesWaypointsOrder.map(index => zones[index]);
        // console.log('zonesSorted', zonesSorted);

        let previousWaypoint = currentLocation;
        let nextIndex = 1;
        let nextWaypoint = !_.isNil(zonesWaypoints[nextIndex]) ? zonesWaypoints[nextIndex] : endLocation;
        for (let zone of zonesSorted) {
            if (cancellationRequired(counterValue)) {
                console.error('CANCELLATION OCCURRED');
                return;
            }

            let waypoints = pickupsByZone[zone].map(pickup => ({
                location: {
                    // lat: parseFloat(pickup.location.lat),
                    // lng: parseFloat(pickup.location.lng),
                    placeId: pickup.location.place_id
                },
                stopover: true
            }));
            // console.log('zone', zone, previousWaypoint, nextWaypoint, waypoints);

            // Get directions with connection to the next zone
            let response = await getDirectionsPoll(
                directionsService,
                previousWaypoint,
                nextWaypoint,
                waypoints,
                counterValue,
                cancellationRequired
            );
            // console.log('response', response);
            if (!_.isNil(response)) {
                let waypointsOrder = response.routes[0].waypoint_order;
                // Get actual directions to render (exclude last waypoint unless this was the last zone)
                if (!_.isNil(zonesWaypoints[nextIndex])) {
                    let lastWaypoint = waypoints[_.last(waypointsOrder)];
                    let responseToRender = await getDirectionsPoll(
                        directionsService,
                        previousWaypoint,
                        lastWaypoint,
                        _.without(waypoints, lastWaypoint),
                        counterValue,
                        cancellationRequired
                    );
                    responses.push(responseToRender);
                } else {
                    responses.push(response);
                }
                // Update previousWaypoint and nextWaypoint:
                previousWaypoint = waypoints[_.last(waypointsOrder)];
                nextIndex++;
                nextWaypoint = !_.isNil(zonesWaypoints[nextIndex]) ? zonesWaypoints[nextIndex] : endLocation;
            } else {
                nextIndex++;
                nextWaypoint = !_.isNil(zonesWaypoints[nextIndex]) ? zonesWaypoints[nextIndex] : endLocation;
            }
        }

        if (cancellationRequired(counterValue)) {
            console.error('CANCELLATION OCCURRED');
            return;
        }
        resolve(responses);
    });
}

function getZonesWaypoints(zones, pickupsByZone, strategy) {
    return zones.map(zone => {
        let pickup;
        switch (strategy.type) {
            case 'USE_GIVEN_INDEX':
                let index = strategy.payload.index;
                pickup = index < pickupsByZone[zone].length ? pickupsByZone[zone][index] : _.last(pickupsByZone[zone]);
                break;
            default:
                throw new Error('getZonesWaypoints: strategy not found');
        }
        // TODO: what's wrong with ObjectId('5bda3b7b6a7b8065ab87034d') ?
        return {
            location: {
                lat: parseFloat(pickup.location.lat),
                lng: parseFloat(pickup.location.lng)
            },
            stopover: true
        };
    });
}

async function getDirectionsPoll(
    directionsService,
    startLocation,
    endLocation,
    waypoints,
    counterValue,
    cancellationRequired
) {
    try {
        if (cancellationRequired(counterValue)) {
            console.error('CANCELLATION OCCURRED');
            return undefined;
        }
        return await getDirections(directionsService, startLocation, endLocation, waypoints);
    } catch (status) {
        if (status !== 'OVER_QUERY_LIMIT') {
            console.error('Fatal error:', status);
            return undefined;
        } else {
            if (cancellationRequired(counterValue)) {
                console.error('CANCELLATION OCCURRED');
                return undefined;
            }
            console.log('Waiting...', status);
            await wait(1000);
            return await getDirectionsPoll(
                directionsService,
                startLocation,
                endLocation,
                waypoints,
                counterValue,
                cancellationRequired
            );
        }
    }
}

function getDirections(directionsService, startLocation, endLocation, waypoints) {
    return new Promise((resolve, reject) => {
        directionsService.route(
            {
                origin: _.isNil(startLocation.location.placeId)
                    ? { lat: startLocation.location.lat, lng: startLocation.location.lng }
                    : { placeId: startLocation.location.placeId },
                destination: _.isNil(endLocation.location.placeId)
                    ? { lat: endLocation.location.lat, lng: endLocation.location.lng }
                    : { placeId: endLocation.location.placeId },
                waypoints,
                optimizeWaypoints: true,
                travelMode: 'DRIVING'
            },
            (response, status) => {
                if (status === 'OK') {
                    return resolve(response);
                } else {
                    return reject(status);
                }
            }
        );
    });
}

export function addGoogleServiceSDKFields(google, serverResponse: any) {
    const browserResponse = _.cloneDeep(serverResponse.json);
    browserResponse.request = serverResponse.query;
    browserResponse.request.waypoints = [
        {
            location: {
                lat: 51.0723333,
                lng: -114.0626435 // 139 22 Ave NE, Calgary, AB T2E 1T4, Canada
            },
            stopover: true
        }
    ];
    browserResponse.routes = browserResponse.routes.map(response => {
        console.log('inner response', response, response.overview_polyline);
        const bounds = new google.maps.LatLngBounds(response.bounds.southwest, response.bounds.northeast);
        response.bounds = bounds;
        response.overview_path = google.maps.geometry.encoding.decodePath(response.overview_polyline.points);

        response.legs = response.legs.map(leg => {
            leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);
            leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);
            leg.steps = leg.steps.map(step => {
                step.path = google.maps.geometry.encoding.decodePath(step.polyline.points);
                step.start_location = new google.maps.LatLng(step.start_location.lat, step.start_location.lng);
                step.end_location = new google.maps.LatLng(step.end_location.lat, step.end_location.lng);
                return step;
            });
            return leg;
        });

        return response;
    });

    console.log('browserResponse', browserResponse);
    return browserResponse;
}

/* const latlng = {
    lat: Float,
    lng: Float,
}; */

export function getDetailsFromLatLng(latlng, geocoder, callback) {
    geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
            if (results[0]) {
                callback(results);
            } else {
                console.error('No results found for latlng');
                callback(null);
            }
        } else {
            console.error('Geocoder failed due to: ' + status);
            callback(null);
        }
    });
}

export function generateAutoCompleteRequest(data) {
    const env = process.env.REACT_APP_REGION_EXT;
    const { searchText, location, google, radius, types, bounds, sessionToken, componentRestrictions } = data;

    let request = {
        input: searchText,
        location: new google.maps.LatLng(location.lat, location.lng),
        radius,
        types,
        bounds,
        sessionToken,
        componentRestrictions: {
            ...componentRestrictions,
            country: ['ca', 'us']
        }
    };

    if (env === 'AUS') {
        request.componentRestrictions.country = ['au', 'nz'];
    }

    if (env === 'CON') {
        request = {
            ...request,
            location: new google.maps.LatLng(46.8139, -71.208), //Lat&lng in Quebec
            radius: '50000',
            bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(45.4103, -74.0427), // SW corner of QC
                new google.maps.LatLng(62.3933, -57.3584) // NE corner of QC
            ),
            componentRestrictions: {
                ...componentRestrictions,
                country: ['ca']
            }
        };
    }

    if (env === 'EXP') {
        request = {
            ...request,
            location: new google.maps.LatLng(50.046, -124.489), //Lat&lng in BC
            radius: '50000',
            bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(47.897, -135.387), // SW corner of BC
                new google.maps.LatLng(60.863, -113.019) // NE corner of BC
            ),
            componentRestrictions: {
                ...componentRestrictions,
                country: ['ca']
            }
        };
    }

    if (process.env.REACT_APP_REGION_EXT_DEMO === 'SAR') {
        request = {
            ...request,
            location: new google.maps.LatLng(37.125, -119.855), //Lat&lng in Cali
            radius: '50000',
            bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(31.912, -127.634), // 31.91225351941303, -127.63402217540703
                new google.maps.LatLng(43.118, -112.191) // 43.11823390160838, -112.19070568452486
            ),
            componentRestrictions: {
                ...componentRestrictions,
                country: ['us']
            }
        };
    }

    return request;
}

export function getFilteredAutoCompleteResults(data) {
    if (_.isEmpty(data)) {
        return [];
    }

    const env = process.env.REACT_APP_REGION_EXT;
    let provIdentifiers = [];
    if (env === 'CON') {
        provIdentifiers = ['QC', 'Quebec'];
    }
    if (env === 'EXP') {
        provIdentifiers = ['BC', 'British Columbia'];
    }
    if (process.env.REACT_APP_REGION_EXT_DEMO === 'SAR') {
        provIdentifiers = ['CA', 'California'];
    }
    if (_.isEmpty(provIdentifiers)) {
        return data;
    }

    const filteredData = [];
    for (let place of data) {
        const terms = _.get(place, 'terms', []);
        if (_.isEmpty(terms)) {
            continue;
        }

        for (let term of terms) {
            const val = _.get(term, 'value');
            if (provIdentifiers.includes(val)) {
                filteredData.push(place);
                break;
            }
        }
    }

    return filteredData;
}
