import React from 'react';

import _ from 'lodash';

import { Typography, Icon, IconButton } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';
import { useContext } from 'react';

const SummaryPickupSubdiget = ({ theme, pickups, charities, clicksAllowed, onPickupDialog, customerId }) => {
    const iconStyle = { marginRight: theme.spacing.unit, fontSize: 18 };
    const textStyle = { fontSize: 12 };

    const { lang } = useContext(LocalizationContext);

    // const charityFromPickup = _.find(charities, charity => charity._id === pickup.charity);
    // const charityName = !_.isNil(charityFromPickup) ? charityFromPickup.name : 'N/A';

    return (
        <div
            id="summary-pickup-subwidget"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <div
                style={{
                    display: 'flex',
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2,
                    overflow: 'hidden'
                }}
            >
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Icon color="action" style={iconStyle}>
                                    calendar_today
                                </Icon>
                            </td>
                            <td>
                                <Typography style={textStyle} data-cy="summary-pickup-subwidget-text">
                                    {loc('pickupWidgets22', lang, { numPickups: pickups.length })}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Icon color="action" style={iconStyle}>
                                    info
                                </Icon>
                            </td>
                            <td>
                                <Typography variant="caption">
                                    {loc('pickupWidgets20', lang, { id: customerId })}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                style={{
                    borderLeft: '1px solid ' + theme.palette.text.hint,
                    paddingLeft: theme.spacing.unit,
                    paddingRight: theme.spacing.unit
                }}
            >
                <IconButton
                    data-cy="summpary-pickup-subwidget-edit-button"
                    disabled={!clicksAllowed}
                    onClick={onPickupDialog}
                >
                    <Icon>edit</Icon>
                </IconButton>
            </div>
        </div>
    );
};

export default withTheme()(SummaryPickupSubdiget);
