import React from 'react';
import { Typography, withTheme } from '@material-ui/core';

function TabWrapper(props) {
    const { theme, title, color, children, ...rest } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} {...rest}>
            <div
                style={{
                    background: color,
                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
                }}
            >
                <Typography
                    variant="body1"
                    style={{
                        marginLeft: theme.spacing.unit * 2,
                        marginRight: theme.spacing.unit * 2,
                        marginTop: theme.spacing.unit * 0.75,
                        marginBottom: theme.spacing.unit * 0.5,
                        color: theme.palette.common.white
                    }}
                >
                    {title}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    paddingTop: theme.spacing.unit,
                    paddingBottom: theme.spacing.unit
                }}
            >
                <div style={{ width: '100%' }}>{children}</div>
            </div>
        </div>
    );
}

export default withTheme()(TabWrapper);
