import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    GridList,
    TextField,
    InputAdornment,
    DialogActions,
    LinearProgress,
    Button,
    Fade,
    colors,
    withTheme,
    withMobileDialog,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import InlineChip from 'components/Chips/InlineChip';

import { _commodity, _time } from 'std';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageCapture from 'components/ImageUploads/ImageCapture';

function CommodityPickupDialog(props) {
    const {
        open,
        commodity,
        //openBulkCounts,
        commodityPickup = null,
        loading,
        onClose,
        onSubmit,
        theme,
        fullScreen
        /*isAdmin,
        allCollectorsBySkuType,
        collectorIdsSelected = [],
        endDate,
        setCollectorIdsSelected,
        setEndDate,
        imageURLs,
        imagePreviews,
        handleAddImage,
        handleDeleteImage,
        uploadingImage,
        resetImages = () => {}*/
    } = props;

    /*const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);*/
    const { lang } = useContext(LocalizationContext);

    //const [step, setStep] = useState(0);

    const [fieldValues, setFieldValues] = useState([]);
    const [trailerLabel, setTrailerLabel] = useState('');

    //const editing = !_.isNil(commodityPickup);

    //const skuType = _commodity.getSkuType(commodity);

    const totalFieldValuesZero = _.sum(fieldValues) === 0;

    /*const bulkBreakdownForCommodity = _.get(openBulkCounts, _commodity.getSkuType(commodity));
    const openOrders = _.get(bulkBreakdownForCommodity, 'totalBulks', 0);
    const totalCommodityAmount = _.get(bulkBreakdownForCommodity, 'totalCommodityAmount', 0);
    const openOrdersRequired =
        (_commodity.isSplitCompleted(commodity) || _commodity.isAcceptUnitsCompleted(commodity)) && !editing;
    const isSplitCompletedCommodity = _commodity.isSplitCompleted(commodity);
    const configuredCollectors = _.get(allCollectorsBySkuType, skuType, []);*/

    const handleChangeFieldValue = (e, i) => {
        let newVal = e.target.value;
        if (!_.isEmpty(newVal)) {
            newVal = parseInt(e.target.value);
        } else {
            newVal = 0;
        }
        const fieldValuesCopy = _.cloneDeep(fieldValues);
        fieldValuesCopy[i] = newVal;
        setFieldValues(fieldValuesCopy);
    };

    /*const handlePayloadNext = () => {
        const totalPayload = _.sum(fieldValues);
        if (editing) {
            onSubmit(fieldValues, trailerLabel);
        } else if (_commodity.isAcceptUnitsCompleted(commodity) && totalPayload < totalCommodityAmount) {
            warnAction(
                () => setStep(step + 1),
                <DialogContentText>
                    <Typography variant="h6">Are You Sure?</Typography>
                    <div style={{ color: colors.red[500], marginTop: theme.spacing.unit * 2 }}>
                        {totalCommodityAmount} {_commodity.getUnit(commodity)}
                        {totalCommodityAmount !== 0 && 's'} have been recovered from customers, please double check the
                        unit total and make sure to include any small or miscellaneous items.
                    </div>
                </DialogContentText>,
                null,
                null,
                { color: colors.red[500] }
            );
        } else {
            setStep(step + 1);
        }
    };*/

    useEffect(() => {
        /*setStep(0);
        resetImages();*/
        setFieldValues(getFieldValuesInitialValue(commodity, commodityPickup));
        setTrailerLabel(_.get(commodityPickup, 'trailerLabel', ''));
        /*if (_.get(commodityPickup, 'collectionPeriod.endDate') && !editing && setEndDate) {
            setEndDate(moment(_.get(commodityPickup, 'collectionPeriod.endDate')).tz(timezone));
        }*/
    }, [commodity, open, commodityPickup]);

    const payloadStep = (
        <>
            <DialogContent style={{ paddingTop: theme.spacing.unit * 2 }}>
                {/*!editing && (
                    <DatePicker
                        style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit }}
                        format="MMM/DD/YYYY"
                        variant="outlined"
                        label="Cut off date"
                        margin="normal"
                        timezone={timezone}
                        value={endDate}
                        onChange={date => setEndDate(date)}
                        type="end"
                    />
                )*/}
                <DialogContentText>
                    {
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: theme.spacing.unit * 2,
                                    flexWrap: 'wrap'
                                }}
                            >
                                <span>Enter the amounts to be picked up:</span>
                                <span>
                                    {/*!editing && !_commodity.isAutoCompleted(commodity) && (
                                        <>
                                            <InlineChip
                                                color={theme.palette.primary.main}
                                                textColor="white"
                                                style={{ flexShrink: 0 }}
                                                text={`Open orders: ${openOrders}`}
                                            />
                                            <InlineChip
                                                color={theme.palette.secondary.main}
                                                textColor="white"
                                                style={{ flexShrink: 0, marginLeft: theme.spacing.unit }}
                                                text={`${totalCommodityAmount} ${_commodity.getUnit(commodity)}${
                                                    totalCommodityAmount !== 1 ? 's' : ''
                                                }`}
                                            />
                                        </>
                                    )*/}
                                </span>
                            </div>
                            <Grid container spacing={theme.spacing.unit}>
                                {_.get(commodity, 'collectorPickupFields', []).map((pickupField, i) => {
                                    return (
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    marginTop: theme.spacing.unit
                                                }}
                                                type="tel"
                                                fullWidth
                                                label={_.get(pickupField, 'description')}
                                                name={_.get(pickupField, 'description')}
                                                value={fieldValues[i]}
                                                variant="outlined"
                                                onChange={e => handleChangeFieldValue(e, i)}
                                                InputProps={{
                                                    endAdornment: !_.isEmpty(_.get(pickupField, 'unit')) && (
                                                        <InputAdornment position="end">
                                                            {_.get(pickupField, 'unit')}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                data-cy={`commodity-pickup-dialog-payload-${_.kebabCase(
                                                    _.get(pickupField, 'description')
                                                )}`}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <div style={{ marginTop: theme.spacing.unit * 2 }}>
                                <span>Enter the trailer number:</span>
                                <Grid
                                    container
                                    spacing={theme.spacing.unit}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                >
                                    <Grid item xs={12} lg={4}>
                                        <TextField
                                            style={{
                                                marginTop: theme.spacing.unit
                                            }}
                                            fullWidth
                                            label="Trailer Number"
                                            value={trailerLabel}
                                            variant="outlined"
                                            onChange={e => setTrailerLabel(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose} data-cy="commodity-pickup-dialog-close">
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(fieldValues, trailerLabel)}
                    disabled={totalFieldValuesZero || loading}
                    data-cy="commodity-pickup-dialog-payload-next-btn"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </>
    );

    /*const pictureStep = (
        <>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                <DialogContentText>
                    <ImageCapture
                        imageURLs={imagePreviews}
                        handleAddImage={handleAddImage}
                        handleDeleteImage={handleDeleteImage}
                        uploadingImage={uploadingImage}
                        placeholderText={<span>Please upload or take a photo of what needs to be picked up.</span>}
                        onSnackbar={onSnackbar}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setStep(step - 1)}>
                    Back
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(fieldValues, imageURLs)}
                    disabled={
                        loading || (_.isNil(imagePreviews) && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE))
                    }
                    data-cy="commodity-pickup-dialog-picture-next-btn"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </>
    );

    const steps = [payloadStep, pictureStep];*/

    return (
        <Dialog open={open} fullWidth onClose={onClose} fullScreen={fullScreen}>
            <DialogTitlePrimary closeButtonShown={true} onClose={onClose}>
                Request {_commodity.getSkuType(commodity)} pickup
            </DialogTitlePrimary>
            {payloadStep}
            <Fade in={loading}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CommodityPickupDialog));

function getFieldValuesInitialValue(commoditySelected, commodityPickup) {
    let initialValues = _.get(commoditySelected, 'collectorPickupFields', []).map(pickupField => {
        const payloadForField = _.find(_.get(commodityPickup, 'payload', []), {
            description: pickupField.description,
            unit: pickupField.unit
        });
        return _.get(payloadForField, 'quantity', 0);
    });
    const payloadForOther = _.find(_.get(commodityPickup, 'payload', []), {
        description: 'Other'
    });
    initialValues.push(_.get(payloadForOther, 'quantity', 0)); //add extra zero for 'Other' field
    return initialValues;
}

function commodityNotConfigured(configForCommodity) {
    return _.isNil(configForCommodity) || _.isEmpty(_.get(configForCommodity, 'emails'));
}
