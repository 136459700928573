import React, { useState, useContext } from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { withTheme } from '@material-ui/core/styles';

import { formatAsCurrency } from 'utils/misc';
import { Input, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../../localizations/localizationHandler';

function DeleteAccountDialog({
    theme,
    open,
    fullScreen,
    balance,
    deleteReason,
    pickupsLeft,
    handleClose,
    handleDeleteAccount,
    handleDeleteReasonChange
}) {
    const { lang } = useContext(LocalizationContext);
    const formControlStyle = {
        marginTop: theme.spacing.unit + theme.spacing.unit / 2
    };

    const [deleteText, setDeleteText] = useState('');
    const deleteReasons = [
        { reason: 'I already have an account' },
        { reason: "I don't like this service", label: 'What can we do better?' },
        { reason: "I'm recycling a different way", label: 'What is your preferred method?' },
        { reason: 'Other', label: 'Reason' }
    ];

    return (
        <Dialog fullWidth open={open} fullScreen={fullScreen} onClose={handleClose}>
            <DialogTitle style={{ paddingTop: 'max(24px, env(safe-area-inset-top, 24px))' }}>
                {loc('deleteAccount1', lang)}
            </DialogTitle>
            <DialogContent>
                <FormControl
                    data-cy="disable-account-dialog"
                    fullWidth
                    style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }}
                >
                    <InputLabel htmlFor="deleteReason">Why are you disabling your account?</InputLabel>
                    <Select
                        value={deleteReason.reason || ''}
                        input={<Input value={deleteReason.reason || ''} name="reason" id="deleteReason" />}
                        onChange={handleDeleteReasonChange}
                        data-cy="delete-account-reason-select"
                    >
                        {deleteReasons.map(function(disable, i) {
                            return (
                                <MenuItem key={i} data-cy={'delete-account-reason-' + i} value={disable.reason}>
                                    {disable.reason}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {_.get(_.find(deleteReasons, ['reason', deleteReason.reason]), 'label') && (
                        <FormControl style={formControlStyle} fullWidth>
                            <TextField
                                data-cy="disable-account-reason-comments"
                                name="comments"
                                label={_.get(_.find(deleteReasons, ['reason', deleteReason.reason]), 'label')}
                                value={deleteReason.comments}
                                variant="outlined"
                                onChange={handleDeleteReasonChange}
                            />
                        </FormControl>
                    )}
                </FormControl>
                {balance > 0 && (
                    //     <DialogContentText style={{ marginBottom: theme.spacing.unit * 2 }}>
                    //         {loc('deleteAccount2a', lang, { balance: formatAsCurrency(balance) })}{' '}
                    //         <span style={{ color: 'red', fontWeight: 'bold' }}>{loc('deleteAccount2b', lang)}</span>
                    //     </DialogContentText>
                    // )}
                    // <DialogContentText>
                    //     {loc('deleteAccount3a', lang)}{' '}
                    //     <span style={{ color: 'red', fontWeight: 'bold' }}>{loc('deleteAccount3b', lang)}</span>{' '}
                    //     {loc('deleteAccount3c', lang)}

                    <DialogContentText
                        data-cy="delete-account-balance-remaining"
                        style={{ marginBottom: theme.spacing.unit }}
                    >
                        {loc('deleteAccount2a', lang, { balance: formatAsCurrency(balance, lang) })}{' '}
                        {/* You have a remaining account balance of {formatAsCurrency(balance)}. If you delete your account,{' '} */}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                            {loc('deleteAccount2b', lang, { balance: formatAsCurrency(balance, lang) })}
                            {/* you will not be able to redeem your remaining balance! */}
                        </span>
                    </DialogContentText>
                )}
                {pickupsLeft && (
                    <DialogContentText
                        data-cy="delete-account-pickups-remaining"
                        style={{ marginBottom: theme.spacing.unit }}
                    >
                        You still have pending pickups for today.{' '}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                            Deleting your account will cancel all pending pickups.
                        </span>
                    </DialogContentText>
                )}

                <DialogContentText style={{ marginTop: theme.spacing.unit * 2 }}>
                    All of your account data will be deleted.{' '}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>THIS CANNOT BE UNDONE.</span> To proceed with
                    deleting your account, enter the word <span style={{ fontWeight: 'bold' }}>delete</span> in the text
                    box below and then press the confirmation button.
                </DialogContentText>
                <FormControl style={formControlStyle} fullWidth>
                    <TextField
                        data-cy="delete-account-text-field"
                        name="comments"
                        value={deleteText}
                        variant="outlined"
                        onChange={e => setDeleteText(e.target.value)}
                    />
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button data-cy="delete-account-cancel-button" color="primary" onClick={handleClose}>
                    {loc('cancel', lang)}
                </Button>
                <Button
                    data-cy="delete-account-accept-button"
                    color="primary"
                    disabled={
                        _.isEmpty(deleteReason.reason) ||
                        (_.get(_.find(deleteReasons, ['reason', deleteReason.reason]), 'label') &&
                            _.isEmpty(deleteReason.comments)) ||
                        deleteText
                            .toString()
                            .normalize()
                            .toLowerCase() !== 'delete'
                    }
                    onClick={handleDeleteAccount}
                >
                    {loc('confirm', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(DeleteAccountDialog);
