import React, { Component } from 'react';

import _ from 'lodash';

import { wait } from 'utils/misc';

import { FinancialWidget, StatsWidget, CharityFullInfoWidget } from 'components/CustomerWidgets';
import CharityInfoWidget from 'components/CustomerWidgets/CharityInfoWidget';
import CharityTasksCompletionWidget from 'components/CustomerWidgets/CharityTasksCompletionWidget';

import Swipeable from 'react-swipeable';
import Hidden from '@material-ui/core/Hidden';
import { withTheme } from '@material-ui/core/styles';
import { _user } from 'std';

let UPPER_SWIPE_LIMIT;
let LOWER_SWIPE_LIMIT;
const STICKING_OUT = 145; // in px
const HYSTERESIS = 0.125;

class CharityDashboard extends Component {
    swipeablePanel;
    charityInfoWidget;
    backdrop;
    topValueInit = 0;

    initialize = () => {
        if (_.isNil(this.swipeablePanel)) {
            return;
        }

        this.swipeablePanel.style.top = `calc(100% - ${STICKING_OUT}px)`;
        this.swipeablePanel.style.height = STICKING_OUT;
        this.charityInfoWidget.style.opacity = 1;
        this.backdrop.style.opacity = 0;

        this.topValueInit = this.swipeablePanel.offsetTop;
        // console.log('this.topValueInit', this.topValueInit); // TODO: fix this broken shit
        LOWER_SWIPE_LIMIT = this.topValueInit;
        UPPER_SWIPE_LIMIT = -this.swipeablePanel.scrollHeight + LOWER_SWIPE_LIMIT + STICKING_OUT;
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.setState({ charityShares: this.props.charity.shares || 0 });
        this.props.reloadCharity();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = e => {
        this.initialize();
    };

    handleSwiping = (e, deltaX, deltaY, absX, absY, velocity) => {
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
        if (_.isNil(UPPER_SWIPE_LIMIT)) {
            this.initialize();
            this.swipeablePanel.addEventListener('wheel', this.handleWheel);
        }

        if (this.topValueInit - deltaY <= UPPER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = UPPER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${UPPER_SWIPE_LIMIT}px)`;
            this.charityInfoWidget.style.opacity = 0;
        } else if (this.topValueInit - deltaY >= LOWER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
            this.charityInfoWidget.style.opacity = 1;
            this.backdrop.style.opacity = 0;
        } else {
            let topValueNew = this.topValueInit - deltaY;
            this.swipeablePanel.style.top = topValueNew.toString() + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${topValueNew.toString()}px)`;

            let darkenRatio = 1 - topValueNew / LOWER_SWIPE_LIMIT;
            this.charityInfoWidget.style.opacity = topValueNew / LOWER_SWIPE_LIMIT;
            this.backdrop.style.opacity = darkenRatio;
        }
    };

    handleSwipedUp = async (e, deltaY) => {
        let scrollRatio = 1 - this.swipeablePanel.offsetTop / LOWER_SWIPE_LIMIT;

        if (scrollRatio > 0 && scrollRatio < 1) {
            if (scrollRatio >= HYSTERESIS) {
                this.animateScroll();

                this.swipeablePanel.style.top = 56 + 'px';
                this.swipeablePanel.style.height = `calc(100% - 56px - env(safe-area-inset-top))`;
                this.charityInfoWidget.style.opacity = 0;
                this.backdrop.style.opacity = 1;
            } else {
                this.animateScroll();

                this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
                this.charityInfoWidget.style.opacity = 1;
                this.backdrop.style.opacity = 0;
            }
        }

        await wait(200);
        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    handleSwipedDown = async (e, deltaY) => {
        let scrollRatio = 1 - this.swipeablePanel.offsetTop / LOWER_SWIPE_LIMIT;

        if (scrollRatio > 0 && scrollRatio < 1) {
            if (scrollRatio >= 1 - HYSTERESIS) {
                this.animateScroll();

                this.swipeablePanel.style.top = 56 + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${56}px)`;
                this.charityInfoWidget.style.opacity = 0;
                this.backdrop.style.opacity = 1;
            } else {
                this.animateScroll();

                this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
                this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
                this.charityInfoWidget.style.opacity = 1;
                this.backdrop.style.opacity = 0;
            }
        }

        await wait(200);
        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    handleWheel = async e => {
        if (this.topValueInit - e.deltaY <= UPPER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = UPPER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${UPPER_SWIPE_LIMIT}px)`;
            this.charityInfoWidget.style.opacity = 0;
        } else if (this.topValueInit - e.deltaY >= LOWER_SWIPE_LIMIT) {
            this.swipeablePanel.style.top = LOWER_SWIPE_LIMIT + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${LOWER_SWIPE_LIMIT}px)`;
            this.charityInfoWidget.style.opacity = 1;
            this.backdrop.style.opacity = 0;
        } else {
            let topValueNew = this.topValueInit - e.deltaY;
            this.swipeablePanel.style.top = topValueNew.toString() + 'px';
            this.swipeablePanel.style.height = `calc(100% - ${topValueNew.toString()}px)`;

            let darkenRatio = 1 - topValueNew / LOWER_SWIPE_LIMIT;
            this.charityInfoWidget.style.opacity = topValueNew / LOWER_SWIPE_LIMIT;
            this.backdrop.style.opacity = darkenRatio;
        }

        this.topValueInit = this.swipeablePanel.offsetTop;
    };

    animateScroll = async () => {
        this.swipeablePanel.classList.add('gradual-transition-scroll');
        this.charityInfoWidget.classList.add('gradual-transition-scroll');
        this.backdrop.classList.add('gradual-transition-scroll');

        await wait(150 + 50);

        this.swipeablePanel.classList.remove('gradual-transition-scroll');
        this.charityInfoWidget.classList.remove('gradual-transition-scroll');
        this.backdrop.classList.remove('gradual-transition-scroll');
    };

    render() {
        const {
            bulks,
            bulksLoaded,
            theme,
            charity,
            financialStats,
            environmentalStats,
            onCharityTaskSelected,
            charityTasks,
            redemptions,
            redemptionPending,
            onCharityGrowthPlan,
            customersDonating,
            socialMediaShares,
            onShareDialogOpen,
            onRedirectToPage,
            shareDialogEnabled,
            adminPermissions,
            operator
        } = this.props;

        const isAdmin =
            _user.isSystemAdmin(operator) || _user.isCollectorAdmin(operator) || _user.isInternalRole(operator);
        if (_.isNil(charity)) {
            return null;
        }

        const lastRedemption = _.last(redemptions);
        let amountPending = redemptionPending ? lastRedemption.amount : financialStats.amountPending;

        const filteredFinancialStats = {
            totalRevenue: financialStats.totalRevenue,
            totalDonations: financialStats.totalDonations,
            totalQuantity: financialStats.totalQuantity
        };

        const widgets = elevated => (
            <React.Fragment>
                <FinancialWidget
                    elevated={elevated}
                    bulks={bulks}
                    bulksLoaded={bulksLoaded}
                    isCharity
                    charity={charity}
                    redemptions={redemptions}
                    balance={financialStats.balance}
                    amountPending={amountPending}
                    lastRedemption={lastRedemption}
                    style={{ marginTop: 0 }} // TODO: get rid of this hackity-hack
                    onRedemptionDialog={this.props.onCharityRedemptionDialog}
                    onRedirectToPage={onRedirectToPage}
                    disabled={isAdmin && !_.get(adminPermissions, 'canRedeemCharityBalance', false)}
                    adminPermissions={adminPermissions}
                />

                <StatsWidget
                    bulks={bulks}
                    elevated={elevated}
                    environmentalStats={environmentalStats}
                    financialStats={filteredFinancialStats}
                    isCharity
                />

                <CharityFullInfoWidget
                    elevated={elevated}
                    charity={charity}
                    onCharityInfoEdit={this.props.onCharityInfoEdit}
                    onSnackbar={this.props.onSnackbar}
                />

                <CharityTasksCompletionWidget
                    charity={charity}
                    elevated={elevated}
                    charityTasks={charityTasks}
                    onTaskSelected={onCharityTaskSelected}
                    onCharityGrowthPlan={onCharityGrowthPlan}
                    customersDonating={customersDonating}
                    socialMediaShares={socialMediaShares}
                    onShareDialogOpen={onShareDialogOpen}
                    shareDialogEnabled={shareDialogEnabled}
                    onRedirectToPage={onRedirectToPage}
                    bulks={bulks}
                />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Hidden smUp>
                    {/*<LargeMap
                        google={google}
                        showDirections={false}
                        offsetLegalInfo
                        homeOrganization={homeOrganization}
                        onCharityClick={this.props.onCharityClick}
                        onDonateClick={this.props.onDonateClick}
                    />*/}

                    <div
                        ref={elem => (this.backdrop = elem)}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: theme.palette.grey[900],
                            opacity: 0,
                            pointerEvents: 'none'
                        }}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            marginTop: 56
                        }}
                    >
                        <CharityInfoWidget
                            assignRef={elem => (this.charityInfoWidget = elem)}
                            elevated
                            charity={charity}
                            onCharityStatsDialog={this.props.onCharityStatsDialog}
                            onSnackbar={this.props.onSnackbar}
                            onCharityGrowthPlan={onCharityGrowthPlan}
                            customersDonating={customersDonating}
                            socialMediaShares={socialMediaShares}
                            onShareDialogOpen={onShareDialogOpen}
                            onRedirectToPage={onRedirectToPage}
                            shareDialogEnabled={shareDialogEnabled}
                            bulks={bulks}
                        />
                    </div>

                    <div
                        ref={elem => (this.swipeablePanel = elem)}
                        style={{
                            position: 'absolute',
                            top: `calc(100% - ${STICKING_OUT}px)`,
                            height: STICKING_OUT,
                            width: '100%',
                            backgroundColor: 'transparent',
                            zIndex: 1000,
                            touchAction: 'none',
                            overflow: 'hidden'
                        }}
                    >
                        <Swipeable
                            trackMouse
                            onSwiping={this.handleSwiping}
                            onSwipedUp={this.handleSwipedUp}
                            onSwipedDown={this.handleSwipedDown}
                            style={{ paddingTop: theme.spacing.unit * 2 }}
                        >
                            {widgets(true)}
                        </Swipeable>
                    </div>
                </Hidden>

                <Hidden xsDown>
                    <div style={{ marginTop: theme.spacing.unit * 2 }}>{widgets(false)}</div>
                </Hidden>
            </React.Fragment>
        );
    }
}

export default withTheme()(CharityDashboard);
