import React, { useState, useRef, useContext } from 'react';

import _ from 'lodash';

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, IconButton } from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { Typography, Button, Dialog } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { deviceHelper } from 'utils/misc';

import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    RedditIcon,
    EmailIcon
} from 'react-share';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import QRCode from 'qrcode.react';

function ReferralScreen(props) {
    const {
        http,
        isAdmin,
        customer,
        hashtags,
        referralConfigObj,
        onNextScreen,
        onBack,
        onClose,
        onSnackbar,
        iconSize,
        iconStyle,
        description,
        theme,
        showSingleStep,
        onSubmit,
        isLastScreen
    } = props;

    const { lang } = useContext(LocalizationContext);
    const inviteRef = useRef(null);

    const scrollToBottom = () => {
        if (inviteRef.current) {
            inviteRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [userReferred, setUserReferred] = useState(false);

    const referralStyles = {
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: theme.spacing.unit / 2,
        minHeight: theme.spacing.unit * 4 + 2,
        padding: theme.spacing.unit / 2,
        margin: 'auto',
        marginBottom: theme.spacing.unit * 1.5,
        maxWidth: 260,
        cursor: 'pointer'
    };

    const {
        referralConfig,
        rulesDialogHandle,
        rulesTitle,
        rules,
        codeDisplayLabel,
        primaryActionLabel,
        referralText,
        referralURL,
        referralTitle,
        sharePopupDialogTitle,
        referralURLDisplayLabel
    } = referralConfigObj;

    const handleCopyToClipboard = (string, snackbarString = null) => () => {
        try {
            Clipboard.write({ string });
            onSnackbar(loc('copiedToClipboard', lang, { str: _.isNil(snackbarString) ? string : snackbarString }));
            onReferAction();
        } catch (err) {
            onSnackbar(loc('copyFailed', lang), 'error');
        }
    };

    const handleDownloadQRCode = () => {
        const canvas = document.getElementById('referral-link-qr-code');
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = loc('qrDownload', lang) + '.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleReferrFriends = async () => {
        let shareRet = await Share.share({
            title: referralTitle,
            text: referralText,
            url: referralURL,
            dialogTitle: sharePopupDialogTitle
        });

        onReferAction();
    };

    const onReferAction = () => {
        setUserReferred(true);

        if (isAdmin) return;

        http.post('/users/customerReferralAction', {});
    };

    return (
        <>
            <DialogTitle disableTypography style={{ textAlign: 'center' }}>
                <Typography variant="h5">
                    <b>{referralConfig.enabled ? referralConfig.title : loc('referralScreen4', lang)}</b>
                </Typography>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText style={{ marginBottom: theme.spacing.unit * 2 }}>
                    {/* LOCALIZATION CHANGE */}
                    {referralConfig.enabled ? referralConfig.text : loc('referralScreen1', lang)}
                </DialogContentText>
                {referralConfig.enabled && (
                    <>
                        <Typography
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                marginBottom: theme.spacing.unit * 2,
                                cursor: 'pointer'
                            }}
                            color="primary"
                            variant="body1"
                            onClick={() => setHelpDialogOpen(true)}
                        >
                            {rulesDialogHandle}
                        </Typography>
                        <Typography variant="subtitle2">{codeDisplayLabel}</Typography>
                        <div
                            style={referralStyles}
                            onClick={
                                deviceHelper.isNativeApp() || !_.isNil(window.navigator.share)
                                    ? handleReferrFriends
                                    : handleCopyToClipboard(customer.uniqueID)
                                // : scrollToBottom
                            }
                            data-cy="share-and-feedback-invite-friends-button"
                        >
                            <Typography variant="h6">
                                <strong>
                                    {customer.uniqueID}
                                    {deviceHelper.isNativeApp() || !_.isNil(window.navigator.share)
                                        ? ` - ${loc('referralScreen5', lang)}`
                                        : ''}
                                </strong>
                            </Typography>
                        </div>
                        <Typography variant="subtitle2">{referralURLDisplayLabel}</Typography>
                        <Typography
                            onClick={handleCopyToClipboard(referralURL, 'referral link')}
                            variant="subtitle2"
                            color="primary"
                            style={{ marginBottom: theme.spacing.unit * 2, cursor: 'pointer' }}
                        >
                            {referralURL}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    paddingRight: theme.spacing.unit + 48,
                                    paddingLeft: theme.spacing.unit + 48,
                                    paddingBottom: 24
                                }}
                            >
                                <QRCode id="referral-link-qr-code" value={referralURL} />
                                <div style={{ position: 'absolute', bottom: 0, right: '10px' }}>
                                    <Tooltip title={loc('save', lang)}>
                                        <IconButton size="small" onClick={handleDownloadQRCode}>
                                            <SaveAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!deviceHelper.isNativeApp() && _.isNil(window.navigator.share) && (
                    <div style={{ marginBottom: theme.spacing.unit * 2 }}>
                        {/* Desktop only version */}
                        <Typography variant="subtitle2" style={{ marginBottom: theme.spacing.unit / 2 }}>
                            {primaryActionLabel}
                        </Typography>
                        <div style={{ display: 'inline-flex' }}>
                            <FacebookShareButton
                                data-cy="share-and-feedback-invite-friends-button"
                                style={iconStyle}
                                url={referralURL}
                                hashtag={`#${hashtags[0]}`}
                                quote={referralText}
                                onShareWindowClose={onReferAction}
                            >
                                <FacebookIcon size={iconSize} round={true} />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                style={iconStyle}
                                url={referralURL}
                                title={referralText}
                                description={description}
                                onShareWindowClose={onReferAction}
                            >
                                <LinkedinIcon size={iconSize} round={true} />
                            </LinkedinShareButton>
                            <TwitterShareButton
                                style={iconStyle}
                                url={referralURL}
                                title={referralText}
                                hashtags={hashtags}
                                onShareWindowClose={onReferAction}
                                via={`${process.env.REACT_APP_BRAND_NAME} App`}
                            >
                                <TwitterIcon size={iconSize} round={true} />
                            </TwitterShareButton>
                            <RedditShareButton
                                style={iconStyle}
                                url={referralURL}
                                title={referralText}
                                onShareWindowClose={onReferAction}
                            >
                                <RedditIcon size={iconSize} round={true} />
                            </RedditShareButton>
                            <EmailShareButton
                                style={iconStyle}
                                url={referralURL}
                                subject={referralTitle}
                                body={referralText}
                                onShareWindowClose={onReferAction}
                            >
                                <EmailIcon size={iconSize} round={true} />
                            </EmailShareButton>
                            <div ref={inviteRef} />
                        </div>
                    </div>
                )}
            </DialogContent>

            {isLastScreen ? (
                <DialogActions
                    style={{
                        justifyContent: showSingleStep ? 'right' : 'space-between',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    {!showSingleStep && <Button onClick={onBack}>{loc('back', lang)}</Button>}

                    <Button
                        data-cy="share-and-feedback-dialog-no-thanks-button"
                        onClick={showSingleStep ? onNextScreen : onSubmit}
                    >
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions
                    style={{
                        justifyContent: showSingleStep ? 'right' : 'space-between',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    {!showSingleStep && <Button onClick={onBack}>{loc('back', lang)}</Button>}

                    {(isAdmin || showSingleStep) && (
                        <Button data-cy="share-and-feedback-dialog-no-thanks-button" onClick={onClose}>
                            {loc('close', lang)}
                        </Button>
                    )}
                    {!showSingleStep && (
                        <Button data-cy="share-and-feedback-next-button" onClick={onNextScreen}>
                            {loc('next', lang)}
                        </Button>
                    )}
                </DialogActions>
            )}
            <Dialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)}>
                <DialogTitle>{rulesTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{rules}</DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withTheme()(ReferralScreen);
