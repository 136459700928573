import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

const TripIncompleteDialog = ({ open, handleClose }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    You can only clock out once all your pickups are completed. If you cannot complete your pickups
                    contact SkipTheDepot support.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TripIncompleteDialog;
