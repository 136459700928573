import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { deviceHelper, getCustomerName, getReceiverName, uppercaseFirstLetter } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';

import {
    Button,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    InputAdornment,
    Typography,
    colors,
    ListItem,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    List,
    FormHelperText,
    Collapse
} from '@material-ui/core';
import useTable from 'components/Table/useTable';

import { openWindow } from 'helpers/windowHelper';

import DashBoardTable from 'components/Table/DashBoardTable';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { Icon as MDIcon } from '@mdi/react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { useEffect } from 'react';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import TimePicker from 'material-ui-pickers/TimePicker';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import GoogleContext from 'utils/contexts/GoogleContext';
import { createLocation, getEmptyLocation } from 'helpers/locationHelper';
import { mdiAccountSearch } from '@mdi/js';
import CharitySelectDialog from 'components/CharitySelect/CharitySelectDialog';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import QuickRegisterFormDialog from 'components/Dialogs/QuickRegister';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';
import useQuickRegister from '../QuickRegistration/hooks/useQuickRegister';

const pendingHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Date^', key: 'date', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const completedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Date^', key: 'date', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const rejectedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Reject Reason', key: 'rejectReason', padding: 'none' },
    { name: 'Date^', key: 'date', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const BottleDrives = ({ theme, http, onSnackbar, history, operator, auth, setRefreshPendingBottleDriveCount }) => {
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [reviewedFiltersOpen, setReviewedFiltersOpen] = useState(null);

    const [checkedPendingBottleDrives, setCheckedPendingBottleDrives] = useState([]);
    const [checkedRejectedBottleDrives, setCheckedRejectedBottleDrives] = useState([]);
    const [checkedCompletedBottleDrives, setCheckedCompletedBottleDrives] = useState([]);

    const [allCharities, setAllCharities] = useState([]);
    const [allProcessors, setAllProcessors] = useState([]);
    const [allTransporters, setAllTransporters] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);
    const [completedBottleDriveIds, setCompletedBottleDriveIds] = useState(null);

    const [selectedBottleDrive, setSelectedBottleDrive] = useState({});
    const [selectedBottleDriveId, setSelectedBottleDriveId] = useState('');

    const [showSchedulePickupDialog, setShowSchedulePickupDialog] = useState(false);
    const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
    const [bottleDriveIdsToReject, setBottleDriveIdsToReject] = useState([]);
    const [rejectReason, setRejectReason] = useState('');

    const { quickRegisterFormOpen, setQuickRegisterFormOpen, handleRegisterUser } = useQuickRegister({
        http,
        history,
        reloadUsers: () => {},
        onSnackbar,
        viewCustomerAfterRegister: false
    });
    const [services, setServices] = useState([]);

    const { google } = useContext(GoogleContext);
    const { defaultCoordinates } = useDefaultCoordinates(http);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        data,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: pendingHeaders.map(h => h.name),
        queryObj: {
            rejected: { $ne: true }
        }
    });

    const {
        loading: loading3,
        getData: getData3,
        page: page3,
        rowsPerPage: rowsPerPage3,
        data: data3,
        filteredData: filteredData3,
        search: search3,
        filteredHeaders: filteredHeaders3,
        totalDataEntries: totalDataEntries3,
        handleChange: handleChange3,
        handleSearch: handleSearch3,
        handleChangeFilterHeaders: handleChangeFilterHeaders3,
        handleAllFilterHeaders: handleAllFilterHeaders3,
        handleChangePage: handleChangePage3,
        handleChangeRowsPerPage: handleChangeRowsPerPage3
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: completedHeaders.map(h => h.name),
        queryObj: { rejected: { $ne: true } }
    });

    const {
        loading: loading2,
        getData: getData2,
        page: page2,
        rowsPerPage: rowsPerPage2,
        data: data2,
        filteredData: filteredData2,
        search: search2,
        filteredHeaders: filteredHeaders2,
        totalDataEntries: totalDataEntries2,
        handleChange: handleChange2,
        handleSearch: handleSearch2,
        handleChangeFilterHeaders: handleChangeFilterHeaders2,
        handleAllFilterHeaders: handleAllFilterHeaders2,
        handleChangePage: handleChangePage2,
        handleChangeRowsPerPage: handleChangeRowsPerPage2
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: rejectedHeaders.map(h => h.name),
        queryObj: {
            rejected: true
        }
    });

    const getServices = async () => {
        let res = await http.getJSON('/services', false, true);
        if (res.ok) {
            setServices(_.get(res, 'data.services', []));
        }
    };

    const fetchData = async () => {
        const [resCharities, resCollectors, resDrivers] = await Promise.all([
            http.getJSON('/allCharities', false, true),
            http.getJSON('/collectors', false, true),
            http.getJSON('/users/getAllDrivers', false, true)
        ]);
        if (resCharities.ok) {
            setAllCharities(_.get(resCharities, 'data.charities', []));
        }
        if (resCollectors.ok) {
            const allCollectors = _.get(resCollectors, 'data.collectors', []);
            setAllProcessors(
                _.filter(allCollectors, c => _.get(c, 'configuration.enableCounting', true) && !c.disabled)
            );
            setAllTransporters(
                _.filter(allCollectors, c => _.get(c, 'configuration.enablePickups', true) && !c.disabled)
            );
        }
        if (resDrivers.ok) {
            setAllDrivers(_.get(resDrivers, 'data.drivers', []));
        }
    };

    const getCompletedBottleDrives = async () => {
        const resCompleteBottleDrives = await http.getJSON('/bottleDrives/completedBottleDrives', false, true);
        if (resCompleteBottleDrives.ok) {
            const bottleDriveIds = _.map(_.get(resCompleteBottleDrives, 'data', []), item => item._id.toString());
            setCompletedBottleDriveIds(bottleDriveIds);
        }
    };

    useEffect(() => {
        let newSelectedBottleDrive = _.find(filteredData, { _id: selectedBottleDriveId });
        if (_.isNil(newSelectedBottleDrive)) {
            newSelectedBottleDrive = _.find(filteredData2, { _id: selectedBottleDriveId });
        }
        if (!_.isNil(newSelectedBottleDrive)) {
            setSelectedBottleDrive(newSelectedBottleDrive);
        }
    }, [filteredData, filteredData2, filteredData3]);

    useEffect(() => {
        getCompletedBottleDrives();
        getServices();
        fetchData();
    }, []);

    useEffect(() => {
        getCompletedBottleDrives();
    }, [data, data2, data3]);

    const handleView = _id => {
        const URL = `/customers/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    const handleSubmit = e => {
        handleSearch(e);
        handleSearch2(e);
        handleSearch3(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
        if (reviewedFiltersOpen) {
            setReviewedFiltersOpen(false);
        }
    };

    const handleSchedulePickupDialog = bottleDrive => {
        setSelectedBottleDrive(bottleDrive);
        setShowSchedulePickupDialog(true);
    };

    const handleReject = async () => {
        const res = await http.post('/bottleDrives/reject', {
            bottleDriveIds: bottleDriveIdsToReject,
            rejectReason
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
                getData3();
            }, 50);
            onSnackbar('Bottle drive(s) rejected.');
            setCheckedPendingBottleDrives([]);
            setBottleDriveIdsToReject([]);
            setShowRejectReasonDialog(false);
            setRejectReason('');
            setRefreshPendingBottleDriveCount(true);
        } else {
            onSnackbar('Unable to reject bottle drive(s)', 'error');
        }
    };

    const handleRejectDialog = bottleDriveIds => {
        setBottleDriveIdsToReject(bottleDriveIds);
        setShowRejectReasonDialog(true);
    };

    const handleUnreject = async bottleDriveIds => {
        const res = await http.post('/bottleDrives/unreject', { bottleDriveIds });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
                getData3();
            }, 50);
            onSnackbar('Bottle drive(s) unrejected.');
            setCheckedRejectedBottleDrives([]);
            setRefreshPendingBottleDriveCount(true);
        } else {
            onSnackbar('Unable to unreject bottle drive(s)', 'error');
        }
    };

    const onChangeFilterHeader = e => {
        handleChangeFilterHeaders3(e);
        handleChangeFilterHeaders2(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeaders2(all)(e);
        handleAllFilterHeaders3(all)(e);
    };

    const handleChangeAll = e => {
        handleChange(e);
        handleChange2(e);
        handleChange3(e);
    };

    function handlePendingCheckbox() {
        if (
            checkedPendingBottleDrives.length === totalDataEntries ||
            checkedPendingBottleDrives.length === rowsPerPage
        ) {
            setCheckedPendingBottleDrives([]);
        } else {
            let newCheckedPendingBottleDrives = Array.from(filteredData, bottleDrive => {
                return bottleDrive._id;
            });
            setCheckedPendingBottleDrives(newCheckedPendingBottleDrives);
        }
    }

    function handleRejectedCheckbox() {
        if (
            checkedRejectedBottleDrives.length === totalDataEntries2 ||
            checkedRejectedBottleDrives.length === rowsPerPage
        ) {
            setCheckedRejectedBottleDrives([]);
        } else {
            let newcheckedRejectedBottleDrives = Array.from(filteredData2, bottleDrive => {
                return bottleDrive._id;
            });
            setCheckedRejectedBottleDrives(newcheckedRejectedBottleDrives);
        }
    }

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    return (
        <>
            <SchedulePickupDialog
                open={showSchedulePickupDialog}
                setQuickRegisterFormOpen={setQuickRegisterFormOpen}
                lang={lang}
                theme={theme}
                selectedBottleDrive={selectedBottleDrive}
                onClose={() => {
                    setShowSchedulePickupDialog(false);
                }}
                google={google}
                http={http}
                onSnackbar={onSnackbar}
                charities={allCharities}
                processors={allProcessors}
                transporters={allTransporters}
                receivers={allDrivers}
                handleView={handleView}
                afterSubmitSuccess={() => {
                    setTimeout(() => {
                        getData();
                        getData2();
                        getData3();
                    }, 50);
                    setShowSchedulePickupDialog(false);
                    setSelectedBottleDrive(null);
                    onSnackbar('Successfully scheduled bottle drive pickup');
                    setRefreshPendingBottleDriveCount(true);
                }}
            />

            <QuickRegisterFormDialog
                open={quickRegisterFormOpen}
                google={google}
                collectorObj={null}
                onClose={() => setQuickRegisterFormOpen(false)}
                onSubmitForm={handleRegisterUser}
                defaultCoordinates={defaultCoordinates}
                services={services}
                startingValues={{
                    name: {
                        first: _.get(selectedBottleDrive, 'contactName', ''),
                        last: ''
                    },
                    location: _.get(selectedBottleDrive, 'location'),
                    email: _.get(selectedBottleDrive, 'contactEmail', ''),
                    phone: _.get(selectedBottleDrive, 'contactPhone', '')
                }}
            />
            <Dialog open={showRejectReasonDialog}>
                <DialogContent>
                    <Typography variant="h6">
                        Reason for Rejecting
                        {bottleDriveIdsToReject.length > 1 && ` ${bottleDriveIdsToReject.length} Bottle Drives`}
                    </Typography>
                    <TextField
                        data-cy="account-status-warning-dialog-reason-input"
                        label={`Reason`}
                        error={!rejectReason}
                        value={rejectReason}
                        onChange={e => setRejectReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        helperText="Customer will see this text."
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" size="small" onClick={() => setShowRejectReasonDialog(false)}>
                        {loc('nevermind', lang)}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        disabled={!rejectReason}
                        onClick={() => handleReject()}
                        data-cy="account-status-warning-dialog-confirm"
                    >
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    <RenderSearch
                                        filtersOpen={filtersOpen}
                                        handleFiltersOpen={handleFiltersOpen}
                                        collapseSearch={collapseSearch}
                                        theme={theme}
                                    >
                                        <div style={{ flexDirection: 'row', display: 'flex', 'flex-wrap': 'wrap' }}>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders2}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {rejectedHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search2 || ''}
                                                    onChange={handleChangeAll}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                <Button
                                                    color="primary"
                                                    disabled={loading || loading2 || loading3}
                                                    variant="contained"
                                                    data-cy="dashboard-search-button"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Icon>search</Icon>
                                                </Button>
                                            </>
                                        </div>
                                    </RenderSearch>

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()} data-cy="bottleDrive-filters">
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {!_.isNil(completedBottleDriveIds) && (
                <>
                    <DashBoardTable
                        title="Pending Bottle Drives"
                        loading={loading}
                        headers={pendingHeaders}
                        filteredHeaders={_.intersection(filteredHeaders2, pendingHeaders.map(h => h.name))}
                        length={totalDataEntries}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={(e, newPage) => {
                            handleChangePage(e, newPage);
                            setCheckedPendingBottleDrives([]);
                        }}
                        handleChangeRowsPerPage={e => {
                            handleChangeRowsPerPage(e);
                            setCheckedPendingBottleDrives([]);
                        }}
                        refresh={getData}
                        checkbox
                        handleCheckbox={handlePendingCheckbox}
                        checkedItems={checkedPendingBottleDrives.length}
                        cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                        customActionAlign="left"
                        customAction={
                            <Button
                                data-cy="reject"
                                variant="contained"
                                color="secondary"
                                disabled={checkedPendingBottleDrives.length === 0}
                                style={{}}
                                onClick={e => {
                                    e.preventDefault();
                                    handleRejectDialog(checkedPendingBottleDrives);
                                }}
                            >
                                Reject
                            </Button>
                        }
                        customActionStyle={{ paddingTop: 0 }}
                    >
                        {filteredData &&
                            filteredData
                                .filter(bottleDrive => !completedBottleDriveIds.includes(bottleDrive._id.toString()))
                                .map(bottleDrive => {
                                    return (
                                        <BottleDriveRows
                                            bottleDrive={bottleDrive}
                                            filteredHeaders={_.intersection(
                                                filteredHeaders2,
                                                pendingHeaders.map(h => h.name)
                                            )}
                                            checkedList={checkedPendingBottleDrives}
                                            setCheckedList={setCheckedPendingBottleDrives}
                                            handleView={handleView}
                                            theme={theme}
                                            handleReject={handleRejectDialog}
                                            handleUnreject={handleUnreject}
                                            onSchedulePickup={handleSchedulePickupDialog}
                                        />
                                    );
                                })}
                    </DashBoardTable>

                    <DashBoardTable
                        title="Completed Bottle Drives"
                        loading={loading3}
                        headers={completedHeaders}
                        filteredHeaders={_.intersection(filteredHeaders2, completedHeaders.map(h => h.name))}
                        length={totalDataEntries3}
                        page={page3}
                        rowsPerPage={rowsPerPage3}
                        handleChangePage={(e, newPage) => {
                            handleChangePage3(e, newPage);
                            setCheckedRejectedBottleDrives([]);
                        }}
                        handleChangeRowsPerPage={e => {
                            handleChangeRowsPerPage3(e);
                            setCheckedRejectedBottleDrives([]);
                        }}
                        refresh={getData3}
                        cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    >
                        {filteredData3 &&
                            filteredData3
                                .filter(bottleDrive => completedBottleDriveIds.includes(bottleDrive._id.toString()))
                                .map(bottleDrive => {
                                    return (
                                        <BottleDriveRows
                                            bottleDrive={bottleDrive}
                                            filteredHeaders={_.intersection(
                                                filteredHeaders2,
                                                completedHeaders.map(h => h.name)
                                            )}
                                            checkedList={[]}
                                            setCheckedList={() => {}}
                                            handleView={handleView}
                                            theme={theme}
                                            handleReject={handleRejectDialog}
                                            handleUnreject={handleUnreject}
                                            onSchedulePickup={handleSchedulePickupDialog}
                                            checkbox={false}
                                        />
                                    );
                                })}
                    </DashBoardTable>

                    <DashBoardTable
                        title="Rejected Bottle Drives"
                        loading={loading2}
                        headers={rejectedHeaders}
                        filteredHeaders={filteredHeaders2}
                        length={totalDataEntries2}
                        page={page2}
                        rowsPerPage={rowsPerPage2}
                        handleChangePage={(e, newPage) => {
                            handleChangePage2(e, newPage);
                            setCheckedRejectedBottleDrives([]);
                        }}
                        handleChangeRowsPerPage={e => {
                            handleChangeRowsPerPage2(e);
                            setCheckedRejectedBottleDrives([]);
                        }}
                        refresh={getData2}
                        checkbox
                        handleCheckbox={handleRejectedCheckbox}
                        checkedItems={checkedRejectedBottleDrives.length}
                        cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                        customActionAlign="left"
                        customActionStyle={{ paddingTop: 0 }}
                        customAction={
                            <Button
                                data-cy="unreject"
                                variant="contained"
                                color="secondary"
                                disabled={checkedRejectedBottleDrives.length === 0}
                                style={{}}
                                onClick={() => {
                                    warnAction(() => {
                                        handleUnreject(checkedRejectedBottleDrives);
                                    }, `Unreject ${checkedRejectedBottleDrives.length} bottle drive(s)?`);
                                }}
                            >
                                Unreject
                            </Button>
                        }
                    >
                        {filteredData2 &&
                            filteredData2.map(bottleDrive => {
                                return (
                                    <BottleDriveRows
                                        bottleDrive={bottleDrive}
                                        filteredHeaders={filteredHeaders2}
                                        checkedList={checkedRejectedBottleDrives}
                                        setCheckedList={setCheckedRejectedBottleDrives}
                                        handleView={handleView}
                                        theme={theme}
                                        handleReject={handleRejectDialog}
                                        handleUnreject={handleUnreject}
                                        onSchedulePickup={handleSchedulePickupDialog}
                                    />
                                );
                            })}
                    </DashBoardTable>
                </>
            )}
        </>
    );
};

export default withTheme()(BottleDrives);

const cellStyle = { whiteSpace: 'nowrap', paddingLeft: 12, paddingRight: 12 };
const BottleDriveRows = props => {
    const {
        bottleDrive,
        filteredHeaders,
        headers,
        setCheckedList,
        checkedList,
        handleView,
        handleReject,
        handleUnreject,
        onSchedulePickup,
        checkbox = true
    } = props;
    const {
        _id,
        date,
        startTime,
        endTime,
        customer,
        charity,
        collector,
        contactName,
        contactEmail,
        contactPhone,
        location,
        comment,
        charityName,
        collectorName,
        rejected,
        pickup
    } = bottleDrive;
    let allHeaders = headers;

    const isPastDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    const isNearDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .add(3, 'days')
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    return (
        <TableRow key={_id}>
            {checkbox && (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={checkedList.includes(_id)}
                        onChange={e => {
                            if (!e.target.checked) {
                                setCheckedList(
                                    checkedList.filter(element => {
                                        return element !== _id;
                                    })
                                );
                            } else {
                                setCheckedList([...checkedList, _id]);
                            }
                        }}
                    />
                </TableCell>
            )}
            {filteredHeaders.includes('Actions') && (
                <TableCell
                    style={{ ...cellStyle, paddingLeft: 0, paddingRight: 0 }}
                    padding={_.get(_.find(allHeaders, { name: 'Actions' }), 'padding', 'default')}
                >
                    <Tooltip title="View Customer">
                        <IconButton
                            onClick={() => (_.isNil(customer) ? {} : handleView(customer._id))}
                            disabled={_.isNil(customer)}
                        >
                            <Icon>visibility</Icon>
                        </IconButton>
                    </Tooltip>
                    {!_.isNil(pickup) && !rejected && (
                        <Tooltip title="Pickup Booked">
                            <span>
                                <IconButton disabled>
                                    <Icon style={{ color: 'green' }}>event_available</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {_.isNil(pickup) && !rejected && (
                        <Tooltip title="Schedule Pickup">
                            <IconButton
                                onClick={() => {
                                    onSchedulePickup(bottleDrive);
                                }}
                            >
                                <Icon>calendar_month</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {_.isNil(pickup) &&
                        (rejected ? (
                            <Tooltip title="Unreject">
                                <IconButton
                                    onClick={() => {
                                        handleUnreject([bottleDrive._id]);
                                    }}
                                >
                                    <Icon>check</Icon>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Reject">
                                <IconButton
                                    onClick={() => {
                                        handleReject([bottleDrive._id]);
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        ))}
                </TableCell>
            )}
            {filteredHeaders.includes('Reject Reason') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {_.get(bottleDrive, 'rejectReason', 'N/A')}
                </TableCell>
            )}
            {filteredHeaders.includes('Date^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Date' }), 'padding', 'default')}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!rejected &&
                            _.isNil(pickup) &&
                            (isPastDate ? (
                                <Tooltip title="Date for bottle drive has passed">
                                    <Icon style={{ color: colors.red[500] }}>error</Icon>
                                </Tooltip>
                            ) : isNearDate ? (
                                <Tooltip title="Date for bottle drive is coming up">
                                    <Icon style={{ color: colors.amber[500] }}>warning</Icon>
                                </Tooltip>
                            ) : (
                                ''
                            ))}
                        {moment(date)
                            .tz(
                                _.get(
                                    location,
                                    'timezone',
                                    _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                )
                            )
                            .format('MMM D, YYYY')}
                    </div>
                </TableCell>
            )}
            {filteredHeaders.includes('Start Time^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {moment(startTime)
                        .tz(
                            _.get(
                                location,
                                'timezone',
                                _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                            )
                        )
                        .format('h:mm A')}
                </TableCell>
            )}
            {filteredHeaders.includes('End Time^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {moment(endTime)
                        .tz(
                            _.get(
                                location,
                                'timezone',
                                _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                            )
                        )
                        .format('h:mm A')}
                </TableCell>
            )}
            {filteredHeaders.includes('Contact Name^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactName}
                </TableCell>
            )}

            {filteredHeaders.includes('Contact Email^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactEmail}
                </TableCell>
            )}

            {filteredHeaders.includes('Contact Phone') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactPhone}
                </TableCell>
            )}
            {filteredHeaders.includes('Address^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        maxWidth: 250,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Address^' }), 'padding', 'default')}
                >
                    <Tooltip title={_.get(location, 'description', 'N/A')} interactive>
                        <p style={{ width: 200 }}>{_.get(location, 'description', 'N/A')}</p>
                    </Tooltip>
                </TableCell>
            )}
            {filteredHeaders.includes('Comment/Directions') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        minWidth: 200,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {comment}
                </TableCell>
            )}

            {filteredHeaders.includes('Referring Collector^') && (
                <TableCell style={cellStyle}>{_.get(collector, 'name', collectorName)}</TableCell>
            )}

            {filteredHeaders.includes('Charity^') && (
                <TableCell style={cellStyle}>{_.get(charity, 'name', charityName)}</TableCell>
            )}
        </TableRow>
    );
};

const RenderSearch = ({ children, filtersOpen, handleFiltersOpen, collapseSearch, theme }) => {
    if (collapseSearch) {
        return (
            <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: theme.spacing.unit * 2
                    }}
                >
                    {children}
                </div>
            </Menu>
        );
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
            </div>
        );
    }
};

function SchedulePickupDialog(props) {
    const {
        open,
        lang,
        theme,
        selectedBottleDrive,
        onClose,
        http,
        afterSubmitSuccess,
        handleView,
        transporters,
        processors,
        charities,
        receivers,
        google,
        setQuickRegisterFormOpen
    } = props;

    const [date, setDate] = useState(moment(_.get(selectedBottleDrive, 'date')));
    const [customer, setCustomer] = useState(_.get(selectedBottleDrive, 'customer'));
    const [customTime, setCustomTime] = useState(_.get(selectedBottleDrive, 'endTime'));
    const [estimatedBags, setEstimatedBags] = useState(_.get(selectedBottleDrive, 'estimatedBags'));
    const [comment, setComment] = useState(_.get(selectedBottleDrive, 'comment'));
    const [charity, setCharity] = useState(_.get(selectedBottleDrive, 'charity._id'));
    const [charityId, setCharityId] = useState(_.get(selectedBottleDrive, 'charity'));
    const [initialCharitySuggestion, setInitialCharitySuggestion] = useState(_.get(selectedBottleDrive, 'charityName'));
    const [subdivision, setSubdivision] = useState(_.get(selectedBottleDrive, 'subdivision'));
    const [transporter, setTransporter] = useState(null);
    const [receiver, setReceiver] = useState(null);
    const [processor, setProcessor] = useState(_.get(selectedBottleDrive, 'collector'));
    const [location, setLocation] = useState(_.get(selectedBottleDrive, 'location'));

    const [driversSelectable, setDriversSelectable] = useState([]);
    const [charitySelectDialogOpen, setCharitySelectDialogOpen] = useState(false);
    const [charityInfoDialogOpen, setCharityInfoDialogOpen] = useState(false);
    const [charityInfo, setCharityInfo] = useState('');
    const [userSearchString, setUserSearchString] = useState(_.get(selectedBottleDrive, 'contactEmail'));
    const [userSearchError, setUserSearchError] = useState('');
    const handleAddressChange = () => {
        setLocation(getEmptyLocation());
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(userSearchString)) {
            handleGetUser();
        }
    };

    const handleGetUser = async () => {
        let res = await http.getJSON(`/users/getCustomer/${userSearchString}`);
        if (res.ok && !_.isNil(res.data.user)) {
            setCustomer(res.data.user);
        } else {
            setUserSearchError(_.get(res, 'data.message', 'Search string did not match any registered users.'));
        }
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        let locationUpdated = createLocation(
            place,
            suggestion.description,
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        setLocation(locationUpdated);
    };

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    useEffect(() => {
        setDate(moment(_.get(selectedBottleDrive, 'date')));
        setUserSearchString(_.get(selectedBottleDrive, 'contactEmail'));
        setUserSearchError('');
        setCustomer(_.get(selectedBottleDrive, 'customer'));
        setCustomTime(_.get(selectedBottleDrive, 'endTime'));
        setEstimatedBags(_.get(selectedBottleDrive, 'estimatedBags'));
        setComment(_.get(selectedBottleDrive, 'comment'));
        setCharity(_.get(selectedBottleDrive, 'charity'));
        setInitialCharitySuggestion(_.get(selectedBottleDrive, 'charityName'));
        setSubdivision(_.get(selectedBottleDrive, 'subdivision', null));
        setTransporter(_.get(selectedBottleDrive, 'collector._id', null));
        setReceiver(null);
        setProcessor(_.get(selectedBottleDrive, 'collector'));
        setLocation(_.get(selectedBottleDrive, 'location'));
    }, [selectedBottleDrive]);

    useEffect(() => {
        if (_.isNil(transporter)) {
            setDriversSelectable([]);
        } else {
            setDriversSelectable(
                _.filter(
                    receivers,
                    d => !d.banned && !d.disabled && _.get(d, 'collector._id', '').toString() === transporter.toString()
                )
            );
        }
    }, [transporter]);

    const handleCharityInfoDialog = (state, _id) => () => {
        setCharityInfoDialogOpen(state);
        setCharityInfo(_.find(charities, charity => charity._id === _id));
    };

    const handleCharityPreferred = _id => e => {
        setCharitySelectDialogOpen(false);
        setCharity(_.find(charities, c => c._id === _id));
        setCharityId(_id);
    };

    const handleRequestPickup = async () => {
        let values = {
            pickup_id: null,
            date: moment(date)
                .set('hour', moment(customTime).hour())
                .set('minute', moment(customTime).minute()),
            placeAtStartOfTrip: false,
            charity,
            subdivision,
            taxReceiptRequested: _.get(customer, 'receiveTaxReceipts'),
            location,
            payload: { beverage: estimatedBags },
            comment,
            recurring: false,
            frequency: null,
            phone: _.get(customer, 'phone'),
            manualTimeOverride: true,
            customTime,
            isBottleDrive: true,
            timePreference: 'None',
            efficiencyModifier: 1,
            transporterCollector: transporter,
            collector: transporter,
            overrideProcessor: processor,
            receiver,
            donatingToBottleDrive: true,
            bottleDriveId: selectedBottleDrive._id,
            pickupType: _.get(customer, 'location.locationType', 'Residential')
        };
        const resBottleDrive = await http.post(`/bottleDrives/${selectedBottleDrive._id}/update`, {
            customer,
            charity,
            collector: processor
        });
        const res = await http.post(`/pickups/requestPickup/${customer._id}`, values);
        if (res.ok) {
            afterSubmitSuccess();
        }
    };

    const isPastDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle style={{ paddingBottom: 14 }}>Confirm Bottle Drive Details</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={formControlStyle}>
                    {_.isNil(customer) ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                verticalAlign: 'center',
                                paddingTop: theme.spacing.unit
                            }}
                        >
                            <TextField
                                data-cy="link-account-customer-id-search"
                                label="Customer ID, Email, or Phone"
                                value={userSearchString}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                fullWidth
                                helperText={!_.isEmpty(userSearchError) ? userSearchError : null}
                                error={!_.isEmpty(userSearchError)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MDIcon path={mdiAccountSearch} size={1} color={colors.blue[500]} />
                                        </InputAdornment>
                                    )
                                }}
                                style={{
                                    marginTop: theme.spacing.unit / 2,
                                    marginRight: theme.spacing.unit
                                }}
                                onChange={e => {
                                    setUserSearchError('');
                                    setUserSearchString(e.target.value);
                                }}
                                onKeyPress={handleKeyPress}
                            />
                            <Button
                                data-cy={`link-account-customer-id-search-submit`}
                                onClick={() => handleGetUser()}
                                variant="contained"
                                color="primary"
                            >
                                Link
                            </Button>
                            <Button
                                data-cy={`link-account-customer-id-search-submit`}
                                onClick={() => setQuickRegisterFormOpen(true)}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: theme.spacing.unit }}
                            >
                                <Icon>add</Icon> New
                            </Button>
                        </div>
                    ) : (
                        <List
                            style={{
                                margin: 0
                            }}
                        >
                            <ListItem
                                style={{
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: theme.shape.borderRadius,
                                    whiteSpace: 'normal'
                                }}
                            >
                                <Avatar
                                    style={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white'
                                    }}
                                >
                                    {_.get(customer, 'name.first[0]', '?')}
                                </Avatar>
                                <ListItemText
                                    primary={
                                        <span
                                            style={{
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                display: 'block',
                                                paddingRight: 'inherit'
                                            }}
                                        >
                                            {`${_.get(customer, 'name.first', 'N/A')} ${_.get(
                                                customer,
                                                'name.last',
                                                ''
                                            )}`}
                                        </span>
                                    }
                                    style={{
                                        whiteSpace: 'normal',
                                        paddingRight: theme.spacing.unit * 3,
                                        position: 'relative'
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IconButton onClick={() => handleView(customer._id)}>
                                            <Icon>visibility</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => setCustomer(null)}>
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </div>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )}
                    <FormHelperText style={{ marginTop: 0 }}>
                        Contact email was{' '}
                        <span style={{ fontWeight: 'bold' }}>{_.get(selectedBottleDrive, 'contactEmail', 'N/A')}</span>{' '}
                        and contact phone was{' '}
                        <span style={{ fontWeight: 'bold' }}>{_.get(selectedBottleDrive, 'contactPhone', 'N/A')}</span>
                    </FormHelperText>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <FormControl fullWidth style={formControlStyle}>
                        <DatePicker
                            variant="outlined"
                            timezone={process.env.REACT_APP_REGION_TIMEZONE}
                            value={date}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>date_range</Icon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={date => setDate(date)}
                            disablePast
                        />
                    </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <FormControl fullWidth style={formControlStyle}>
                        <TimePicker
                            fullWidth
                            label={loc('endTime', lang)}
                            variant="outlined"
                            value={customTime}
                            format="h:mm A"
                            onChange={time => setCustomTime(time)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>schedule</Icon>
                                    </InputAdornment>
                                )
                            }}
                            data-cy="bottle-drive-end-time-input"
                            error={_.isNil(customTime) || _.isEmpty(customTime)}
                            helperText={
                                (_.isNil(customTime) || _.isEmpty(customTime)) && 'You must select a pickup time.'
                            }
                            FormHelperTextProps={{ 'data-cy': `bottle-drive-end-time-input-helper-txt` }}
                        />
                    </FormControl>
                </div>

                <GMapsAutocomplete
                    data-cy="registration-address-input"
                    google={google}
                    location={location}
                    label={loc('address', lang)}
                    selectedValue={_.get(location, 'description')}
                    types={['address']}
                    error={{
                        fail: _.isNil(location),
                        reason: 'You must set a location.'
                    }}
                    onChange={handleAddressChange}
                    onSuggestionSelected={handleSuggestionSelected}
                    suggestionsOccupyVerticalSpace={true}
                    style={formControlStyle}
                />

                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        multiline
                        label={loc('pickupDialog41', lang)}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ 'data-cy': `bottle-drive-comment-input` }}
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-comment-input-helper-txt` }}
                    />
                </FormControl>

                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        value={estimatedBags}
                        onChange={e => setEstimatedBags(e.target.value)}
                        label={loc('registrationBottleDrive1', lang)}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ 'data-cy': `bottle-drive-bags-input` }}
                        error={estimatedBags < 40}
                        helperText={estimatedBags < 40 && 'Must have at least 40 bags'}
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-bags-input-helper-txt` }}
                    />
                </FormControl>

                <FormControl fullWidth data-cy="charity-select-organization-select" style={formControlStyle}>
                    <TextField
                        id="outlined-select-charity"
                        data-cy="charity-select-org-select"
                        value={_.isNil(charity) ? loc('noDonation', lang) : _.get(charity, 'name')}
                        onClick={() => setCharitySelectDialogOpen(true)}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon>arrow_drop_down</Icon>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon style={{ color: colors.red[500] }}>favorite</Icon>
                                </InputAdornment>
                            )
                        }}
                        label={loc('organizationName', lang, {
                            organizationName: terms.ORGANIZATION_NAME
                        })}
                        variant="outlined"
                    />
                    <FormHelperText>
                        Donation preference was{' '}
                        <span style={{ fontWeight: 'bold' }}>{_.get(selectedBottleDrive, 'charityName', 'N/A')}</span>
                    </FormHelperText>
                </FormControl>
                <Collapse in={_.get(charity, 'subdivisions', []).length > 0}>
                    <FormControl
                        data-cy="charity-select-select-dropdown"
                        fullWidth
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    >
                        <InputLabel variant="outlined" htmlFor="subdivisionPreferred">
                            {loc('pickupDialog62', lang)}
                        </InputLabel>
                        <Select
                            value={subdivision}
                            onChange={e => setSubdivision(e.target.value)}
                            renderValue={value => `${value}`}
                            input={
                                <OutlinedInput
                                    labelWidth={180}
                                    name="subdivisionPreferred"
                                    id="subdivisionPreferred"
                                    data-cy="charity-select-team-dropdown"
                                    className="select-icon"
                                />
                            }
                        >
                            <MenuItem value={null}>
                                <ListItemText primary="General Donation" style={{ fontStyle: 'italic' }} />
                            </MenuItem>
                            {_.get(charity, 'subdivisions', []).length > 0 &&
                                _.get(charity, 'subdivisions', []).map((subdivision, index) => {
                                    return (
                                        <MenuItem
                                            data-cy={`charity-select-select-dropdown-team-${index}`}
                                            key={subdivision}
                                            value={subdivision}
                                        >
                                            {subdivision}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Collapse>
                <CharitySelectDialog
                    open={charitySelectDialogOpen}
                    charities={charities}
                    onChange={handleCharityPreferred}
                    onInfoDialog={handleCharityInfoDialog}
                    onClose={() => setCharitySelectDialogOpen(false)}
                    hideSubdivisions={true}
                    initialSearchValue={initialCharitySuggestion}
                />

                <CharityInfoDialog
                    open={charityInfoDialogOpen}
                    charity={charityInfo}
                    onClose={handleCharityInfoDialog(false)}
                />
                <FormControl
                    fullWidth
                    data-cy="create-edit-trip-dialog-collector-select"
                    style={{ marginTop: theme.spacing.unit * 2 }}
                >
                    <InputLabel htmlFor="transporterCollector" variant="outlined" shrink>
                        Transporter
                    </InputLabel>
                    <Select
                        value={transporter}
                        onChange={e => setTransporter(e.target.value)}
                        inputProps={{ id: 'transporterCollector', name: 'transporterCollector' }}
                        input={
                            <OutlinedInput
                                name="transporterCollector"
                                id="transporterCollector"
                                labelWidth={'Transporter'.length * theme.spacing.unit}
                                notched
                            />
                        }
                    >
                        {transporters.map(collector => {
                            return (
                                <MenuItem
                                    key={collector._id}
                                    value={collector._id}
                                    data-cy={`create-edit-trip-transporterCollector-${collector.name
                                        .replace(/\s*\W*\s+/g, '-')
                                        .toLowerCase()}-li`}
                                >
                                    {collector.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {_.isEmpty(transporter) && (
                    <Typography
                        style={{
                            marginTop: theme.spacing.unit / 2,
                            color: theme.palette.error.main,
                            marginLeft: 5
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: theme.spacing.unit / 2,
                                lineHeight: 1.25,
                                color: theme.palette.error.main
                            }}
                        >
                            warning
                        </Icon>{' '}
                        No transporter selected!
                    </Typography>
                )}
                <FormControl
                    fullWidth
                    data-cy="create-edit-trip-dialog-collector-select"
                    style={{ marginTop: theme.spacing.unit * 2 }}
                >
                    <InputLabel htmlFor="collector" variant="outlined" shrink>
                        Processor
                    </InputLabel>
                    <Select
                        value={processor}
                        onChange={e => setProcessor(e.target.value)}
                        inputProps={{ id: 'collector', name: 'collector' }}
                        input={
                            <OutlinedInput
                                name="collector"
                                id="collector"
                                labelWidth={'Processor'.length * theme.spacing.unit}
                                notched
                            />
                        }
                    >
                        {processors.map(collector => {
                            return (
                                <MenuItem
                                    key={collector._id}
                                    value={collector._id}
                                    data-cy={`create-edit-trip-${collector.name
                                        .replace(/\s*\W*\s+/g, '-')
                                        .toLowerCase()}-li`}
                                >
                                    {collector.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>
                        Referring Depot was{' '}
                        <span style={{ fontWeight: 'bold' }}>{_.get(selectedBottleDrive, 'collectorName', 'N/A')}</span>
                    </FormHelperText>
                </FormControl>
                {_.isEmpty(processor) && (
                    <Typography
                        style={{
                            marginTop: theme.spacing.unit / 2,
                            color: colors.amber[700],
                            marginLeft: 5
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: theme.spacing.unit / 2,
                                lineHeight: 1.25,
                                color: colors.amber[700]
                            }}
                        >
                            warning_amber
                        </Icon>{' '}
                        No processor selected
                    </Typography>
                )}
                <FormControl
                    fullWidth
                    data-cy="create-edit-trip-dialog-driver-select"
                    style={{ marginTop: theme.spacing.unit * 2 }}
                >
                    <InputLabel htmlFor="driver" variant="outlined" shrink>
                        Driver
                    </InputLabel>
                    <Select
                        disabled={_.isEmpty(transporter)}
                        value={receiver}
                        onChange={e => {
                            setReceiver(e.target.value);
                        }}
                        inputProps={{ id: 'driver', name: 'driver' }}
                        input={
                            <OutlinedInput
                                name="driver"
                                id="driver"
                                labelWidth={'Driver'.length * theme.spacing.unit}
                                notched
                            />
                        }
                    >
                        {driversSelectable.map(driver => {
                            return (
                                <MenuItem
                                    key={driver._id}
                                    value={driver._id}
                                    data-cy={`${getReceiverName(driver)
                                        .replace(/\s/g, '-')
                                        .toLowerCase()}-create-edit-trip-driver-li`}
                                >
                                    {getReceiverName(driver)} ➡{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {_.get(_.find(processors, c => c._id.toString() === processor), 'code', 'N/A')}
                                    </span>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {_.isEmpty(receiver) && (
                    <Typography
                        style={{
                            marginTop: theme.spacing.unit / 2,
                            color: colors.amber[700],
                            marginLeft: 5,
                            marginBottom: 5
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: theme.spacing.unit / 2,
                                lineHeight: 1.25,
                                color: colors.amber[700]
                            }}
                        >
                            warning_amber
                        </Icon>{' '}
                        No driver selected
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
                <Button
                    data-cy="bottle-drive-submit-btn"
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={handleRequestPickup}
                    disabled={_.isNil(customer) || isPastDate || estimatedBags < 40 || _.isNil(transporter)}
                >
                    Schedule Pickup
                </Button>
            </DialogActions>
        </Dialog>
    );
}
