import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { withTheme } from '@material-ui/core/styles';

import { formatsSecondsToTime } from 'utils/misc';
import { Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../../localizations/localizationHandler';
import { useEffect } from 'react';

function TwoFactorAuthenticationDialog({
    theme,
    open,
    onCancel,
    onLogOut,
    onSubmit,
    onClose,
    twoFactorAuthenticationPhoneNumber,
    onSnackbar,
    user
}) {
    const { lang } = useContext(LocalizationContext);

    const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState('');
    const [attemptingTwoFactorAuthentication, setAttemptingTwoFactorAuthentication] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [enableSendTime, setEnableSendTime] = useState(null);
    const [authenticationSID, setAuthenticationSID] = useState(null);
    const [codeInput, setCodeInput] = useState(null);
    const [codeSent, setCodeSent] = useState(false);

    const disableSendButton = timeoutInSeconds => {
        setResendDisabled(true);
        setTimeout(() => setResendDisabled(false), timeoutInSeconds * 1000);
    };

    const handleSendVerificationCode = async (channel = 'sms') => {
        let body = {
            twoFactorAuthenticationPhoneNumber,
            channel,
            authenticationSID,
            user_id: user._id
        };
        fetch(process.env.REACT_APP_API_URL + '/multipurposeSendTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    const json = await res.json();
                    let currentTime = moment(new Date());
                    onSnackbar(loc('authCodeSent', lang));
                    setAuthenticationSID(json.authenticationSID);
                    setEnableSendTime(currentTime.add(json.timeUntilNextRetry, 'seconds'));
                    disableSendButton(json.timeUntilNextRetry);
                    if (codeInput) {
                        codeInput.focus();
                    }
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = loc('badRequest', lang); // error message to be generated locally
                    }
                    onSnackbar(message, 'error');
                } else if (res.status === 429) {
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    onLogOut();
                }
            })
            .catch(err => {});
        setCodeSent(true);
    };

    const handleVerify = async e => {
        e.preventDefault();

        setAttemptingTwoFactorAuthentication(true);
        await onSubmit(twoFactorAuthenticationCode, twoFactorAuthenticationPhoneNumber);

        setTwoFactorAuthenticationCode('');
        setAttemptingTwoFactorAuthentication(false);
    };

    return (
        <Dialog fullWidth open={open} onClose={onCancel}>
            <DialogTitle style={{ paddingTop: 'max(24px, env(safe-area-inset-top, 24px))' }}>
                {loc('twoFactorAuthentication1', lang)}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" color="textSecondary">
                    {loc('twoFactorAuthentication2', lang, {
                        formattedPhoneNumber: formatAndRedactPhoneNumber(twoFactorAuthenticationPhoneNumber)
                    })}
                </Typography>
                {process.env.REACT_APP_ENV === 'TEST' && (
                    <Typography variant="body2" color="error">
                        {loc('registration2fa3', lang)}
                    </Typography>
                )}
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <TextField
                        data-cy="login-2fa-code-input"
                        inputRef={elem => setCodeInput(elem)}
                        // disabled={twoFactorAuthenticationCodeInputDisabled}
                        variant="outlined"
                        name="twoFactorAuthenticationCode"
                        type="numeric"
                        label={loc('registration2fa6', lang)}
                        placeholder="e.g. 123456"
                        value={twoFactorAuthenticationCode}
                        InputProps={{
                            style: { backgroundColor: theme.palette.background.paper }
                        }}
                        onChange={e => setTwoFactorAuthenticationCode(e.target.value)}
                    />
                </FormControl>
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '80%' }}>
                    {loc('registration2fa4', lang)}{' '}
                    <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                        {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                    </a>
                </Typography>
                <div
                    fullWidth
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start'
                    }}
                >
                    <div>
                        <Typography style={{ marginTop: theme.spacing.unit }} variant="body2">
                            <span
                                style={{
                                    color:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? theme.palette.text.secondary
                                            : theme.palette.primary.main,
                                    textDecoration:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? ''
                                            : 'underline',
                                    cursor:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? 'default'
                                            : 'pointer'
                                }}
                                data-cy="2fa-resend-code-via-text"
                                onClick={
                                    resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                        ? () => {}
                                        : e => {
                                              e.preventDefault();
                                              handleSendVerificationCode('sms');
                                          }
                                }
                            >
                                {codeSent
                                    ? `${loc('resendViaText', lang)} >`
                                    : `${loc('twoFactorAuthentication3', lang)} >`}
                            </span>
                        </Typography>
                    </div>
                    <div>
                        <Typography style={{ marginTop: theme.spacing.unit }} variant="body2">
                            <span
                                style={{
                                    color:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? theme.palette.text.secondary
                                            : theme.palette.primary.main,
                                    textDecoration:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? ''
                                            : 'underline',
                                    cursor:
                                        resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                            ? 'default'
                                            : 'pointer'
                                }}
                                data-cy="2fa-resend-code-via-voice"
                                onClick={
                                    resendDisabled || isIncompletePhoneNumber(twoFactorAuthenticationPhoneNumber)
                                        ? () => {}
                                        : e => {
                                              e.preventDefault();
                                              handleSendVerificationCode('call');
                                          }
                                }
                            >
                                {codeSent
                                    ? `${loc('resendViaVoice', lang)} >`
                                    : `${loc('twoFactorAuthentication4', lang)} >`}
                            </span>
                        </Typography>
                    </div>
                    {resendDisabled && (
                        <div fullwidth style={{ display: 'inline-flex' }}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ float: 'right', fontSize: '60%' }}
                            >
                                {loc('registration2fa5', lang)} <WaitToRetry time={enableSendTime} />
                            </Typography>
                        </div>
                    )}
                </div>
            </DialogContent>

            <DialogActions>
                <Button data-cy="delete-account-cancel-button" color="primary" onClick={onCancel}>
                    {loc('cancel', lang)}
                </Button>
                <Button
                    data-cy="delete-account-accept-button"
                    color="primary"
                    disabled={
                        _.isNil(twoFactorAuthenticationCode) ||
                        _.isEmpty(twoFactorAuthenticationCode) ||
                        attemptingTwoFactorAuthentication
                    }
                    onClick={handleVerify}
                >
                    {loc('verify', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(TwoFactorAuthenticationDialog);

function formatAndRedactPhoneNumber(phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{2})(\d{6})(\d{2})$/);
    if (match) {
        return '(' + match[1] + '*) ***-**' + match[3];
    }
    return phoneNumber;
}

function WaitToRetry({ time }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(time));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(time));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(time)
            .diff(moment(new Date()), 'seconds')
            .toString()
    );
}

function isIncompletePhoneNumber(phoneNumber) {
    return _.isNil(phoneNumber) || phoneNumber.toString().length < 10;
}
