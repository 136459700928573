import React, { Fragment, useState, useMemo, useContext } from 'react';

import _ from 'lodash';

import { BOTTLE_DRIVE, DEPOT_SEARCH, EXTERNAL_LINK, INSTRUCTIONS, LABELS, QR_SCAN } from 'constants.js';
import { Link } from 'react-router-dom';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';

import * as colors from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { withTheme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import { AVAILABLE_LANGS } from '../../../../constants';
import { getQuickDropName, isEXPRegion } from 'utils/misc';

const ServiceTypeSelection = props => {
    const {
        theme,
        inProgress,
        customer,
        pickupForm,
        serviceSelections,
        servicesLoading,
        onSelectPickupType,
        toggleHelpDialog,
        onRemovePromo,
        onApplyPromo,
        onPromoInputSetDonation,
        promosEnabled
        // allowRequestLabels
    } = props;

    const { lang } = useContext(LocalizationContext);

    let language = lang;
    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
    if (!availableLangs.includes(lang)) {
        language = 'en';
    }

    const { promos, charityPreferred, donate } = pickupForm;

    const [bottleDriveDialogOpen, setBottleDriveDialogOpen] = useState(false);

    const handleBottleDriveDialog = state => () => {
        setBottleDriveDialogOpen(state);
    };

    const handleServiceType = (service, pickupType) => {
        if (service.pickupType === EXTERNAL_LINK) {
            return () => window.open(service.externalLink);
        } else if (service.pickupType === LABELS || service.pickupType === QR_SCAN) {
            return props.onQRDialog(true);
        } else if (service.pickupType === DEPOT_SEARCH) {
            return props.onSearchDialog;
        }
        return onSelectPickupType(pickupType, service);
    };

    const selections = useMemo(
        () =>
            serviceSelections.map((selection, index) => {
                if (_.get(selection, 'hidden')) return;

                const selectionTitle = _.get(selection, `text.${language}.title`, 'title');
                const selectionSecondaryTitle = _.get(selection, `text.${language}.secondaryTitle`, 'secondaryTitle');
                let subServices = _.get(selection, 'subServices', []);
                let pickupType = selection.pickupType;
                let locationType = _.get(customer, 'location.locationType', null);
                if (
                    !(isEXPRegion() && pickupType === getQuickDropName()) && //TODO - Revisit this logic to see if there is a better way to handle this case
                    !_.isNil(locationType) &&
                    !_.isEmpty(subServices)
                ) {
                    if (!_.isNil(_.find(subServices, { pickupType: locationType }))) {
                        pickupType = locationType;
                    }
                }

                if (selection.pickupType === LABELS) {
                    return <div />;
                }
                return (
                    <div key={selection.title}>
                        <ListItem
                            data-cy={`multi-step-dialog-customer-type-${index}`}
                            button
                            disabled={
                                selection.disabled ||
                                (selection.charityLocked &&
                                    (_.isNil(customer.charities) || _.isEmpty(customer.charities)))
                            }
                            style={{
                                marginTop: index > 0 ? theme.spacing.unit : 0,
                                border: `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: theme.shape.borderRadius,
                                whiteSpace: 'normal'
                            }}
                            onClick={handleServiceType(selection, pickupType)}
                        >
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
                                    <MDIcon path={_.get(allIcons, selection.mdiIcon)} size={1} color={'white'} />
                                    {/* <Icon>{selection.icon}</Icon> */}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectionTitle}
                                secondary={
                                    _.isEmpty(promos) || selection === BOTTLE_DRIVE || selection.disablePromos ? (
                                        selectionSecondaryTitle
                                    ) : (
                                        <span style={{ color: colors.green[500] }}>
                                            {_.get(_.first(promos), 'variables.customerSplit', 0) * 100}%{' '}
                                            {loc('appServiceType9', lang)}
                                        </span>
                                    )
                                }
                                style={{
                                    paddingRight: theme.spacing.unit * 8
                                }}
                            />
                            <ListItemSecondaryAction>
                                {!(
                                    selection.charityLocked &&
                                    (_.isNil(customer.charities) || _.isEmpty(customer.charities))
                                ) ? (
                                    <div>
                                        {!selection.hideHelpButton && (
                                            <IconButton
                                                size="small"
                                                disabled={selection.disabled}
                                                onClick={toggleHelpDialog(selection.helpDialogKey)}
                                            >
                                                <Icon>help</Icon>
                                            </IconButton>
                                        )}
                                        <IconButton
                                            size="large"
                                            variant="outlined"
                                            disabled={selection.disabled}
                                            onClick={handleServiceType(selection, pickupType)}
                                            // onClick={onSelectPickupType(pickupType)}
                                        >
                                            <Icon>arrow_forward</Icon>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <Button
                                        data-cy="multistep-dialog-learn-more-button"
                                        color="primary"
                                        disabled={selection.disabled}
                                        onClick={handleBottleDriveDialog(true)}
                                    >
                                        Learn More
                                    </Button>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                        {/*<div>
                            {selection.pickupType === BOTTLE_DRIVE ? (
                                <FormHelperText>
                                    Bottle drives are for large collection events with 50 bags or more
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                            </div>*/}
                    </div>
                );
            }),
        [customer, onSelectPickupType, promos, serviceSelections, theme, toggleHelpDialog]
    );

    return (
        <Fragment>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog2', lang)} iconName="assignment" />
                {promosEnabled && (
                    <FormControl fullWidth>
                        <PromoCodesInput
                            customer={customer}
                            promos={promos}
                            onApplyPromo={onApplyPromo}
                            onRemovePromo={onRemovePromo}
                            charityPreferred={charityPreferred}
                            onCharityPreferred={onPromoInputSetDonation}
                            donate={donate}
                        />
                    </FormControl>
                )}
                {servicesLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: theme.spacing.unit * 2
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <List style={{ marginTop: theme.spacing.unit }}>{selections}</List>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Hidden smUp>
                    <Button data-cy="multistep-dialog-close-button" color="primary" onClick={props.onClose}>
                        {loc('close', lang)}
                    </Button>
                </Hidden>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
            <Dialog open={bottleDriveDialogOpen} fullWidth maxWidth="sm" onClose={handleBottleDriveDialog(false)}>
                <DialogTitle>Bottle drives, school & community events</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Register your charity, school, or community for free to book bottle drive pickups and start
                        collecting residential donations!
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={handleBottleDriveDialog(false)}>
                        Not Now
                    </Button>
                    <Button
                        color="primary"
                        component={Link}
                        style={{ padding: theme.spacing.unit * 1.5 }}
                        to="/register?tab=organization"
                    >
                        Register
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default withTheme()(ServiceTypeSelection);
