import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getQueryStringValue } from 'utils/query';

import { withTheme } from '@material-ui/core/styles';

import { _commodity } from 'std';

import PromoForm from './Forms/PromoForm';

import CRUDTable from './CRUDTable';

import useCRUD from './hooks/useCRUD';
import useGetJSON from 'utils/hooks/useGetJSON';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

function PromoTable(props) {
    const { operator, http, onSnackbar } = props;

    const endpoints = {
        getEndPoint: '/promos/getPromosCRUD', // url to get all documents
        getEditEndPoint: _id => `/promos/${_id}/updatePromo`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/promos/createPromo` // url to add new documents
    };

    const [commodities, setCommodities] = useState([]);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const {
        data,

        startDateFilterStats,
        endDateFilterStats,
        loading,

        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        { key: 'code', header: 'Code' },
        {
            key: 'variables.customerSplit',
            header: 'Refund',
            formatValue: value => `${value * 100}%`
        },
        { key: 'label', header: 'Label' },
        {
            key: 'commodities',
            header: 'Commodities',
            formatValue: value => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {Object.assign([], value)
                        .sort()
                        .map(commodityId => {
                            const commodity = _.find(commodities, { _id: commodityId });
                            const commodityIcon = _.get(commodity, 'iconName');
                            return (
                                <MDIcon
                                    path={_.get(allIcons, commodityIcon)}
                                    size={1}
                                    color={_.get(commodity, 'color')}
                                />
                            );
                        })}
                </span>
            )
        },
        {
            key: 'startDate',
            header: 'Start Date',
            formatValue: value => (_.isNil(value) ? '' : moment(value).format('DD/MM/YYYY'))
        },
        {
            key: 'expirationDate',
            header: 'End Date',
            formatValue: value => (_.isNil(value) ? '' : moment(value).format('DD/MM/YYYY'))
        },
        { key: 'description', header: 'Description', width: '9ch' },
        { key: 'newCustomersOnly', header: 'New Customers Only', isBoolean: true },
        {
            key: 'numberOfUses',
            header: 'Uses Remaining',
            formatValue: value => (_.isNil(value) ? <span style={{ fontSize: '25px' }}>∞</span> : value)
        },
        { key: 'lockByCampaign', header: 'Single Use', isBoolean: true },
        { key: 'internalUseOnly', header: 'Internal', isBoolean: true },
        { key: 'recurring', header: 'Recurring', isBoolean: true },
        { key: 'campaign', header: 'Campaign', width: '9ch' },
        { key: 'charity.name', header: 'Charity' }
    ];

    const { loading: charitiesLoading, data: charities = [] } = useGetJSON('/charities/all', 'charities');

    const {
        fastTrackCharity,
        fastTrack,
        defaultLabel,
        defaultDescription,
        defaultCharity,
        defaultRequireFeautured,
        defaultCustomerSplit,
        defaultLockByCampaign
    } = useMemo(() => getDefaultFormValues(charities), [charities]);

    useEffect(() => {
        if (fastTrack && !editDialogOpen) {
            setEditDialogOpen(fastTrack);
        }
    }, [charities]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/commodities/getAllEnabledCommodities');
            if (res.ok) {
                setCommodities(res.data.commodities);
            }
        })();
    }, []);

    const editForm = (
        <PromoForm
            {...selectedDocument}
            fastTrackCharity={fastTrackCharity}
            fastTrack={fastTrack}
            defaultLabel={defaultLabel}
            defaultDescription={defaultDescription}
            defaultCharity={defaultCharity}
            defaultRequireFeautured={defaultRequireFeautured}
            defaultCustomerSplit={defaultCustomerSplit}
            defaultLockByCampaign={defaultLockByCampaign}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            allData={data}
            charities={charities}
            allCommodities={commodities}
        />
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={promo => !_.get(promo, 'active', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Enabled Promos'}
                disabledHeaderText={'Disabled Promos'}
                loading={loading || charitiesLoading}
                hideDateFilters
            />
        </>
    );
}

export default withTheme()(PromoTable);

const getDefaultFormValues = charities => {
    const fastTrackCharity = _.find(charities, charity => charity._id === getQueryStringValue('fastTrackCharity'));
    const fastTrack = !_.isEmpty(fastTrackCharity);

    return {
        fastTrackCharity,
        fastTrack,
        defaultLabel: fastTrack ? 'FULL REFUND' : '',
        defaultDescription: fastTrack ? fastTrackCharity.name + ' Promo' : '',
        defaultCharity: _.get(fastTrackCharity, '_id', null),
        defaultRequireFeautured: fastTrack ? true : false,
        defaultCustomerSplit: fastTrack ? '100' : '',
        defaultLockByCampaign: fastTrack ? false : true
    };
};
