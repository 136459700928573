import React, { Fragment, useEffect, useState } from 'react';

import _ from 'lodash';

import { BOTTLE_DRIVE } from 'constants.js';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withTheme } from '@material-ui/core/styles';

import { _pickup, _zone, _commodity } from 'std';

import PayloadInput from 'components/InputComponents/PayloadInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { useContext } from 'react';
import BinRequestDialog from '../BinRequestDialog';
import { colors, Icon } from '@material-ui/core';

import { isMXDRegion } from 'utils/misc';

const Payload = props => {
    const {
        theme,
        pickupForm,
        formErrors,
        inProgress,
        customer,
        toggleHelpDialog,
        onNext,
        onBack,
        onPayload,
        onSubPayloads,
        handleRequestBinChange,
        handleBinRequestMessageChange,
        commoditiesAvailable = []
    } = props;

    const [binRequestDialogOpen, setBinRequestDialogOpen] = useState(false);

    const { lang } = useContext(LocalizationContext);

    const { payload, subPayloads, zone, binRequested, binRequestMessage } = pickupForm;

    const payloadAccepted = _.get(zone, 'payloadAccepted', commoditiesAvailable.filter(c => c.unserviced));

    const formContainsErrors = _.some(payloadAccepted, commodity =>
        _.get(formErrors, `${_commodity.getSkuType(commodity)}.fail`, false)
    );

    const binRequestMessageError = _.get(formErrors, 'binRequestMessage');

    return (
        <Fragment>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog25', lang)} iconName="list_alt" />
                <PayloadInput
                    payload={payload}
                    subPayloads={subPayloads}
                    onPayload={onPayload}
                    onSubPayloads={onSubPayloads}
                    formErrors={formErrors}
                    toggleHelpDialog={toggleHelpDialog}
                    payloadAccepted={payloadAccepted}
                    payloadRequired={_zone.getPayloadRequired(zone)}
                    adminView={_.get(customer, 'adminView')}
                    lowVolumeContainerThreshold={props.lowVolumeContainerThreshold}
                    minPickupFeeEnabled={props.minPickupFeeEnabled}
                />
                <DialogContentText style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit / 2 }}>
                    {loc('pickupDialog34a', lang)}
                    <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                        {customer.uniqueID}
                    </strong>
                    {loc('pickupDialog34b', lang)}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: theme.spacing.unit * 2
                        }}
                    >
                        {!isMXDRegion() && _pickup.isCommercial(pickupForm) && (
                            <Button variant="outlined" onClick={() => setBinRequestDialogOpen(true)}>
                                <Icon
                                    style={{
                                        fontSize: '24px',
                                        marginRight: theme.spacing.unit / 2,
                                        color: binRequested ? colors.green[400] : colors.grey[700]
                                    }}
                                >
                                    {binRequested ? 'mark_email_read' : 'delete_outline'}
                                </Icon>
                                {binRequested ? loc('binRequest6', lang) : loc('binRequest5', lang)}
                            </Button>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button color="primary" onClick={onBack}>
                            {loc('back', lang)}
                        </Button>
                        <Button
                            color="primary"
                            disabled={
                                formContainsErrors ||
                                (_pickup.payloadIsEmpty(payload) && _pickup.subPayloadsAreEmpty(subPayloads))
                            }
                            onClick={onNext}
                            data-cy="3-pickupdialog-next-button"
                        >
                            {loc('next', lang)}
                        </Button>
                    </div>
                </div>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
            <BinRequestDialog
                open={binRequestDialogOpen}
                onClose={() => setBinRequestDialogOpen(false)}
                handleRequestBinChange={handleRequestBinChange}
                handleBinRequestMessageChange={handleBinRequestMessageChange}
                binRequestMessage={binRequestMessage}
                binRequestMessageError={binRequestMessageError}
            />
        </Fragment>
    );
};

export default withTheme()(Payload);
