import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Icon,
    Grid,
    Paper,
    Table as TableMUI,
    TableHead,
    TableBody,
    TableSortLabel,
    TableCell,
    TableRow,
    TablePagination,
    FormControl,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    OutlinedInput,
    TextField,
    Button,
    IconButton,
    Typography,
    Checkbox,
    Menu
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import useQueryString from 'utils/hooks/useQueryString';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Table = ({
    title,
    loading,
    theme,
    headers,
    filteredHeaders,
    length,
    page,
    rowsPerPage,
    search,
    noSearch,
    customSearch,
    customAction,
    handleChange,
    handleChangeFilterHeaders,
    handleAllFilterHeaders,
    handleSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleCheckbox,
    refresh,
    checkbox,
    checkedItems,
    cellStyle = {},
    children,
    headerContent,
    dataCy
}) => {
    const { lang } = useContext(LocalizationContext);
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [sortBy, setSortBy] = useQueryString('sort_by', window.localStorage.getItem(`${title}-sortBy`) || '', true);
    const [sortDir, setSortDir] = useQueryString(
        'sort_dir',
        window.localStorage.getItem(`${title}-sortDir`) || 'asc',
        true
    );

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const handleSortBy = name => () => {
        if (sortBy !== name) {
            setSortBy(name);
            setSortDir('desc');
            window.localStorage.setItem(`${title}-sortBy`, name);
            window.localStorage.setItem(`${title}-sortDir`, 'desc');
        } else if (sortBy === name && sortDir === 'desc') {
            setSortDir('asc');
            window.localStorage.setItem(`${title}-sortDir`, 'asc');
        } else {
            setSortBy();
            setSortDir();
            window.localStorage.removeItem(`${title}-sortBy`);
            window.localStorage.removeItem(`${title}-sortDir`);
        }
        if (refresh) {
            refresh();
        }
    };

    const handleSubmit = e => {
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    <div>{customSearch}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    return (
        <div
            style={{
                padding: theme.spacing.unit,
                paddingLeft: theme.spacing.unit * 2,
                paddingRight: theme.spacing.unit * 2
            }}
        >
            <Grid container spacing={theme.spacing.unit * 2}>
                <Grid item xs={12}>
                    <Paper data-cy={dataCy}>
                        {loading && <LinearProgress />}
                        <div
                            style={{
                                padding: theme.spacing.unit * 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                variant="h6"
                                noWrap
                                style={{ flex: '1 0 auto', marginRight: theme.spacing.unit }}
                            >
                                {title}
                            </Typography>

                            <div style={{ flex: '0 1 100%' }}>{headerContent}</div>

                            <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                {renderSearch(
                                    <>
                                        <FormControl style={inputStyles}>
                                            <InputLabel htmlFor="collection" variant="outlined">
                                                Column Filters
                                            </InputLabel>
                                            <Select
                                                variant="outlined"
                                                multiple
                                                value={filteredHeaders}
                                                onChange={handleChangeFilterHeaders}
                                                style={{ width: 250 }}
                                                input={<OutlinedInput labelWidth={140} />}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                    <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                </div>
                                                {headers.map(({ name, locKey }) => (
                                                    <MenuItem key={name} value={name}>
                                                        {!_.isNil(locKey)
                                                            ? loc(locKey, lang)
                                                            : (name || '')
                                                                  .replace('^', '') //remove ^ from sortable columns
                                                                  .replace(
                                                                      /\w+/g,
                                                                      _.capitalize
                                                                  ) /*uppercase first letter of words, _.startCase() removes special characters like hyphens and ampersands */}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <>
                                            {!noSearch && !customSearch && (
                                                <TextField
                                                    data-cy={`${_.kebabCase(title)}-search`}
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />
                                            )}
                                            {customAction}
                                            {!noSearch && (
                                                <Button
                                                    color="primary"
                                                    disabled={loading}
                                                    variant="contained"
                                                    data-cy="dashboard-search-button"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Icon>search</Icon>
                                                </Button>
                                            )}
                                        </>
                                    </>
                                )}

                                {collapseSearch && (
                                    <div style={{ textAlign: 'right' }}>
                                        <IconButton onClick={handleFiltersOpen()}>
                                            <Icon>filter_list</Icon>
                                        </IconButton>
                                    </div>
                                )}
                            </form>
                        </div>

                        <div style={{ overflow: 'auto' }}>
                            <TableMUI size="small" padding="dense">
                                <TableHead>
                                    <TableRow>
                                        {checkbox && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={checkedItems === rowsPerPage || checkedItems === length}
                                                    onChange={handleCheckbox}
                                                />
                                            </TableCell>
                                        )}
                                        {headers.map(({ name, locKey }, i) => {
                                            if (!filteredHeaders.includes(name)) return null;
                                            const formattedName = name.split('^');
                                            const labelText = !_.isNil(locKey) ? loc(locKey, lang) : formattedName[0];

                                            const canSortBy = name.includes('^');
                                            return (
                                                <TableCell style={cellStyle} key={i}>
                                                    {canSortBy ? (
                                                        <TableSortLabel
                                                            onClick={handleSortBy(formattedName[0])}
                                                            active={sortBy === formattedName[0]}
                                                            direction={sortDir}
                                                        >
                                                            {labelText}
                                                        </TableSortLabel>
                                                    ) : (
                                                        labelText
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{children}</TableBody>
                            </TableMUI>
                        </div>
                        <div style={{ overflow: 'auto' }}>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 'All']}
                                component="div"
                                count={length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default withTheme()(Table);
