import React, { useContext } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import ExternalLink from '../ExternalLink/ExternalLink';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { formatAsAddress } from 'utils/misc';
import { StatsWidget } from 'components/CustomerWidgets';
import { _user } from 'std';

function ChildAccountInfoDialog({
    theme,
    open,
    account,
    location,
    onClose,
    onViewAccount,
    childAccountBulks,
    childAccountTips,
    childAccountRedemptions
}) {
    const { lang } = useContext(LocalizationContext);
    if (_.isNil(account)) {
        return null;
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogTitlePrimary>
                {account.name.first} {account.name.last} - {formatAsAddress(location)}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                {location.nextPickupDate ? (
                    <Typography variant="h6">
                        Next Pickup Date:{' '}
                        {locDate(location.nextPickupDate, 'dddd, MMM DD YYYY', lang, location.timezone)}
                    </Typography>
                ) : (
                    <Typography variant="h6">No Pickup Booked</Typography>
                )}
                <StatsWidget
                    elevated={false}
                    bulks={_.filter(
                        childAccountBulks[_.get(account, '_id')],
                        b => _.get(b, 'pickup.location.place_id', '').toString() === location.place_id.toString()
                    )}
                    bottleDrives={_user.getBottleDrives(account)}
                    customer={account}
                    tips={childAccountTips[_.get(account, '_id')]}
                    redemptions={childAccountRedemptions[_.get(account, '_id')]}
                    childAccountInfoDonationShow={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={e => {
                        onViewAccount(account);
                    }}
                >
                    View Account
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ChildAccountInfoDialog);
