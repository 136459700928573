import React from 'react';

import _ from 'lodash';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTheme } from '@material-ui/core/styles';

const defaultTextVariant = 'h6';

function DialogTitlePrimary(props) {
    const { theme, children, closeButtonShown, helpButtonShown, style = {}, textVariant, backgroundColor } = props;

    const styleCustom = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: closeButtonShown
            ? `max(${theme.spacing.unit * 1.5}px, env(safe-area-inset-top, ${theme.spacing.unit * 1.5}px))`
            : `max(${theme.spacing.unit * 3}px, env(safe-area-inset-top, ${theme.spacing.unit * 3}px))`,
        paddingBottom: closeButtonShown ? theme.spacing.unit * 1.5 : theme.spacing.unit * 3,
        background: backgroundColor || theme.palette.primary.main,
        ...style
    };

    const contrastColor = theme.palette.getContrastText(theme.palette.primary.main);

    return (
        <DialogTitle disableTypography style={styleCustom}>
            <Typography
                variant={textVariant || defaultTextVariant}
                style={{ maxWidth: '100%', color: theme.palette.primary.contrastText || contrastColor }}
            >
                {children}
            </Typography>
            {helpButtonShown && (
                <IconButton
                    style={{ color: contrastColor }}
                    onClick={props.onHelp}
                    data-cy="dialog-title-primary-help-button"
                >
                    <Icon>help</Icon>
                </IconButton>
            )}
            {closeButtonShown && (
                <IconButton
                    style={{ color: theme.palette.primary.contrastText || contrastColor }}
                    onClick={props.onClose}
                    data-cy="dialog-title-primary-close-button"
                >
                    <Icon>close</Icon>
                </IconButton>
            )}
        </DialogTitle>
    );
}

export default withTheme()(DialogTitlePrimary);
