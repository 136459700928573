export const MODES = {
    ADMIN: {
        index: 0,
        message: `Looks like you already know what you're doing!`,
        icon: 'developer_mode'
    },
    PICKUP_SELECT: {
        index: 1,
        message: 'Select how to handle this pickup:',
        icon: 'ballot'
    },
    PICKUP: {
        index: 2,
        message: 'Proceed to your next pickup:',
        icon: 'directions_car'
    },
    SELECT: {
        index: 3,
        message: 'Your next pickup:',
        icon: 'ballot'
    },
    DROPOFF: {
        index: 4,
        message: 'Proceed to your drop zone:',
        icon: 'directions_car'
    },
    START_ROUTE: {
        index: 5,
        message: 'Proceed to your starting location',
        icon: 'directions_car'
    },
    LUNCH: {
        index: 6,
        message: 'Time remaining for lunch:',
        icon: 'directions_car'
    }
};

export const DRIVER_REASONS_AVAILABLE = [
    { short: 'No bags found / Not refundable', description: 'we had troubles finding your bags', userFault: true },
    { short: 'Could not find / access location', description: 'we had troubles accessing your bags', userFault: true },
    {
        short: 'Bad GPS - Location too far away',
        description: 'we had troubles getting to your location',
        userFault: true
    },
    { short: 'Hazardous', description: 'we were unable to pickup your bags', userFault: true }
];

export const ADMIN_REASONS_AVAILABLE = DRIVER_REASONS_AVAILABLE.concat([
    { short: 'Reschedule', description: 'we have rescheduled your pickup', userFault: false }
]);

export const MODIFIERS = ['confirmed', 'unconfirmed', 'bypassZoneChecks', 'highPriority', 'alwaysBypassZoneChecks'];
