import React from 'react';

// import _ from 'lodash';
import { formatAsCurrency } from 'utils/misc';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

function FinancialWidget(props) {
    const { theme, balance, thisWeekRevenue, style } = props;

    return (
        <Paper style={style}>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        Earnings this week
                    </Typography>
                    <Typography variant="h4" color="default">
                        {process.env.REACT_APP_CURRENCY.substr(0, 2)} {formatAsCurrency(thisWeekRevenue)}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: theme.spacing.unit * 2, width: '100%' }}
                        onClick={props.onRedemptionDialog(true)}
                    >
                        Cash out {formatAsCurrency(balance)}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default withTheme()(FinancialWidget);
