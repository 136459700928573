import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    InputAdornment,
    Icon,
    FormControl,
    withTheme,
    TextField,
    MenuItem,
    Button,
    CircularProgress,
    colors,
    Typography
} from '@material-ui/core';

import * as allIcons from '@mdi/js';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getCustomerName, uppercaseFirstLetter } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext.js';
import { loc } from '../../localizations/localizationHandler';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import TimePicker from 'material-ui-pickers/TimePicker';

import GMapsAutocomplete from 'components/GMapsAutocomplete';
import GoogleContext from 'utils/contexts/GoogleContext';

import { createLocation, getEmptyLocation } from 'helpers/locationHelper';

import CharitySelect from 'components/CharitySelect/CharitySelect';

import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';

import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

import { MAX_NUM_OF_RECYCLING_BAGS, MIN_NUM_OF_RECYCLING_BAGS } from 'constants.js';
import HttpContext from 'utils/contexts/HttpContext';

import { validate } from 'utils/validate';

function BottleDriveForm(props) {
    const {
        formControlStyle,
        textFieldStyle,
        formik,
        handleSetDonation,
        donate,
        loading,
        displayConfirmation,
        onClose,
        theme
    } = props;

    const { lang } = useContext(LocalizationContext);
    const { google } = useContext(GoogleContext);

    const handleAddressChange = () => {
        formik.setFieldValue('location', getEmptyLocation());
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        let locationUpdated = createLocation(
            place,
            suggestion.description,
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        formik.setFieldValue('location', locationUpdated);
    };

    return displayConfirmation ? (
        <div
            style={{
                marginTop: theme.spacing.unit * 2,
                border: `1px solid ${theme.palette.text.secondary}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing.unit * 2,
                backgroundColor: theme.palette.background.paper
            }}
        >
            <Typography variant="h6" align="center">
                {loc('registrationBottleDrive3', lang)}
            </Typography>{' '}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing.unit * 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={onClose}
                    disabled={loading}
                    data-cy="bottle-drive-close-btn"
                >
                    <span>{loc('close', lang)}</span>
                </Button>
            </div>
        </div>
    ) : (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <FormControl fullWidth>
                    <DatePicker
                        variant="outlined"
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        value={_.get(formik, 'values.date')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            ),
                            style: textFieldStyle
                        }}
                        helperText={loc('pickupDialogBottleDrive12', lang)}
                        onChange={date => formik.setFieldValue('date', date)}
                        disablePast
                        minDate={moment()
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .add(3, 'days')}
                    />
                </FormControl>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <FormControl fullWidth style={{ marginRight: theme.spacing.unit, ...formControlStyle }}>
                    <TimePicker
                        fullWidth
                        label={loc('startTime', lang)}
                        variant="outlined"
                        value={_.get(formik, 'values.startTime')}
                        format="h:mm A"
                        onChange={time => formik.setFieldValue('startTime', time)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>schedule</Icon>
                                </InputAdornment>
                            ),
                            style: textFieldStyle
                        }}
                        data-cy="bottle-drive-start-time-input"
                        error={
                            _.get(formik.touched, 'startTime', false) && _.get(formik.errors, 'startTime', false)
                                ? true
                                : null
                        }
                        helperText={
                            _.get(formik.touched, 'startTime', false) &&
                            _.get(formik.errors, 'startTime', false) &&
                            _.get(formik.errors, 'startTime', '')
                        }
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-start-time-input-helper-txt` }}
                    />
                </FormControl>
                <FormControl fullWidth style={formControlStyle}>
                    <TimePicker
                        fullWidth
                        label={loc('endTime', lang)}
                        variant="outlined"
                        value={_.get(formik, 'values.endTime')}
                        format="h:mm A"
                        onChange={time => formik.setFieldValue('endTime', time)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>schedule</Icon>
                                </InputAdornment>
                            ),
                            style: textFieldStyle
                        }}
                        data-cy="bottle-drive-end-time-input"
                        error={
                            _.get(formik.touched, 'endTime', false) &&
                            _.get(formik.errors, 'endTime', false) &&
                            _.get(formik.errors, 'endTime', '')
                                ? true
                                : null
                        }
                        helperText={_.get(formik.touched, 'endTime', false) && _.get(formik.errors, 'endTime', false)}
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-end-time-input-helper-txt` }}
                    />
                </FormControl>
            </div>

            <GMapsAutocomplete
                data-cy="registration-address-input"
                google={google}
                location={_.get(formik, 'values.location')}
                label={loc('address', lang)}
                selectedValue={_.get(formik, 'values.location.description')}
                types={['address']}
                style={{ ...formControlStyle, ...textFieldStyle }}
                error={{
                    fail:
                        _.get(formik.touched, 'location.description', false) &&
                        _.get(formik.errors, 'location.description', false),
                    reason:
                        _.get(formik.touched, 'location.description', false) &&
                        _.get(formik.errors, 'location.description', '')
                }}
                onChange={handleAddressChange}
                onSuggestionSelected={handleSuggestionSelected}
                suggestionsOccupyVerticalSpace={true}
            />

            <FormControl fullWidth>
                <TextField
                    {...formik.getFieldProps('comment')}
                    multiline
                    label={loc('pickupDialog41', lang)}
                    variant="outlined"
                    InputProps={{ style: textFieldStyle }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `bottle-drive-comment-input` }}
                    error={
                        _.get(formik.touched, 'comment', false) && _.get(formik.errors, 'comment', false) ? true : null
                    }
                    helperText={
                        _.get(formik.touched, 'comment', false) &&
                        _.get(formik.errors, 'comment', false) &&
                        _.get(formik.errors, 'comment', false)
                    }
                    FormHelperTextProps={{ 'data-cy': `bottle-drive-comment-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    {...formik.getFieldProps('charity')}
                    label={loc('registrationBottleDrive4', lang)}
                    variant="outlined"
                    InputProps={{ style: textFieldStyle }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `bottle-drive-charity-input` }}
                    error={
                        _.get(formik.touched, 'charity', false) && _.get(formik.errors, 'charity', false) ? true : null
                    }
                    helperText={
                        _.get(formik.touched, 'charity', false) &&
                        _.get(formik.errors, 'charity', false) &&
                        _.get(formik.errors, 'charity', false)
                    }
                    FormHelperTextProps={{ 'data-cy': `bottle-drive-charity-input-helper-txt` }}
                />
            </FormControl>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <FormControl fullWidth style={{ marginRight: theme.spacing.unit, ...formControlStyle }}>
                    <TextField
                        {...formik.getFieldProps('firstName')}
                        label={uppercaseFirstLetter(loc('firstName', lang))}
                        variant="outlined"
                        InputProps={{ style: textFieldStyle }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ 'data-cy': `bottle-drive-first-name-input` }}
                        error={
                            _.get(formik.touched, 'firstName', false) && _.get(formik.errors, 'firstName', false)
                                ? true
                                : null
                        }
                        helperText={
                            _.get(formik.touched, 'firstName', false) &&
                            _.get(formik.errors, 'firstName', false) &&
                            _.get(formik.errors, 'firstName', false)
                        }
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-first-name-input-helper-txt` }}
                    />
                </FormControl>
                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        {...formik.getFieldProps('lastName')}
                        label={uppercaseFirstLetter(loc('lastName', lang))}
                        variant="outlined"
                        InputProps={{ style: textFieldStyle }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ 'data-cy': `bottle-drive-last-name-input` }}
                        error={
                            _.get(formik.touched, 'lastName', false) && _.get(formik.errors, 'lastName', false)
                                ? true
                                : null
                        }
                        helperText={
                            _.get(formik.touched, 'lastName', false) &&
                            _.get(formik.errors, 'lastName', false) &&
                            _.get(formik.errors, 'lastName', false)
                        }
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-last-name-input-helper-txt` }}
                    />
                </FormControl>
            </div>
            <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        {...formik.getFieldProps('contactEmail')}
                        label={loc('email', lang)}
                        variant="outlined"
                        InputProps={{ style: textFieldStyle }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{ 'data-cy': `bottle-drive-contact-email-input` }}
                        error={
                            _.get(formik.touched, 'contactEmail', false) && _.get(formik.errors, 'contactEmail', false)
                                ? true
                                : null
                        }
                        helperText={
                            _.get(formik.touched, 'contactEmail', false) &&
                            _.get(formik.errors, 'contactEmail', false) &&
                            _.get(formik.errors, 'contactEmail', false)
                        }
                        FormHelperTextProps={{ 'data-cy': `bottle-drive-contact-email-input-helper-txt` }}
                    />
                </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    {...formik.getFieldProps('contactPhone')}
                    label={loc('taxReceiptCol3', lang)}
                    variant="outlined"
                    InputProps={{ style: textFieldStyle, inputComponent: TextMaskPhoneNumber }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `bottle-drive-contact-phone-input` }}
                    error={
                        _.get(formik.touched, 'contactPhone', false) && _.get(formik.errors, 'contactPhone', false)
                            ? true
                            : null
                    }
                    helperText={
                        _.get(formik.touched, 'contactPhone', false) &&
                        _.get(formik.errors, 'contactPhone', false) &&
                        _.get(formik.errors, 'contactPhone', false)
                    }
                    FormHelperTextProps={{ 'data-cy': `bottle-drive-contact-phone-input-helper-txt` }}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    {...formik.getFieldProps('collectorName')}
                    label={loc('registrationBottleDrive2', lang)}
                    variant="outlined"
                    InputProps={{ style: textFieldStyle }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `bottle-drive-collector-input` }}
                    error={
                        _.get(formik.touched, 'collectorName', false) && _.get(formik.errors, 'collectorName', false)
                            ? true
                            : null
                    }
                    helperText={
                        _.get(formik.touched, 'collectorName', false) &&
                        _.get(formik.errors, 'collectorName', false) &&
                        _.get(formik.errors, 'collectorName', false)
                    }
                    FormHelperTextProps={{ 'data-cy': `bottle-drive-collector-name-input-helper-txt` }}
                />
            </FormControl>

            <FormControl fullWidth style={formControlStyle}>
                <TextField
                    {...formik.getFieldProps('estimatedBags')}
                    multiline
                    label={loc('registrationBottleDrive1', lang)}
                    variant="outlined"
                    InputProps={{ style: textFieldStyle }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `bottle-drive-bags-input` }}
                    error={
                        _.get(formik.touched, 'estimatedBags', false) && _.get(formik.errors, 'estimatedBags', false)
                            ? true
                            : null
                    }
                    helperText={
                        _.get(formik.touched, 'estimatedBags', false) &&
                        _.get(formik.errors, 'estimatedBags', false) &&
                        _.get(formik.errors, 'estimatedBags', false)
                    }
                    FormHelperTextProps={{ 'data-cy': `bottle-drive-bags-input-helper-txt` }}
                />
            </FormControl>
        </>
    );
}

export default withTheme()(BottleDriveForm);
