import { NO_TECHNOLOGY } from '../constants';
import _ from 'lodash';

export function getClosestDropLocation(location, dropLocations) {
    return _.minBy(dropLocations, dropLocation => {
        return getDistanceFromLatLonInKm(
            location.lat,
            location.lng,
            dropLocation.location.lat,
            dropLocation.location.lng
        );
    });
}

export function getClosestReturnSite(location, dropLocations, collectors, types, preferredReturnSite = null) {
    // const dropAndGoTypes = ['App Only', 'Kiosk', 'Kiosk & Scanner', 'Access Door']; // Hardcoded for now...
    // const walkInTypes = ['Reverse Vending Machine'];
    const dropLocationsByType = _.filter(dropLocations, dropLocation => {
        if (!types || _.isEmpty(types)) return true; // Don't filter if no type is passed in (i.e. viewing from map)
        const dropOffType = dropLocation.dropOffType;
        if (types.includes(dropOffType)) return true;
        // if (['Drop&Go', 'Express'].includes(type) && dropAndGoTypes.includes(dropOffType)) return true;
        // if (type === 'Walk-In' && walkInTypes.includes(dropOffType)) return true;

        return false;
    });

    const dropLocationsByAddressAndType = _.groupBy(dropLocationsByType, 'location.description');
    let minDistance = Number.MAX_SAFE_INTEGER;
    let closestAddress = '';
    let closestDropLocations = [];
    let closestCollector = null;

    if (
        !_.isNil(preferredReturnSite) && //preferred return site exists
        (_.some(dropLocationsByType, d => _.get(d, 'location.description', '') === preferredReturnSite.description) || // and one of the filtered drop locations is at this location
            (!_.isNil(types) &&
            types.includes(NO_TECHNOLOGY) && //or filters include collector only locations
                _.some(collectors, d => _.get(d, 'location.description', '') === preferredReturnSite.description))) //and one of the collectors is at this location
    ) {
        let collector = _.find(
            collectors,
            c => _.get(c, 'location.description', '') === preferredReturnSite.description
        );
        let dropLocationsAtPreferredSite = _.filter(
            dropLocationsByType,
            d => _.get(d, 'location.description', '') === preferredReturnSite.description
        );
        if (
            (!_.isNil(collector) || !_.isEmpty(dropLocationsAtPreferredSite)) &&
            (_.get(collector, 'openToPublic', false) ||
                !_.some(dropLocationsAtPreferredSite, d => d.existsAtReturnSite))
        ) {
            let distance = getDistanceFromLatLonInKm(
                location.lat,
                location.lng,
                preferredReturnSite.lat,
                preferredReturnSite.lng
            );
            return {
                distance,
                address: preferredReturnSite.description,
                dropLocations: _.filter(
                    dropLocations,
                    d => _.get(d, 'location.description', '') === preferredReturnSite.description
                ),
                collector
            };
        }
    }
    _.keys(dropLocationsByAddressAndType).forEach(address => {
        let dropLocationsAtAddress = dropLocationsByAddressAndType[address];
        if (!_.isEmpty(dropLocationsAtAddress) && address !== 'undefined') {
            let distance = getDistanceFromLatLonInKm(
                location.lat,
                location.lng,
                _.first(dropLocationsAtAddress).location.lat,
                _.first(dropLocationsAtAddress).location.lng
            );
            let collector;
            if (
                !_.isEmpty(dropLocationsAtAddress) &&
                _.some(dropLocationsAtAddress, location => location.existsAtReturnSite)
            ) {
                collector = _.find(collectors, c => c._id === _.first(dropLocationsAtAddress).collector._id);
            } else {
                collector = null;
            }

            if ((_.isNil(collector) || collector.openToPublic) && distance < minDistance) {
                minDistance = distance;
                closestAddress = address;
                closestDropLocations = _.filter(dropLocations, d => _.get(d, 'location.description', '') === address);
                closestCollector = collector;
            }
        }
    });
    if (!types || _.isEmpty(types) || types.includes(NO_TECHNOLOGY.value)) {
        _.filter(
            collectors,
            collector =>
                collector.openToPublic &&
                !_.isNil(collector.location) &&
                !_.isNil(collector.location.lat) &&
                !_.isNil(collector.location.lng)
        ).forEach(collector => {
            let distance = getDistanceFromLatLonInKm(
                location.lat,
                location.lng,
                collector.location.lat,
                collector.location.lng
            );
            if (distance < minDistance) {
                minDistance = distance;
                closestAddress = collector.location.description;
                closestDropLocations = [];
                closestCollector = collector;
            }
        });
    }
    return {
        distance: minDistance,
        address: closestAddress,
        dropLocations: closestDropLocations,
        collector: closestCollector
    };
}

export function clusterAndSortReturnSitesByDistance(location, dropLocations, collectors, collectorCommodities = []) {
    const dropLocationsByAddress = _.groupBy(dropLocations, 'location.description');
    const returnSites = [];

    _.keys(dropLocationsByAddress).forEach(address => {
        let dropLocationsAtAddress = dropLocationsByAddress[address];
        if (!_.isEmpty(dropLocationsAtAddress)) {
            let distance =
                !_.isNil(location.lat) && !_.isNil(location.lng)
                    ? getDistanceFromLatLonInKm(
                          location.lat,
                          location.lng,
                          _.first(dropLocationsAtAddress).location.lat,
                          _.first(dropLocationsAtAddress).location.lng
                      )
                    : 0;
            let returnSite = {
                dropLocations: dropLocationsAtAddress,
                address,
                distance
            };
            let commodities = [];
            dropLocationsAtAddress.forEach(d => {
                commodities = commodities.concat(d.payloadAccepted);
            });
            commodities = _.uniq(commodities);
            let technologies = dropLocationsAtAddress.map(d => d.dropOffType);
            if (
                !_.isEmpty(dropLocationsAtAddress) &&
                _.some(dropLocationsAtAddress, location => location.existsAtReturnSite)
            ) {
                returnSite.collector = _.find(collectors, c => c._id === _.first(dropLocationsAtAddress).collector._id);
                technologies.push('Primary Return Site');
            } else {
                returnSite.collector = null;
            }
            returnSite.technologies = technologies;
            returnSite.commodities = commodities;
            if (_.isNil(returnSite.collector) || _.get(returnSite, 'collector.openToPublic')) {
                returnSites.push(returnSite);
            }
        }
    });
    _.filter(
        collectors,
        collector =>
            collector.openToPublic &&
            !_.isNil(collector.location) &&
            !_.isNil(collector.location.lat) &&
            !_.isNil(collector.location.lng)
    ).forEach(collector => {
        let distance =
            !_.isNil(location.lat) && !_.isNil(location.lng)
                ? getDistanceFromLatLonInKm(location.lat, location.lng, collector.location.lat, collector.location.lng)
                : 0;
        let collectorAlreadyIncluded = _.find(
            returnSites,
            returnSite => _.get(returnSite, 'collector._id', '') === collector._id
        );
        if (_.isNil(collectorAlreadyIncluded)) {
            returnSites.push({
                dropLocations: [],
                address: collector.location.description,
                distance,
                collector,
                technologies: ['Primary Return Site'],
                commodities: []
            });
        }
    });
    const sortedReturnSites = _.orderBy(returnSites, 'distance', 'asc');
    return sortedReturnSites;
}

export function sortRegionsByRadius(locations) {
    return _.sortBy(locations, 'rad');
}

export function getNameOfClosestRegion(location, regions) {
    return _.get(getClosestRegion(location, regions), 'name', '');
}

/**
 * Description.
 * Returns closest region if a containing region is not found
 * Requires db updates from GitLab snippets:
 * REQUIRED: Region Radius additions STD 2.14.2
 * SUGGESTED: Lat/Long Data tidy 2.14.2 STD
 *
 * @param {Object} location
 * @param {Array} regions
 *
 * @returns {Object} Nearest Region
 *
 */

export function getClosestRegion(location, regions) {
    /* return _.minBy(regions, city => {
        return getDistanceFromLatLonInKm(location.lat, location.lng, city.lat, city.lng);
    }); */

    let [nearestRegion, nearestDistance] = [undefined, Number.MAX_SAFE_INTEGER];
    let sortedRegions = sortRegionsByRadius(regions);

    for (let region of sortedRegions) {
        let distanceFromRegion =
            getDistanceFromLatLonInKm(location.lat, location.lng, region.lat, region.lng) - region.rad;
        if (distanceFromRegion < 0) {
            return region;
        } else if (distanceFromRegion < nearestDistance) {
            [nearestRegion, nearestDistance] = [region, distanceFromRegion];
        }
    }
    return nearestRegion;
}

//https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export function isValidGps(lat, lng) {
    const isValidLat = !isNaN(lat) && isFinite(lat) && Math.abs(lat) <= 90 && parseFloat(lat) !== 0;
    const isValidLng = !isNaN(lng) && isFinite(lng) && Math.abs(lng) <= 180 && parseFloat(lng) !== 0;
    return isValidLat && isValidLng;
}
