import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatAsCurrency, getPlural } from 'utils/misc';
import { _commodity, _pickup } from 'std';
import { Paper, Typography, Divider, List, ListItem, Avatar, colors, CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

const CompletedTripsWidget = ({
    http,
    completedTrips,
    theme,
    operator,
    commodities,
    tipsGroupedByTrip,
    tipsAreEstimated,
    tipsAreEnabled,
    loading = false
}) => {
    const container = commodities.find(
        commodity => commodity.skuType === 'beverage' || commodity.skuType === 'collectionbins'
    );
    const clothing = commodities.find(commodity => commodity.skuType === 'clothing');
    const electronics = commodities.find(commodity => commodity.skuType === 'electronics');

    const [maxGasPayout, setMaxGasPayout] = useState(NaN);

    useEffect(() => {
        (async function() {
            const res = await http.getJSON('/system/driverConfig');
            if (res.ok) {
                setMaxGasPayout(_.get(res, 'data.maxGasPayout', NaN));
            }
        })();
    }, []);

    return (
        <Paper style={{ padding: theme.spacing.unit * 2 }}>
            <Typography variant="h6">Completed Trips</Typography>
            <Divider />
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <List style={{ paddingLeft: theme.spacing.unit * 0.5, paddingRight: theme.spacing.unit * 0.5 }}>
                    {_.isEmpty(completedTrips) && (
                        <Typography style={{ margin: theme.spacing.unit * 2, marginBottom: 0 }}>No Trips</Typography>
                    )}
                    {completedTrips &&
                        completedTrips.map(trip => {
                            const {
                                abortedPickupsCount,
                                bagsCount,
                                containerPlaceholder,
                                clothingPlaceholder,
                                electronicsSum,
                                stopPayAmount
                            } = trip.pickups.reduce(
                                (obj, pickup) => ({
                                    abortedPickupsCount: pickup.aborted
                                        ? obj.abortedPickupsCount + 1
                                        : obj.abortedPickupsCount,
                                    bagsCount: obj.bagsCount + _pickup.numberOfPayloadBags(pickup, commodities),
                                    containerPlaceholder:
                                        obj.containerPlaceholder +
                                        _.get(pickup, 'payload.beverage', 0) +
                                        _.get(pickup, 'payload.collectionbins', 0),
                                    clothingPlaceholder: obj.clothingPlaceholder + _.get(pickup, 'payload.clothing', 0),
                                    electronicsSum: obj.electronicsSum + _.get(pickup, 'payload.electronics', 0),
                                    stopPayAmount: obj.stopPayAmount + _.get(pickup, 'stopPayAmount', 0)
                                }),
                                {
                                    abortedPickupsCount: 0,
                                    bagsCount: 0,
                                    containerPlaceholder: 0,
                                    clothingPlaceholder: 0,
                                    electronicsSum: 0,
                                    stopPayAmount: 0
                                }
                            );

                            const driveDuration = _.get(trip, 'measuredDuration', 0) / 3600;

                            const totalDuration =
                                _.get(trip, 'startTripTime', 0) / 60 +
                                _.get(trip, 'measuredDuration', 0) / 3600 +
                                _.get(trip, 'offloadTime', _.get(trip, 'collector.offloadTime', 0)) / 60;
                            const estimatedDuration =
                                _.get(trip, 'duration', 0) / 3600 + _.get(trip, 'collector.offloadTime', 0) / 60;

                            const pay = _.get(trip, 'payment.amount', 0);
                            const estimatedPay = _.get(trip, 'estimatedPay', 0);

                            const payoutGas = _.get(trip, 'payOutGas', _.get(trip, 'transporter.payOutGas', false));

                            const gasPrice = _.get(trip, 'gasPrice', 0);
                            const distanceKm = _.get(trip, 'distance', 0) / 1000;
                            const kmPerLiter = _.get(trip, 'kmPerLiter', 0);

                            const calculatedGasCost = (gasPrice * distanceKm) / kmPerLiter;
                            const gasReimbursement = Math.min(calculatedGasCost, maxGasPayout * 100);

                            const primaryTextStyle = {
                                fontSize: '0.875rem',
                                paddingLeft: `${theme.spacing.unit * 2}px`
                            };

                            const secondaryTextStyle = {
                                fontSize: '0.75rem',
                                paddingLeft: `${theme.spacing.unit * 2}px`
                            };

                            const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                trip.pickups,
                                commodities
                            );
                            const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

                            const isProcessing = !trip.payable || _.isNil(trip.measuredDuration);

                            return (
                                <Fragment key={trip._id}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{
                                                marginTop: theme.spacing.unit * 2,
                                                marginRight: theme.spacing.unit * 2,
                                                fontWeight: 400
                                            }}
                                        >
                                            {moment(trip.startTime)
                                                .tz(operator.collector.timezone)
                                                .format('dddd, MMM D YYYY')}
                                        </Typography>
                                        {isProcessing && (
                                            <Typography
                                                style={{
                                                    marginTop: theme.spacing.unit * 2,
                                                    color: colors.orange[500]
                                                }}
                                            >
                                                (Processing ...)
                                            </Typography>
                                        )}
                                    </div>
                                    <ListItem
                                        dense
                                        disableGutters
                                        style={{
                                            paddingTop: theme.spacing.unit / 2,
                                            paddingBottom: theme.spacing.unit / 2
                                        }}
                                    >
                                        {operator.permissions.payThroughSystem ? (
                                            <Avatar
                                                style={{
                                                    width: 54,
                                                    height: 54,
                                                    borderRadius: 8,
                                                    backgroundColor: theme.palette.primary.main,
                                                    flexWrap: 'wrap'
                                                }}
                                            >
                                                <div
                                                    data-cy="driver-total-pay"
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        fontSize: 14,
                                                        marginTop: 8,
                                                        marginBottom: -8
                                                    }}
                                                >
                                                    {isProcessing
                                                        ? formatAsCurrency(estimatedPay)
                                                        : formatAsCurrency(pay)}
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        fontSize: 11,
                                                        marginTop: -4
                                                    }}
                                                >
                                                    {isProcessing ? 'Est.' : ''} Pay
                                                </div>
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                style={{
                                                    borderRadius: 6,
                                                    width: 64,
                                                    height: Object.keys(groupedPayloadTotals).length * 24,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-around',
                                                    alignItems: 'center',
                                                    backgroundColor: theme.palette.primary.main
                                                }}
                                            >
                                                {Object.keys(groupedPayloadTotals).map(payloadType => {
                                                    const amount = groupedPayloadTotals[payloadType];

                                                    return (
                                                        <div
                                                            data-cy={`driver-${payloadType}-count`}
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                fontSize: 14
                                                            }}
                                                        >
                                                            {`${amount} ${
                                                                amount > 1 ? getPlural(payloadType) : payloadType
                                                            }`}
                                                        </div>
                                                    );
                                                })}
                                            </Avatar>
                                        )}

                                        <div
                                            style={{
                                                padding: `0px 0px 0px ${theme.spacing.unit * 3}px`,
                                                margin: 'auto',
                                                minWidth: 0,
                                                width: '100%'
                                            }}
                                        >
                                            {!operator.permissions.payThroughSystem && (
                                                <Typography variant="body2" color="primary">
                                                    You drove for <strong>{driveDuration.toFixed(2)} hours</strong> and
                                                    completed{' '}
                                                    <strong>
                                                        {trip.pickups.length} pickup
                                                        {trip.pickups.length === 1 ? '' : 's'}
                                                    </strong>
                                                    .
                                                    {/* {operator.permissions.payThroughSystem &&
                                                isFinite(pay) &&
                                                trip.allPickupsCounted && (
                                                    <>
                                                        Your {operator.permissions.paymentModel.toLowerCase()} for this
                                                        trip was <strong>{formatAsCurrency(pay)}</strong>
                                                    </>
                                                )} */}
                                                </Typography>
                                            )}
                                            {operator.permissions.payThroughSystem ? (
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        justifyContent: 'center',
                                                        gridTemplateColumns: 'repeat(auto-fill, 100px)',
                                                        gap: `${theme.spacing.unit}px`
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="default"
                                                            style={primaryTextStyle}
                                                        >
                                                            {trip.pickups.length}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            style={secondaryTextStyle}
                                                        >
                                                            Pickups
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="default"
                                                            style={primaryTextStyle}
                                                        >
                                                            {_.isNil(trip.measuredDuration)
                                                                ? estimatedDuration.toFixed(2)
                                                                : totalDuration.toFixed(2)}{' '}
                                                            hrs
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            style={secondaryTextStyle}
                                                        >
                                                            {_.isNil(trip.measuredDuration) ? 'Estimated Time' : 'Time'}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="default"
                                                            style={primaryTextStyle}
                                                        >
                                                            {_.round(
                                                                _.get(trip, 'distance', 0) / 1000,
                                                                2
                                                            ).toLocaleString('en-CA')}{' '}
                                                            km
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            style={secondaryTextStyle}
                                                        >
                                                            Distance
                                                        </Typography>
                                                    </div>
                                                    {(_.get(trip, 'containers', 0) > 0 || containerPlaceholder > 0) && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={{
                                                                    ...primaryTextStyle,
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {!_.isNil(container) && (
                                                                    <MDIcon
                                                                        key={container.iconName}
                                                                        path={allIcons[container.iconName]}
                                                                        color={container.color}
                                                                        size={0.7}
                                                                    />
                                                                )}{' '}
                                                                <span>
                                                                    {_.get(trip, 'containers', 0) === 0
                                                                        ? containerPlaceholder
                                                                        : _.get(trip, 'containers', 0).toLocaleString(
                                                                              'en-CA'
                                                                          )}
                                                                </span>
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                {_.get(trip, 'containers', 0) === 0
                                                                    ? 'Containers(bags)'
                                                                    : 'Beverage(units)'}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {(_.get(trip, 'clothing', 0) > 0 || clothingPlaceholder > 0) && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={{
                                                                    ...primaryTextStyle,
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {!_.isNil(clothing) && (
                                                                    <MDIcon
                                                                        key={clothing.iconName}
                                                                        path={allIcons[clothing.iconName]}
                                                                        color={clothing.color}
                                                                        size={0.7}
                                                                    />
                                                                )}{' '}
                                                                <span>
                                                                    {_.get(trip, 'clothing', 0) === 0
                                                                        ? clothingPlaceholder
                                                                        : _.round(
                                                                              _.get(trip, 'clothing', 0),
                                                                              2
                                                                          ).toLocaleString('en-CA')}
                                                                </span>
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                {_.get(trip, 'clothing', 0) === 0
                                                                    ? 'Clothing(bags)'
                                                                    : 'Clothing(lbs)'}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {electronicsSum > 0 && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={{
                                                                    ...primaryTextStyle,
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {!_.isNil(electronics) && (
                                                                    <MDIcon
                                                                        key={electronics.iconName}
                                                                        path={allIcons[electronics.iconName]}
                                                                        color={electronics.color}
                                                                        size={0.7}
                                                                    />
                                                                )}{' '}
                                                                <span>{electronicsSum.toLocaleString('en-CA')}</span>
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                {'Electronics(units)'}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {payoutGas && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={primaryTextStyle}
                                                            >
                                                                {formatAsCurrency(gasReimbursement)}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                Gas Reimbursement
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {stopPayAmount > 0 && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={primaryTextStyle}
                                                            >
                                                                {formatAsCurrency(stopPayAmount)}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                Stop Payment
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {operator.permissions.payThroughSystem && tipsAreEnabled && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="default"
                                                                style={primaryTextStyle}
                                                            >
                                                                {!_.isNil(
                                                                    _.find(
                                                                        tipsGroupedByTrip[trip._id],
                                                                        t =>
                                                                            _.isNil(t.adjustedAmount) ||
                                                                            _.isNil(t.dateTipped)
                                                                    )
                                                                )
                                                                    ? 'TBD'
                                                                    : formatAsCurrency(
                                                                          _.sumBy(
                                                                              _.filter(
                                                                                  tipsGroupedByTrip[trip._id],
                                                                                  t => t.adjustedAmount && t.dateTipped
                                                                              ),
                                                                              'adjustedAmount'
                                                                          ) * 100
                                                                      )}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                style={secondaryTextStyle}
                                                            >
                                                                Tips{tipsAreEstimated ? ' (Est.)' : ''}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 130px))'
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color="default"
                                                        style={{ fontSize: '0.75rem' }}
                                                    >
                                                        Pickups: {trip.pickups.length}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="default"
                                                        style={{ fontSize: '0.75rem' }}
                                                    >
                                                        Duration: {driveDuration.toFixed(2)} hrs
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="default"
                                                        style={{ fontSize: '0.75rem' }}
                                                    >
                                                        Distance: {(_.get(trip, 'distance', 0) / 1000).toFixed(2)} km
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="default"
                                                        style={{ fontSize: '0.75rem' }}
                                                    >
                                                        Aborted: {abortedPickupsCount}
                                                    </Typography>
                                                    {_.get(trip, 'containers', 0) > 0 && (
                                                        <Typography
                                                            variant="body2"
                                                            color="default"
                                                            style={{
                                                                fontSize: '0.75rem',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            Beverage(units):{' '}
                                                            {!_.isNil(container) && (
                                                                <MDIcon
                                                                    key={container.iconName}
                                                                    path={allIcons[container.iconName]}
                                                                    color={container.color}
                                                                    size={0.7}
                                                                />
                                                            )}{' '}
                                                            {_.get(trip, 'containers', 0).toLocaleString('en-CA')}
                                                        </Typography>
                                                    )}
                                                    {_.get(trip, 'clothing', 0) > 0 && (
                                                        <Typography
                                                            variant="body2"
                                                            color="default"
                                                            style={{
                                                                fontSize: '0.75rem',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            Clothing(lbs):{' '}
                                                            {!_.isNil(clothing) && (
                                                                <MDIcon
                                                                    key={clothing.iconName}
                                                                    path={allIcons[clothing.iconName]}
                                                                    color={clothing.color}
                                                                    size={0.7}
                                                                />
                                                            )}{' '}
                                                            {_.round(trip.clothing, 2).toLocaleString('en-CA')} lbs
                                                        </Typography>
                                                    )}
                                                    {electronicsSum > 0 && (
                                                        <Typography
                                                            variant="body2"
                                                            color="default"
                                                            style={{
                                                                fontSize: '0.75rem',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            Electronics(units):{' '}
                                                            {!_.isNil(electronics) && (
                                                                <MDIcon
                                                                    key={electronics.iconName}
                                                                    path={allIcons[electronics.iconName]}
                                                                    color={electronics.color}
                                                                    size={0.7}
                                                                />
                                                            )}{' '}
                                                            {electronicsSum.toLocaleString('en-CA')}{' '}
                                                            {`${electronicsSum !== 1 ? 'items' : 'item'}`}
                                                        </Typography>
                                                    )}
                                                    {/* {operator.permissions.payThroughSystem && (
                                                    <>
                                                        {operator.permissions.paymentModel === 'Wage' && (
                                                            <Typography
                                                                variant="body2"
                                                                color="default"
                                                                style={{ fontSize: '0.75rem' }}
                                                            >
                                                                Wage: {formatAsCurrency(trip.driverWage)}
                                                            </Typography>
                                                        )}
                                                        {operator.permissions.paymentModel === 'Commission' && (
                                                            <Typography
                                                                variant="body2"
                                                                color="default"
                                                                style={{ fontSize: '0.75rem' }}
                                                            >
                                                                Commission: {formatAsCurrency(trip.driverCommission)}
                                                            </Typography>
                                                        )}

                                                        <Typography
                                                            variant="body2"
                                                            color="default"
                                                            style={{ fontSize: '0.75rem' }}
                                                        >
                                                            Pay: {formatAsCurrency(pay)}
                                                        </Typography>
                                                    </>
                                                )} */}
                                                </div>
                                            )}
                                        </div>
                                    </ListItem>
                                </Fragment>
                            );
                        })}
                </List>
            )}
        </Paper>
    );
};

export default withTheme()(CompletedTripsWidget);
