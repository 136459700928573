import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton, Icon, Badge, withStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

import CommodityForm from './Forms/CommodityForm';
// import EditSkuValueDialog from './Dialogs/EditSkuValueDialog';

import CommodityPickupFieldsDialog from './Dialogs/CommodityPickupFieldsDialog';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { formatAsCurrency } from 'utils/misc';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const styles = theme => ({
    badgeOk: {
        backgroundColor: green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: red[500],
        color: 'white'
    }
});

function CommodityTable(props) {
    const { operator, classes } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const endpoints = {
        getEndPoint: '/commodities/getAllCommodities',
        getEditEndPoint: _id => `/commodities/${_id}/updateCommodity`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/commodities/createCommodity' // url to add new documents
    };

    const [commodityPickupFieldsDialogOpen, setCommodityPickupFieldsDialogOpen] = useState(false);

    const [allRates, setAllRates] = useState([]);
    const [allFees, setAllFees] = useState([]);
    const [loadingRates, setLoadingRates] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        {
            key: 'iconName',
            header: 'Icon',
            formatValue: (value, document) => (
                <MDIcon path={_.get(allIcons, value)} color={_.get(document, 'color')} size={1} />
            )
        },
        { key: 'skuType', header: 'Name' },
        { key: `payloadInputName`, header: 'Display Name', formatValue: value => _.get(value, lang, '') },
        { key: 'units', header: 'Unit', formatValue: value => _.get(value, lang, '') },
        { key: 'completionStrategy', header: 'Completion Strategy' },
        { key: 'unserviced', header: 'Available Unserviced', isBoolean: true },
        { key: 'imageRequired', header: 'Image Required', isBoolean: true },
        { key: 'includeContainersInStats', header: 'System Beverage Containers', isBoolean: true },
        { key: 'showAsDonatedInHistory', header: 'Show Always as Donated', isBoolean: true },
        { key: 'showOnMap', header: 'Show on Map', isBoolean: true },
        { key: 'preAppliedFee.amount', header: 'Pre Applied Fee', formatValue: value => formatAsCurrency(value) },
        { key: 'defaultSku', header: 'Auto Complete Sku' },
        {
            key: `payloadHelperText`,
            header: 'Customer Input Helper Text',
            formatValue: value => _.get(value, lang, '')
        }
    ];

    const handleReloadRates = async () => {
        setLoadingRates(true);
        const res = await http.getJSON('/rates/allRates');
        if (res.ok) {
            setAllRates(_.get(res, 'data.rates', []));
        }
        setLoadingRates(false);
    };

    const handleLoadFees = async () => {
        const res = await http.getJSON('/fees/getAllEnabledFees');
        if (res.ok) {
            setAllFees(_.get(res, 'data.collectionData', []));
        }
    };

    const handleOpenCommodityPickupFieldsDialog = doc => {
        setSelectedDocument(doc);
        setCommodityPickupFieldsDialogOpen(true);
    };

    const handleUpdateCommodityPickupFields = async (
        collectorPickupFields,
        intuitCustomerRef,
        intuitItemRef,
        commodityPickupsEnabled
    ) => {
        const commodityCopy = _.cloneDeep(selectedDocument);
        commodityCopy.collectorPickupFields = collectorPickupFields;
        _.set(commodityCopy, 'intuit.customerRef', intuitCustomerRef);
        _.set(commodityCopy, 'intuit.itemRef', intuitItemRef);
        commodityCopy.commodityPickupsEnabled = commodityPickupsEnabled;
        const res = await http.postJSON(`/commodities/${selectedDocument._id}/updateCommodity`, commodityCopy);
        if (res.ok) {
            handleReloadData();
            setCommodityPickupFieldsDialogOpen(false);
        }
    };

    const editForm = (
        <CommodityForm
            allFees={allFees}
            allRates={allRates}
            commodity={selectedDocument}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            onSubmit={
                _.isNil(selectedDocument)
                    ? async values => {
                          await handleCreate(values);
                          await handleReloadRates();
                      }
                    : async values => {
                          await handleEdit(values);
                          await handleReloadRates();
                      }
            }
            loadingRates={loadingRates}
        />
    );

    const renderExtraActions = document => (
        <>
            <Tooltip title={'Collector commodity pickup fields'} disableFocusListener disableTouchListener>
                <span>
                    <IconButton
                        data-cy="commodity-pickup-config-dialog-open-button"
                        onClick={() => {
                            handleOpenCommodityPickupFieldsDialog(document);
                        }}
                    >
                        <Badge
                            classes={{
                                badge: _.get(document, 'commodityPickupsEnabled', false)
                                    ? classes.badgeOk
                                    : classes.badgeError
                            }}
                            badgeContent={
                                <Icon style={{ fontSize: 13 }}>
                                    {_.get(document, 'commodityPickupsEnabled', false) ? 'check' : 'close'}
                                </Icon>
                            }
                        >
                            <MDIcon path={_.get(allIcons, 'mdiFormSelect')} size={1} color={props.theme.palette.text.secondary} />
                        </Badge>
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );

    useEffect(() => {
        handleReloadRates();
        handleLoadFees();
    }, []);

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={commodity => _.get(commodity, 'disabled', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Commodities'}
                disabledHeaderText={'Disabled Commodities'}
                loading={loading}
                hideDateFilters
                renderExtraActions={renderExtraActions}
            />

            {commodityPickupFieldsDialogOpen && (
                <CommodityPickupFieldsDialog
                    open={commodityPickupFieldsDialogOpen}
                    commodity={selectedDocument}
                    onClose={() => setCommodityPickupFieldsDialogOpen(false)}
                    onSubmit={handleUpdateCommodityPickupFields}
                />
            )}
        </>
    );
}

export default withStyles(styles)(withTheme()(CommodityTable));
