import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';

import MdiIcon from '@mdi/react';
import * as allMdiIcons from '@mdi/js';
import { withTheme } from '@material-ui/core/styles';
import {
    withWidth,
    Icon,
    Paper,
    Grid,
    Button,
    Typography,
    IconButton,
    Collapse,
    CircularProgress,
    TextField,
    Divider,
    colors,
    InputAdornment,
    Switch,
    Select,
    OutlinedInput,
    MenuItem,
    Menu,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    FormControl,
    Tooltip,
    Chip,
    Checkbox
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { getTextInput } from 'components/CRUDTables/helperFunctions';
import StringListInput from 'components/CustomInputs/StringListInput';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { isAUSRegion, isCONRegion, isEXPRegion } from 'utils/misc';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useRef } from 'react';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { validate } from 'utils/validate';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import DOMPurify from 'dompurify';

const Emails = ({ theme, collectors, width, pickupsEnabled }) => {
    const headerRef = useRef(null);
    const [headerWidth, setHeaderWidth] = useState(width);
    const isSmallScreen = isWidthDown('md', width);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);
    const [renderScreen, setRenderScreen] = useState(0);
    const [showMenu, setShowMenu] = useState(true);
    const [menuEl, setMenuEl] = useState(null);

    const [emailsConfigLoading, setEmailsConfigLoading] = useState();
    const [emails, setEmails] = useState({});

    const [sendTestEmailExpanded, setSendTestEmailExpanded] = useState('');
    const [emailTemplateSelected, setEmailTemplateSelected] = useState('');
    const [emailTemplateSearch, setEmailTemplateSearch] = useState('');
    const [testEmailAddress, setTestEmailAddress] = useState('');
    const [customizeTestData, setCustomizeTestData] = useState(false);
    const [emailDataLocationType, setEmailDataLocationType] = useState('Residential');
    const [includeCharityFields, setIncludeCharityFields] = useState(true);
    const [testData, setTestData] = useState('');
    const [currentEmailHtml, setCurrentEmailHtml] = useState(
        '<div style="padding: 10px 50px 50px;"><p>Please <b>refresh</b> to see updated email content</p></div>'
    );

    const iconPath = isAUSRegion()
        ? 'https://skipthedepot.com/img/app/aus'
        : isCONRegion()
        ? 'https://skipthedepot.com/img/app/con'
        : 'https://skipthedepot.com/img/app/std';

    const [isLoading, setIsLoading] = useState(false);

    const onSnackbar = useContext(SnackbarContext);

    const loadEmailTestData = async () => {
        const res = await http.getJSON(
            `/system/getEmailTestData?includeCharityFields=${includeCharityFields}&locationType=${emailDataLocationType}&lang=${lang}`
        );
        if (res.ok) {
            setTestData(JSON.stringify(res.data));
        }
    };

    const handleMenu = open => e => {
        if (open) {
            if (isSmallScreen) {
                setMenuEl(e.currentTarget);
            }
            setShowMenu(true);
        } else {
            setMenuEl(null);
            setShowMenu(false);
        }
    };

    const updateCurrentEmailHtml = async () => {
        if (_.isEmpty(emailTemplateSelected)) return;
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData);
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        const res = await http.postJSON(
            '/system/getUpdatedEmailHtml',
            {
                emailTemplateSelected,
                recipientEmail: testEmailAddress,
                testData: testDataToSend,
                lang,
                emailConfig: _.get(formik.values, 'emails'),
                locationType: emailDataLocationType,
                includeCharityFields
            },
            true
        );
        if (res.ok) {
            setCurrentEmailHtml(res.data.emailHtml);
        } else {
            setCurrentEmailHtml('Unable to render email html');
        }
    };

    const loadInformationFormsConfig = async () => {
        setEmailsConfigLoading(true);
        const res = await http.getJSON(`/system/emails/`);
        if (res.ok) {
            setEmails(res.data.emails);
            let firstEmailTemplate = _.first(_.keys(_.get(res.data, 'emails', {})));
            if (!_.isNil(firstEmailTemplate)) {
                setEmailTemplateSelected(firstEmailTemplate);
            }
        }
        setEmailsConfigLoading(false);
    };

    const handleSendTestEmail = async () => {
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData);
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        const res = await http.post('/system/sendTestEmail', {
            emailTemplateSelected,
            recipientEmail: testEmailAddress,
            testData: testDataToSend,
            lang,
            emailConfig: _.get(formik.values, 'emails'),
            locationType: emailDataLocationType,
            includeCharityFields
        });
        if (res.ok) {
            onSnackbar(`Test ${emailTemplateSelected} email sent to ${testEmailAddress}`);
        } else {
            onSnackbar(`Error sending test email`, 'error');
        }
    };

    const handleSendAllTestEmails = async () => {
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData);
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        const res = await http.post('/system/sendAllTestEmails', {
            recipientEmail: testEmailAddress,
            testData: testDataToSend,
            lang,
            emailConfig: _.get(formik.values, 'emails'),
            locationType: emailDataLocationType,
            includeCharityFields
        });
        if (res.ok) {
            onSnackbar(`All test emails sent to ${testEmailAddress}`);
        } else {
            onSnackbar(`Error sending test emails`, 'error');
        }
    };

    const handleRerender = () => {
        setRenderScreen(renderScreen === 0 ? 1 : 0);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            emails
        },
        onSubmit: async values => {
            setIsLoading(true);
            _.values(values.emails).forEach(email => {
                if (!email.registrationEmail) {
                    delete email.registrationEmail;
                }
                if (!email.organizationApprovalEmail) {
                    delete email.organizationApprovalEmail;
                }
                if (!email.customEmail) {
                    delete email.customEmail;
                }
            });

            const localizationRes = await http.postJSON(
                '/system/updateEmails',
                {
                    emails: values.emails
                },
                true
            );
            if (localizationRes.ok) {
                onSnackbar('Values updated successfully');
                const res = await http.getJSON(`/system/emails/`);
                if (res.ok) {
                    setEmails(res.data.emails);
                }
            } else {
                onSnackbar('Failed to update localizations.', 'error');
            }

            setIsLoading(false);
        }
    });

    const handleFormikSave = () => {
        // since we're saving remove our general dirty field
        formik.setFieldValue('hasUnsavedChanges', undefined);
        formik.handleSubmit();
    };

    useEffect(() => {
        loadInformationFormsConfig();
        formik.handleReset();
    }, []);

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        const newWidth = headerRef.current.offsetWidth;
        setHeaderWidth(newWidth);
    }, [width]);

    useEffect(() => {
        if (!_.isEmpty(emailTemplateSelected)) {
            loadEmailTestData();
            updateCurrentEmailHtml();
        }
    }, [emailTemplateSelected, lang, includeCharityFields, emailDataLocationType]);

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {!isSmallScreen && (
                <div
                    style={{
                        width: 400
                    }}
                >
                    <Paper style={{ height: '100%', overflowY: 'auto' }}>
                        <ListItem data-cy={'system-information-editor-menu'}>
                            <Typography
                                variant="body1"
                                style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    display: 'block',
                                    paddingRight: 0,
                                    color: `${theme.palette.text.primary}`
                                }}
                            >
                                Emails / SMS / Push
                            </Typography>
                        </ListItem>
                        <SearchBar
                            searchString={emailTemplateSearch}
                            handleSearchChange={e => setEmailTemplateSearch(e.target.value)}
                        />
                        {_.keys(emails)
                            .filter(
                                key =>
                                    !key.includes('COLLECTOR_COMMODITY') &&
                                    (key.toLowerCase().includes(emailTemplateSearch.toLowerCase()) ||
                                        key
                                            .replace('MSG_', '')
                                            .replace('_', ' ')
                                            .toLowerCase()
                                            .includes(emailTemplateSearch.toLowerCase()) ||
                                        _.some(_.values(_.get(formik.values, `emails.${key}.${lang}`, {})), value => {
                                            if (_.isNil(value) || typeof value !== 'string') return false;
                                            return value.toLowerCase().includes(emailTemplateSearch.toLowerCase());
                                        }))
                            )
                            .map(emailTemplate => {
                                return (
                                    <MenuItem
                                        dense
                                        onClick={() => setEmailTemplateSelected(emailTemplate)}
                                        selected={emailTemplateSelected === emailTemplate}
                                        style={{ marginLeft: theme.spacing.unit }}
                                        data-cy={`information-forms-menu-item-${emailTemplate}`}
                                    >
                                        <Typography variant="caption">
                                            {_.startCase(
                                                emailTemplate
                                                    .replace('MSG_', '')
                                                    .replace('_', ' ')
                                                    .toLowerCase()
                                            )}
                                        </Typography>
                                    </MenuItem>
                                );
                            })}
                    </Paper>
                </div>
            )}

            <div
                ref={headerRef}
                style={{ width: '100%', padding: theme.spacing.unit * 2, height: '100%', overflowY: 'scroll' }}
            >
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        zIndex: 10,
                        boxShadow: theme.shadows[2],
                        width: _.isNil(headerRef.current) ? headerWidth - 50 : headerRef.current.offsetWidth - 50
                    }}
                >
                    <Typography variant="h5">
                        {isSmallScreen && (
                            <IconButton onClick={handleMenu(true)} data-cy="information-forms-menu-button">
                                <MdiIcon path={allMdiIcons['mdiMenu']} size={0.7} color={theme.palette.text.primary} />
                            </IconButton>
                        )}
                        {_.startCase(
                            emailTemplateSelected
                                .replace('MSG_', '')
                                .replace('_', ' ')
                                .toLowerCase()
                        )}
                    </Typography>
                    {isSmallScreen && (
                        <Menu
                            open={showMenu && isSmallScreen && !_.isNil(menuEl)}
                            anchorEl={menuEl}
                            onClose={handleMenu(false)}
                        >
                            <SearchBar
                                searchString={emailTemplateSearch}
                                handleSearchChange={e => setEmailTemplateSearch(e.target.value)}
                            />
                            {_.keys(emails)
                                .filter(
                                    key =>
                                        !key.includes('COLLECTOR_COMMODITY') &&
                                        (key.toLowerCase().includes(emailTemplateSearch.toLowerCase()) ||
                                            key
                                                .replace('MSG_', '')
                                                .replace('_', ' ')
                                                .toLowerCase()
                                                .includes(emailTemplateSearch.toLowerCase()) ||
                                            _.some(
                                                _.values(_.get(formik.values, `emails.${key}.${lang}`, {})),
                                                value => {
                                                    if (_.isNil(value) || typeof value !== 'string') return false;
                                                    return value
                                                        .toLowerCase()
                                                        .includes(emailTemplateSearch.toLowerCase());
                                                }
                                            ))
                                )
                                .map(emailTemplate => {
                                    return (
                                        <MenuItem
                                            dense
                                            onClick={() => setEmailTemplateSelected(emailTemplate)}
                                            selected={emailTemplateSelected === emailTemplate}
                                            style={{ marginLeft: theme.spacing.unit }}
                                            data-cy={`information-forms-menu-item-${emailTemplate}`}
                                        >
                                            <Typography variant="caption">
                                                {_.startCase(
                                                    emailTemplate
                                                        .replace('MSG_', '')
                                                        .replace('_', ' ')
                                                        .toLowerCase()
                                                )}
                                            </Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Menu>
                    )}
                    <div>
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={(e, lang) => {
                                if (!_.isEmpty(lang)) setLang(lang);
                            }}
                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: theme.spacing.unit }}
                        >
                            <ToggleButton style={{ flexGrow: 1 }} value="en" data-cy={`language-toggle-en`}>
                                English
                            </ToggleButton>
                            <ToggleButton style={{ flexGrow: 1 }} value="fr" data-cy={`language-toggle-fr`}>
                                French
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {formik.dirty && (
                            <Typography variant="body2" style={{ color: 'red', marginTop: theme.spacing.unit }}>
                                There are unsaved changes
                            </Typography>
                        )}
                    </div>

                    <Button
                        data-cy={`information-editor-submit`}
                        color="primary"
                        onClick={handleFormikSave}
                        disabled={isLoading}
                        variant="contained"
                    >
                        Save
                        {isLoading && <CircularProgress size={16} />}
                    </Button>
                </Paper>
                <div
                    style={{
                        paddingTop: theme.spacing.unit,
                        marginTop: formik.dirty ? 75 : 50,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    {emailsConfigLoading ? (
                        <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                            <div
                                style={{
                                    margin: theme.spacing.unit * 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress />
                            </div>
                        </Paper>
                    ) : (
                        <>
                            <FormsWidget theme={theme} formik={formik} cyString={`send-test-email`}>
                                <Grid container spacing={theme.spacing.unit}>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="h6">Send Test Email</Typography>
                                            <IconButton
                                                style={{ padding: 2 }}
                                                onClick={() => setSendTestEmailExpanded(!sendTestEmailExpanded)}
                                            >
                                                <Icon>
                                                    {sendTestEmailExpanded
                                                        ? 'keyboard_arrow_up'
                                                        : 'keyboard_arrow_down'}
                                                </Icon>
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Collapse in={sendTestEmailExpanded}>
                                        <Grid item xs="12">
                                            <Divider style={{ marginBottom: theme.spacing.unit * 2 }} />

                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item xs="12">
                                                    <div style={{ display: 'flex' }}>
                                                        <TextField
                                                            type="email"
                                                            fullWidth
                                                            variant="outlined"
                                                            name="lat"
                                                            label={'Recipient Email'}
                                                            value={testEmailAddress}
                                                            onChange={e =>
                                                                setTestEmailAddress(e.target.value.toLowerCase())
                                                            }
                                                            style={{ paddingRight: theme.spacing.unit * 0.75 }}
                                                            error={
                                                                !_.isEmpty(testEmailAddress) &&
                                                                validate(['email'], testEmailAddress, lang).fail
                                                            }
                                                            helperText={
                                                                !_.isEmpty(testEmailAddress) &&
                                                                validate(['email'], testEmailAddress, lang).reason
                                                            }
                                                        />
                                                        <Button
                                                            data-cy="send-test-email-button"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleSendTestEmail}
                                                            style={{ height: 55, width: 110 }}
                                                            disabled={
                                                                _.isEmpty(testEmailAddress) ||
                                                                validate(['email'], testEmailAddress, lang).fail
                                                            }
                                                        >
                                                            Send
                                                            <Icon style={{ marginLeft: theme.spacing.unit }}>
                                                                reply
                                                            </Icon>
                                                        </Button>
                                                        <Button
                                                            data-cy="send-test-all-emails-button"
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                warnAction(() => {
                                                                    handleSendAllTestEmails();
                                                                }, `Are send ALL test emails to ${testEmailAddress}?`)
                                                            }
                                                            style={{
                                                                height: 55,
                                                                marginLeft: theme.spacing.unit,
                                                                width: 160
                                                            }}
                                                            disabled={
                                                                _.isEmpty(testEmailAddress) ||
                                                                validate(['email'], testEmailAddress, lang).fail
                                                            }
                                                        >
                                                            Send All
                                                            <Icon style={{ marginLeft: theme.spacing.unit }}>
                                                                reply_all
                                                            </Icon>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs="9">
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography variant="body2">
                                                            <Switch
                                                                name="customizeTestData"
                                                                checked={customizeTestData}
                                                                onChange={e => setCustomizeTestData(e.target.checked)}
                                                                color="primary"
                                                                style={{ padding: 0, margin: 0, height: '25px' }}
                                                            />
                                                            Customize Test Data for Email
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={emailDataLocationType === 'Residential'}
                                                                onChange={e => setEmailDataLocationType('Residential')}
                                                                value={'Residential'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            {isEXPRegion() ? 'Personal' : 'Residential'}
                                                        </Typography>
                                                        {!isEXPRegion() && (
                                                            <Typography variant="body2">
                                                                <Checkbox
                                                                    checked={emailDataLocationType === 'Condo'}
                                                                    onChange={e => setEmailDataLocationType('Condo')}
                                                                    value={'Condo'}
                                                                    style={{
                                                                        padding: theme.spacing.unit / 2
                                                                    }}
                                                                />
                                                                Condo
                                                            </Typography>
                                                        )}
                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={emailDataLocationType === 'Commercial'}
                                                                onChange={e => setEmailDataLocationType('Commercial')}
                                                                value={'Commercial'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            {isEXPRegion() ? 'Business' : 'Commercial'}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={includeCharityFields}
                                                                onChange={e =>
                                                                    setIncludeCharityFields(e.target.checked)
                                                                }
                                                                value={includeCharityFields}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            Donating / Is Charity
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs="3">
                                                    <div style={{ width: '100%', textAlign: 'right' }}>
                                                        <ExternalLink
                                                            text="Edit SendGrid Template"
                                                            url={'https://mc.sendgrid.com/dynamic-templates'}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs="12">
                                                    <Collapse in={customizeTestData}>
                                                        <TextField
                                                            type="text"
                                                            fullWidth
                                                            multiline
                                                            variant="outlined"
                                                            name="lat"
                                                            label={'TestData'}
                                                            value={testData}
                                                            onChange={e => setTestData(e.target.value)}
                                                            style={{ paddingRight: theme.spacing.unit * 0.75 }}
                                                        />
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </FormsWidget>
                            <FormsWidget theme={theme} formik={formik} cyString={`${lang}-emails-input`}>
                                {!_.isNil(emailTemplateSelected) && !_.isEmpty(emailTemplateSelected) && (
                                    <Grid container spacing={theme.spacing.unit}>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography variant="h6">Edit Content</Typography>
                                            </div>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs="12">
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Typography variant="body2" style={{ marginTop: 10 }}>
                                                    Supported Dynamic Fields:{' '}
                                                </Typography>
                                                {_.get(
                                                    formik,
                                                    `values.emails.${emailTemplateSelected}.dynamicFields`,
                                                    []
                                                ).map((value, i) => (
                                                    <Chip
                                                        key={value}
                                                        label={value}
                                                        style={{ margin: theme.spacing.unit / 2, padding: 0 }}
                                                    />
                                                ))}
                                            </div>
                                        </Grid>
                                        {!_.isNil(_.get(formik, `values.emails.${emailTemplateSelected}.icon`)) && (
                                            <>
                                                <Grid item xs="12">
                                                    <CustomFormTitle
                                                        style={{
                                                            margin: theme.spacing.unit,
                                                            marginTop: theme.spacing.unit * 2
                                                        }}
                                                        titleText="Edit Email Content"
                                                        iconName="edit"
                                                    />
                                                </Grid>
                                                <Grid item xs="12">
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={
                                                                    !(
                                                                        _.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.registrationEmail`
                                                                        ) === true ||
                                                                        _.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.organizationApprovalEmail`
                                                                        ) === true ||
                                                                        _.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.customEmail`
                                                                        ) === true
                                                                    )
                                                                }
                                                                onChange={e => {
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.registrationEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.organizationApprovalEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.customEmail`,
                                                                        false
                                                                    );
                                                                    handleRerender();
                                                                }}
                                                                value={'General'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            General
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={
                                                                    _.get(
                                                                        formik.values,
                                                                        `emails.${emailTemplateSelected}.registrationEmail`
                                                                    ) === true
                                                                }
                                                                onChange={e => {
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.registrationEmail`,
                                                                        true
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.organizationApprovalEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.customEmail`,
                                                                        false
                                                                    );
                                                                    handleRerender();
                                                                }}
                                                                value={'Registration'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            Registration
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            <Checkbox
                                                                checked={
                                                                    _.get(
                                                                        formik.values,
                                                                        `emails.${emailTemplateSelected}.organizationApprovalEmail`
                                                                    ) === true
                                                                }
                                                                onChange={e => {
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.registrationEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.organizationApprovalEmail`,
                                                                        true
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.customEmail`,
                                                                        false
                                                                    );
                                                                    handleRerender();
                                                                }}
                                                                value={'General'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            Organization Approval
                                                        </Typography>
                                                        {/* <Typography variant="body2">
                                                            <Checkbox
                                                                checked={
                                                                    _.get(
                                                                        formik.values,
                                                                        `emails.${emailTemplateSelected}.customEmail`
                                                                    ) === true
                                                                }
                                                                onChange={e => {
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.registrationEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.organizationApprovalEmail`,
                                                                        false
                                                                    );
                                                                    formik.setFieldValue(
                                                                        `emails.${emailTemplateSelected}.customEmail`,
                                                                        true
                                                                    );
                                                                    handleRerender();
                                                                }}
                                                                value={'Registration'}
                                                                style={{
                                                                    padding: theme.spacing.unit / 2
                                                                }}
                                                            />
                                                            Custom
                                                        </Typography> */}
                                                    </div>
                                                </Grid>
                                                <Grid item xs="12" sm="6">
                                                    <div
                                                        style={{
                                                            maxHeight: 650,
                                                            overflowY: 'scroll',
                                                            overflowX: 'hidden',
                                                            padding: 10
                                                        }}
                                                    >
                                                        <Grid container spacing={theme.spacing.unit}>
                                                            <Grid item xs={12}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <img
                                                                        alt={_.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.icon`
                                                                        )}
                                                                        style={{
                                                                            height: 55,
                                                                            marginRight: theme.spacing.unit
                                                                        }}
                                                                        src={`${iconPath}/${_.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.icon`
                                                                        )}.png`}
                                                                    />
                                                                    <FormControl fullWidth>
                                                                        <InputLabel variant="outlined">Icon</InputLabel>
                                                                        <Select
                                                                            {...formik.getFieldProps(
                                                                                `emails.${emailTemplateSelected}.icon`
                                                                            )}
                                                                            // value={emailTemplateSelected}
                                                                            // onChange={e => setEmailTemplateSelected(e.target.value)}
                                                                            input={
                                                                                <OutlinedInput
                                                                                    labelWidth={
                                                                                        'Icon'.length *
                                                                                        theme.spacing.unit
                                                                                    }
                                                                                    name="iconSelect"
                                                                                />
                                                                            }
                                                                            style={{
                                                                                minWidth: 168
                                                                            }}
                                                                            data-cy={`information-forms-email-icon-select`}
                                                                        >
                                                                            {[
                                                                                'appcircle',
                                                                                'calendar',
                                                                                'truck',
                                                                                'cardcircle',
                                                                                'check',
                                                                                'heart',
                                                                                'bin'
                                                                            ].map(value => (
                                                                                <MenuItem
                                                                                    data-cy={`information-forms-email-icon-select-${value}`}
                                                                                    value={value}
                                                                                >
                                                                                    {value}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </Grid>

                                                            {Object.keys(
                                                                _.get(
                                                                    formik,
                                                                    `values.emails.${emailTemplateSelected}.${lang}`
                                                                )
                                                            ).map((objKey, idx) => {
                                                                let path = `emails.${emailTemplateSelected}.${lang}`;
                                                                let obj = _.get(
                                                                    formik,
                                                                    `values.emails.${emailTemplateSelected}.${lang}`
                                                                );
                                                                // if contain array of strings, create textfield for each element
                                                                if (
                                                                    objKey.toLowerCase().includes('sms') ||
                                                                    objKey.toLowerCase().includes('push')
                                                                ) {
                                                                    return <></>;
                                                                } else if (Array.isArray(obj[objKey])) {
                                                                    return obj[objKey].map((item, childIdx) => (
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                {...formik.getFieldProps(
                                                                                    `${path}.${objKey}[${childIdx}]`
                                                                                )}
                                                                                error={
                                                                                    _.get(
                                                                                        formik.touched,
                                                                                        `${path}.${objKey}[${childIdx}]`,
                                                                                        false
                                                                                    ) &&
                                                                                    _.get(
                                                                                        formik.errors,
                                                                                        `${path}.${objKey}[${childIdx}]`,
                                                                                        false
                                                                                    )
                                                                                        ? true
                                                                                        : null
                                                                                }
                                                                                InputLabelProps={{
                                                                                    shrink: !_.isEmpty(
                                                                                        formik.getFieldProps(
                                                                                            `${path}.${objKey}[${childIdx}]`
                                                                                        ).value
                                                                                    )
                                                                                }}
                                                                                label={`${_.startCase(
                                                                                    objKey
                                                                                )} ${childIdx + 1}`}
                                                                                style={{
                                                                                    marginTop: theme.spacing.unit
                                                                                }}
                                                                                multiline
                                                                                variant="outlined"
                                                                                helperText={
                                                                                    _.get(
                                                                                        formik.touched,
                                                                                        `${path}.${objKey}[${childIdx}]`,
                                                                                        false
                                                                                    ) &&
                                                                                    _.get(
                                                                                        formik.errors,
                                                                                        `${path}.${objKey}[${childIdx}]`,
                                                                                        false
                                                                                    )
                                                                                        ? _.get(
                                                                                              formik.errors,
                                                                                              `${path}.${objKey}[${childIdx}]`,
                                                                                              false
                                                                                          )
                                                                                        : null
                                                                                }
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    ));
                                                                } else {
                                                                    return (
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                {...formik.getFieldProps(
                                                                                    `${path}.${objKey}`
                                                                                )}
                                                                                error={
                                                                                    _.get(
                                                                                        formik.touched,
                                                                                        `${path}.${objKey}`,
                                                                                        false
                                                                                    ) &&
                                                                                    _.get(
                                                                                        formik.errors,
                                                                                        `${path}.${objKey}`,
                                                                                        false
                                                                                    )
                                                                                        ? true
                                                                                        : null
                                                                                }
                                                                                InputLabelProps={{
                                                                                    shrink: !_.isEmpty(
                                                                                        formik.getFieldProps(
                                                                                            `${path}.${objKey}`
                                                                                        ).value
                                                                                    )
                                                                                }}
                                                                                label={_.startCase(objKey)}
                                                                                style={{
                                                                                    marginTop: theme.spacing.unit
                                                                                }}
                                                                                multiline
                                                                                variant="outlined"
                                                                                helperText={
                                                                                    _.get(
                                                                                        formik.touched,
                                                                                        `${path}.${objKey}`,
                                                                                        false
                                                                                    ) &&
                                                                                    _.get(
                                                                                        formik.errors,
                                                                                        `${path}.${objKey}`,
                                                                                        false
                                                                                    )
                                                                                        ? _.get(
                                                                                              formik.errors,
                                                                                              `${path}.${objKey}`,
                                                                                              false
                                                                                          )
                                                                                        : null
                                                                                }
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    );
                                                                }
                                                            })}
                                                        </Grid>
                                                    </div>
                                                </Grid>

                                                <Grid item xs="12" sm="6">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="h6">Preview</Typography>
                                                        <IconButton
                                                            style={{ padding: 0 }}
                                                            onClick={updateCurrentEmailHtml}
                                                        >
                                                            <Icon>refresh</Icon>
                                                        </IconButton>
                                                    </div>
                                                    <Divider />
                                                    <div
                                                        style={{
                                                            minHeight: 300,
                                                            maxHeight: 600,
                                                            overflowY: 'scroll',
                                                            border: '1px solid grey',
                                                            borderRadius: 5,
                                                            margin: theme.spacing.unit,
                                                            padding: theme.spacing.unit / 2,
                                                            fontFamily: 'sans-serif',
                                                            color: colors.grey[700],
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(currentEmailHtml)
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            </>
                                        )}

                                        {_.some(['smsText', 'smsTextBasic', 'smsTextDetailed'], key =>
                                            Object.keys(
                                                _.get(formik, `values.emails.${emailTemplateSelected}.${lang}`)
                                            ).includes(key)
                                        ) && (
                                            <>
                                                <Grid item xs="12">
                                                    <CustomFormTitle
                                                        style={{
                                                            margin: theme.spacing.unit,
                                                            marginTop: theme.spacing.unit * 2
                                                        }}
                                                        titleText="Edit SMS Content"
                                                        iconName="edit"
                                                    />
                                                </Grid>
                                                {['smsText', 'smsTextBasic', 'smsTextDetailed'].map(field => {
                                                    if (
                                                        _.isNil(
                                                            _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )
                                                        )
                                                    ) {
                                                        return <></>;
                                                    }
                                                    return (
                                                        <TextField
                                                            {...formik.getFieldProps(
                                                                `emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )}
                                                            error={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? true
                                                                    : null
                                                            }
                                                            InputLabelProps={{
                                                                shrink: !_.isEmpty(
                                                                    formik.getFieldProps(
                                                                        `emails.${emailTemplateSelected}.${lang}.${field}`
                                                                    ).value
                                                                )
                                                            }}
                                                            label={_.startCase(field)}
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            multiline
                                                            variant="outlined"
                                                            helperText={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? _.get(
                                                                          formik.errors,
                                                                          `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                          false
                                                                      )
                                                                    : null
                                                            }
                                                            fullWidth
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}

                                        {_.some(['pushText', 'pushTitle', 'pushTextCharity'], key =>
                                            Object.keys(
                                                _.get(formik, `values.emails.${emailTemplateSelected}.${lang}`)
                                            ).includes(key)
                                        ) && (
                                            <>
                                                <Grid item xs="12">
                                                    <CustomFormTitle
                                                        style={{
                                                            margin: theme.spacing.unit,
                                                            marginTop: theme.spacing.unit * 2
                                                        }}
                                                        titleText="Edit Push Notification Content"
                                                        iconName="edit"
                                                    />
                                                </Grid>
                                                {['pushTitle', 'pushText', 'pushTextCharity'].map(field => {
                                                    if (
                                                        _.isNil(
                                                            _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )
                                                        )
                                                    ) {
                                                        return <></>;
                                                    }
                                                    return (
                                                        <TextField
                                                            {...formik.getFieldProps(
                                                                `emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )}
                                                            error={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? true
                                                                    : null
                                                            }
                                                            InputLabelProps={{
                                                                shrink: !_.isEmpty(
                                                                    formik.getFieldProps(
                                                                        `emails.${emailTemplateSelected}.${lang}.${field}`
                                                                    ).value
                                                                )
                                                            }}
                                                            label={_.startCase(field)}
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            multiline
                                                            variant="outlined"
                                                            helperText={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? _.get(
                                                                          formik.errors,
                                                                          `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                          false
                                                                      )
                                                                    : null
                                                            }
                                                            fullWidth
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </FormsWidget>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withWidth()(withTheme()(Emails));

function FormsWidget(props) {
    const { children, theme, cyString } = props;
    return (
        <Paper
            style={{
                padding: theme.spacing.unit * 2,
                margin: theme.spacing.unit / 2,
                marginTop: theme.spacing.unit * 2
            }}
        >
            <Grid container spacing={theme.spacing.unit} data-cy={cyString ? cyString : null}>
                {children}
            </Grid>
        </Paper>
    );
}

const SearchBar = ({ searchString, handleSearchChange }) => {
    return (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
            <TextField
                name="search"
                label="Search"
                fullWidth
                variant="outlined"
                value={searchString || ''}
                onChange={e => handleSearchChange(e)}
                margin="dense"
                autoComplete="false"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};
