import React, { useState, useMemo, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import moment from 'moment-timezone';

import { USER_PERMISSIONS, DRIVER_PAYMENT_MODELS, ROLES } from 'constants.js';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    LinearProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    colors,
    Typography,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    TextField,
    IconButton,
    Icon,
    FormGroup,
    FormControlLabel,
    Switch,
    FormLabel,
    Divider,
    FormHelperText,
    Chip
} from '@material-ui/core';

import { validate } from '../../../utils/validate';

import GMapsAutocomplete from 'components/GMapsAutocomplete';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

import CommissionRatesInput from 'components/CustomInputs/CommissionRatesInput';

import { getSelect, getTextInput, getTimePicker, getSwitch } from '../helperFunctions';

import HelpDialog from 'components/HelpDialog/HelpDialog';
import useGetJSON from 'utils/hooks/useGetJSON';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import DateListInput from 'components/InputComponents/DateListInput';
import { useEffect } from 'react';
import { useRef } from 'react';

const styles = theme => ({
    formGroup: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing.unit * 6
        }
    },
    twoFactorAuth: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    payThroughSystemCentered: {
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'center',
            marginBottom: theme.spacing.unit * -2
        }
    }
});

function UserForm(props) {
    const {
        data: { twoFactorAuthentication }
    } = useGetJSON(`/system/twoFactorAuthentication`);

    const {
        user,
        http,
        google,
        loading,
        editing,
        operator,
        open,
        collectors,
        processors,
        commodities,
        onSubmit,
        onClose,
        theme,
        fullScreen,
        driverConfig,
        twoFactorAuthenticationWarning,
        classes,
        promptForDriverCode,
        accountTypes = ['System Administrator', 'Collector Administrator', 'Collector Employee', ...ROLES],
        pickupsEnabled
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [userAccountType, setUserAccountType] = useState(
        _.get(
            user,
            'accountType',
            accountTypes.includes('Collector Employee') ? 'Collector Employee' : _.first(accountTypes)
        )
    );
    const [collectorId, setCollectorId] = useState(
        operator.accountType === 'Collector Administrator' && _.get(operator, 'collectors', []).length <= 1
            ? _.get(user, 'collector._id', _.get(operator, 'collector._id', ''))
            : _.get(user, 'collector._id', '')
    );
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newAccountPermissions, setNewAccountPermissions] = useState([]);
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [newTwoFactorAuthentication, setTwoFactorAuthentication] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [newUserId, setNewUserId] = useState('');
    const collector = _.get(operator, 'collector', '');
    const [selectedCollector, setSelectedCollector] = useState(
        _.find(collectors, c => c._id.toString() === collectorId.toString())
    );

    const driverCodeRef = useRef(null);
    const [driverCodeHelperText, setDriverCodeHelperText] = useState('');

    let userPermissions =
        operator.accountType === 'System Administrator'
            ? USER_PERMISSIONS.filter(p => !(p === 'Driver' && !pickupsEnabled))
            : USER_PERMISSIONS.filter(
                  p =>
                      !(
                          p === 'Driver' &&
                          (!_.get(collector, 'configuration.enablePickups', false) || !pickupsEnabled)
                      ) && !(p === 'Clerk' && !_.get(collector, 'configuration.enableCounting', false))
              );

    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const defaultStartTime = moment()
        .startOf('day')
        .add(7, 'hours')
        .add(50, 'minutes')
        .toDate();

    const defaultEndTime = moment()
        .startOf('day')
        .add(17, 'hours')
        .toDate();

    const handleChangeCollector = e => {
        const { value } = e.target;
        setCollectorId(value);
        setSelectedCollector(_.find(collectors, c => c._id.toString() === value.toString()));
    };

    const handleChangeAccountType = e => {
        const { value } = e.target;
        setUserAccountType(value);

        if (value === 'System Administrator' || ROLES.includes(operator.accountType)) {
            setCollectorId(_.get(operator, 'collector._id', ''));
        }
    };

    const handleClearTrustedDevices = async () => {
        const _id = _.get(user, '_id');
        const res = await http.post(`/users/${_id}/clearTrustedDevices`);
    };

    const onUserFormSubmit = values => {
        let valuesToSubmit = {};

        valuesToSubmit.accountType = values.accountType;
        valuesToSubmit.email = values.email.trim();
        valuesToSubmit.phone = values.phone.replace(/[^0-9.]/g, '');
        valuesToSubmit.bypassTwoFactorAuthentication = values.bypassTwoFactorAuthentication;
        valuesToSubmit.twoFactorAuthentication = {};
        valuesToSubmit.twoFactorAuthentication.isEnabled = values.twoFactorAuthentication.isEnabled;
        valuesToSubmit.name = {};
        valuesToSubmit.name.first = values.name.first.trim();
        valuesToSubmit.name.last = values.name.last.trim();
        valuesToSubmit.home = values.home;
        valuesToSubmit._id = values._id;
        valuesToSubmit.permissions = {};
        valuesToSubmit.permissions.seeAllPendingPickups = values.permissions.seeAllPendingPickups;
        valuesToSubmit.permissions.seeCustomerPhoneNumbers = values.permissions.seeCustomerPhoneNumbers;

        valuesToSubmit.depotLoginID = values.depotLoginID;

        if (values.accountType !== 'System Administrator' && !ROLES.includes(values.accountType)) {
            if (values.accountType === 'Collector Administrator') {
                valuesToSubmit.collector = values.collectors[0];
                valuesToSubmit.collectors = values.collectors;
            } else {
                valuesToSubmit.collector = values.collector;
            }
        }
        if (values.accountType === 'Collector Employee') {
            const payThroughSystem = values.permissions.payThroughSystem;

            valuesToSubmit.accountPermissions = values.accountPermissions;
            valuesToSubmit.wage = values.wage && Math.round(values.wage * 100); //convert to cents
            //valuesToSubmit.permissions.seeGasCost = values.permissions.seeGasCost;
            //valuesToSubmit.permissions.seePaymentInfo = values.permissions.seePaymentInfo;
            valuesToSubmit.permissions.payThroughSystem = payThroughSystem;
            valuesToSubmit.permissions.contractor = payThroughSystem ? values.permissions.contractor : false;
            valuesToSubmit.permissions.paymentModel = values.permissions.paymentModel;
            valuesToSubmit.commissionRates = values.commissionRates;
            if (values.accountPermissions.includes('Driver')) {
                valuesToSubmit.startTime = values.startTime;
                valuesToSubmit.endTime = values.endTime;
                valuesToSubmit.driverCode = values.driverCode;
                valuesToSubmit.kpl = values.kpl;
                valuesToSubmit.driverEfficiency = values.driverEfficiency;
                valuesToSubmit.stopBaseDuration = values.stopBaseDuration;
                valuesToSubmit.automaticallyUpdateDriverETAVariables = values.automaticallyUpdateDriverETAVariables;
                valuesToSubmit.enableLunch = values.enableLunch;
                valuesToSubmit.paidLunch = values.paidLunch;
                valuesToSubmit.driverPickupCapacity = values.driverPickupCapacity;
                valuesToSubmit.driverLoadsPerDay = values.driverLoadsPerDay;
                valuesToSubmit.driverLoadTimeOffset = values.driverLoadTimeOffset;
                valuesToSubmit.driverBagCapacity = values.driverBagCapacity;
                valuesToSubmit.driverVacationDays = values.driverVacationDays.map(date => moment(date));
                valuesToSubmit.driverDestination =
                    values.driverDestination === 'Empty' || values.driverDestination === ''
                        ? null
                        : values.driverDestination;
                valuesToSubmit.permissions.abortPickups = values.permissions.abortPickups;
                valuesToSubmit.payOutGas = payThroughSystem ? values.payOutGas : false;
                valuesToSubmit.recordVehicleData = values.recordVehicleData;
            }
        } else {
            valuesToSubmit.accountPermissions = [];
        }
        onSubmit(valuesToSubmit);
    };

    const groupedCollectors = useMemo(() => _.keyBy(collectors, '_id'), [collectors]);
    const selectableCollectorsAlphabetical = useMemo(() => {
        const activeCollectors = _.filter(collectors, collector => !_.get(collector, 'disabled', false));
        //make sure the users current collector is selectable even if disabled
        if (!_.isNil(_.get(user, 'collector._id'))) {
            const userCollector = _.find(collectors, { _id: _.get(user, 'collector._id') });
            if (_.get(userCollector, 'disabled', false)) {
                return [...activeCollectors, userCollector];
            }
        }
        return _.sortBy(activeCollectors, 'name');
    }, [collectors, user]);

    useEffect(() => {
        if (promptForDriverCode && driverCodeRef.current) {
            driverCodeRef.current.focus();
            setDriverCodeHelperText('Please input a driver code for this user');
        }
    }, [driverCodeRef, driverCodeRef.current]);

    const renderVacationDays = (date, onRemoveDate) => {
        const timezone = _.get(selectedCollector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
        return moment(date).isBefore(moment()) ? (
            <Chip
                key={moment(date)
                    .tz(timezone)
                    .format('MMM D, YYYY')}
                style={{
                    backgroundColor: colors.grey[200],
                    margin: theme.spacing.unit / 2
                }}
                deleteIcon={
                    <IconButton style={{ padding: 0 }} disabled>
                        <Icon>cancel</Icon>
                    </IconButton>
                }
                label={moment(date)
                    .tz(timezone)
                    .format('MMM D, YYYY')}
                onDelete={() => {}}
            />
        ) : (
            <Chip
                key={moment(date)
                    .tz(timezone)
                    .format('MMM D, YYYY')}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    margin: theme.spacing.unit / 2
                }}
                label={moment(date)
                    .tz(timezone)
                    .format('MMM D, YYYY')}
                onDelete={() => onRemoveDate(date)}
            />
        );
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    // All accounts
                    accountType: userAccountType,
                    accountPermissions: user === null ? newAccountPermissions : _.get(user, 'accountPermissions', []),
                    name:
                        user === null
                            ? {
                                  first: newFirstName,
                                  last: newLastName
                              }
                            : {
                                  first: _.get(user, 'name.first', ''),
                                  last: _.get(user, 'name.last', '')
                              },
                    email: user === null ? newEmail : _.get(user, 'email', ''),
                    phone: user === null ? newPhoneNumber : _.get(user, 'phone', ''),
                    twoFactorAuthentication: {
                        isEnabled:
                            user === null
                                ? newTwoFactorAuthentication
                                : _.get(user, 'twoFactorAuthentication.isEnabled', false)
                    },
                    bypassTwoFactorAuthentication: _.get(user, 'bypassTwoFactorAuthentication', false),
                    home: _.get(user, 'home', _.get(groupedCollectors, `${collectorId}.location`)),
                    _id: _.get(user, '_id', ''),
                    permissions: {
                        seeAllPendingPickups: _.get(
                            user,
                            'permissions.seeAllPendingPickups',
                            operator.accountType === 'Collector Administrator' ? true : false
                        ),
                        seeCustomerPhoneNumbers: _.get(user, 'permissions.seeCustomerPhoneNumbers', true),
                        //seeGasCost: _.get(user, 'permissions.seeGasCost', false),
                        //seePaymentInfo: _.get(user, 'permissions.seePaymentInfo', false),
                        payThroughSystem: _.get(user, 'permissions.payThroughSystem', false),
                        contractor: _.get(user, 'permissions.contractor', false),
                        paymentModel: _.get(user, 'permissions.paymentModel', DRIVER_PAYMENT_MODELS[0]),
                        abortPickups: _.get(user, 'permissions.abortPickups', true)
                    },

                    // Collector Administrators / Drivers
                    collector: collectorId,
                    collectors: _.get(user, 'collectors', []).map(collector => collector._id),

                    // Drivers
                    startTime: _.get(
                        user,
                        'startTime',
                        moment(_.get(driverConfig, 'defaultStartTime', defaultStartTime)).toDate()
                    ),
                    endTime: _.get(user, 'endTime', _.get(driverConfig, 'defaultEndTime', defaultEndTime)),
                    driverCode: _.get(user, 'driverCode', ''),
                    wage: _.get(user, 'wage')
                        ? user.wage / 100
                        : _.get(groupedCollectors, `${collectorId}.employeeWage`, 0) / 100,
                    kpl: _.get(user, 'kpl') ? user.kpl : _.get(groupedCollectors, `${collectorId}.fuelEfficiency`),
                    driverEfficiency: _.get(user, 'driverEfficiency', _.get(driverConfig, 'stopPerBagDuration', 30)),
                    stopBaseDuration: _.get(user, 'stopBaseDuration', _.get(driverConfig, 'stopBaseDuration', 240)),
                    automaticallyUpdateDriverETAVariables: _.get(
                        user,
                        'automaticallyUpdateDriverETAVariables',
                        _.get(driverConfig, 'defaultAutomaticallyUpdateDriverETAVariables', true)
                    ),
                    enableLunch: _.get(user, 'enableLunch', false),
                    paidLunch: _.get(user, 'paidLunch', false),
                    driverPickupCapacity: _.get(
                        user,
                        'driverPickupCapacity',
                        _.get(driverConfig, 'defaultDriverPickupCapacity', 40)
                    ),
                    driverLoadsPerDay: _.get(user, 'driverLoadsPerDay', 1),
                    driverLoadTimeOffset: _.get(user, 'driverLoadTimeOffset', 5),
                    driverVacationDays: _.get(user, 'driverVacationDays', []),
                    driverBagCapacity: _.get(
                        user,
                        'driverBagCapacity',
                        _.get(driverConfig, 'defaultDriverBagCapacity', 220)
                    ),
                    driverDestination:
                        _.get(selectedCollector, 'routeDestinationBy') === 'Zone'
                            ? 'Empty'
                            : _.get(user, 'driverDestination._id', ''),
                    //commissionRate: _.has(user, 'commissionRate') ? user.commissionRate / 100 : 0,
                    commissionRates: _.has(user, 'commissionRates') ? user.commissionRates : [],
                    payOutGas: _.get(user, 'payOutGas', false),
                    depotLoginID: user === null ? newUserId : _.get(user, 'depotLoginID', ''),
                    recordVehicleData: _.get(
                        user,
                        'recordVehicleData',
                        _.get(selectedCollector, 'configuration.enableClockin') ? true : false
                    )
                }}
                validationSchema={Yup.object().shape(
                    {
                        // All accounts
                        accountType: Yup.string()
                            .required('You must select an account type')
                            .oneOf(accountTypes, 'You must select an account type'),
                        accountPermissions: Yup.array().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.array().min(1, 'At least one permission is required')
                        }),
                        name: Yup.object({
                            first: Yup.string()
                                .required('First name is required')
                                .trim(),
                            last: Yup.string()
                                .required('Last name is required')
                                .trim()
                        }),
                        email: Yup.string()
                            .email()
                            .trim()
                            .required('Email is required'),
                        phone: Yup.string()
                            .required('Phone number is required')
                            .test('validPhoneNumber', 'Phone number invalid', value => {
                                if (!value) return false;
                                let errorsObj = validate(['phone'], value, lang);
                                return !errorsObj.fail;
                            }),
                        home: Yup.object()
                            .when(['accountType', 'accountPermissions'], {
                                is: (accountType, accountPermissions) =>
                                    accountType === 'Collector Employee' && accountPermissions.includes('Driver'),
                                then: Yup.object().required()
                            })
                            .nullable(),
                        permissions: Yup.object({
                            seeAllPendingPickups: Yup.boolean(),
                            seeCustomerPhoneNumbers: Yup.boolean()
                        }),

                        // Collector Administrators / Drivers
                        collector: Yup.string().when('accountType', {
                            is: 'Collector Employee', //is: value => value === 'System Administrator' && !ROLES.includes(value),
                            then: Yup.string().required('You must select a collector'),
                            otherwise: Yup.string()
                        }),
                        collectors: Yup.array().when('accountType', {
                            is: 'Collector Administrator',
                            then: Yup.array().min(1, 'You must select at least one collector'),
                            otherwise: Yup.array()
                        }),

                        // Driver Specific fields
                        driverCode: Yup.string().when(['accountType', 'accountPermissions'], {
                            is: (accountType, accountPermissions) =>
                                accountType === 'Collector Employee' && accountPermissions.includes('Driver'),
                            then: Yup.string()
                                .trim()
                                .required('Driver code Required')
                        }),
                        wage: Yup.number().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.number().required('Employee wage is required')
                        }),
                        kpl: Yup.number().when(['accountType', 'accountPermissions'], {
                            is: (accountType, accountPermissions) =>
                                accountType === 'Collector Employee' && accountPermissions.includes('Driver'),
                            then: Yup.number().required('Fuel efficiency is required')
                        }),
                        driverPickupCapacity: Yup.number().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.number()
                                .required('You must enter a pickup capacity')
                                .integer('This must be a whole number')
                                .min(1, 'This must be greater then 0')
                        }),
                        driverBagCapacity: Yup.number().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.number()
                                .required('You must enter a bag capacity')
                                .integer('This must be a whole number')
                                .min(1, 'This must be greater then 0')
                        }),
                        driverDestination: Yup.string().when(['accountType', 'accountPermissions'], {
                            is: (accountType, accountPermissions) =>
                                accountType === 'Collector Employee' && accountPermissions.includes('Driver'),
                            then: Yup.string().required('You must select a destination')
                        }),
                        driverLoadsPerDay: Yup.number().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.number()
                                .required('You must enter loads per day')
                                .integer('This must be a whole number')
                                .min(1, 'This must be greater then 0')
                        }),
                        driverLoadTimeOffset: Yup.number().when('accountType', {
                            is: value => value === 'Collector Employee',
                            then: Yup.number()
                                .required('You must enter load time')
                                .min(1, 'This must be greater then 0')
                        }),
                        //commissionRate: Yup.number().max(1, 'Must be less than or equal to 1'),
                        payOutGas: Yup.boolean(),
                        recordVehicleData: Yup.boolean(),
                        depotLoginID: Yup.string()
                            .when(['accountType', 'accountPermissions'], {
                                is: (accountType, accountPermissions) =>
                                    accountType === 'Collector Employee' && accountPermissions.includes('Clerk'),
                                then: Yup.string()
                                    .trim()
                                    .required('User ID Required')
                            })
                            .matches(/^([0-9]*\.*[0-9]*)*$/, {
                                message: 'Must be a number value'
                            })
                            .min(4, 'Must be exactly 4 digit number')
                            .max(4, 'Must be exactly 4 digit number')
                    },
                    ['depotLoginID']
                )}
                onSubmit={onUserFormSubmit}
            >
                {formik => {
                    return (
                        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
                            <DialogTitle>
                                <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Grid item sm={4} xs={12}>
                                        {editing ? 'Update' : 'Create'}
                                    </Grid>
                                    {!_.get(twoFactorAuthentication, 'isRequired', false) && (
                                        <Grid item xs={6} sm={4} className={classes.twoFactorAuth}>
                                            {getSwitch(
                                                theme,
                                                'twoFactorAuthentication.isEnabled',
                                                'Use 2FA',
                                                formik,
                                                {},
                                                {},
                                                user === null
                                                    ? e => {
                                                          setTwoFactorAuthentication(e.target.value === 'false');
                                                      }
                                                    : undefined
                                            )}
                                        </Grid>
                                    )}
                                    {_.get(twoFactorAuthentication, 'isRequired', false) && (
                                        <Grid item xs={6} sm={4} className={classes.twoFactorAuth}>
                                            {getSwitch(theme, 'bypassTwoFactorAuthentication', 'Bypass 2FA', formik)}
                                        </Grid>
                                    )}
                                    <Grid item xs={6} sm={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={handleClearTrustedDevices}
                                            data-cy="user-form-clear-trusted-devices"
                                        >
                                            {loc('accountSettings25', lang)}
                                        </Button>
                                    </Grid>
                                    {!_.isEmpty(twoFactorAuthenticationWarning) &&
                                        process.env.REACT_APP_ENV === 'TEST' && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" style={{ color: colors.red[500] }}>
                                                    {twoFactorAuthenticationWarning}
                                                </Typography>
                                            </Grid>
                                        )}
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Typography
                                    style={{ color: colors.orange[500], marginBottom: theme.spacing.unit * 2 }}
                                    variant="h6"
                                >
                                    {_.get(user, 'banned', false) && 'This account is currently banned'}
                                </Typography>

                                <FormLabel>User Info</FormLabel>
                                <Divider />
                                <FormGroup className={classes.formGroup}>
                                    <Grid container spacing={theme.spacing.unit}>
                                        {(operator.accountType === 'System Administrator' ||
                                            ROLES.includes(operator.accountType)) &&
                                            accountTypes.length > 1 && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={
                                                        _.get(formik, 'values.accountType') === 'Collector Employee'
                                                            ? 4
                                                            : _.get(formik, 'values.accountType') ===
                                                                  'System Administrator' ||
                                                              ROLES.includes(_.get(formik, 'values.accountType'))
                                                            ? 12
                                                            : 6
                                                    }
                                                >
                                                    {getSelect(
                                                        theme,
                                                        'accountType',
                                                        'Account Type',
                                                        accountTypes,
                                                        formik,
                                                        handleChangeAccountType
                                                    )}
                                                </Grid>
                                            )}
                                        {(operator.accountType === 'System Administrator' ||
                                            ROLES.includes(operator.accountType) ||
                                            (operator.accountType === 'Collector Administrator' &&
                                                _.get(operator, 'collectors', []).length > 1)) &&
                                            formik.values.accountType === 'Collector Employee' && (
                                                <Grid item xs={12} sm={4}>
                                                    <FormControl
                                                        fullWidth
                                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                                        error={
                                                            _.get(formik.touched, 'collector', false) &&
                                                            _.get(formik.errors, 'collector', false)
                                                                ? true
                                                                : null
                                                        }
                                                    >
                                                        <InputLabel variant="outlined">Collector</InputLabel>

                                                        <Select
                                                            {...formik.getFieldProps('collector')}
                                                            fullWidth
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={'collector'.length * theme.spacing.unit}
                                                                    data-cy={`${_.kebabCase('collector')}-input`}
                                                                />
                                                            }
                                                            onChange={handleChangeCollector}
                                                        >
                                                            {selectableCollectorsAlphabetical.map((collector, idx) => (
                                                                <MenuItem value={collector._id} key={collector._id}>
                                                                    {_.get(collector, 'name')}
                                                                    {_.get(collector, 'disabled') && (
                                                                        <span
                                                                            style={{
                                                                                marginLeft: theme.spacing.unit * 2,
                                                                                color: theme.palette.text.secondary
                                                                            }}
                                                                        >
                                                                            DISABLED
                                                                        </span>
                                                                    )}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        {(operator.accountType === 'System Administrator' ||
                                            ROLES.includes(operator.accountType)) &&
                                            formik.values.accountType === 'Collector Administrator' && (
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl
                                                        fullWidth
                                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                                        error={
                                                            _.get(formik.touched, 'collectors', false) &&
                                                            _.get(formik.errors, 'collectors', false)
                                                                ? true
                                                                : null
                                                        }
                                                    >
                                                        <InputLabel variant="outlined">Collectors</InputLabel>

                                                        <Select
                                                            {...formik.getFieldProps('collectors')}
                                                            fullWidth
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={
                                                                        'collectors'.length * theme.spacing.unit
                                                                    }
                                                                    data-cy={`${_.kebabCase('collectors')}-input`}
                                                                />
                                                            }
                                                            multiple={true}
                                                        >
                                                            {selectableCollectorsAlphabetical.map(
                                                                (collector, index) => (
                                                                    <MenuItem value={collector._id}>
                                                                        {_.get(collector, 'disabled') ? (
                                                                            <>
                                                                                {_.get(collector, 'name')}
                                                                                <span
                                                                                    style={{
                                                                                        marginLeft:
                                                                                            theme.spacing.unit * 2,
                                                                                        color:
                                                                                            theme.palette.text.secondary
                                                                                    }}
                                                                                >
                                                                                    DISABLED
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            _.get(collector, 'name')
                                                                        )}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        {formik.touched.collectors && formik.errors.collectors ? (
                                                            <FormHelperText error>
                                                                {formik.errors.collectors}
                                                            </FormHelperText>
                                                        ) : null}
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        {formik.values.accountType === 'Collector Employee' && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={
                                                    _.get(formik, 'values.accountType') === 'Collector Employee'
                                                        ? operator.accountType === 'Collector Administrator'
                                                            ? 6
                                                            : 4
                                                        : 12
                                                }
                                            >
                                                {getSelect(
                                                    theme,
                                                    'accountPermissions',
                                                    'Account Permissions',
                                                    userPermissions,
                                                    formik,
                                                    e => {
                                                        formik.getFieldProps('accountPermissions').onChange(e);
                                                        if (user === null) {
                                                            setNewAccountPermissions(e.target.value);
                                                        }
                                                    },
                                                    null,
                                                    null,
                                                    true
                                                )}
                                            </Grid>
                                        )}
                                        {operator.accountType === 'Collector Administrator' && (
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'email',
                                                    'Email',
                                                    formik,
                                                    'text',
                                                    {},
                                                    {},
                                                    null,
                                                    false,
                                                    user === null
                                                        ? e => {
                                                              setNewEmail(e.target.value);
                                                          }
                                                        : undefined
                                                )}
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            {getTextInput(
                                                theme,
                                                'name.first',
                                                'First Name',
                                                formik,
                                                'text',
                                                {},
                                                {},
                                                null,
                                                false,
                                                user === null
                                                    ? e => {
                                                          setNewFirstName(e.target.value);
                                                      }
                                                    : undefined
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {getTextInput(
                                                theme,
                                                'name.last',
                                                'Last Name',
                                                formik,
                                                'text',
                                                {},
                                                {},
                                                null,
                                                false,
                                                user === null
                                                    ? e => {
                                                          setNewLastName(e.target.value);
                                                      }
                                                    : undefined
                                            )}
                                        </Grid>
                                        {(operator.accountType === 'System Administrator' ||
                                            ROLES.includes(operator.accountType)) && (
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'email',
                                                    'Email',
                                                    formik,
                                                    'text',
                                                    {},
                                                    {},
                                                    null,
                                                    false,
                                                    user === null
                                                        ? e => {
                                                              setNewEmail(e.target.value);
                                                          }
                                                        : undefined
                                                )}
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            {getTextInput(
                                                theme,
                                                'phone',
                                                'Phone Number',
                                                formik,
                                                'tel',
                                                {
                                                    inputComponent: TextMaskPhoneNumber
                                                },
                                                {},
                                                null,
                                                false,
                                                user === null
                                                    ? e => {
                                                          setNewPhoneNumber(e.target.value);
                                                      }
                                                    : undefined
                                            )}
                                        </Grid>
                                        {!ROLES.includes(formik.values.accountType) &&
                                            formik.values.accountType !== 'System Administrator' && (
                                                <>
                                                    <Grid item xs={12} sm={6}>
                                                        {getTextInput(
                                                            theme,
                                                            'depotLoginID',
                                                            'User ID',
                                                            formik,
                                                            'text',
                                                            {},
                                                            {},
                                                            null,
                                                            false,
                                                            user === null
                                                                ? e => {
                                                                      setNewUserId(e.target.value);
                                                                  }
                                                                : undefined
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                    </Grid>
                                </FormGroup>

                                {formik.values.accountType === 'Collector Employee' &&
                                    _.get(formik, 'values.accountPermissions', []).includes('Driver') && (
                                        <>
                                            <FormLabel>Driver Info</FormLabel>
                                            <Divider />
                                            <FormGroup className={classes.formGroup}>
                                                <Grid container spacing={theme.spacing.unit}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={
                                                            _.get(formik, 'values.accountType') === 'Collector Employee'
                                                                ? 4
                                                                : 6
                                                        }
                                                    >
                                                        {/* {getTextInput(
                                                            theme,
                                                            'driverCode',
                                                            'Driver Unique Code',
                                                            formik
                                                        )} */}
                                                        <TextField
                                                            {...formik.getFieldProps('driverCode')}
                                                            multiline
                                                            type={'text'}
                                                            error={
                                                                _.get(formik.touched, 'driverCode', false) &&
                                                                _.get(formik.errors, 'driverCode', false)
                                                                    ? true
                                                                    : null
                                                            }
                                                            label={'Driver Unique Code'}
                                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                                            variant="outlined"
                                                            helperText={
                                                                _.get(formik.touched, 'driverCode', false) &&
                                                                _.get(formik.errors, 'driverCode', false)
                                                                    ? _.get(formik.errors, 'driverCode', false)
                                                                    : driverCodeHelperText
                                                            }
                                                            fullWidth
                                                            data-cy={`${_.kebabCase('driverCode')}-input`}
                                                            onChange={e => {
                                                                formik.getFieldProps('driverCode').onChange(e);
                                                            }}
                                                            inputRef={driverCodeRef}
                                                        />
                                                    </Grid>
                                                    {_.get(formik, 'values.accountType') === 'Collector Employee' && (
                                                        <>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTimePicker(
                                                                    theme,
                                                                    'startTime',
                                                                    'Start Time',
                                                                    formik,
                                                                    moment(formik.values.startTime).tz(
                                                                        _.get(
                                                                            groupedCollectors[formik.values.collector],
                                                                            'timezone',
                                                                            process.env.REACT_APP_REGION_TIMEZONE
                                                                        )
                                                                    ),
                                                                    time => {
                                                                        const hour = time.get('hour');
                                                                        const minute = time.get('minute');
                                                                        const { timezone } = groupedCollectors[
                                                                            formik.values.collector
                                                                        ];
                                                                        formik.setFieldValue(
                                                                            'startTime',
                                                                            moment()
                                                                                .tz(timezone)
                                                                                .set({ hour, minute })
                                                                                .toDate()
                                                                        );
                                                                    }
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTimePicker(
                                                                    theme,
                                                                    'endTime',
                                                                    'End Time',
                                                                    formik,
                                                                    moment(formik.values.endTime).tz(
                                                                        _.get(
                                                                            groupedCollectors[formik.values.collector],
                                                                            'timezone',
                                                                            process.env.REACT_APP_REGION_TIMEZONE
                                                                        )
                                                                    ),
                                                                    time => {
                                                                        const hour = time.get('hour');
                                                                        const minute = time.get('minute');
                                                                        const { timezone } = groupedCollectors[
                                                                            formik.values.collector
                                                                        ];
                                                                        formik.setFieldValue(
                                                                            'endTime',
                                                                            moment()
                                                                                .tz(timezone)
                                                                                .set({ hour, minute })
                                                                                .toDate()
                                                                        );
                                                                    }
                                                                )}
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={
                                                            _.get(formik, 'values.accountType') === 'Collector Employee'
                                                                ? 4
                                                                : 6
                                                        }
                                                    >
                                                        {getTextInput(
                                                            theme,
                                                            'kpl',
                                                            'Fuel Efficiency',
                                                            formik,
                                                            'number',
                                                            {
                                                                endAdornment: (
                                                                    <InputAdornment position="end">Km/L</InputAdornment>
                                                                )
                                                            },
                                                            { shrink: true }
                                                        )}
                                                    </Grid>

                                                    {_.get(formik, 'values.accountType') === 'Collector Employee' && (
                                                        <>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTextInput(
                                                                    theme,
                                                                    'driverPickupCapacity',
                                                                    'Pickup Capacity',
                                                                    formik,
                                                                    'number'
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTextInput(
                                                                    theme,
                                                                    'driverBagCapacity',
                                                                    'Bag Capacity',
                                                                    formik,
                                                                    'number'
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTextInput(
                                                                    theme,
                                                                    'driverLoadsPerDay',
                                                                    'Loads per Day',
                                                                    formik,
                                                                    'number'
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                {getTextInput(
                                                                    theme,
                                                                    'driverLoadTimeOffset',
                                                                    'Load Time Offset (hr)',
                                                                    formik,
                                                                    'number'
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                                                    error={
                                                                        _.get(
                                                                            formik.touched,
                                                                            'driverDestination',
                                                                            false
                                                                        ) &&
                                                                        _.get(formik.errors, 'driverDestination', false)
                                                                            ? true
                                                                            : null
                                                                    }
                                                                >
                                                                    <InputLabel variant="outlined">
                                                                        Destination
                                                                    </InputLabel>
                                                                    <Select
                                                                        {...formik.getFieldProps('driverDestination')}
                                                                        fullWidth
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={
                                                                                    'destination'.length *
                                                                                    theme.spacing.unit
                                                                                }
                                                                                data-cy={`${_.kebabCase(
                                                                                    'destination'
                                                                                )}-input`}
                                                                            />
                                                                        }
                                                                        disabled={
                                                                            _.get(
                                                                                selectedCollector,
                                                                                'routeDestinationBy'
                                                                            ) === 'Zone'
                                                                        }
                                                                    >
                                                                        {_.get(
                                                                            selectedCollector,
                                                                            'routeDestinationBy'
                                                                        ) === 'Zone' ? (
                                                                            <MenuItem value={'Empty'} key={'Empty'}>
                                                                                Zone Determined
                                                                            </MenuItem>
                                                                        ) : (
                                                                            processors.map((collector, idx) => (
                                                                                <MenuItem
                                                                                    value={collector._id}
                                                                                    key={collector._id}
                                                                                >
                                                                                    {_.get(collector, 'name')}
                                                                                </MenuItem>
                                                                            ))
                                                                        )}
                                                                    </Select>{' '}
                                                                    {_.get(
                                                                        formik.touched,
                                                                        'driverDestination',
                                                                        false
                                                                    ) &&
                                                                        _.get(
                                                                            formik.errors,
                                                                            'driverDestination',
                                                                            false
                                                                        ) && (
                                                                            <FormHelperText
                                                                                style={{
                                                                                    color: theme.palette.error.main
                                                                                }}
                                                                            >
                                                                                {_.get(
                                                                                    formik.errors,
                                                                                    'driverDestination',
                                                                                    null
                                                                                )}
                                                                            </FormHelperText>
                                                                        )}
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'driverEfficiency',
                                                            'Time Per Bag (sec)',
                                                            formik,
                                                            'number'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'stopBaseDuration',
                                                            'Time Per Stop (sec)',
                                                            formik,
                                                            'number'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        {getSwitch(
                                                            theme,
                                                            'automaticallyUpdateDriverETAVariables',
                                                            'Auto Update',
                                                            formik,
                                                            { marginTop: theme.spacing.unit * 2 },
                                                            { fontSize: '80%' }
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        {getSwitch(
                                                            theme,
                                                            'enableLunch',
                                                            'Take Lunch',
                                                            formik,
                                                            { marginTop: theme.spacing.unit * 2 },
                                                            { fontSize: '80%' }
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <GMapsAutocomplete
                                                            onBlur={formik.handleBlur}
                                                            http={http}
                                                            google={google}
                                                            location={{
                                                                lat: _.get(formik, 'values.home.lat'),
                                                                lng: _.get(formik, 'values.home.lng')
                                                            }}
                                                            label={
                                                                _.get(formik, 'values.accountType', '') ===
                                                                'Collector Employee'
                                                                    ? 'Start Location'
                                                                    : 'Location'
                                                            }
                                                            placeholder="Enter an address"
                                                            selectedValue={_.get(formik, 'values.home.description', '')}
                                                            types={['address']}
                                                            error={{
                                                                fail:
                                                                    _.get(formik.errors, 'home', false) &&
                                                                    _.get(formik.touched, 'home', false),
                                                                reason: 'You must enter a location'
                                                            }}
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            onSuggestionSelected={({ suggestion, place }) => {
                                                                formik.setFieldValue('home', {
                                                                    description: suggestion.description,
                                                                    place_id: suggestion.place_id,
                                                                    lat: place.geometry.location.lat(),
                                                                    lng: place.geometry.location.lng(),
                                                                    city: place.address_components.find(ac =>
                                                                        ac.types.includes('locality')
                                                                    ).long_name,
                                                                    province: place.address_components.find(ac =>
                                                                        ac.types.includes('administrative_area_level_1')
                                                                    ).long_name,
                                                                    postalCode: _.get(
                                                                        _.find(place.address_components, component =>
                                                                            component.types.includes('postal_code')
                                                                        ),
                                                                        'short_name',
                                                                        undefined
                                                                    )
                                                                });
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                            component="span"
                                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                            disabled={
                                                                _.get(selectedCollector, 'routeDestinationBy') ===
                                                                'Zone'
                                                            }
                                                            onClick={() => {
                                                                let destinationInfo = _.find(
                                                                    collectors,
                                                                    c =>
                                                                        c._id.toString() ===
                                                                        _.get(
                                                                            formik,
                                                                            'values.driverDestination',
                                                                            ''
                                                                        ).toString()
                                                                );
                                                                if (destinationInfo) {
                                                                    formik.setFieldValue('home', {
                                                                        description:
                                                                            destinationInfo.location.description,
                                                                        place_id: destinationInfo.location.place_id,
                                                                        lat: destinationInfo.location.lat,
                                                                        lng: destinationInfo.location.lng
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            Use Destination Address
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DateListInput
                                                            values={formik.values.driverVacationDays}
                                                            onDatesChanged={dates =>
                                                                formik.setFieldValue('driverVacationDays', dates)
                                                            }
                                                            dateFormat="YYYY-MM-DD"
                                                            label="Vacation Days"
                                                            disablePast={true}
                                                            renderDate={renderVacationDays}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={8}>
                                                            {(operator.accountType === 'System Administrator' ||
                                                                ROLES.includes(operator.accountType)) && (
                                                                <>
                                                                    <Grid item xs={6}>
                                                                        {getSwitch(
                                                                            theme,
                                                                            'permissions.seeAllPendingPickups',
                                                                            'View all pickups',
                                                                            formik
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        {getSwitch(
                                                                            theme,
                                                                            'permissions.seeCustomerPhoneNumbers',
                                                                            'View phone numbers',
                                                                            formik
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        {getSwitch(
                                                                            theme,
                                                                            'recordVehicleData',
                                                                            'Record Vehicle Data',
                                                                            formik
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        {getSwitch(
                                                                            theme,
                                                                            'permissions.abortPickups',
                                                                            'Abort Pickups',
                                                                            formik
                                                                        )}
                                                                    </Grid>
                                                                    {/*<Grid item xs={6}>
                                                            {getSwitch(
                                                                theme,
                                                                'permissions.seeGasCost', // Is this used?
                                                                'View gas price',
                                                                formik
                                                            )}
                                                            </Grid>*/}
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </FormGroup>
                                        </>
                                    )}

                                {(_.get(formik, 'values.accountType') === 'Collector Employee' ||
                                    (_.get(formik, 'values.accountType') !== 'Collector Administrator' &&
                                        _.get(formik, 'values.accountPermissions', []).includes('Driver'))) && (
                                    <>
                                        <FormLabel>Payment</FormLabel>
                                        <Divider />
                                        <FormGroup className={classes.formGroup}>
                                            <Grid container spacing={theme.spacing.unit}>
                                                {_.get(formik, 'values.accountType') === 'Collector Employee' && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={
                                                            _.get(formik, 'values.accountPermissions', []).includes(
                                                                'Driver'
                                                            )
                                                                ? 6
                                                                : 12
                                                        }
                                                    >
                                                        {getSelect(
                                                            theme,
                                                            'permissions.paymentModel',
                                                            'Payment Model',
                                                            DRIVER_PAYMENT_MODELS,
                                                            formik
                                                        )}
                                                    </Grid>
                                                )}
                                                {_.get(formik, 'values.accountPermissions', []).includes('Driver') && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={
                                                            _.get(formik, 'values.accountType') === 'Collector Employee'
                                                                ? 6
                                                                : 12
                                                        }
                                                        className={
                                                            _.get(formik, 'values.accountType') ===
                                                                'Collector Employee' && classes.payThroughSystemCentered
                                                        }
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        id={'permissions.payThroughSystem'}
                                                                        name={'permissions.payThroughSystem'}
                                                                        color="primary"
                                                                        onChange={formik.handleChange}
                                                                        value={_.get(
                                                                            formik.values,
                                                                            'permissions.payThroughSystem',
                                                                            false
                                                                        )}
                                                                        checked={_.get(
                                                                            formik.values,
                                                                            'permissions.payThroughSystem',
                                                                            false
                                                                        )}
                                                                        onBlur={formik.handleBlur}
                                                                        data-cy="pay-through-system-toggle"
                                                                    />
                                                                }
                                                                label={
                                                                    <span>
                                                                        Pay Through System{' '}
                                                                        <IconButton
                                                                            onClick={() => setHelpDialogOpen(true)}
                                                                        >
                                                                            <Icon>info</Icon>
                                                                        </IconButton>
                                                                    </span>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                )}

                                                {_.get(formik, 'values.accountType') === 'Collector Employee' &&
                                                _.get(formik, 'values.permissions.paymentModel') === 'Wage' ? (
                                                    <>
                                                        <Grid item xs={10}>
                                                            {getTextInput(
                                                                theme,
                                                                'wage',
                                                                'Hourly',
                                                                formik,
                                                                'number',
                                                                {
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            $/hr
                                                                        </InputAdornment>
                                                                    )
                                                                },
                                                                { shrink: true }
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            {getSwitch(
                                                                theme,
                                                                'paidLunch',
                                                                'Paid Lunch',
                                                                formik,
                                                                { marginTop: theme.spacing.unit * 2 },
                                                                { fontSize: '80%' }
                                                            )}
                                                        </Grid>{' '}
                                                    </>
                                                ) : (
                                                    <Grid item xs={12} sm={12}>
                                                        <div style={{ marginTop: theme.spacing.unit * 2 }}>
                                                            <CommissionRatesInput
                                                                onChange={commissionRates => {
                                                                    formik.setFieldValue(
                                                                        'commissionRates',
                                                                        commissionRates
                                                                    );
                                                                }}
                                                                value={formik.values.commissionRates}
                                                                commodities={commodities}
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}

                                                {_.get(formik, 'values.accountPermissions', []).includes('Driver') &&
                                                    (_.get(formik, 'values.permissions.payThroughSystem', false) && (
                                                        <>
                                                            {(operator.accountType === 'System Administrator' ||
                                                                ROLES.includes(operator.accountType)) && (
                                                                <Grid item xs={6}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            data-cy="permissions-contractor"
                                                                            control={
                                                                                <Switch
                                                                                    id="permissions.contractor"
                                                                                    name="permissions.contractor"
                                                                                    color="primary"
                                                                                    onChange={e => {
                                                                                        //invert value to work with label text
                                                                                        e.target.value = !e.target
                                                                                            .value;
                                                                                        e.target.checked = !e.target
                                                                                            .checked;
                                                                                        formik.handleChange(e);
                                                                                    }}
                                                                                    value={
                                                                                        //invert value to work with label text
                                                                                        !_.get(
                                                                                            formik.values,
                                                                                            'permissions.contractor',
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    checked={
                                                                                        //invert value to work with label text
                                                                                        !_.get(
                                                                                            formik.values,
                                                                                            'permissions.contractor',
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            }
                                                                            label="Charge Wage To Depot"
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={6}>
                                                                {getSwitch(
                                                                    theme,
                                                                    'payOutGas',
                                                                    'Reimburse Gas Cost',
                                                                    formik
                                                                )}
                                                            </Grid>
                                                        </>
                                                    ))}
                                            </Grid>
                                        </FormGroup>
                                    </>
                                )}

                                {/* UNUSED COMPONENTS */}
                                {/* {_.get(formik, 'values.accountPermissions', []).includes('Driver') && (
                                        <>
                                            <Grid item xs={12} sm={12}>
                                                <Typography
                                                    variant="caption"
                                                    style={{ margin: theme.spacing.unit, marginBottom: 0 }}
                                                >
                                                    Driver Efficiency
                                                </Typography>
                                                <div style={{ display: 'flex', overflow: 'hidden' }}>
                                                    <Slider
                                                        id="driverEfficiency"
                                                        value={formik.values.driverEfficiency}
                                                        onChange={(e, val) => formik.setFieldValue('driverEfficiency', val)}
                                                        style={{
                                                            padding: theme.spacing.unit * 3
                                                        }}
                                                        min={0.5}
                                                        max={1.5}
                                                        step={0.125}
                                                    />
                                                    <TextField
                                                        value={Math.round(formik.values.driverEfficiency * 30) + ' s/bag'}
                                                        disabled
                                                        variant="outlined"
                                                        style={{ width: '120px' }}
                                                    />
                                                </div>
                                            </Grid>


                                            <Grid item xs={12}>
                                                <Grid container spacing={8}>
                                                    <Grid item xs={6}>
                                                        {getSwitch(
                                                            theme,
                                                            'permissions.seePaymentInfo',
                                                            'View payment info',
                                                            formik
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )} */}
                                {loading && <LinearProgress />}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} disabled={loading || promptForDriverCode}>
                                    {loc('cancel', lang)}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    onClick={formik.handleSubmit}
                                    data-cy="user-form-submit"
                                >
                                    {loc('submit', lang)}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                }}
            </Formik>
            <HelpDialog
                open={helpDialogOpen}
                title="Pay Through System"
                body={
                    <Typography variant="body1">
                        This will pay the driver through our e-transfer process and charge back the depot if applicable.
                    </Typography>
                }
                onClose={() => setHelpDialogOpen(false)}
            />
        </>
    );
}

export default withStyles(styles)(withMobileDialog()(withTheme()(UserForm)));
