import React, { useState } from 'react';

import _ from 'lodash';
import mustache from 'mustache';

import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Typography, Button } from '@material-ui/core';

import { colors } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { withTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { loc } from 'localizations/localizationHandler';

function RateScreen(props) {
    const {
        nextPlatformToRateOn,
        ratingDialogText,
        onRateApp,
        onNextScreen,
        customer,
        theme,
        lang,
        onSubmit,
        onBack,
        onClose,
        isLastScreen,
        isAdmin
    } = props;

    const [customerRatedUs, setCustomerRatedUs] = useState('');

    const customerRatedUsBefore = !_.isEmpty(_.get(customer, 'platformsRatedOn'));

    const renderedRatingDialogText = _.mapValues(ratingDialogText, text =>
        mustache.render(text, {
            socialPlatformName: _.get(nextPlatformToRateOn, 'ratingPlatform.name')
        })
    );

    const onCustomerRate = () => {
        setCustomerRatedUs(true);
        onRateApp();
    };

    const StarGroup = () => {
        return (
            <>
                <StarIcon style={{ fill: colors.yellow[600] }} />
                <StarIcon style={{ fill: colors.yellow[600] }} />
                <StarIcon style={{ fill: colors.yellow[600] }} />
                <StarIcon style={{ fill: colors.yellow[600] }} />
                <StarIcon style={{ fill: colors.yellow[600] }} />
            </>
        );
    };

    return (
        <>
            {customerRatedUs ? (
                <>
                    <DialogTitle disableTypography style={{ textAlign: 'center', justifyContent: 'center' }}>
                        {' '}
                        <Typography variant="h5">
                            <b>{!_.isNil(renderedRatingDialogText) && renderedRatingDialogText.thanksForRatingTitle}</b>
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <DialogContentText>
                            {!_.isNil(renderedRatingDialogText) && renderedRatingDialogText.thanksForRatingContent}
                        </DialogContentText>
                    </DialogContent>
                    {isLastScreen ? (
                        <DialogActions
                            style={{
                                justifyContent: 'space-between',
                                marginTop: theme.spacing.unit,
                                marginBottom: theme.spacing.unit
                            }}
                        >
                            <Button onClick={onBack}>{loc('back', lang)}</Button>

                            <Button data-cy="share-and-feedback-dialog-no-thanks-button" onClick={onSubmit}>
                                {loc('close', lang)}
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions
                            style={{
                                justifyContent: 'space-between',
                                marginTop: theme.spacing.unit,
                                marginBottom: theme.spacing.unit
                            }}
                        >
                            <Button onClick={onBack}>{loc('back', lang)}</Button>
                            {isAdmin && <Button onClick={onClose}>{loc('close', lang)}</Button>}
                            <Button onClick={onNextScreen} data-cy="share-and-feedback-next-button">
                                {loc('next', lang)}
                            </Button>
                        </DialogActions>
                    )}
                </>
            ) : (
                <>
                    <DialogTitle disableTypography style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Typography variant="h5">
                            <b>
                                {!customerRatedUsBefore
                                    ? _.get(renderedRatingDialogText, 'rateUsTitle')
                                    : _.get(renderedRatingDialogText, 'ratedUsBeforeTitle')}
                            </b>
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <StarGroup />
                    </DialogContent>
                    <DialogContent style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <DialogContentText>
                            {!customerRatedUsBefore
                                ? _.get(renderedRatingDialogText, 'rateUsContent')
                                : _.get(renderedRatingDialogText, 'ratedUsBeforeContent')}
                        </DialogContentText>
                        <br />
                        {!_.isNil(nextPlatformToRateOn) && (
                            <MuiThemeProvider theme={buttonThemeOverride}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    style={{ padding: theme.spacing.unit * 1.5 }}
                                    onClick={onCustomerRate}
                                >
                                    {nextPlatformToRateOn.ratingPlatform.rateUsText}
                                </Button>
                            </MuiThemeProvider>
                        )}
                    </DialogContent>

                    {isLastScreen ? (
                        <DialogActions
                            style={{
                                justifyContent: 'space-between',
                                marginTop: theme.spacing.unit,
                                marginBottom: theme.spacing.unit
                            }}
                        >
                            <Button onClick={onBack}>{loc('back', lang)}</Button>

                            <Button data-cy="share-and-feedback-dialog-no-thanks-button" onClick={onSubmit}>
                                {loc('close', lang)}
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions
                            style={{
                                justifyContent: 'space-between',
                                marginTop: theme.spacing.unit,
                                marginBottom: theme.spacing.unit
                            }}
                        >
                            <Button onClick={onBack}>{loc('back', lang)}</Button>
                            {isAdmin && <Button onClick={onClose}>{loc('close', lang)}</Button>}
                            <Button data-cy="share-and-feedback-next-button" onClick={onNextScreen}>
                                {loc('next', lang)}
                            </Button>
                        </DialogActions>
                    )}
                </>
            )}
        </>
    );
}

export default withTheme()(RateScreen);

const buttonThemeOverride = theme => {
    return createMuiTheme({
        overrides: {
            MuiButton:
                _.get(theme, 'palette.type', 'light') === 'light'
                    ? {
                          root: {
                              color: '#fafafa',
                              backgroundColor: '#424242',
                              '&:hover': {
                                  backgroundColor: '#616161'
                              }
                          }
                      }
                    : {
                          root: {
                              color: '#424242',
                              backgroundColor: '#fafafa',
                              '&:hover': {
                                  backgroundColor: '#e0e0e0'
                              }
                          }
                      }
        }
    });
};
