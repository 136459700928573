import React, { useContext } from 'react';
import _ from 'lodash';

import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    colors,
    withTheme,
    Select,
    MenuItem
} from '@material-ui/core';
import Icon from '@mdi/react';
import { Icon as MaterialUIIcon } from '@material-ui/core';
import * as allIcons from '@mdi/js';

import { Link } from 'react-router-dom';

import NewUserInfo from 'components/TextComponents/NewUserInfo';

import { isEXPRegion, isMXDRegion, isCONRegion, deviceHelper } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';
import FakeLink from 'components/FakeLink/FakeLink';
import AppStoreLink from 'components/AppStoreLink/AppStoreLink';
import { ACCOUNT_TYPE_SELECTIONS, REGISTRATION_TABS } from '../../../constants';

function FormSelector(props) {
    const { lang, setLang } = useContext(LocalizationContext);

    const {
        handleTabIndexChange,
        handleFAQ,
        handleHowItWorks,
        theme,
        registrationTagline,
        isInAppBrowser,
        registrationOptions,
        helpCenterFunctions,
        handleHelpCenter,
        availableLangs
    } = props;

    const styles = {
        button: {
            marginTop: theme.spacing.unit,
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            height: 'auto',
            whiteSpace: 'normal',
            boxShadow: theme.shadows[3],
            cursor: 'pointer',
            position: 'relative',
            overflow: 'hidden',
            paddingRight: 0
        },

        buttonDisabled: {
            marginTop: theme.spacing.unit,
            backgroundColor: colors.grey[300],
            borderRadius: theme.shape.borderRadius,
            height: 'auto',
            whiteSpace: 'normal',
            boxShadow: theme.shadows[3],
            position: 'relative',
            overflow: 'hidden',
            paddingRight: 0
        },
        text: {
            paddingRight: 0
        },
        smallText: { fontSize: theme.typography.captionNext.fontSize },
        iconRight: { marginRight: theme.spacing.unit / 2, color: theme.palette.text.disabled, padding: 0 },
        icon: { marginRight: 0, color: theme.palette.text.disabled }
    };

    const primaryButton = {
        backgroundColor: theme.palette.primary.main
    };
    const secondaryButton = {
        backgroundColor: theme.palette.secondary.main
    };

    const colorText = {
        fontWeight: theme.typography.button.fontWeight
    };
    const getStyle = (styleName, color) => {
        let style = _.get(styles, styleName, {});
        if (styleName === 'button') {
            style = {
                ...style,
                ...(color === 'primary' ? primaryButton : color === 'secondary' ? secondaryButton : {})
            };
        } else if (styleName === 'text' && !['primary', 'secondary'].includes(color)) {
            style = {
                ...style,
                color: theme.palette.text.primary
            };
        } else {
            style = {
                ...style,
                ...(styleName === 'text' && ['primary', 'secondary'].includes(color) ? colorText : {}),
                color:
                    color === 'primary'
                        ? theme.palette.primary.contrastText
                        : color === 'secondary'
                        ? theme.palette.secondary.contrastText
                        : theme.palette.text.secondary
            };
        }
        return style;
    };

    const getIconColor = (color, disabled) => {
        if (disabled) {
            return theme.palette.text.disabled;
        }
        if (color === 'primary') {
            return theme.palette.primary.contrastText;
        }
        if (color === 'secondary') {
            return theme.palette.secondary.contrastText;
        }
        return theme.palette.text.disabled;
    };

    const renderFormSelectorButton = (formName, disabled, color, icon, tabIndex, primaryText, secondaryText) => (
        <ListItem
            data-cy={`registration-li-${formName}`}
            style={disabled ? getStyle('buttonDisabled', color) : getStyle('button', color)}
            onClick={
                disabled
                    ? null
                    : () => {
                          handleTabIndexChange(tabIndex)();
                      }
            } //handleTabIndexChange(tabIndex, ACCOUNT_TYPE_SELECTIONS.PERSONAL)}
            button
        >
            <ListItemIcon style={getStyle('icon', color)}>
                <Icon path={_.get(allIcons, icon)} size={2} color={getIconColor(color, disabled)} />
            </ListItemIcon>
            <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                primaryTypographyProps={{
                    style: getStyle('text', color)
                }}
                secondaryTypographyProps={{
                    style: getStyle('smallText', color)
                }}
                style={{ paddingRight: 0 }}
            />
            <ListItemIcon style={getStyle('iconRight', color)}>
                <MaterialUIIcon style={getStyle('iconRight', color)}>keyboard_arrow_right</MaterialUIIcon>
            </ListItemIcon>
        </ListItem>
    );

    return (
        <React.Fragment>
            <Typography variant="body2" color="textSecondary">
                {/* {_.isNil(registrationTagline) || _.isEmpty(registrationTagline)
                    ? loc('REGISTRATION_TAGLINE', lang)
                    : registrationTagline}
                     */}
                {registrationTagline != null
                    ? _.isEmpty(registrationTagline)
                        ? loc('REGISTRATION_TAGLINE', lang)
                        : registrationTagline
                    : null}
            </Typography>
            {/* <NewUserInfo handleFAQ={handleFAQ} handleHowItWorks={handleHowItWorks} /> */}
            <List>
                {registrationOptions.regCustomer &&
                    renderFormSelectorButton(
                        'customers',
                        false,
                        'primary',
                        'mdiAccount',
                        REGISTRATION_TABS.PERSONAL,
                        loc('registration3', lang),
                        loc('registration7', lang)
                    )}
                {registrationOptions.regBusiness &&
                    renderFormSelectorButton(
                        'businesses',
                        false,
                        'primary',
                        'mdiStore',
                        REGISTRATION_TABS.BUSINESS,
                        loc('registration14', lang),
                        loc('registration15', lang)
                    )}

                {isEXPRegion() &&
                    registrationOptions.regGroup &&
                    renderFormSelectorButton(
                        'groups',
                        isMXDRegion(),
                        'primary',
                        'mdiAccountMultiplePlus',
                        REGISTRATION_TABS.GROUP,
                        loc('registration4a', lang),
                        loc('registration8a', lang)
                    )}
                {registrationOptions.regOrganization &&
                    renderFormSelectorButton(
                        'organizations',
                        isMXDRegion(),
                        isEXPRegion() ? 'primary' : 'secondary',
                        'mdiCharity',
                        REGISTRATION_TABS.CHARITY,
                        isEXPRegion() ? loc('registration4b', lang) : loc('registration4', lang),
                        isEXPRegion() ? loc('registration8b', lang) : loc('registration8', lang)
                    )}
                {registrationOptions.bottleDrive &&
                    renderFormSelectorButton(
                        'bottle-drive',
                        isMXDRegion(),
                        null,
                        'mdiBottleSodaClassic',
                        REGISTRATION_TABS.BOTTLE_DRIVE,
                        loc('registration5', lang),
                        loc('registration9', lang)
                    )}

                {availableLangs.length > 1 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: theme.spacing.unit * 3,
                            marginBottom: theme.spacing.unit * 3
                        }}
                    >
                        <MaterialUIIcon color="inherit" style={{ marginRight: theme.spacing.unit }}>
                            language
                        </MaterialUIIcon>
                        <Select value={lang} onChange={e => setLang(e.target.value)} data-cy="language-select">
                            {availableLangs.map(availableLang => {
                                return (
                                    <MenuItem
                                        value={availableLang}
                                        key={availableLang}
                                        data-cy={`language-select-${availableLang}`}
                                    >
                                        {
                                            {
                                                en: 'English (CAN)',
                                                fr: 'Français (CAN)'
                                            }[availableLang]
                                        }
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                )}
                <Typography
                    variant="caption"
                    color="textSecondary"
                    align="center"
                    style={{
                        fontSize: '0.875rem',
                        marginTop: theme.spacing.unit * 3
                    }}
                >
                    {loc('alreadyHaveAccount', lang)}{' '}
                    <Link data-cy="sign-in-link" to="/login" style={{ color: theme.palette.primary.main }}>
                        {loc('signIn', lang)}
                    </Link>
                </Typography>
                {Object.values(helpCenterFunctions).includes(true) && (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        align="center"
                        style={{
                            fontSize: '0.875rem',
                            marginTop: theme.spacing.unit * 2,
                            marginBottom: theme.spacing.unit * 2
                        }}
                    >
                        {loc('login3a', lang)}{' '}
                        <FakeLink
                            theme={theme}
                            text={loc('login3b', lang)}
                            onClickEvent={handleHelpCenter(true)}
                            color={theme.palette.primary.main}
                            data-cy="registration-help-center"
                        />
                    </Typography>
                )}
            </List>
            {!isInAppBrowser && deviceHelper.MobileWeb() && (
                <div style={{ marginBottom: theme.spacing.unit * 3, marginTop: theme.spacing.unit * 2 }}>
                    <AppStoreLink />
                </div>
            )}
        </React.Fragment>
    );
}

export default withTheme()(FormSelector);
