import React, { useMemo, useState, useContext, useRef } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { formatAsCurrency, mapCodeToName, isLocalEnv } from 'utils/misc';

// custom components
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';
import CountsScreen from 'components/BulkComponents/CountsScreen';
import CountsScreenV2 from 'components/BulkComponents/CountsScreenV2';
// npm components
import * as colors from '@material-ui/core/colors';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Divider } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import { mdiExclamationThick, mdiCheckBold, mdiTagArrowDown } from '@mdi/js';

import { _bulk, _user } from 'std';
import { _charity } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import ReactPlayer from 'react-player';

import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
// const localPlaceholderVideoURL = 'https://youtu.be/qgMfcq47h3M';
// const localPlaceholderVideoURL = 'https://www.w3schools.com/html/movie.mp4';
const localPlaceholderVideoURL =
    'https://local-std-pickup-photos.s3.amazonaws.com/clerk_videos/CV_5e744e80-8a9a-11ec-81fe-c9850d7b1e23.webm';

function CountsPanel({
    theme,
    history,
    inProgress,
    bulk,
    skus,
    grandTotal,
    totalCountFormAmount,
    promoCodes,
    disableEditing,
    customFeesForm,
    countTotal,
    onApplyPromo,
    onRemoveCustomFee,
    onRemovePromo,
    onRemoveCounts,
    onOpenReportDialog,
    onOpenComplaintDialog,
    onClearCountsAndFees,
    onDonation,
    onUpdateCommodityAmount,
    setIdleCountdown,
    cameraIsOn,
    children,
    http,
    operator,
    bagtag,
    recordFinished,
    customerUID,
    videoFilename,
    isEmployee,
    haveVideoPermission,
    resetTimeoutCount,
    // countsScreenToPrintRef,
    printingReceipt,
    setEditNumberOfBagsDialogOpen,
    hideCustomerIdentification,
    commodities,
    commodityColors,
    collector,
    setCurrentCountingSessionId,
    currentCountingSessionId,
    setCreateEditNumberOfCommoditiesDialogOpen,
    handleRemoveCountingSession,
    finishRecording,
    restartRecording,
    handleRemoveSessionlessCounts,
    handleRemoveCustomFeeFromSessionAndBulk,
    onImageDialog,
    setImages,
    setCreatEditNumberOfCommoditiesMode
}) {
    const { lang } = useContext(LocalizationContext);

    const warnAction = useContext(ConfirmDialogContext);
    const [displayTab, setDisplayTab] = useState('ledger');
    const heightOffset = theme.spacing.unit;

    const disabledBagCountEditing =
        operator.accountType === 'System Administrator'
            ? _bulk.isRedeemed(bulk) || _bulk.isInvoiced(bulk)
            : disableEditing;

    const remainingRenderList = useMemo(() => {
        return getRemainingRenderList();
    }, [commodities, bulk]);

    function getRemainingRenderList() {
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let numOfSubCom = getComEquivalent(bulk, commodities);
        let numOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
        let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
        let renderList = [];
        if (numOfBags - sortedBags > 0) {
            renderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: numOfBags - sortedBags,
                name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), numOfBags - sortedBags)
            });
        }
        _.get(bulk, 'pickup.subPayloads', [])
            .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
            .forEach(subCom => {
                let subComId = _.get(subCom, 'subCommodity', '');
                let amount = _.get(subCom, 'amount', '');
                let remainingSubCom =
                    amount - _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', '');
                if (remainingSubCom < 1) return;
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodity = _.find(subCommodityLookup, subComLookupItem => {
                    return subComLookupItem._id === subComId;
                });
                let name = _.get(subCommodity, `name.${lang}`, '');
                renderList.push({
                    color: _.get(subCommodity, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                    amount: remainingSubCom,
                    name: shortenSubComName(name, remainingSubCom)
                });
            });
        return renderList;
    }

    const [videoDetails, setVideoDetails] = React.useState([]);
    const [showVideoDialog, setShowVideoDialog] = React.useState(false);
    const [currentVideoDetail, setCurrentVideoDetail] = React.useState('');
    React.useEffect(() => {
        if (_.isNil(bulk)) return;
        if (recordFinished) {
            setTimeout(() => {
                getVideoDetails(operator._id, _bulk.getTruncatedLabel(bulk), _user.getUniqueID(bulk.owner));
            }, 1000);
        } else {
            getVideoDetails(operator._id, _bulk.getTruncatedLabel(bulk), _user.getUniqueID(bulk.owner));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator, cameraIsOn, recordFinished, bulk]);

    async function getVideoDetails(clerkID, bagtag, customerUID) {
        const newVideoDetails = await http.getJSON(
            `/clerkVideo/getvideoDetails?clerkID=${clerkID}&bagtag=${bagtag}&customerUID=${customerUID}`,
            false,
            true
        );
        if (newVideoDetails.ok) {
            setVideoDetails(newVideoDetails.data || []);
        }
    }

    const [scrollBarWidth, setScrollBarWidth] = useState(0);
    const handleScrollBarWidth = width => {
        setScrollBarWidth(width);
    };

    return (
        <Grid item md={4} xs={12} style={{ height: '100%', paddingBottom: 'env(safe-area-inset-bottom)' }}>
            {_user.isSystemAdmin(operator) && (
                <div
                    style={{
                        marginTop: 5,
                        marginBottom: 5
                    }}
                >
                    <VideoDialog
                        open={showVideoDialog}
                        onClose={() => {
                            setShowVideoDialog(false);
                            resetTimeoutCount();
                        }}
                        currentVideoDetail={currentVideoDetail}
                        commodities={commodities}
                        bulk={bulk}
                        lang={lang}
                        theme={theme}
                    />
                    {/* <Button
                        variant={displayTab === 'ledger' ? 'contained' : 'outlined'}
                        style={{
                            maxHeight: '30px',
                            minHeight: '30px',
                            maxWidth: '75px',
                            minWidth: '75px',
                            fontSize: 14
                        }}
                        color="primary"
                        onClick={() => {
                            setDisplayTab('ledger');
                        }}
                        data-cy="ledgerTab"
                    >
                        Ledger
                    </Button>
                    <Button
                        variant={displayTab === 'recording' ? 'contained' : 'outlined'}
                        style={{
                            marginLeft: 10,
                            maxHeight: '30px',
                            minHeight: '30px',
                            maxWidth: '105px',
                            minWidth: '105px',
                            fontSize: 14
                        }}
                        color="primary"
                        onClick={() => {
                            setDisplayTab('recording');
                        }}
                        data-cy="recordingTab"
                    >
                        Recording
                    </Button> */}
                </div>
            )}
            <Paper
                elevation={1}
                style={{
                    padding: theme.spacing.unit,
                    marginBottom: theme.spacing.unit,
                    display: 'flex',
                    flexDirection: 'column',
                    height: `calc(100% - ${heightOffset + 40}px)`
                }}
            >
                {displayTab === 'ledger' ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: theme.spacing.unit / 2
                            }}
                        >
                            <div
                                style={{
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Typography
                                    style={{
                                        marginRight: 5,
                                        whiteSpace: 'nowrap',
                                        alignSelf: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Remaining:
                                </Typography>
                                {remainingRenderList.length === 0 && (
                                    <Typography
                                        style={{
                                            whiteSpace: 'nowrap',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                            // fontSize: 11
                                        }}
                                    >
                                        Nothing
                                    </Typography>
                                )}
                                {(remainingRenderList || []).map(renderItem => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: 'max-content',
                                                flexDirection: 'row',
                                                marginRight: 5
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: 'center',
                                                    justifyContent: 'center',
                                                    height: 16
                                                }}
                                            >
                                                <MDIcon
                                                    path={_.get(renderItem, 'icon', '')}
                                                    size={0.7}
                                                    color={_.get(renderItem, 'color', '')}
                                                />
                                            </div>
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                    // fontSize: 11
                                                }}
                                            >
                                                {_.get(renderItem, 'amount', '')} {_.get(renderItem, 'name', '')}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                variant="contained"
                                style={{
                                    fontSize: 12,
                                    minWidth: '126px',
                                    maxWidth: '126px',
                                    minHeight: '26px',
                                    maxHeight: '26px',
                                    padding: theme.spacing.unit,
                                    marginRight: scrollBarWidth + theme.spacing.unit * 2
                                }}
                                color="primary"
                                onClick={() => {
                                    setCreatEditNumberOfCommoditiesMode('create');
                                    setCreateEditNumberOfCommoditiesDialogOpen(true);
                                }}
                                data-cy="newSession"
                                disabled={_bulk.isCompleted(bulk)}
                            >
                                Sort More Items
                            </Button>
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit / 2 }} />
                        {bulk && !_.isEmpty(bulk.outstandingBagtags) && (
                            <Card
                                elevation={0}
                                style={{
                                    marginTop: theme.spacing.unit,
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[300],
                                    maxHeight: '100%',
                                    position: 'relative',
                                    padding: '4px 0'
                                }}
                            >
                                <div style={{ padding: theme.spacing.unit }}>
                                    <Typography>Outstanding Bagtags</Typography>
                                    <Divider style={{ margin: theme.spacing.unit, marginLeft: 0, marginBottom: 0 }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            flexDirection: 'row',
                                            marginTop: theme.spacing.unit * 1.5
                                        }}
                                    >
                                        {bulk.outstandingBagtags.map(label => {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <MDIcon
                                                        path={mdiTagArrowDown}
                                                        size={0.6}
                                                        style={{
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                        color={colors.grey[500]}
                                                    />
                                                    <Typography
                                                        style={{
                                                            fontSize: '12px',
                                                            color: label.bulkId ? theme.palette.linkColor : 'default',
                                                            cursor: label.bulkId ? 'pointer' : 'default'
                                                        }}
                                                        onClick={() => {
                                                            if (_.isEmpty(label.bulkId)) return;

                                                            history.push(
                                                                `/operators/${operator._id}/bulks/${label.bulkId}`
                                                            );
                                                        }}
                                                    >
                                                        {label.bagtag}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Card>
                        )}
                        <CountsScreenV2
                            bulk={bulk}
                            disableEditing={disableEditing}
                            inProgress={inProgress}
                            customFeesForm={customFeesForm}
                            skus={skus}
                            countTotal={countTotal}
                            grandTotal={grandTotal}
                            totalCountFormAmount={totalCountFormAmount}
                            onRemoveCounts={onRemoveCounts}
                            onRemoveCustomFee={onRemoveCustomFee}
                            onClearCountsAndFees={onClearCountsAndFees}
                            setIdleCountdown={setIdleCountdown}
                            cameraIsOn={cameraIsOn}
                            resetTimeoutCount={resetTimeoutCount}
                            printingReceipt={printingReceipt}
                            commodityColors={commodityColors}
                            setCurrentCountingSessionId={setCurrentCountingSessionId}
                            commodities={commodities}
                            lang={lang}
                            currentCountingSessionId={currentCountingSessionId}
                            operator={operator}
                            collector={collector}
                            promoCodes={promoCodes}
                            setCreateEditNumberOfCommoditiesDialogOpen={setCreateEditNumberOfCommoditiesDialogOpen}
                            handleRemoveCountingSession={handleRemoveCountingSession}
                            handleRemoveSessionlessCounts={handleRemoveSessionlessCounts}
                            handleRemoveCustomFeeFromSessionAndBulk={handleRemoveCustomFeeFromSessionAndBulk}
                            onImageDialog={onImageDialog}
                            setImages={setImages}
                            handleScrollBarWidth={handleScrollBarWidth}
                            setCreatEditNumberOfCommoditiesMode={setCreatEditNumberOfCommoditiesMode}
                            // ref={countsScreenToPrintRef}
                        />

                        {/* <Divider style={{ marginTop: theme.spacing.unit }} /> */}
                        {/* <TextField
                    fullWidth
                    data-cy="number-of-items-counted-input"
                    name="number-of-items-counted-input"
                    label={`Number of ${bulk.commodityUOM}s processed`}
                    value={`${bulk.commoditiesProcessed || 0} / ${bulk.commodityAmount}`}
                    type="text"
                    variant="outlined"
                    disabled={disableEditing}
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        fontWeight: 'bold'
                    }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    disabled={bulk.commoditiesProcessed < 1 || disabledBagCountEditing || inProgress}
                                    onClick={() => {
                                        onUpdateCommodityAmount(-1);
                                        setIdleCountdown(0);
                                        resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        marginRight: theme.spacing.unit,
                                        padding: 0
                                    }}
                                >
                                    <Icon>remove</Icon>
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={disabledBagCountEditing || inProgress}
                                    onClick={() => {
                                        onUpdateCommodityAmount(+1);
                                        setIdleCountdown(0);
                                        resetTimeoutCount();
                                    }}
                                    style={{
                                        height: theme.spacing.unit * 4,
                                        minWidth: theme.spacing.unit * 6,
                                        padding: 0
                                    }}
                                    data-cy="add-bag-count"
                                >
                                    <Icon>add</Icon>
                                </Button>
                                {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                    <Button
                                        variant="outlined"
                                        disabled={disabledBagCountEditing || inProgress}
                                        onClick={() => {
                                            setEditNumberOfBagsDialogOpen(true);
                                        }}
                                        style={{
                                            height: theme.spacing.unit * 4,
                                            minWidth: theme.spacing.unit * 6,
                                            marginLeft: theme.spacing.unit,
                                            padding: 0
                                        }}
                                        data-cy="edit-bag-count"
                                    >
                                        <Icon>edit</Icon>
                                    </Button>
                                )}
                            </InputAdornment>
                        )
                    }}
                    // onChange={this.props.onBags}
                /> */}
                    </>
                ) : (
                    <List
                        style={{
                            marginTop: -10,
                            marginBottom: -12,
                            flex: `1 1 auto`,
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                    >
                        <VideoFilenameDisplay
                            cameraIsOn={cameraIsOn}
                            recordFinished={recordFinished}
                            bulk={bulk}
                            videoFilename={videoFilename}
                        />
                        {videoDetails.map(videoDetail => {
                            let videoURL = videoDetail.videoURL;
                            let SplitVideoURL = videoURL.split(/[\\/]/);
                            let videoName = SplitVideoURL[SplitVideoURL.length - 1];
                            return (
                                <ListItem style={{ height: '20px', marginTop: -3 }}>
                                    <ListItemAvatar>
                                        <IconButton
                                            style={{ height: '3px' }}
                                            onClick={() => {
                                                setCurrentVideoDetail(videoDetail);
                                                setShowVideoDialog(true);
                                                resetTimeoutCount();
                                            }}
                                        >
                                            <Icon style={{ fontSize: '18px', color: 'grey' }}>visibility</Icon>
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{ fontSize: 11 }}
                                        primary={videoName}
                                        primaryTypographyProps={{
                                            variant: 'subtitle2',
                                            style: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )}
                <List>
                    {_bulk.hasCustomerIssue(bulk) && _bulk.isRedeemed(bulk) && (
                        <ListItem
                            style={{
                                backgroundColor: _bulk.hasUnresolvedCustomerIssue(bulk)
                                    ? colors.red[100]
                                    : colors.green[100],
                                borderRadius: 2
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                    <MDIcon
                                        path={
                                            _bulk.hasUnresolvedCustomerIssue(bulk) ? mdiExclamationThick : mdiCheckBold
                                        }
                                        size={1}
                                        color={
                                            _bulk.hasUnresolvedCustomerIssue(bulk) ? colors.red[300] : colors.green[300]
                                        }
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                secondaryTypographyProps={{
                                    noWrap: true,
                                    style: { marginRight: theme.spacing.unit * 11 }
                                }}
                                primary={
                                    _bulk.hasUnresolvedCustomerIssue(bulk)
                                        ? 'Unresolved Complaint'
                                        : 'Resolved Complaint'
                                }
                                secondary={_bulk.getCustomerComplaint(bulk)}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    disabled={inProgress}
                                    size="small"
                                    onClick={() => {
                                        onOpenComplaintDialog();
                                        resetTimeoutCount();
                                    }}
                                >
                                    {_bulk.hasUnresolvedCustomerIssue(bulk) ? 'resolve' : 'edit'}
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>

                {!hideCustomerIdentification && (
                    <PromoCodesInput
                        customer={bulk.owner}
                        bypassNewCustomersCheck={true}
                        allowSystemPromos={true} // flag that allows more promo codes
                        promos={promoCodes}
                        disabled={inProgress || disableEditing}
                        onApplyPromo={promo => {
                            onApplyPromo(promo);
                            resetTimeoutCount();
                        }}
                        onRemovePromo={promo => {
                            warnAction(() => {
                                resetTimeoutCount();
                                return onRemovePromo(promo, bulk.isLostAndFound);
                            }, 'Are you sure you want to remove this promo code?');
                            resetTimeoutCount();
                        }}
                        charityPreferred={_bulk.getCharityId(bulk)}
                        donate={_bulk.isDonating(bulk)}
                        onCharityPreferred={onDonation}
                    />
                )}
            </Paper>
        </Grid>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CountsPanel));

function VideoDialog(props) {
    const { open, onClose, currentVideoDetail, commodities, bulk, lang, theme } = props;

    const [countsArray, setCountsArray] = React.useState([]);
    const [videoDuration, setVideoDuration] = React.useState(0);
    const playerRef = React.useRef(null);
    if ((videoDuration === 0 || videoDuration === Infinity) && playerRef.current !== null) {
        playerRef.current.seekTo(1e101, 'seconds');
        setTimeout(() => {
            playerRef.current.seekTo(0, 'seconds');
            setVideoDuration(playerRef.current.getDuration());
        }, 100);
    }
    React.useEffect(() => {
        try {
            setVideoDuration(0);
            if (!_.isNil(currentVideoDetail.countDifference)) {
                let parsedCountDifference = JSON.parse(currentVideoDetail.countDifference);
                let newCountsArray = [];
                for (const sku in parsedCountDifference) {
                    newCountsArray.push(parsedCountDifference[sku]);
                }
                setCountsArray(newCountsArray);
            }
        } catch (error) {
            console.log('VideoDialog error: ', error);
        }
    }, [currentVideoDetail]);

    const countFontSize = null;
    const padding = 0;
    const headerFooterColor = null;
    const headerFooterTextColor = theme.palette.text.primary;
    const totalsFontSize = null;
    const countsStyle = { fontSize: countFontSize, paddingLeft: padding };
    const headerFooterStyle = {
        fontSize: totalsFontSize,
        color: headerFooterTextColor
    };
    const commoditiesProcessedBreakdown = _.get(currentVideoDetail, 'commoditiesProcessedBreakdown', []);
    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick maxWidth={'md'}>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {!_.isNil(currentVideoDetail.videoURL) && currentVideoDetail.videoURL !== '' ? (
                        <ReactPlayer
                            url={isLocalEnv ? localPlaceholderVideoURL : currentVideoDetail.videoURL}
                            onDuration={duration => {
                                setVideoDuration(duration);
                            }}
                            controls={true}
                            ref={playerRef}
                        />
                    ) : null}{' '}
                    <Grid item md={4} xs={12} style={{ height: '100%', marginLeft: 20 }}>
                        <div style={{ overflowY: 'auto', flex: `1 1 auto` }} id="custom-scroller">
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'center'
                                }}
                            >
                                Details
                            </Typography>
                            <Typography style={{}}>
                                Duration: {videoDuration !== Infinity ? videoDuration + ' s' : 'calculating...'}
                            </Typography>
                            {!_.isNil(currentVideoDetail.fileSize) && (
                                <Typography style={{}}>
                                    File size: {(currentVideoDetail.fileSize / 1048576).toFixed(2) + 'MB'}
                                </Typography>
                            )}
                            {!_.isNil(currentVideoDetail.bags) && !_.isEmpty(currentVideoDetail.bags) && (
                                <Typography style={{}}>Bags: {currentVideoDetail.bags}</Typography>
                            )}
                            <Typography
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                Date recorded: {moment(currentVideoDetail.date).format('ddd MMM D YYYY')}
                            </Typography>
                            {commoditiesProcessedBreakdown.length > 0 && (
                                <>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            textAlign: 'center',
                                            marginBottom: 10
                                        }}
                                    >
                                        Commodities
                                    </Typography>
                                    <table
                                        style={{
                                            width: '100%',
                                            fontSize: countFontSize,
                                            border: 'none',
                                            borderSpacing: padding,
                                            borderCollapse: 'collapse'
                                        }}
                                    >
                                        <thead style={{ backgroundColor: headerFooterColor }}>
                                            <tr
                                                style={{
                                                    textDecoration: 'underline',
                                                    fontSize: countFontSize,
                                                    color: headerFooterTextColor
                                                }}
                                            >
                                                <td style={{ padding }}>
                                                    <Typography style={headerFooterStyle}>Item</Typography>
                                                </td>
                                                <td style={{ padding }}>
                                                    <Typography style={headerFooterStyle}>Qty</Typography>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: 'white' }}>
                                            {commoditiesProcessedBreakdown.map((item, j) => {
                                                const epicStyle = {
                                                    // borderBottom: isLastItemInCount ? `1px solid ${colors.grey[300]}` : null
                                                };

                                                const currentSkuCom = _.find(commodities, {
                                                    skuType: _.get(bulk, 'skuType', '')
                                                });
                                                let amount = _.get(item, 'amount', 0);
                                                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                                                let subCommodity = _.find(subCommodityLookup, {
                                                    _id: _.get(item, 'subCommodity', '')
                                                });
                                                let name = _.get(subCommodity, `name.${lang}`, '');
                                                let shortenName = item.isSubCommodity
                                                    ? shortenSubComName(name, amount)
                                                    : shortenSubComName(_.get(bulk, 'commodityUOM', 'Bag'), amount);

                                                return (
                                                    <tr
                                                        key={`${item.shortenName}-${j}`}
                                                        style={{
                                                            marginBottom: 5,
                                                            paddingBottom: 5
                                                        }}
                                                    >
                                                        <td style={epicStyle}>
                                                            <Typography style={countsStyle}>{shortenName}</Typography>
                                                        </td>
                                                        <td style={epicStyle}>
                                                            <Typography style={countsStyle}>{amount}</Typography>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'center',
                                    marginBottom: 10
                                }}
                            >
                                Counts
                            </Typography>
                            <table
                                style={{
                                    width: '100%',
                                    fontSize: countFontSize,
                                    border: 'none',
                                    borderSpacing: padding,
                                    borderCollapse: 'collapse'
                                }}
                            >
                                <thead style={{ backgroundColor: headerFooterColor }}>
                                    <tr
                                        style={{
                                            textDecoration: 'underline',
                                            fontSize: countFontSize,
                                            color: headerFooterTextColor
                                        }}
                                    >
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Item</Typography>
                                        </td>
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Qty</Typography>
                                        </td>
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Amt</Typography>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {countsArray.map((item, j) => {
                                        const epicStyle = {
                                            // borderBottom: isLastItemInCount ? `1px solid ${colors.grey[300]}` : null
                                        };

                                        return (
                                            <tr
                                                key={`${item.description}-${j}`}
                                                style={{
                                                    marginBottom: 5,
                                                    paddingBottom: 5
                                                }}
                                            >
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>
                                                        {item.description} {item.size}
                                                    </Typography>
                                                </td>
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>{item.quantity}</Typography>
                                                </td>
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>
                                                        {formatAsCurrency(item.value || 0)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div id="custom-anchor" />
                        </div>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size="small" onClick={onClose}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function VideoFilenameDisplay(props) {
    const { cameraIsOn, recordFinished, bulk, videoFilename } = props;
    const countIsFinished = !_.isNil(bulk) && !_.isNil(bulk.dateCompleted);
    if (countIsFinished) {
        return null;
    }
    if (recordFinished) {
        return null;
    }
    if (cameraIsOn) {
        return (
            <ListItem style={{ height: '20px', marginTop: -3 }}>
                <ListItemAvatar>
                    <IconButton disabled style={{ height: '3px' }}>
                        <Icon style={{ fontSize: '18px', color: colors.green[700] }}>videocam</Icon>
                    </IconButton>
                </ListItemAvatar>
                <ListItemText
                    style={{ fontSize: 11 }}
                    primary={videoFilename}
                    primaryTypographyProps={{
                        variant: 'subtitle2',
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }}
                />
            </ListItem>
        );
    }
    return null;
}

function getBulkReportedIssues(bulk, options) {
    const issues = _.get(bulk, 'payloadIssues.issues', []);
    if (_.isEmpty(issues)) {
        return ['Press here to report the customer for garbage, ineligible containers, etc'];
    } else {
        const isLegacyIssueFormat = issues.some(issue => issue.length > 4);
        return isLegacyIssueFormat ? issues : mapCodeToName(options, issues);
    }
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
