import _ from 'lodash';

import { _commodity, _pickup } from 'std';

import { MAX_NUM_OF_RECYCLING_BAGS, IGNORE_PAYLOAD_SIZE_SKUS, INVALID_REDEMPTION_EMAILS } from 'constants.js';
import { loc } from 'localizations/localizationHandler';
import { isCONRegion, isEXPRegion } from './misc';

const validator = require('validator');

export function validate(entities, value, lang = 'en') {
    if (_.isNil(entities)) {
        return { fail: false, reason: null };
    }

    for (let i = 0; i < entities.length; i++) {
        let entity = entities[i].split(':')[0];
        let argument = entities[i].split(':')[1];
        switch (entity) {
            case 'phone':
                let filtered = value.replace(/[^0-9.]/g, '');
                if (filtered.toString().length !== 10) {
                    return { fail: true, reason: loc('validate1', lang) };
                }

                switch (process.env.REACT_APP_REGION_EXT) {
                    case 'AUS':
                        // if (!filtered.match(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/)) {
                        //     return { fail: true, reason: 'Please enter a valid Australian phone number' };
                        // }
                        break;
                    case 'STD':
                    case 'EXP':
                    case 'MXD':
                    case 'CON':
                    default:
                    // TODO add CAD regex validation here
                }

                break;
            case 'email':
                let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!regexEmail.test(value.toLowerCase())) {
                    return { fail: true, reason: loc('validate2', lang) };
                }
                break;
            case 'e-transfer-email':
                if (_.some(INVALID_REDEMPTION_EMAILS, string => value.includes(string))) {
                    return {
                        fail: true,
                        reason: loc('validate40', lang, { examples: INVALID_REDEMPTION_EMAILS.join(', ') })
                    };
                }
                break;
            case 'currency':
                let regexCurrency = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/; // https://stackoverflow.com/a/16242575/5309948
                if (!regexCurrency.test(value)) {
                    return { fail: true, reason: loc('validate3', lang) };
                }
                break;
            case 'required':
                if (_.isNil(value) || value.toString().length === 0) {
                    return { fail: true, reason: loc('validate4', lang) };
                }
                break;
            case 'name':
                if (value.toString().length >= 40) {
                    return { fail: true, reason: loc('validate23', lang, { arg: '40' }) };
                }
                let regexName = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s-/.@&',()]+$/;
                if (!regexName.test(value)) {
                    return { fail: true, reason: loc('validate34', lang) };
                }
                break;
            case 'password':
                if (JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)) {
                    if (value.length < 6) {
                        return { fail: true, reason: loc('validate5', lang, { chars: 8 }) };
                    }
                } else {
                    const SPECIAL_CHARS = [
                        '~',
                        '!',
                        '@',
                        '?',
                        '#',
                        '$',
                        '%',
                        '^',
                        '&',
                        '*',
                        '(',
                        ')',
                        '_',
                        '-',
                        '+',
                        '='
                    ];
                    if (value.length < 8) {
                        return { fail: true, reason: loc('validate5', lang, { chars: 8 }) };
                    } else if (value.length > 127) {
                        return { fail: true, reason: loc('validate6', lang) };
                    } else if (!/\d/.test(value)) {
                        //check it contains a number
                        return { fail: true, reason: loc('validate35', lang) };
                    } else if (!/[A-Z]/.test(value) || !/[a-z]/.test(value)) {
                        //check it contains lower and uppercase alphabet characters
                        return { fail: true, reason: loc('validate36', lang) };
                    } else if (!_.some(SPECIAL_CHARS, specialChar => value.includes(specialChar))) {
                        return { fail: true, reason: loc('validate37', lang, { charsStr: SPECIAL_CHARS.join('') }) };
                    }
                }

                break;
            case 'passphrase':
                if (process.env.REACT_APP_REGION_EXT === 'EXP') {
                    if (value.toString().length < 3 || value.toString().length > 25) {
                        return {
                            fail: true,
                            reason: loc('validate7', lang)
                        };
                    } else if (!/^[0-9a-zA-Z-]+$/.test(value)) {
                        return {
                            fail: true,
                            reason: loc('validate8', lang)
                        };
                    }
                } else {
                    if (value.toString().length < 6 || value.toString().length > 15) {
                        return { fail: true, reason: loc('validate9', lang) };
                    } else if (!/^[0-9a-zA-Z]+$/.test(value)) {
                        return { fail: true, reason: loc('validate10', lang) };
                    }
                }
                break;
            case 'notZero':
                if (parseInt(value) === 0) {
                    return { fail: true, reason: loc('validate11', lang) };
                }
                break;
            case 'number-of-bags':
                if (value > 1e12) {
                    return {
                        fail: true,
                        reason: `${loc('validate12', lang)} ${MAX_NUM_OF_RECYCLING_BAGS}`
                    };
                } else if (parseInt(value) === 42069) {
                    return { fail: true, reason: 'Nice.' };
                } else if (value > 99) {
                    return { fail: true, reason: `${loc('validate13', lang)} ${MAX_NUM_OF_RECYCLING_BAGS}` };
                } else if (value < (parseInt(argument) || 0) || !/^\d+$/.test(value)) {
                    return { fail: true, reason: loc('validate14', lang) };
                }
                break;
            case 'number-of-clothing-bags':
                if (value > 1e12) {
                    return {
                        fail: true,
                        reason: loc('validate15', lang)
                    };
                } else if (value > 99) {
                    return { fail: true, reason: loc('validate16a', lang) };
                } else if (value < 0) {
                    return { fail: true, reason: loc('validate16b', lang) };
                }
                break;
            case 'electronics-weight':
                if (value > 99) {
                    return { fail: true, reason: loc('validate18', lang) };
                } else if (value < 0) {
                    return { fail: true, reason: loc('validate17', lang) };
                }
                break;

            case 'electronics-units':
                if (value > 99) {
                    return { fail: true, reason: loc('validate16a', lang) };
                } else if (value < 0) {
                    return { fail: true, reason: loc('validate19', lang) };
                }
                break;
            case 'url':
                let regex = /^(http|https):\/\/[^ "]+$/;
                if (!regex.test(value)) {
                    return { fail: true, reason: loc('validate20', lang) };
                }
                break;
            case 'url-https':
                if (value.substring(0, 8) !== 'https://') {
                    return { fail: true, reason: loc('validate21', lang) };
                }
                break;
            case 'internal-url':
                if (value.toString().length < 1) {
                    return { fail: true, reason: loc('validate22', lang) };
                } else if (value.toString().length > 50) {
                    return { fail: true, reason: loc('validate23', lang, { arg: '50' }) };
                } else if (!/^[0-9a-zA-Z]+$/.test(value)) {
                    return { fail: true, reason: loc('validate24', lang) };
                }
                break;
            case 'max-length':
                if (!_.isNil(value) && value.toString().length > argument) {
                    return { fail: true, reason: loc('validate23', lang, { arg: `${argument}` }) };
                }
                break;
            case 'gps':
                if (_.isEmpty(value.replace(/[, ]+/g, '').trim()) || !validator.isLatLong(value)) {
                    return { fail: true, reason: loc('validate25', lang) };
                }
                break;
            case 'latCoordinate':
                const latCoord = Number(value);
                if (isNaN(latCoord) || latCoord < -90 || latCoord > 90 || value === '') {
                    return { fail: true, reason: loc('validate26', lang) };
                }
                break;
            case 'lngCoordinate':
                const lngCoord = Number(value);
                if (isNaN(lngCoord) || lngCoord < -180 || lngCoord > 180 || value === '') {
                    return { fail: true, reason: loc('validate26', lang) };
                }
                break;
            case 'charity-registration-number':
                if (_.isNil(value)) {
                    break;
                }
                if (
                    !(
                        value.toString().length === 15
                    ) /*&&
                        /^[0-9]+$/.test(value.substr(0, 9)) &&
                        value.substr(9, 2) === 'RR' &&
                        /^[0-9]+$/.test(value.substr(11, 4))*/
                ) {
                    return { fail: true, reason: loc('validate27', lang, { num: '15' }) };
                }
                break;
            case 'australian-business-number':
                if (_.isNil(value)) {
                    break;
                }
                if (!(value.toString().length === 11) /*&& /^[0-9]+$/.test(value)*/) {
                    return { fail: true, reason: loc('validate27', lang, { num: '11' }) };
                }
                break;
            case 'uniqueID':
                if (value.toString().length !== (isEXPRegion() || isCONRegion() ? 5 : 4)) {
                    return {
                        fail: true,
                        reason: loc('validate27', lang, { num: isEXPRegion() || isCONRegion() ? '5' : '4' })
                    };
                } else if (!/^[0-9a-zA-Z]+$/.test(value)) {
                    return { fail: true, reason: loc('validate24', lang) };
                }
                break;
            case 'bsbNumber':
                if (value.toString().length !== 6) {
                    return { fail: true, reason: loc('validate28', lang, { num: '6' }) };
                }
                break;
            case 'accountNumber':
                if (value.toString().length < 6 || value.toString().length > 9) {
                    return { fail: true, reason: loc('validate33', lang, { numA: '6', numB: '9' }) };
                } else if (!/^[0-9]+$/.test(value)) {
                    return { fail: true, reason: loc('validate29', lang) };
                }
                break;
            case 'bankTransferAccountNumber':
                if (value.toString().length < 7 || value.toString().length > 12) {
                    return { fail: true, reason: loc('validate33', lang, { numA: '7', numB: '12' }) };
                } else if (!/^[0-9]+$/.test(value)) {
                    return { fail: true, reason: loc('validate29', lang) };
                }
                break;
            case 'bankTransferInstitutionNumber':
                if (value.toString().length !== 3) {
                    return { fail: true, reason: loc('validate30', lang, { num: '3' }) };
                } else if (!/^[0-9]+$/.test(value)) {
                    return { fail: true, reason: loc('validate29', lang) };
                }
                break;
            case 'bankTransferTransitNumber':
                if (value.toString().length !== 5) {
                    return { fail: true, reason: loc('validate30', lang, { num: '5' }) };
                } else if (!/^[0-9]+$/.test(value)) {
                    return { fail: true, reason: loc('validate29', lang) };
                }
                break;
            case 'accountName':
                if (value.toString().length > 32) {
                    return { fail: true, reason: loc('validate23', lang, { arg: '32' }) };
                }
                break;
            case 'pickupLimit':
                const limit = Number(value);
                if (_.isNaN(limit) || limit < 0) {
                    return { fail: true, reason: loc('validate31', lang) };
                }
                break;
            case 'notNull':
                if (_.isNil(value)) {
                    return { fail: true, reason: loc('validate4', lang) };
                }
                break;
            default:
                throw new Error(loc('validate32', lang));
        }
    }

    return { fail: false, reason: null };
}

export function initFormErrors(formShape, form, formErrors, keysValidated) {
    let formShapeErrors = _.cloneDeep(formShape);

    _.keys(formShapeErrors).forEach(key => {
        if (_.isObject(formShapeErrors[key])) {
            formShapeErrors[key] = initFormErrors(formShapeErrors[key]);
        } else {
            if (_.isNil(form) || formShape[key] === form[key] || !keysValidated.includes(key)) {
                formShapeErrors[key] = {
                    fail: false,
                    reason: ''
                };
            } else {
                formShapeErrors[key] = formErrors[key];
            }
        }
    });

    return formShapeErrors;
}

export function containsErrors(formErrors) {
    let errorsFound = false;

    _.keys(formErrors).forEach(key => {
        if (_.isObject(formErrors[key]) && _.isNil(formErrors[key].fail)) {
            errorsFound = errorsFound || containsErrors(formErrors[key]);
        } else {
            errorsFound = errorsFound || formErrors[key].fail;
        }
    });

    return errorsFound;
}

// TODO: state validator

export function validatePassword(password, passwordConfirm) {
    // TEMP
}

export function evaluateCondition(condition, message) {
    if (!condition) return { fail: true, reason: message };
}

export function validatePayload(commodities, payloadRequired, payload, ignorePayloadSizes, subPayloads = []) {
    let validation = {};

    const adjustedPayload = _.cloneDeep(payload); // tmp payload for validation
    _commodity.addSubPayloadsToPayload(adjustedPayload, subPayloads);

    const commoditiesRequired = _.filter(commodities, commodity => payloadRequired.includes(commodity._id));
    const requiredCommodityEntered = _.some(commoditiesRequired, requiredCommodity => {
        const skuType = _commodity.getSkuType(requiredCommodity);
        return parseInt(_.get(adjustedPayload, skuType, 0)) !== 0;
    });

    commodities.forEach(commodity => {
        const skuType = _commodity.getSkuType(commodity);
        // let validationForSku = ['number-of-bags:0'];
        let validationForSku = [];

        if (!(ignorePayloadSizes && IGNORE_PAYLOAD_SIZE_SKUS.includes(skuType))) {
            validationForSku.push('number-of-bags:0');
        }
        const isRequired = _.some(payloadRequired, commodityId => commodityId === _.get(commodity, '_id', ''));

        if (!_pickup.payloadIsEmpty(adjustedPayload) && isRequired && !requiredCommodityEntered) {
            validationForSku.push('notZero');
        }
        validation[skuType] = validate(validationForSku, _.get(adjustedPayload, skuType, 0));
    });

    return validation;
}
