import React, { useEffect, useContext, useState } from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    Typography,
    Icon,
    IconButton
} from '@material-ui/core';

import ServiceFeesForm from '../Forms/CollectorForms/ServiceFeesForm';

import useTaxGroups from 'utils/hooks/useTaxGroups';

import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ServiceFeesDialog({ open, collector, commodities, collectorRates, onClose, onSubmit, theme }) {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const [serviceFees, setServiceFees] = useState(_.get(collector, 'configuration.serviceFees', []));

    const [serviceFeeEditDialogOpen, setServiceFeeEditDialogOpen] = useState(false);
    const [serviceFeeSelectedI, setServiceFeeSelectedI] = useState(null);

    const { taxGroups, taxGroupsLoading } = useTaxGroups();

    const handleAddServiceFeeDialog = () => {
        setServiceFeeEditDialogOpen(true);
        setServiceFeeSelectedI(null);
    };

    const handleEditServiceFeeDialog = index => {
        setServiceFeeEditDialogOpen(true);
        setServiceFeeSelectedI(index);
    };

    const handleAddServiceFee = serviceFee => {
        setServiceFees([...serviceFees, serviceFee]);
        setServiceFeeEditDialogOpen(false);
    };

    const handleEditServiceFee = newServiceFee => {
        let serviceFeesCopy = _.cloneDeep(serviceFees);
        serviceFeesCopy[serviceFeeSelectedI] = newServiceFee;
        setServiceFees(serviceFeesCopy);
        setServiceFeeEditDialogOpen(false);
    };

    const handleRemoveServiceFee = index => {
        let serviceFeesCopy = _.cloneDeep(serviceFees);
        serviceFeesCopy.splice(index, 1);
        setServiceFees(serviceFeesCopy);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Update service fees</DialogTitle>
            <DialogContent>
                <div style={{ margin: theme.spacing.unit }}>
                    {serviceFees.map((serviceFee, index) => {
                        const taxGroup = _.find(taxGroups, {
                            _id: serviceFee.tax
                        });
                        const taxes = _.get(taxGroup, 'taxes', []);

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: theme.spacing.unit,
                                    marginBottom: theme.spacing.unit * 2,
                                    marginTop: theme.spacing.unit * 2
                                }}
                                key={index}
                            >
                                <span style={{ flex: 1 }}>
                                    <Typography variant="h6">{serviceFee.description}</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ marginRight: theme.spacing.unit * 2 }}>
                                            {_.get(serviceFee, 'type', '')}: {getTypePayoutText(serviceFee)} -{' '}
                                            {serviceFee.skuType}
                                        </Typography>
                                        {taxes.length > 0 && (
                                            <Typography>
                                                Taxes:{' '}
                                                {taxes
                                                    .map(
                                                        tax =>
                                                            `${tax.name} (${_.round(
                                                                tax.configuration.value * 100,
                                                                5
                                                            )}%)`
                                                    )
                                                    .join(', ')}
                                                {/* Tax: {serviceFee.tax.value * 100}% - {serviceFee.tax.type} */}
                                            </Typography>
                                        )}
                                    </div>
                                </span>
                                <span>
                                    <IconButton onClick={() => handleEditServiceFeeDialog(index)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton onClick={() => handleRemoveServiceFee(index)}>
                                        <Icon>cancel</Icon>
                                    </IconButton>
                                </span>
                            </div>
                        );
                    })}
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: '100%', marginTop: theme.spacing.unit * 2 }}
                        onClick={handleAddServiceFeeDialog}
                    >
                        <Icon>add_circle</Icon>
                    </Button>
                </div>
                {serviceFeeEditDialogOpen && (
                    <ServiceFeesForm
                        taxGroups={taxGroups}
                        loading={taxGroupsLoading}
                        rates={collectorRates}
                        commodities={commodities}
                        theme={theme}
                        open={serviceFeeEditDialogOpen}
                        onClose={() => setServiceFeeEditDialogOpen(false)}
                        serviceFee={serviceFees[serviceFeeSelectedI]}
                        onSubmit={_.isNil(serviceFeeSelectedI) ? handleAddServiceFee : handleEditServiceFee}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onSubmit(serviceFees)} color="primary">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ServiceFeesDialog);

function getTypePayoutText(serviceFee) {
    if (_.get(serviceFee, 'type', '') === 'Percent Fee') {
        return `${serviceFee.value * 100}%`;
    } else if (_.get(serviceFee, 'type', '') === 'Count Split') {
        return `${_.get(serviceFee, 'split.customer', 0)}¢/${_.get(serviceFee, 'split.charity', 0)}¢/${_.get(
            serviceFee,
            'split.collector',
            0
        )}¢/${_.get(serviceFee, 'split.processor', 0)}¢`;
    }
}
