import React, { useContext } from 'react';
import _ from 'lodash';

import { Dialog, DialogTitle, DialogActions, DialogContent, Button, withTheme } from '@material-ui/core';

import { getClosestRegion } from 'utils/latlngFunctions';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import CharitySelect from 'components/CharitySelect/CharitySelect';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function Donation(props) {
    const {
        donate,
        charities,
        charityPreferred,
        subdivisionPreferred,
        taxReceiptRequested,
        onCharityPreferred,
        onSubdivision,
        toggleDonate,
        onSetDonation,
        // toggleTaxReceiptRequested,
        inProgress,
        onBack,
        onNext,
        // customer,
        selectedDropLocation,
        regions,
        hasLastPickup,
        address,
        onAddress,
        http,
        google,
        theme,
        isLastStep,
        lockDonationPreference
    } = props;

    const { lang } = useContext(LocalizationContext);

    const charitySelected = _.find(charities, charity => charity._id === charityPreferred && charity.approved);
    let nextDisabled =
        _.isEmpty(_.get(address, 'description', null)) &&
        taxReceiptRequested &&
        donate &&
        charitySelected.taxReceiptsIssued;

    const dropLocationRegion = getClosestRegion(_.get(selectedDropLocation, 'location', { lat: 0, lng: 0 }), regions);

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog49', lang)} iconName="loyalty" />
                <CharitySelect
                    style={{ marginBottom: theme.spacing.unit * 2 }}
                    donate={donate}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    subdivisionPreferred={subdivisionPreferred}
                    taxReceiptRequested={taxReceiptRequested}
                    taxReceiptError={nextDisabled}
                    onCharityPreferred={onCharityPreferred}
                    onChange={onSubdivision}
                    onSwitch={toggleDonate}
                    onSetDonation={onSetDonation}
                    // onToggleTaxReceiptRequested={toggleTaxReceiptRequested}
                    showAddressInputOnTaxRecipts={!hasLastPickup}
                    location={address}
                    onLocation={onAddress}
                    http={http}
                    google={google}
                    regionToFilterFeatured={dropLocationRegion}
                    lockDonationPreference={lockDonationPreference}
                />
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={onNext}
                    data-cy="quickdrop-steps-next"
                    disabled={inProgress || nextDisabled}
                >
                    {loc(isLastStep ? 'submit' : 'next', lang)}
                </Button>
            </DialogActions>
        </>
    );
}

export default withTheme()(Donation);
