import { useState } from 'react';

import _ from 'lodash';

import { getEmailHeading } from '../BulkRescheduleDialog';

const RESCHEDULE_EMAIL_BODY = {
    en: `If you need to make changes to your pickup, please use the app/website to confirm or modify your pickup. If you have any issues please reply to ${
        process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
    } - sorry for the inconvenience.`,
    fr: `Si vous devez apporter des modifications à votre ramassage, veuillez utiliser l'application/le site Web pour confirmer ou modifier votre ramassage. Si vous rencontrez des problèmes, veuillez répondre à ${
        process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
    } - désolé pour la gêne occasionnée.`
};

const rescheduleReasons = [
    { title: 'Default', body: '' },
    {
        title: 'Vehicle Issues',
        body: { en: 'vehicle issues', fr: 'problèmes de véhicule' }
    },
    {
        title: 'Driver Illness',
        body: { en: 'driver illness', fr: 'maladie du conducteur' }
    },
    {
        title: 'Road Conditions',
        body: { en: 'poor road conditions', fr: 'mauvais état des routes' }
    },
    {
        title: 'Vehicle Accident',
        body: { en: 'a vehicle accident', fr: 'un accident de voiture' }
    },
    {
        title: 'Construction',
        body: { en: 'construction in your area', fr: 'chantier dans votre région' }
    }
];

function useBulkRescheduleDialog({
    theme,
    currentDate,
    allPickups,
    pickupIdsChecked,
    unselectEverything,
    onSnackbar,
    http,
    handleRecalc
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [form, setForm] = useState({
        updateNextDates: false,
        confirmed: 'unchanged',
        sendNotifications: true,
        date: currentDate,
        emailBody: RESCHEDULE_EMAIL_BODY,
        rescheduleReasonSelected: 'Default',
        rescheduleReason: ''
    });
    const [inProgress, setInProgress] = useState(false);

    const handleBulkRescheduleDialog = state => {
        const updatedForm = _.cloneDeep(form);
        updatedForm.date = currentDate;
        setForm(updatedForm);
        setDialogOpen(state);
    };

    const handleBulkRescheduleChange = e => {
        const { name, value, checked } = e.target;

        const updatedForm = _.cloneDeep(form);

        if (!_.isNil(checked)) {
            _.set(updatedForm, name, checked);
        } else {
            _.set(updatedForm, name, value);
        }

        setForm(updatedForm);
    };

    const handleRescheduleReasonChange = e => {
        const updatedForm = _.cloneDeep(form);
        let newVal = _.get(e, 'target.value', _.get(updatedForm, 'rescheduleReasonSelected', ''));
        _.set(updatedForm, 'rescheduleReasonSelected', newVal);
        let reason = _.find(rescheduleReasons, t => t.title === newVal);
        _.set(updatedForm, 'reason', _.get(reason, `body`, ''));
        setForm(updatedForm);
    };

    const handleBulkRescheduleSubmit = async () => {
        setInProgress(true);

        const firstCheckedPickup = _.find(allPickups, pickup => pickup._id === _.first(pickupIdsChecked));
        const timezone = firstCheckedPickup.collector.timezone;

        const res = await http.post('/pickups/reschedulePickups', {
            pickup_ids: pickupIdsChecked,
            email: {
                en:
                    getEmailHeading(theme, timezone, firstCheckedPickup.date, form.date, form.reason) +
                    ' ' +
                    form.emailBody['en'],
                fr:
                    getEmailHeading(theme, timezone, firstCheckedPickup.date, form.date, form.reason, false, 'fr') +
                    ' ' +
                    form.emailBody['fr']
            },
            timezone: form.date.tz(),
            ...form
        });

        if (res.ok) {
            let updatedForm = _.cloneDeep(form);
            _.set(updatedForm, 'date', null);
            setDialogOpen(false);
            setForm(updatedForm);
            unselectEverything();
            onSnackbar('Rescheduled pickups');
            setInProgress(false);

            handleRecalc();
        } else {
            onSnackbar('Failed to reschedule pickups. Please try again in a moment.', 'error');
        }
    };

    return {
        dialogOpen,
        form,
        inProgress,
        handleBulkRescheduleDialog,
        handleBulkRescheduleChange,
        handleBulkRescheduleSubmit,
        rescheduleReasons,
        handleRescheduleReasonChange
    };
}

export default useBulkRescheduleDialog;
