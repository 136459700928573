import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';

import { formatAsCurrency } from 'utils/misc';
import * as userHelper from 'helpers/userHelper';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import AddressDropdown from 'components/RecurringFeesDialog/AddressDropdown';

import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import {
    DialogContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    MenuItem,
    Button,
    IconButton,
    Divider,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    Grid,
    InputAdornment,
    Icon,
    TextField,
    DialogActions,
    Grow,
    colors,
    Switch,
    FormControlLabel,
    Typography
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiDelete, mdiPencil } from '@mdi/js';

import useGetJSON from 'utils/hooks/useGetJSON';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { FeeCard } from 'components/BulkComponents/BulkFeesSelectionDialog';

const intervals = [7, 14, 21, 28];

function RecurringFeesDialog(props) {
    const { theme, open, http, auth, fullScreen, customer, reloadCustomer, onClose, onSnackbar, pickups } = props;

    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const pendingPickups = _.filter(pickups, p => !p.complete && !p.aborted);
    const mostRecentPendingPickup = _.first(_.sortBy(pendingPickups, ['date']));
    const defaultAddress = mostRecentPendingPickup ? _.get(mostRecentPendingPickup, 'location.description', '') : '';

    // form stat
    const [inProgress, setInProgress] = useState(false);
    const [customFeeId, setCustomFeeId] = useState('');
    const [selectedFeeGroup, setSelectedFeeGroup] = useState({});
    const [recurrence, setRecurrence] = useState(7);
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState(defaultAddress);
    const [binSwapRequired, setBinSwapRequired] = useState(false);
    const [customAmount, setCustomAmount] = useState(null);
    const [startDate, setStartDate] = useState(
        moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day')
    );
    const [selectedSubscription, setSelectedSubscription] = useState(undefined);

    const {
        data: { collectionData: fees = [] }
    } = useGetJSON(`/fees/getAllEnabledFees`);

    const {
        data: { feeGroups = [] }
    } = useGetJSON(`/system/configuration/subscription/feeGroups`);

    const handleSelectCustomFee = selectedFee => {
        const targetfee = fees.find(fee => fee._id === selectedFee._id);
        setCustomFeeId(selectedFee._id);
        setBinSwapRequired(_.get(targetfee, 'binSwapRequired', binSwapRequired));
    };

    const handleSelectFeeGroup = e => {
        const feeGroupId = e.target.value;
        const targetFeeGroup = feeGroups.find(feeGroup => feeGroup._id === feeGroupId);
        setCustomFeeId('');
        setSelectedFeeGroup(targetFeeGroup);
    };

    const handleSelectSubscription = subscription => {
        setSelectedSubscription(subscription);
        setDescription(subscription.description);
        setCustomFeeId(subscription.fee._id);
        setRecurrence(subscription.frequency);
        setCustomAmount(
            _.isNil(subscription.customAmount) ? subscription.customAmount : subscription.customAmount / 100
        );
        setBinSwapRequired(_.get(subscription, 'binSwapRequired', false));
        setAddress(_.get(subscription, 'address', ''));
        setStartDate(moment(subscription.dateApplied).tz(process.env.REACT_APP_REGION_TIMEZONE));
    };

    const handleCancelEdit = () => {
        if (_.isNil(selectedSubscription)) {
            return;
        }
        setSelectedSubscription(undefined);
        setDescription('');
        setCustomFeeId('');
        setRecurrence(7);
        setCustomAmount(null);
        setBinSwapRequired(false);
        setAddress(defaultAddress);
        setStartDate(
            moment()
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .startOf('day')
        );
    };

    const handleApplyCustomFee = async () => {
        setInProgress(true);

        const res = await http.post(`/users/${customer._id}/addSubscription`, {
            customFeeId,
            recurrence,
            startDate,
            description,
            customAmount,
            binSwapRequired,
            address
        });

        if (res.ok) {
            onSnackbar('Applied subscription successfully');
            setDescription('');
            setCustomFeeId('');
            setRecurrence(7);
            setCustomAmount(null);
            setBinSwapRequired(false);
            setAddress(defaultAddress);

            reloadCustomer();
        }

        setInProgress(false);
    };

    const handleUpdateCustomFee = async subscriptionId => {
        setInProgress(true);

        const res = await http.post(`/users/${customer._id}/updateSubscription`, {
            subscriptionId,
            customFeeId,
            recurrence,
            startDate,
            description,
            customAmount,
            binSwapRequired,
            address
        });

        if (res.ok) {
            onSnackbar('Updated subscription successfully');
            setSelectedSubscription(undefined);
            setDescription('');
            setCustomFeeId('');
            setRecurrence(7);
            setCustomAmount(null);
            setBinSwapRequired(false);
            setAddress(defaultAddress);

            reloadCustomer();
        }

        setInProgress(false);
    };

    const handleRemoveCustomFee = async fee => {
        setInProgress(true);

        const res = await http.post(`/users/${customer._id}/removeSubscription`, { subscriptionId: fee._id });

        if (res.ok) {
            onSnackbar('Removed subscription successfully');
            setSelectedSubscription(undefined);
            reloadCustomer();
        }

        setInProgress(false);
    };

    const adminView = _user.isSystemAdmin(auth) || _user.isCollectorAdmin(auth) || _user.isInternalRole(auth);

    const pendingAddresses = pendingPickups.map(p => _.get(p, 'location.description', '').trim());
    const isPendingPickupAddress = pendingAddresses.map(a => a.toLowerCase()).includes(address.toLowerCase().trim());

    return (
        <>
            <Dialog fullScreen={fullScreen} fullWidth open={open}>
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    {loc('customerSubscription1', lang)}
                </DialogTitlePrimary>
                <DialogContent>
                    {adminView && (
                        <>
                            <DialogContentText
                                style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                            >
                                To apply recurring/subscription fees to an account, select the fee, billing interval,
                                and a starting date. If you choose a starting date in the past, it will include that in
                                the first calculation.
                            </DialogContentText>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth style={{ marginRight: 8 }}>
                                        <InputLabel htmlFor="commodity-filter">Select a fee group</InputLabel>
                                        <Select
                                            data-cy="recurring-fee-dialog-select-fee" //TODO: potential cypress changes
                                            value={_.get(selectedFeeGroup, '_id', '')}
                                            disabled={inProgress}
                                            onChange={handleSelectFeeGroup}
                                            inputProps={{
                                                name: 'commodity-filter',
                                                id: 'commodity-filter'
                                            }}
                                            style={{
                                                minWidth: 132
                                            }}
                                        >
                                            {feeGroups.map((groupFee, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={groupFee._id}
                                                        data-cy={`recurring-fee-dialog-select-fee-${index}`}
                                                    >
                                                        {groupFee.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth style={{ marginRight: 8 }}>
                                        {selectedFeeGroup.fees && (
                                            <>
                                                <InputLabel htmlFor="commodity-filter">Select a fee</InputLabel>
                                                <Grid container spacing={theme.spacing.unit}>
                                                    {selectedFeeGroup.fees.map((fee, index) => {
                                                        return (
                                                            <FeeCard
                                                                key={index}
                                                                fee={fee}
                                                                theme={theme}
                                                                handleOnClick={handleSelectCustomFee}
                                                                isSelected={fee._id === customFeeId}
                                                            />
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth style={{ marginRight: 8 }}>
                                        <TextField
                                            data-cy="recurring-fee-dialog-overwrite-amount"
                                            fullWidth
                                            value={_.isNil(customAmount) ? '' : customAmount}
                                            type="number"
                                            onChange={e =>
                                                setCustomAmount(e.target.value !== '' ? e.target.value : null)
                                            }
                                            label="Overwrite Amount"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth style={{ marginRight: 8 }}>
                                        <InputLabel htmlFor="commodity-filter">Interval</InputLabel>
                                        <Select
                                            data-cy="recurring-fee-dialog-select-interval"
                                            value={recurrence}
                                            onChange={e => setRecurrence(e.target.value)}
                                            disabled={inProgress}
                                            inputProps={{
                                                name: 'commodity-filter',
                                                id: 'commodity-filter'
                                            }}
                                            style={{
                                                minWidth: 132
                                            }}
                                        >
                                            <MenuItem value={0} data-cy="select-interval-0">
                                                One Time Fee
                                            </MenuItem>
                                            {intervals.map((interval, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={interval}
                                                        data-cy={`select-interval-${interval}`}
                                                    >
                                                        Every {interval} days
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <DatePicker
                                        data-cy="recurring-fee-dialog-start-date"
                                        disabled={inProgress}
                                        fullWidth
                                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                        label="Start date"
                                        name="date"
                                        format="MMMM Do, YYYY"
                                        value={startDate}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>date_range</Icon>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={date => setStartDate(date)}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                        label="Description/Identifier"
                                    />
                                </Grid>
                                {/* <Grid item sm={6} xs={12}>
                                    <FormControlLabel
                                        style={{ margin: 0 }}
                                        control={
                                            <Switch
                                                data-cy="customer-fee-toggle-bin-swap"
                                                checked={binSwapRequired}
                                                onChange={() => setBinSwapRequired(!binSwapRequired)}
                                            />
                                        }
                                        label="Bin Swap Required"
                                        labelPlacement="start"
                                    />
                                </Grid> */}
                                <Grid item sm={12} xs={12}>
                                    <AddressDropdown
                                        inputProps={{
                                            value: address,
                                            onChange: e => setAddress(e.target.value),
                                            label: 'Address',
                                            placeholder: 'Use pending pickup address'
                                        }}
                                        theme={theme}
                                        pendingAddresses={pendingAddresses}
                                        onSuggestionSelected={setAddress}
                                    />
                                    {isPendingPickupAddress && (
                                        <div style={{ height: 0, position: 'relative' }}>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    position: 'absolute'
                                                }}
                                            >
                                                <Icon style={{ color: colors.green[500] }}>check</Icon>
                                                Match
                                            </Typography>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <Grow in={!_.isNil(selectedSubscription)}>
                                    {!_.isNil(selectedSubscription) ? (
                                        <Button
                                            // data-cy="recurring-fee-dialog-cancel-update-btn" TODO: add cypress test
                                            style={{ marginTop: theme.spacing.unit }}
                                            disabled={inProgress}
                                            size="small"
                                            variant="outlined"
                                            onClick={handleCancelEdit}
                                        >
                                            Cancel
                                        </Button>
                                    ) : (
                                        <span />
                                    )}
                                </Grow>
                                <Button
                                    data-cy="recurring-fee-dialog-apply-btn"
                                    style={{ marginTop: theme.spacing.unit }}
                                    disabled={
                                        inProgress ||
                                        _.isEmpty(customFeeId) ||
                                        (!_.isNil(customAmount) && customAmount < 0)
                                    }
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        _.isNil(selectedSubscription)
                                            ? handleApplyCustomFee
                                            : () => handleUpdateCustomFee(selectedSubscription._id)
                                    }
                                >
                                    {_.isNil(selectedSubscription) ? 'Apply subscription' : 'Update subscription'}
                                </Button>
                            </DialogActions>

                            <Divider
                                style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                            />
                        </>
                    )}

                    {!userHelper.hasSubscriptions(customer) ? (
                        <DialogContentText>{loc('customerSubscription2', lang)}</DialogContentText>
                    ) : (
                        <List>
                            {(customer.recurringFees || []).map((fee, index) => {
                                const feeAmount = _.isNil(fee.customAmount) ? fee.fee.amount : fee.customAmount;
                                const isLegacyBinFee =
                                    fee.fee.description.includes('Bin') &&
                                    _.isNil(fee.binSwapRequired) &&
                                    _.isNil(fee.address);
                                return (
                                    <ListItem
                                        key={index}
                                        style={{
                                            marginTop: theme.spacing.unit,
                                            backgroundColor: theme.palette.background.paper,
                                            border: `2px solid ${
                                                _.get(selectedSubscription, '_id') === fee._id
                                                    ? colors.green[400]
                                                    : theme.palette.grey[400]
                                            }`,
                                            borderRadius: theme.shape.borderRadius,
                                            height: 'auto',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        <ListItemText
                                            style={{ paddingRight: theme.spacing.unit * 8 }}
                                            primary={[
                                                <div>
                                                    {formatAsCurrency(feeAmount, lang)} - {fee.fee.description}{' '}
                                                    {(fee.binSwapRequired || isLegacyBinFee) && (
                                                        <Icon style={{ fontSize: '1rem', verticalAlign: 'text-top' }}>
                                                            delete
                                                        </Icon>
                                                    )}
                                                </div>,
                                                <div>
                                                    {`${fee.description &&
                                                        `${loc('description', lang)}: ${fee.description}`} `}{' '}
                                                </div>,
                                                <div>{(fee.address || isLegacyBinFee) && fee.address}</div>
                                            ]}
                                            secondary={determineLastDatePaid(fee, lang)}
                                        />
                                        {adminView && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    disabled={
                                                        inProgress || _.get(selectedSubscription, '_id') === fee._id
                                                    }
                                                    onClick={() => {
                                                        handleSelectSubscription(fee);
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiPencil}
                                                        size={1}
                                                        title="Edit subscription"
                                                        color={
                                                            _.get(selectedSubscription, '_id') === fee._id
                                                                ? colors.green[400]
                                                                : theme.palette.text.secondary
                                                        }
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    disabled={inProgress}
                                                    onClick={() => {
                                                        warnAction(() => {
                                                            handleRemoveCustomFee(fee);
                                                        }, 'Are you sure you want to delete this subscription?');
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiDelete}
                                                        size={1}
                                                        title="Remove subscription"
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {!adminView && (
                        <DialogContentText style={{ marginTop: theme.spacing.unit }}>
                            {loc('customerSubscription3', lang)}{' '}
                            <a href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}>
                                {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                            </a>
                        </DialogContentText>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(RecurringFeesDialog));

function determineLastDatePaid(fee, lang) {
    let text = '';
    if (fee.frequency === 0) {
        if (!_.isNil(fee.lastDatePaid)) {
            return `One time fee paid on ${locDate(fee.lastDatePaid, 'DD MMM YYYY', lang)}`;
        } else {
            return 'Pending one time fee';
        }
    }

    if (!_.isNil(fee.lastDatePaid)) {
        text = `last paid on ${locDate(fee.lastDatePaid, 'DD MMM YYYY', lang)}`;
    } else {
        text = `starting on ${locDate(fee.dateApplied, 'DD MMM YYYY', lang)}`;
    }

    return `Every ${fee.frequency} days, ${text}`;
}
