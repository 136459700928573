import React, { useState } from 'react';
import _ from 'lodash';

import {
    Paper,
    withTheme,
    LinearProgress,
    Collapse,
    Typography,
    IconButton,
    Icon,
    Button,
    Avatar
} from '@material-ui/core';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import useWindowSize from 'utils/hooks/useWindowSize';

function StatisticsChart(props) {
    const [width, height] = useWindowSize();
    const [expanded, setExpanded] = useState(true);
    let mobileMode = width <= 600;
    const {
        data,
        dataLoading,
        visibleTrendKeys,
        trendLines,
        totalUsers,
        totalPickups,
        totalOrders,
        title = 'Statistics Chart',
        YAxisProps = {},
        tooltipComponent = <UsageTrendsTooltip />,
        theme,
        handleDownload,
        handleRefresh,
        handleRemoveChart,
        handleFilters
    } = props;

    return (
        <Paper style={{ padding: theme.spacing.unit * 2, paddingBottom: 0, width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: expanded ? 0 : theme.spacing.unit * 2
                }}
            >
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <Typography variant="h6" style={{ margin: theme.spacing.unit }}>
                        {title}
                    </Typography>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleFilters} data-cy="statistics-chart-filter">
                        <Icon>filter_list</Icon>
                    </IconButton>
                    <IconButton onClick={handleDownload} data-cy="statistics-chart-download">
                        <Icon>download</Icon>
                    </IconButton>
                    <IconButton onClick={handleRefresh} data-cy="statistics-chart-refresh">
                        <Icon>refresh</Icon>
                    </IconButton>

                    <IconButton onClick={() => setExpanded(!expanded)} data-cy="statistics-chart-expand">
                        <Icon>{expanded ? 'expand_less' : 'expand_more'}</Icon>
                    </IconButton>
                    <IconButton onClick={handleRemoveChart} data-cy="statistics-chart-delete">
                        <Icon>clear</Icon>
                    </IconButton>
                </div>
            </div>

            <Collapse in={expanded}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: mobileMode ? 'column' : 'row'
                    }}
                >
                    <ResponsiveContainer width="95%" height={700} minWidth={200}>
                        <LineChart data={data} style={{ fontFamily: 'Roboto' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis type="number" tickCount={8} allowDataOverflow={true} {...YAxisProps} />
                            <Tooltip content={tooltipComponent} />
                            <Legend />
                            {_.filter(trendLines, line => visibleTrendKeys.includes(line.dataKey)).map(dataSet => (
                                <Line
                                    key={dataSet.dataKey}
                                    type="monotone"
                                    dataKey={dataSet.dataKey}
                                    stroke={dataSet.stroke}
                                    name={dataSet.name}
                                    dot={<UsageTrendsGraphDot />}
                                    activeDot={<UsageTrendsGraphDot />}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        padding: theme.spacing.unit * 2,
                        flexWrap: 'wrap'
                    }}
                >
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>people</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Customers: {totalUsers}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>local_shipping</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Pickups: {totalPickups}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>ballot</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Orders: {totalOrders}
                        </Typography>
                    </span>
                </div>
                <div style={{ paddingTop: theme.spacing.unit * 2 }}>{dataLoading && <LinearProgress />}</div>
            </Collapse>
        </Paper>
    );
}

export default withTheme()(StatisticsChart);

function UsageTrendsTooltip(props) {
    if (props.payload && props.payload.length > 0) {
        const label = _.get(props, 'payload[0].payload.label', null);
        return (
            <div
                data-cy="graph-tooltip"
                style={{
                    border: '1px solid lightgray',
                    backgroundColor: 'white',
                    padding: '5px 10px'
                }}
            >
                {!_.isNil(label) && (
                    <span
                        data-cy="tooltip-label"
                        style={{
                            paddingBottom: '4px',
                            paddingTop: '8px'
                        }}
                    >
                        {label}
                    </span>
                )}
                {props.payload.map(item => {
                    const { name, value, color, dataKey } = item;
                    return (
                        <div style={{ fontSize: '90%', paddingBottom: '2px', paddingTop: '2px' }}>
                            <span data-cy={`tooltip-${dataKey}`} style={{ color: color }}>
                                {name}: {value}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return <span data-cy="empty-tooltip" />;
    }
}

function UsageTrendsGraphDot(props) {
    const label = _.get(props, 'payload.label', null);
    const dataKey = _.get(props, 'dataKey', null);
    return <Dot {...props} role={`dot-${label}-${dataKey}`} />;
}
