import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk } from 'std';

import { getCustomerName, truncateBagtag, formatAsCurrency } from 'utils/misc';

// npm components
import * as colors from '@material-ui/core/colors';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import { Menu, ListItem, Avatar, Tooltip, Icon, MenuItem } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon as MDIcon } from '@mdi/react';
import { mdiEye, mdiAlertOutline, mdiAlertDecagram, mdiArrowDownDropCircle } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import { Typography, IconButton } from '@material-ui/core';
import { BULK_STATUS_COLORS, BULK_TYPE_ICONS, BULK_TYPE_COLORS, COMMODITY_COLORS } from 'constants.js';
import useWindowSize from 'utils/hooks/useWindowSize';
import { isCONRegion, isEXPRegion } from 'utils/misc';

function HistoryBulkListItem({
    theme,
    bulk,
    historyBulk,
    grandTotal,
    totalCountFormAmount,
    key,
    commodityColors,
    onViewBulk,
    resetTimeoutCount,
    reportOptions,
    onImageDialog,
    setImages,
    hideCustomerIdentification
}) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 500;
    const [reportText, setReportText] = useState(['Reported with issue']);
    const [deactivateMenuEl, setDeactivateMenuEl] = React.useState(null);
    const bulkStatus = bulkHelper.getStatus(historyBulk);
    // const total =
    //     bulk._id === historyBulk._id ? grandTotal + totalCountFormAmount : bulkHelper.getTotalValue(historyBulk);
    const total = bulkHelper.getTotalValue(historyBulk);

    let totalCommoditiesProcessed = _.get(historyBulk, 'commoditiesProcessed', 0);
    if (totalCommoditiesProcessed === 0) {
        totalCommoditiesProcessed = _.get(historyBulk, 'commodityAmount', 0);
    }
    const bulkImages = _bulk.getPickupOrDropOffPhotoURL(historyBulk || {});
    const sortedBulkImages = _bulk.sortBulkImages(bulkImages, _.get(historyBulk, 'skuType'));

    let promos = _.get(historyBulk, 'promosApplied', []);
    let promoCodes = Array.from(promos, item => item.code).join(', ');
    const countingSessions = _.get(historyBulk, 'countingSessions', []);
    const bagtags = [];
    for (let session of countingSessions) {
        if (historyBulk.bulkType !== 'inhouse' || !isCONRegion()) break;
        if (session.bagtag) bagtags.push(session.bagtag);
    }

    const [expandBagtags, setExpandBagtags] = useState(false);

    useEffect(() => {
        let customerReportCodes = _.get(historyBulk, 'payloadIssues.issues', []);
        let driverReportCodes = _.get(historyBulk, 'driverIssues.issues', []);

        let reportCodes = [...customerReportCodes, ...driverReportCodes];
        let newReportText = [];
        for (let i = 0; i < reportCodes.length; i++) {
            let reportObject = _.find(reportOptions, element => {
                return element.code === reportCodes[i];
            });
            if (reportObject) newReportText.push(reportObject.label);
        }
        setReportText(newReportText);
    }, [historyBulk]);

    return (
        <ListItem
            key={key}
            style={{
                borderRadius: 4,
                border:
                    bulk._id === historyBulk._id
                        ? `1px solid ${colors.grey[500]}`
                        : `1px solid ${theme.palette.background.paper}`
            }}
        >
            <div
                style={{
                    display: 'inline-grid',
                    textAlign: 'center',
                    borderRight: `1px solid ${colors.grey[500]}`,
                    padding: theme.spacing.unit,
                    paddingLeft: 0,
                    marginRight: theme.spacing.unit
                }}
            >
                <Avatar style={{ backgroundColor: colors.blue[500] }}>
                    <MDIcon
                        path={BULK_TYPE_ICONS[historyBulk.bulkType]}
                        size={1}
                        color={theme.palette.background.paper}
                    />
                </Avatar>
                <Typography
                    data-cy="bulk-list-item-balance"
                    variant="caption"
                    style={{ color: theme.palette.text.secondary }}
                >
                    {formatAsCurrency(total)}
                </Typography>
            </div>
            <div>
                <Typography variant="body2">
                    <CustomChip theme={theme} bgColor={BULK_TYPE_COLORS[historyBulk.bulkType]}>
                        <span data-cy="bulk-list-item-bulk-type">
                            {_.capitalize(bulkHelper.getBulkTypeFormatted(historyBulk))}
                        </span>
                    </CustomChip>
                    {/* <span data-cy="bulk-list-item-balance">{formatAsCurrency(total)}</span>{' '} */}
                    {moment(historyBulk.datePickedUp).format('MMM D YYYY, h:mm A')}{' '}
                    {/* {totalCommoditiesProcessed > 1 && bulkHelper.isCompleted(historyBulk) && (
                        <>
                            ({formatAsCurrency(total / totalCommoditiesProcessed)} per {historyBulk.commodityUOM})
                        </>
                    )}{' '} */}
                    {!_.isEmpty(reportText) && (
                        <MDIcon
                            path={mdiAlertOutline}
                            size={'16px'}
                            color={colors.deepOrange[500]}
                            title={reportText.join()}
                        />
                    )}
                    {bulkHelper.hasCustomerIssue(historyBulk) && (
                        <MDIcon
                            path={mdiAlertDecagram}
                            size={'16px'}
                            color={colors.red[500]}
                            title={bulkHelper.getCustomerComplaint(historyBulk)}
                        />
                    )}
                </Typography>

                <Typography variant="body2">
                    <PayloadInformation bulk={historyBulk} />
                </Typography>
                <Typography variant="body2">
                    <CustomChip theme={theme} bgColor={BULK_STATUS_COLORS[bulkStatus]}>
                        {_.capitalize(bulkStatus)}
                    </CustomChip>
                    <CustomChip theme={theme} bgColor={commodityColors[historyBulk.skuType]}>
                        {_.capitalize(historyBulk.skuType)}
                    </CustomChip>
                    <CustomChip theme={theme} bgColor={colors.blue[100]}>
                        {truncateBagtag(historyBulk.label)}
                    </CustomChip>
                </Typography>
                {promos.length > 0 && !hideCustomerIdentification && (
                    <Typography variant="body2">Promos applied: {promoCodes}</Typography>
                )}
                {bagtags.length > 0 && (
                    <Typography
                        noWrap={!expandBagtags}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        variant="body2"
                    >
                        Processed bagtags:{' '}
                        <span
                            onClick={() => setExpandBagtags(!expandBagtags)}
                            style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                        >
                            {expandBagtags ? 'Collapse' : 'Show'}
                        </span>
                    </Typography>
                )}
                {expandBagtags && <Typography variant="body2">{bagtags.join(', ')}</Typography>}
            </div>
            <ListItemSecondaryAction>
                {bulk._id === historyBulk._id ? (
                    <Typography variant="caption" style={{ color: theme.palette.text.secondary }}>
                        Viewing
                    </Typography>
                ) : !_.isEmpty(sortedBulkImages) ? (
                    mobileMode ? (
                        <>
                            <IconButton
                                onClick={event => {
                                    setDeactivateMenuEl(event.currentTarget);
                                }}
                            >
                                <MDIcon path={mdiArrowDownDropCircle} size={1} color={'grey'} />
                            </IconButton>
                            <Menu
                                data-cy="help-menu"
                                anchorEl={deactivateMenuEl}
                                open={Boolean(deactivateMenuEl)}
                                onClose={() => {
                                    setDeactivateMenuEl(null);
                                }}
                            >
                                <MenuItem
                                    style={{ justifyContent: 'center' }}
                                    onClick={() => {
                                        setImages(sortedBulkImages);
                                        onImageDialog(true);
                                        resetTimeoutCount();
                                    }}
                                >
                                    <Tooltip title="View Pickup Image">
                                        <Icon>image</Icon>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem
                                    style={{ justifyContent: 'center' }}
                                    onClick={() => {
                                        onViewBulk(historyBulk._id);
                                        resetTimeoutCount();
                                    }}
                                    disabled={bulk._id === historyBulk._id}
                                >
                                    <Tooltip title="View Order">
                                        <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                                    </Tooltip>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <IconButton
                                onClick={() => {
                                    setImages(sortedBulkImages);
                                    onImageDialog(true);
                                    resetTimeoutCount();
                                }}
                            >
                                <Tooltip title="View Pickup Image">
                                    <Icon>image</Icon>
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    onViewBulk(historyBulk._id);
                                    resetTimeoutCount();
                                }}
                                disabled={bulk._id === historyBulk._id}
                            >
                                <Tooltip title="View Order">
                                    <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                                </Tooltip>
                            </IconButton>
                        </>
                    )
                ) : (
                    <IconButton
                        onClick={() => {
                            onViewBulk(historyBulk._id);
                            resetTimeoutCount();
                        }}
                        disabled={bulk._id === historyBulk._id}
                    >
                        <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(HistoryBulkListItem));

export const CustomChip = ({ theme, children, bgColor }) => {
    return (
        <span
            style={{
                background: bgColor,
                padding: '2px 4px',
                marginRight: 4,
                borderRadius: 4,
                marginBottom: theme.spacing.unit / 2
            }}
        >
            {children}
        </span>
    );
};

const PayloadInformation = ({ bulk }) => {
    switch (bulk.bulkType) {
        case 'pickup':
            return (
                <>
                    {bulk.commodityAmount} {bulkHelper.getCommodityUOMFormatted(bulk)} picked up by{' '}
                    {getCustomerName(bulk.receiver)}
                </>
            );
        case 'inhouse':
            return (
                <>
                    {bulk.commodityAmount} {bulkHelper.getCommodityUOMFormatted(bulk)} dropped off by{' '}
                    {getCustomerName(bulk.owner)}
                </>
            );
        case 'adjustment':
            return (
                <>
                    {bulk.commodityAmount} {bulkHelper.getCommodityUOMFormatted(bulk)} created by{' '}
                    {getCustomerName(bulk.receiver)}
                </>
            );

        default:
            return null;
    }
};
