import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Grid,
    Paper,
    Button,
    Icon,
    LinearProgress,
    withTheme,
    List,
    ListItem,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    colors
} from '@material-ui/core';
import { useState } from 'react';

import SendOutEditDialog from './SendOutEditDialog';

import useGetJSON from 'utils/hooks/useGetJSON';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import InlineChip from 'components/Chips/InlineChip';

const headers = [
    { name: 'Actions', locKey: null },
    { name: 'Name', locKey: null },
    { name: 'Date', locKey: null },
    { name: 'User Lists', locKey: null },
    { name: 'Notifications', locKey: null },
    { name: 'Total Customers Notified', locKey: null }
];

function SendOutsTable(props) {
    const { regions, theme } = props;

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const [sendOutEditDialogOpen, setSendOutEditDialogOpen] = useState(false);
    const [sendOutSelected, setSendOutSelected] = useState(null);
    const [loading, setLoading] = useState(false);

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);

    const { loading: zonesLoading, error: zonesLoadingErr, data: zoneData } = useGetJSON(`/system/zones`);
    /*const {
        loading: sendOutsLoading,
        error: sendOutsLoadingErr,
        data: sendOutsData,
        refresh: reloadSendOuts
    } = useGetJSON(`/sendOuts`);*/
    const { loading: charitiesLoading, error: charitiesLoadingErr, data: charityData } = useGetJSON(
        `/charities/sendouts`
    );
    const { loading: servicesLoading, error: servicesLoadingErr, data: servicesData } = useGetJSON('/services');
    const { loading: ratingPlatformsLoading, error: ratingPlatformsErr, data: ratingPlatformsData } = useGetJSON(
        '/ratingPlatforms'
    );

    const { loading: smsConfigLoading, error: smsConfigError, data: smsConfigData } = useGetJSON('/config/smsConfig');

    const {
        loading: sendOutListsLoading,
        error: sendOutListsErr,
        data: sendOutListsData,
        refresh: handleReloadSendOutLists
    } = useGetJSON('/sendOuts/sendOutLists');

    const {
        loading: sendOutsLoading,
        searchTerm,
        getData: reloadSendOuts,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/sendOuts',
        key: 'sendOuts',
        headers: headers.map(({ name }) => name),
        queryObj: {}
    });

    const handleCreateDialog = () => {
        let newSendOut = filteredData.length > 0 ? { emailNotification: filteredData[0].emailNotification } : null;
        setSendOutSelected(newSendOut);
        setSendOutEditDialogOpen(true);
    };

    const handleEditDialog = sendOut => {
        setSendOutSelected(sendOut);
        setSendOutEditDialogOpen(true);
    };

    const handleCopyDialog = sendOut => {
        let sendOutCopy = _.cloneDeep(sendOut);
        delete sendOutCopy._id;
        delete sendOutCopy.name;
        setSendOutSelected(sendOutCopy);
        setSendOutEditDialogOpen(true);
    };

    const handleSubmitValues = async values => {
        if (!_.isNil(sendOutSelected) && !_.isNil(sendOutSelected._id)) {
            const res = await http.post(`/sendOuts/${sendOutSelected._id}/updateSendOut`, values);
            if (res.ok) {
                onSnackbar('Successfully updated sendout');
                setSendOutEditDialogOpen(false);
                setSendOutSelected(null);
                await reloadSendOuts();
            }
        } else {
            const res = await http.post('/sendOuts/createSendOut', values);
            if (res.ok) {
                onSnackbar('Successfully created sendout');
                setSendOutEditDialogOpen(false);
                await reloadSendOuts();
            }
        }
    };

    const handleDeleteSendout = async sendOut => {
        const res = await http.post(`/sendOuts/${sendOut._id}/deleteSendOut`);
        if (res.ok) {
            onSnackbar('Successfully deleted sendout');
            await reloadSendOuts();
        }
    };

    const renderRow = (sendOut, filteredHeaders) => {
        const {
            _id,
            name,
            sent,
            sendDate,
            sendCompletionDate,
            lastUserUpdate,
            totalUserCount,
            emailNotification,
            smsNotification,
            pushNotification,
            createdAt,
            sendOutLists
        } = sendOut;

        const cellStyle = { whiteSpace: 'nowrap' };

        let sendStatus;
        if (sent) {
            if (_.isNil(sendCompletionDate)) {
                sendStatus = (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon style={{ marginRight: theme.spacing.unit, color: colors.yellow[500] }} fontSize="small">
                            circle
                        </Icon>
                        In progress, started at{' '}
                        {moment(sendDate)
                            .tz(timezone)
                            .format('ddd, MMM D, YYYY h:mm a z')}
                    </span>
                );
            } else {
                sendStatus = (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon style={{ marginRight: theme.spacing.unit, color: colors.green[500] }} fontSize="small">
                            circle
                        </Icon>
                        Sent on{' '}
                        {moment(sendDate)
                            .tz(timezone)
                            .format('ddd, MMM D, YYYY h:mm a z')}
                    </span>
                );
            }
        } else {
            sendStatus = (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon style={{ marginRight: theme.spacing.unit, color: colors.grey[500] }} fontSize="small">
                        circle
                    </Icon>
                    Scheduled for{' '}
                    {moment(sendDate)
                        .tz(timezone)
                        .format('ddd, MMM D, YYYY h:mm a z')}
                </span>
            );
        }

        const sendOutListNames = sendOutLists.map(_id => {
            const list = _.find(_.get(sendOutListsData, 'sendOutLists', []), { _id });
            const name = _.get(list, 'name', '');
            return (
                <InlineChip
                    color={colors.grey[500]}
                    textColor="white"
                    style={{ margin: theme.spacing.unit * 0.25 }}
                    text={name}
                />
            );
        });

        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={cellStyle}>
                        <IconButton onClick={() => handleEditDialog(sendOut)} disabled={sent}>
                            <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                warnAction(() => {
                                    handleDeleteSendout(sendOut);
                                }, 'Are you sure you want to delete this send out?');
                            }}
                            // disabled={sent}
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                        <IconButton onClick={() => handleCopyDialog(sendOut)}>
                            <Icon>file_copy</Icon>
                        </IconButton>
                    </TableCell>
                )}

                {filteredHeaders.includes('Name') && (
                    <TableCell style={cellStyle}>
                        <div>{sendStatus}</div>
                        <Typography variant="h6" color="primary">
                            {_.isEmpty(name) ? <i>Untitled</i> : name}
                        </Typography>
                    </TableCell>
                )}

                {filteredHeaders.includes('Date') && (
                    <TableCell style={cellStyle}>
                        <Typography>
                            {_.isNil(lastUserUpdate)
                                ? `Created ${moment(createdAt)
                                      .tz(timezone)
                                      .format('ddd, MMM D, YYYY h:mm a')}`
                                : `Edited ${moment(lastUserUpdate)
                                      .tz(timezone)
                                      .format('ddd, MMM D, YYYY h:mm a')}`}
                        </Typography>
                    </TableCell>
                )}

                {filteredHeaders.includes('User Lists') && <TableCell style={cellStyle}>{sendOutListNames}</TableCell>}

                {filteredHeaders.includes('Notifications') && (
                    <TableCell style={cellStyle}>
                        <div>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {_.get(emailNotification, 'sendEmailNotification', false) && (
                                    <Icon
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            color: colors.green[500]
                                        }}
                                    >
                                        mail
                                    </Icon>
                                )}
                                {_.get(smsNotification, 'sendSms', false) && (
                                    <Icon
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            color: colors.green[500]
                                        }}
                                    >
                                        phone
                                    </Icon>
                                )}
                                {_.get(pushNotification, 'sendPushNotification', false) && (
                                    <Icon
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            color: colors.green[500]
                                        }}
                                    >
                                        mobile_friendly
                                    </Icon>
                                )}
                            </span>
                        </div>
                    </TableCell>
                )}

                {filteredHeaders.includes('Total Customers Notified') && (
                    <TableCell style={cellStyle}>{totalUserCount}</TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <>
            <Grid
                container
                spacing={theme.spacing.unit * 2}
                style={{ padding: theme.spacing.unit, paddingBottom: 0, width: '100%', margin: 0 }}
            >
                <Grid item xs={12}>
                    <Paper
                        style={{
                            padding: theme.spacing.unit,
                            paddingTop: 0
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingTop: theme.spacing.unit
                            }}
                        >
                            <Button
                                style={{ margin: theme.spacing.unit }}
                                variant="outlined"
                                onClick={handleCreateDialog}
                                color="primary"
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                Add New
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Table
                title="Single Sends"
                loading={sendOutsLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                style={{ padding: 0 }}
            >
                {filteredData &&
                    filteredData.map(sendOut => {
                        return renderRow(sendOut, filteredHeaders);
                    })}
            </Table>
            {sendOutEditDialogOpen && (
                <SendOutEditDialog
                    open={sendOutEditDialogOpen}
                    regions={regions}
                    zones={_.get(zoneData, 'zones', [])}
                    charities={_.get(charityData, 'charities', [])}
                    services={_.get(servicesData, 'services', [])}
                    ratingPlatforms={_.get(ratingPlatformsData, 'ratingPlatforms', [])}
                    sendOutLists={_.get(sendOutListsData, 'sendOutLists', [])}
                    onReloadSendOutLists={handleReloadSendOutLists}
                    onClose={() => setSendOutEditDialogOpen(false)}
                    onSubmit={handleSubmitValues}
                    smsConfigData={smsConfigData}
                    sendOut={sendOutSelected}
                />
            )}
        </>
    );
}

export default withTheme()(SendOutsTable);
