import React, { useState } from 'react';
import {
    withTheme,
    Typography,
    Paper,
    Divider,
    Avatar,
    Collapse,
    IconButton,
    Icon,
    Badge,
    colors
} from '@material-ui/core';

const CustomerReviewWrapper = props => {
    const { theme, type, count, viewed, handleExpand, driverRatingObj } = props;

    const [collapsed, setCollapsed] = useState(false);

    return (
        <Paper
            style={{
                padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
                marginBottom: theme.spacing.unit
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                    <Badge badgeContent={viewed ? 0 : count} color="primary">
                        <Avatar
                            style={{
                                height: 48,
                                width: 48,
                                backgroundColor: type === 'compliments' ? colors.green[300] : colors.red[300],
                                color: 'white',
                                fontSize: theme.typography.display1.fontSize
                            }}
                        >
                            <Icon size={0.75}>
                                {type === 'compliments' ? 'sentiment_very_satisfied' : 'sentiment_very_dissatisfied'}
                            </Icon>
                        </Avatar>
                    </Badge>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ marginLeft: 10 }}>
                            {type === 'compliments' ? 'Compliments' : 'Complaints'}
                        </Typography>
                    </div>
                </div>
                <IconButton
                    onClick={() => {
                        handleExpand(driverRatingObj);
                        setCollapsed(!collapsed);
                    }}
                >
                    {collapsed ? <Icon size={0.75}>expand_less</Icon> : <Icon size={0.75}>expand_more</Icon>}
                </IconButton>
            </div>
            <Collapse in={collapsed}>
                <Divider />
                <div
                    style={{
                        marginTop: theme.spacing.unit,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {props.children}
                </div>
            </Collapse>
        </Paper>
    );
};

export default withTheme()(CustomerReviewWrapper);
