import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import HttpContext from 'utils/contexts/HttpContext';

import { getCheckbox } from 'components/CRUDTables/helperFunctions';

import {
    Button,
    Paper,
    Typography,
    Grid,
    withTheme,
    CircularProgress,
    withWidth,
    Menu,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem
} from '@material-ui/core';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import CustomWrapper from '../BulkCounter/CustomWrapper';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useRef } from 'react';

function RolePermissions(props) {
    const { theme, operator, width } = props;
    const headerRef = useRef(null);
    const [headerWidth, setHeaderWidth] = useState(width);
    const isSmallScreen = isWidthDown('md', width);

    const [configLoading, setConfigLoading] = useState(false);
    const [configUpdating, setConfigUpdating] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [permissionIndex, setPermissionIndex] = useState(0);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const loadConfig = async () => {
        setConfigLoading(true);
        const res = await http.getJSON('/system/allConfigs');
        if (res.ok) {
            setPermissions(_.get(res.data, 'config.permissions', []));
        }

        setConfigLoading(false);
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            permissions
        },
        validationSchema: Yup.object({}),
        onSubmit: async values => {
            setConfigUpdating(true);
            const configRes = await http.postJSON('/system/updateRolePermissionConfigs', { config: values }, true);
            if (configRes.ok) {
                setPermissions(_.get(configRes.data, 'config.permissions', []));
                onSnackbar('Role permissions updated');
            } else {
                onSnackbar(configRes.errorMessage, 'error');
                setConfigUpdating(false);
                return;
            }

            setConfigUpdating(false);
        }
    });

    useEffect(() => {
        loadConfig();
    }, []);

    useEffect(() => {
        formik.handleReset();
    }, [permissions]);

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        const newWidth = headerRef.current.offsetWidth;
        setHeaderWidth(newWidth);
    }, [width]);

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div
                ref={headerRef}
                style={{ width: '100%', padding: theme.spacing.unit * 2, height: '100%', overflowY: 'scroll' }}
            >
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        zIndex: 10,
                        boxShadow: theme.shadows[2],
                        width: _.isNil(headerRef.current) ? headerWidth - 50 : headerRef.current.offsetWidth - 50
                    }}
                >
                    <Typography variant="h5">System Permissions</Typography>
                    {formik.dirty && (
                        <Typography variant="body2" style={{ color: 'red', marginTop: theme.spacing.unit }}>
                            There are unsaved changes
                        </Typography>
                    )}
                    <Button
                        color="primary"
                        data-cy={`settings-submit`}
                        onClick={formik.handleSubmit}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Paper>
                <FormControl fullWidth style={{ margin: theme.spacing.unit / 2, marginTop: 80 }}>
                    <InputLabel variant="outlined" htmlFor="collector-filter">
                        Role
                    </InputLabel>
                    <Select
                        fullWidth
                        data-cy="bulk-type-selection-dropdown"
                        value={permissionIndex}
                        onChange={e => setPermissionIndex(e.target.value)}
                        disabled={configLoading}
                        inputProps={{
                            name: 'collector-filter',
                            id: 'collector-filter'
                        }}
                        input={<OutlinedInput labelWidth="30" name="role" id="outlined-role" />}
                        style={{
                            backgroundColor: theme.palette.background.paper,
                            minWidth: 125
                        }}
                    >
                        {configLoading && (
                            <MenuItem value="loading">
                                <em>Loading...</em>
                            </MenuItem>
                        )}
                        {permissions.map((permission, idx) => {
                            return (
                                <MenuItem value={idx} key={idx}>
                                    {permission.role}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {configLoading ? (
                    <Paper style={{ margin: theme.spacing.unit / 2, padding: theme.spacing.unit * 3 }}>
                        <div
                            style={{
                                margin: theme.spacing.unit * 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </div>
                    </Paper>
                ) : (
                    <Paper style={{ margin: theme.spacing.unit / 2, padding: theme.spacing.unit }}>
                        {/* <Typography variant="h6">
                            {_.get(permissions, `${permissionIndex}.role`, '')} System Permissions
                        </Typography> */}
                        <Grid container spacing={theme.spacing.unit}>
                            <Grid item xs={12} sm={6} md={3}>
                                <CustomWrapper
                                    title="Account Access"
                                    outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                >
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounts.customer`,
                                        'Customer',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounts.charity`,
                                        'Charity',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounts.driver`,
                                        'Driver',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounts.collector`,
                                        'Collector',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounts.systemAdministrator`,
                                        'System Administrator',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="Pickups" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.today`,
                                        'Today',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.completed`,
                                        'Completed',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.calendar`,
                                        'Calendar',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.tripStatistics`,
                                        'Trip Statistics',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.commodity`,
                                        'Commodity',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="Orders" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].orders.counter`,
                                        'Counter',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].orders.clerkReport`,
                                        'Clerk Report',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].orders.history`,
                                        'History',
                                        formik
                                    )}
                                </CustomWrapper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CustomWrapper
                                    title="Accounting"
                                    outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                >
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounting.redemptions`,
                                        'Redemptions',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounting.invoicing`,
                                        'Invoicing',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounting.quickbooks`,
                                        'Quickbooks',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].accounting.agingBalancesReport`,
                                        'Aging Report',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="Users" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].users.admins`,
                                        'Admins',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].users.customers`,
                                        'Customers',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].users.organizations`,
                                        'Organizations',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].users.employees`,
                                        'Employees',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].users.collectors`,
                                        'Collectors',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="Tools" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.announcements`,
                                        'Announcements',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.assetTracking`,
                                        'Asset Tracking',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.bottleDrives`,
                                        'Bottle Drives',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.imgCaptureStatus`,
                                        'Image Capture Device Status',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].pickups.import`,
                                        'Import',
                                        formik
                                    )}

                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.questionnaires`,
                                        'Questionnaires',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.sendOuts`,
                                        'Single Sends',
                                        formik
                                    )}
                                </CustomWrapper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CustomWrapper title="Usage" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.trends`,
                                        'Trends',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.questionnaires`,
                                        'Questionnaires',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.promoUsage`,
                                        'Promo Usage',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.donations`,
                                        'Donations',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.fees`,
                                        'Subscriptions & Fees',
                                        formik
                                    )}
                                    {getCheckbox(theme, `permissions[${permissionIndex}].usage.tips`, 'Tips', formik)}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].usage.complaints`,
                                        'Complaints',
                                        formik
                                    )}
                                    {getCheckbox(theme, `permissions[${permissionIndex}].usage.stops`, 'Stops', formik)}
                                </CustomWrapper>
                                <CustomWrapper
                                    title="Configuration"
                                    outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                >
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.commodities`,
                                        'Commodities',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.fees`,
                                        'Fees',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.promos`,
                                        'Promos',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.rates`,
                                        'Rates',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.regions`,
                                        'Regions',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.ratingPlatforms`,
                                        'Rating Platforms',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.services`,
                                        'Services & Account Types',
                                        formik
                                    )}

                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.taxes`,
                                        'Taxes',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].configuration.trucks`,
                                        'Trucks',
                                        formik
                                    )}
                                </CustomWrapper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <CustomWrapper title="Audit" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].audit.reviewInfractions`,
                                        'Review Infractions',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="Logs" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].logs.googleCalls`,
                                        'Google Calls',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].logs.databaseLogs`,
                                        'Database',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].logs.messageLogs`,
                                        'Notifications (SMS/Email/App)',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].logs.requestLogs`,
                                        'HTTP Requests',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper
                                    title="Security"
                                    outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                >
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].security.ipBlocking`,
                                        'Ip Blocking',
                                        formik
                                    )}
                                </CustomWrapper>
                                <CustomWrapper title="System" outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.integrations`,
                                        'Integrations',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.savedNotifications`,
                                        'Saved Notifications',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.settings`,
                                        'Settings',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.informationForms`,
                                        'Information Editor',
                                        formik
                                    )}
                                    {getCheckbox(
                                        theme,
                                        `permissions[${permissionIndex}].system.systemTasks`,
                                        'Automated Tasks',
                                        formik
                                    )}
                                </CustomWrapper>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                <div />
            </div>
        </div>
    );
}

export default withWidth()(withTheme()(RolePermissions));
