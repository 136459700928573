import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Paper,
    Typography,
    Button,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Icon,
    OutlinedInput,
    colors,
    withTheme,
    LinearProgress,
    Chip,
    Grid,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';

import DateListInput from 'components/InputComponents/DateListInput';
import InlineChip from 'components/Chips/InlineChip';

import { TIMEZONES, ZONES_COLORS } from 'constants.js';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getTextInput, getSelect } from 'components/CRUDTables/helperFunctions';

import { AVAILABLE_FREQUENCIES } from 'constants.js';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function ZoneEditPanel(props) {
    const {
        loading,
        zone = {},
        collectors,
        commodities,
        creatingNewZone,
        onCancel,
        onSubmitValues,
        onDisableZone,
        hidden,
        theme
    } = props;
    const { _id } = useParams();

    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            active: _.get(zone, 'active', true),
            name: _.get(zone, 'name', ''),
            timezone: _.get(zone, 'timezone', _.first(TIMEZONES)),
            startingDates: _.get(zone, 'startingDates', []).map(startingDateStr => new Date(startingDateStr)),
            efficiencyModifier: _.get(zone, 'efficiencyModifier', 1),
            frequency: _.get(zone, 'frequency', 7),
            collector: _.get(zone, 'collector._id', _.first(collectors)._id),
            destination: _.get(zone, 'destination', _.get(zone, 'collector._id', _.first(collectors)._id)),
            payloadAccepted: _.get(zone, 'payloadAccepted', []),
            payloadRequired: _.get(zone, 'payloadRequired', [])
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a value'),
            startingDates: Yup.array()
                .required('You must select at least 1')
                .min(1, 'You must select at least 1'),
            payloadAccepted: Yup.array()
                .required('You must select at least 1')
                .min(1, 'You must select at least 1'),
            efficiencyModifier: Yup.number().min(1, 'Must be 1 or higher')
        }),
        onSubmit: onSubmitValues
    });

    const handleChangeCollector = async e => {
        const { value } = e.target;
        formik.setFieldValue('collector', value);
    };
    const handleChangeDestination = async e => {
        const { value } = e.target;
        formik.setFieldValue('destination', value);
    };

    const handleAcceptedPayloadChange = e => {
        const { value } = e.target;

        const newPayloadRequired = _.filter(
            value,
            v =>
                formik.getFieldProps('payloadRequired').value.includes(v) &&
                formik.getFieldProps('payloadAccepted').value.includes(v)
        );

        formik.setFieldValue('payloadAccepted', value);
        formik.setFieldValue('payloadRequired', newPayloadRequired);
    };

    const renderStartingDates = (date, onRemoveDate) => {
        return (
            <Chip
                key={moment(date)
                    .tz(formik.values.timezone)
                    .format('MMM D, YYYY')}
                style={{
                    backgroundColor:
                        ZONES_COLORS[
                            moment(date)
                                .tz(formik.values.timezone)
                                .isoWeekday() - 1
                        ][500],
                    padding: '2px 4px',
                    margin: '2px 4px',
                    borderRadius: 4,
                    color: 'white',
                    fontSize: theme.typography.fontSize * 0.875,
                    width: '48%'
                }}
                label={`${moment(date)
                    .tz(formik.values.timezone)
                    .format('dddd')}s (${moment(date)
                    .tz(formik.values.timezone)
                    .format('MMM D, YYYY')})`}
                onDelete={() => onRemoveDate(date)}
            />
        );
    };

    const renderUnservicedDate = () => {
        if (_.isNil(formik.values.collector) || _.isNil(collectors)) {
            return null;
        }
        const selectedCollector = collectors.find(collector => collector._id === formik.values.collector);
        const futureUnservicedDates = selectedCollector.notServicedDates
            ? selectedCollector.notServicedDates.filter(date => moment(date, 'YYYY/MM/DD').isSameOrAfter(moment()))
            : [];

        if (!_.isEmpty(futureUnservicedDates)) {
            // limited to show no more than 4 dates
            let formattedUnservicedDates = futureUnservicedDates
                .sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
                .filter((date, idx) => idx < 4);
            return (
                <Grid item xs={12}>
                    <Typography style={{ marginTop: theme.spacing.unit * 1 }}>
                        <Typography
                            style={{ fontSize: 13, marginLeft: theme.spacing.unit / 2 }}
                            color={colors.grey[300]}
                        >
                            Upcoming Unserviced Date
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginTop: theme.spacing.unit / 2,
                                marginBottom: theme.spacing.unit / 2
                            }}
                        >
                            {formattedUnservicedDates.map(date => (
                                <InlineChip
                                    style={{ margin: theme.spacing.unit / 2 }}
                                    key={date}
                                    color={colors.grey[300]}
                                    text={date}
                                />
                            ))}
                        </div>
                    </Typography>
                </Grid>
            );
        }
    };

    useEffect(() => {
        formik.handleReset();
    }, [zone]);

    return (
        !hidden && (
            <div
                style={{
                    position: 'absolute',
                    left: theme.spacing.unit * 2,
                    top: theme.spacing.unit * 2,
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    maxWidth: 480,
                    maxHeight: `calc(100% - ${theme.spacing.unit * 8}px)`,
                    overflowY: 'auto'
                }}
            >
                <Paper style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs={6}>
                            <Typography variant="h6"> {creatingNewZone ? 'Create' : 'Update'} Zone</Typography>
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link
                                to={`/operators/${_id}/collectors`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <Button color="primary">View Collectors</Button>
                            </Link>
                        </Grid>
                        {_.isNil(zone.bounds) ? (
                            <Typography>Click the map to start creating a zone</Typography>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    {getTextInput(theme, 'name', 'Name', formik)}
                                </Grid>
                                <Grid item xs={6}>
                                    {getSelect(theme, 'timezone', 'Timezone', TIMEZONES, formik)}
                                </Grid>

                                <Grid item xs={12}>
                                    <DateListInput
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                        values={formik.values.startingDates}
                                        onDatesChanged={dates => {
                                            formik.setFieldValue('startingDates', dates);
                                        }}
                                        timezone={formik.values.timezone}
                                        label="Service Starting Dates"
                                        renderDate={renderStartingDates}
                                        helperText={
                                            _.get(formik.errors, 'startingDates', false) && (
                                                <Typography style={{ color: colors.red[500] }}>
                                                    {_.get(formik.errors, 'startingDates', false)}
                                                </Typography>
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                        <InputLabel variant="outlined">Frequency</InputLabel>

                                        <Select
                                            {...formik.getFieldProps('frequency')}
                                            fullWidth
                                            input={<OutlinedInput labelWidth={90} />}
                                        >
                                            {Object.keys(AVAILABLE_FREQUENCIES)
                                                .map(f => parseInt(f))
                                                .map(frequency => {
                                                    return (
                                                        <MenuItem value={frequency} key={frequency}>
                                                            {frequency / 7 === 1
                                                                ? `Weekly`
                                                                : `Every ${frequency / 7} Weeks`}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    {getSelect(
                                        theme,
                                        'collector',
                                        'Transporter',
                                        collectors,
                                        formik,
                                        handleChangeCollector,
                                        '_id',
                                        'name'
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    {getSelect(
                                        theme,
                                        'destination',
                                        'Processor',
                                        collectors,
                                        formik,
                                        handleChangeDestination,
                                        '_id',
                                        'name'
                                    )}
                                </Grid>

                                {renderUnservicedDate()}
                                <Grid item xs={4}>
                                    {getSelect(
                                        theme,
                                        'payloadAccepted',
                                        'Accepted Payloads',
                                        _.filter(commodities, c => !_.get(c, 'disabled', false)),
                                        formik,
                                        handleAcceptedPayloadChange,
                                        '_id',
                                        `payloadInputName.${lang}`,
                                        true
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    {getSelect(
                                        theme,
                                        'payloadRequired',
                                        'Required Payloads',
                                        _.filter(commodities, c => formik.values.payloadAccepted.includes(c._id)),
                                        formik,
                                        null,
                                        '_id',
                                        `payloadInputName.${lang}`,
                                        true
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    {getTextInput(
                                        theme,
                                        'efficiencyModifier',
                                        'Difficulty Modifier',
                                        formik,
                                        'number',
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title="Multiplies the number of bags to give the driver more time to complete the route"
                                                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                                    >
                                                        <Icon data-cy="test-info-icon">info</Icon>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }
                                    )}
                                </Grid>

                                {!creatingNewZone && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: theme.spacing.unit * 2
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                                warnAction(
                                                    onDisableZone,
                                                    'Disable this zone? Any new pickups for this zone will be unserviced.'
                                                );
                                            }}
                                        >
                                            Disable Zone
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing.unit * 2 }}>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={_.isNil(zone.bounds) || loading}
                        >
                            Save
                        </Button>
                    </div>
                    {loading && <LinearProgress />}
                </Paper>
            </div>
        )
    );
}

export default withTheme()(ZoneEditPanel);
