import React, { Component, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { formatAsCurrency } from 'utils/misc';

import { Paper, Icon, IconButton, Typography, Divider, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';

import { getTaxableBulksByCustomer } from 'helpers/charityTaxReceiptsHelper';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const CURRENT_YEAR = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .year();

const CURRENT_YEAR_END = moment()
    .tz(process.env.REACT_APP_REGION_TIMEZONE)
    .endOf('year');

class FinancialWidget extends Component {
    static contextType = LocalizationContext;
    render() {
        const {
            theme,
            bulks,
            bulksLoaded,
            redemptions,
            charity,
            elevated,
            isCharity,
            balance,
            amountPending,
            amountDonated,
            lastRedemption,
            onRedirectToPage,
            style,
            disabled,
            adminPermissions
        } = this.props;

        let message;

        if (_.isNil(amountPending) && !_.isNil(amountDonated)) {
            message = (
                <Typography
                    color="textSecondary"
                    style={{ marginTop: theme.spacing.unit }}
                    data-cy="financial-widget-summary-donated"
                    disabled={disabled}
                >
                    {loc('dashboardFinancial4', this.context.lang, {
                        amount: formatAsCurrency(amountDonated, this.context.lang)
                    })}
                </Typography>
            );
        } else if (!_.isNil(amountPending) && _.isNil(amountDonated)) {
            message = (
                <Typography
                    color="textSecondary"
                    style={{ marginTop: theme.spacing.unit }}
                    data-cy="financial-widget-summary-pending"
                    disabled={disabled}
                >
                    {loc('dashboardFinancial2', this.context.lang, {
                        amount: formatAsCurrency(amountPending, this.context.lang)
                    })}
                </Typography>
            );
        } else if (lastRedemption && lastRedemption.complete) {
            message = (
                <Typography
                    color="textSecondary"
                    style={{ marginTop: theme.spacing.unit }}
                    data-cy="financial-widget-summary-complete"
                    disabled={disabled}
                >
                    {loc('dashboardFinancial3', this.context.lang, {
                        amount: formatAsCurrency(lastRedemption.amount - lastRedemption.fee, this.context.lang)
                    })}
                </Typography>
            );
        }

        const issuesTaxReceipts = _.get(charity, 'taxReceiptsIssued');
        let taxReceiptsToIssue = false;

        if (isCharity) {
            const endOfTaxYear = moment(charity.taxReceiptsEndOfYear || CURRENT_YEAR_END)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .set('year', CURRENT_YEAR)
                .endOf('day');

            const taxData = getTaxableBulksByCustomer(charity, bulks, endOfTaxYear, CURRENT_YEAR - 1);
            for (const year of taxData.keys()) {
                if (taxData.get(year).taxableCount > 0) {
                    taxReceiptsToIssue = true;
                    break;
                }
            }
        }

        return (
            <Paper
                elevated={elevated ? 4 : 2}
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
                    ...style
                }}
            >
                <div
                    data-cy="financial-widget"
                    id="financial-widget"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginRight: -theme.spacing.unit * 1.5
                    }}
                >
                    <div style={{ overflow: 'hidden' }}>
                        {isCharity && !bulksLoaded ? (
                            <Typography variant="h4">-</Typography>
                        ) : (
                            <Typography
                                data-cy="financial-widget-balance-value"
                                variant="h4"
                                style={{
                                    color: balance < 0 ? colors.red[500] : theme.palette.linkColor,
                                    fontWeight: 300
                                }}
                                disabled={disabled}
                            >
                                {process.env.REACT_APP_CURRENCY.substr(0, 2)}{' '}
                                {formatAsCurrency(balance, this.context.lang)}
                            </Typography>
                        )}
                        <Typography variant="body1" style={{ fontWeight: 300 }} disabled={disabled}>
                            {isCharity
                                ? loc('dashboardFinancial1', this.context.lang, {
                                      organization: terms.ORGANIZATION_NAME
                                  })
                                : loc('dashboardFinancial6', this.context.lang)}
                        </Typography>
                        {message}
                    </div>
                    <div
                        style={{
                            borderLeft: '1px solid ' + theme.palette.text.hint,
                            paddingLeft: theme.spacing.unit,
                            paddingRight: theme.spacing.unit
                        }}
                    >
                        <IconButton onClick={this.props.onRedemptionDialog(true)} disabled={disabled}>
                            <Icon>payment</Icon>
                        </IconButton>
                    </div>
                </div>
                {isCharity && issuesTaxReceipts && taxReceiptsToIssue && (
                    <>
                        <Divider style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit }} />
                        <MenuItem
                            onClick={() => onRedirectToPage('charityReceipts')}
                            style={{ whiteSpace: 'normal', height: 'inherit' }}
                        >
                            <ListItemIcon>
                                <Icon style={{ color: colors.red[500] }}>error</Icon>
                            </ListItemIcon>
                            <ListItemText>{loc('dashboardFinancial5', this.context.lang)}</ListItemText>
                            <Icon>chevron_right</Icon>
                        </MenuItem>
                    </>
                )}
            </Paper>
        );
    }
}

export default withTheme()(FinancialWidget);
