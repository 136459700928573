import React, { useState } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { deviceHelper } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from '@material-ui/core';

import useTable from 'components/Table/useTable';
import { _user } from 'std';

import { openWindow } from 'helpers/windowHelper';

import DashBoardTable from 'components/Table/DashBoardTable';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import InfractionsDialog from 'components/Dialogs/Review/infractions';

const enabledHeaders = [
    { name: 'Actions', key: null },
    { name: 'Name^', key: 'Name' },
    { name: 'ID', key: null },
    { name: 'Latest Infraction Date^', key: 'Latest Infractio nDate' },
    { name: 'Address^', key: 'Address' },
    { name: 'Business Name^', key: 'Business Name' }
];

const disabledHeaders = [
    { name: 'Actions', key: null },
    { name: 'Name^', key: 'Name' },
    { name: 'ID', key: null },
    { name: 'Latest Infraction Date^', key: 'Latest Infractio nDate' },
    { name: 'Address^', key: 'Address' },
    { name: 'Business Name^', key: 'Business Name' }
];

const Review = ({ theme, http, onSnackbar, history, setRefreshUnderReviewUserCount }) => {
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [showInfractionsDialog, setShowInfractionsDialog] = useState(false);
    const [currentInfractionList, setCurrentInfractionList] = useState([]);
    const [currentUserId, setCurrentUserId] = useState('');
    const [currentHandlerDocs, setCurrentHandlerDocs] = useState([]);
    const [currentCollectorDocs, setCurrentCollectorDocs] = useState([]);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        data,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/users',
        key: 'customers',
        headers: enabledHeaders.map(h => h.name),
        queryObj: {
            accountType: 'Customer',
            disabled: { $ne: true },
            banned: { $ne: true },
            infractions: { $exists: true, $not: { $size: 0 } },
            underReview: true
        }
    });

    const {
        loading: loading2,
        getData: getData2,
        page: page2,
        rowsPerPage: rowsPerPage2,
        data: data2,
        filteredData: filteredData2,
        search: search2,
        filteredHeaders: filteredHeaders2,
        totalDataEntries: totalDataEntries2,
        handleChange: handleChange2,
        handleSearch: handleSearch2,
        handleChangeFilterHeaders: handleChangeFilterHeaders2,
        handleAllFilterHeaders: handleAllFilterHeaders2,
        handleChangePage: handleChangePage2,
        handleChangeRowsPerPage: handleChangeRowsPerPage2
    } = useTable({
        url: '/users',
        key: 'customers',
        headers: disabledHeaders.map(h => h.name),
        queryObj: {
            accountType: 'Customer',
            disabled: { $ne: true },
            banned: { $ne: true },
            infractions: { $exists: true, $not: { $size: 0 } },
            underReview: false
        }
    });

    const handleView = _id => {
        const URL = `/customers/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };
    const handleViewDriver = document => {
        let extension = '';
        if (_user.isDriver(document) || _user.isClerk(document)) {
            extension = 'driver';
        } else if (document.accountType === 'Collector Administrator') {
            extension = 'collector';
        }
        const URL = `/operators/${document._id}/${extension}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };
    const handleViewCollector = document => {
        const URL = `/operators/${document.collectorManager._id}/collector`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    const handleSubmit = e => {
        handleSearch(e);
        handleSearch2(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };
    const onChangeFilterHeader = e => {
        handleChangeFilterHeaders2(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeaders2(all)(e);
    };
    const handleChangeAll = e => {
        handleChange(e);
        handleChange2(e);
    };

    async function resolveInfraction(infractionId) {
        const res = await http.postJSON(`/users/resolveInfraction`, {
            userId: currentUserId,
            infractionId: infractionId
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            let newInfractions = _.get(res, 'data.user.infractions', null);
            if (!_.isNil(newInfractions)) {
                setCurrentInfractionList(newInfractions);
            }
            onSnackbar('Infraction resolved.');
            setRefreshUnderReviewUserCount(true);
        }
    }
    async function unresolveInfraction(infractionId) {
        const res = await http.postJSON(`/users/unresolveInfraction`, {
            userId: currentUserId,
            infractionId: infractionId
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            let newInfractions = _.get(res, 'data.user.infractions', null);
            if (!_.isNil(newInfractions)) {
                setCurrentInfractionList(newInfractions);
            }
            onSnackbar('Infraction unresolved.');
            setRefreshUnderReviewUserCount(true);
        }
    }
    async function resolveAllInfraction() {
        const res = await http.postJSON(`/users/resolveInfraction?all=true`, {
            userId: currentUserId
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            let newInfractions = _.get(res, 'data.user.infractions', null);
            if (!_.isNil(newInfractions)) {
                setCurrentInfractionList(newInfractions);
            }
            onSnackbar('Infractions resolved.');
            setRefreshUnderReviewUserCount(true);
        }
    }
    async function unresolveAllInfraction() {
        const res = await http.postJSON(`/users/unresolveInfraction?all=true`, {
            userId: currentUserId
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            let newInfractions = _.get(res, 'data.user.infractions', null);
            if (!_.isNil(newInfractions)) {
                setCurrentInfractionList(newInfractions);
            }
            onSnackbar('Infractions unresolved.');
            setRefreshUnderReviewUserCount(true);
        }
    }

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const cellStyle = { whiteSpace: 'nowrap' };

    const renderUserRows = (user, filteredHeaders) => {
        const {
            _id,
            fullName,
            uniqueID,
            email,
            oAuth,
            phone,
            location,
            settings,
            balance,
            recurringFees,
            verification,
            banned,
            disabled,
            deleted,
            bulks,
            note,
            infractions = [],
            handlerDocs = [],
            collectorDocs = []
        } = user;
        let latestInfractionDate = _.get(user, 'latestInfractionDate', new Date(0));

        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={cellStyle}>
                        <>
                            <IconButton onClick={() => handleView(_id)}>
                                <Icon>visibility</Icon>
                            </IconButton>
                            <Tooltip title={'View Infractions'}>
                                <IconButton
                                    onClick={() => {
                                        setCurrentInfractionList(infractions);
                                        setCurrentUserId(_id);
                                        setShowInfractionsDialog(true);
                                        setCurrentHandlerDocs(handlerDocs);
                                        setCurrentCollectorDocs(collectorDocs);
                                    }}
                                >
                                    <Icon>policy</Icon>
                                </IconButton>
                            </Tooltip>
                        </>
                    </TableCell>
                )}

                {filteredHeaders.includes('Name^') && <TableCell style={cellStyle}>{fullName}</TableCell>}

                {filteredHeaders.includes('ID') && <TableCell style={cellStyle}>{uniqueID}</TableCell>}
                {filteredHeaders.includes('Latest Infraction Date^') && (
                    <TableCell style={cellStyle}>{moment(latestInfractionDate).format('YYYY-MM-DD HH:mm')}</TableCell>
                )}

                {filteredHeaders.includes('Address^') && (
                    <TableCell
                        style={{
                            ...cellStyle,
                            maxWidth: 250,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                    >
                        <Tooltip title={_.get(location, 'description', null)} interactive>
                            <span>{_.get(location, 'description', null)}</span>
                        </Tooltip>
                    </TableCell>
                )}

                {filteredHeaders.includes('Business Name^') && (
                    <TableCell style={cellStyle}>{_.get(location, 'businessName', null)}</TableCell>
                )}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    return (
        <>
            <InfractionsDialog
                open={showInfractionsDialog}
                onClose={() => {
                    setShowInfractionsDialog(false);
                }}
                title={`Infractions`}
                infractionList={currentInfractionList}
                resolveInfraction={resolveInfraction}
                unresolveInfraction={unresolveInfraction}
                handlerDocs={currentHandlerDocs}
                collectorDocs={currentCollectorDocs}
                handleViewDriver={handleViewDriver}
                handleViewCollector={handleViewCollector}
                resolveAllInfraction={resolveAllInfraction}
                unresolveAllInfraction={unresolveAllInfraction}
            />
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {enabledHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search2 || ''}
                                                    onChange={handleChangeAll}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading || loading2}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Under Review"
                loading={loading}
                headers={enabledHeaders}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderUserRows(user, filteredHeaders);
                    })}
            </DashBoardTable>
            <DashBoardTable
                title="Reviewed"
                loading={loading2}
                headers={disabledHeaders}
                filteredHeaders={filteredHeaders2}
                length={totalDataEntries2}
                page={page2}
                rowsPerPage={rowsPerPage2}
                handleChangePage={handleChangePage2}
                handleChangeRowsPerPage={handleChangeRowsPerPage2}
                refresh={getData2}
            >
                {filteredData2 &&
                    filteredData2.map(user => {
                        return renderUserRows(user, filteredHeaders2);
                    })}
            </DashBoardTable>
        </>
    );
};

export default withTheme()(Review);
