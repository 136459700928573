import React, { useContext } from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { withTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../../localizations/localizationHandler';

function DisableAccountDialog({
    theme,
    open,
    fullScreen,
    pickupsLeft,
    disableReason,
    handleClose,
    handleDisableAccount,
    handleDisableReasonChange
}) {
    const { lang } = useContext(LocalizationContext);

    const formControlStyle = {
        marginTop: theme.spacing.unit + theme.spacing.unit / 2
    };

    const disableReasons = [
        { reason: loc('disableAccount4', lang) },
        { reason: loc('disableAccount5', lang), label: loc('disableAccount8', lang) },
        { reason: loc('disableAccount6', lang), label: loc('disableAccount9', lang) },
        { reason: loc('disableAccount7', lang), label: loc('reason', lang) }
    ];

    return (
        <Dialog fullWidth open={open} fullScreen={fullScreen} onClose={handleClose}>
            <DialogTitle style={{ paddingTop: 'max(24px, env(safe-area-inset-top, 24px))' }}>
                {loc('disableAccount1', lang)}
            </DialogTitle>
            <DialogContent>
                {pickupsLeft && (
                    <DialogContentText style={{ color: colors.red[500] }}>
                        {loc('disableAccount2', lang)}
                    </DialogContentText>
                )}
                <FormControl data-cy="disable-account-dialog" fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <InputLabel htmlFor="disableReason">{loc('disableAccount3', lang)}</InputLabel>
                    <Select
                        value={disableReason.reason || ''}
                        input={<Input value={disableReason.reason || ''} name="reason" id="disableReason" />}
                        onChange={handleDisableReasonChange}
                    >
                        {disableReasons.map(function(disable, i) {
                            return (
                                <MenuItem key={i} data-cy={'disable-account-reason-' + i} value={disable.reason}>
                                    {disable.reason}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {_.get(_.find(disableReasons, ['reason', disableReason.reason]), 'label') && (
                        <FormControl style={formControlStyle} fullWidth>
                            <TextField
                                data-cy="disable-account-reason-comments"
                                name="comments"
                                label={_.get(_.find(disableReasons, ['reason', disableReason.reason]), 'label')}
                                value={disableReason.comments}
                                variant="outlined"
                                onChange={handleDisableReasonChange}
                            />
                        </FormControl>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button data-cy="disable-account-cancel-button" color="primary" onClick={handleClose}>
                    {loc('no', lang)}
                </Button>
                <Button
                    data-cy="disable-account-accept-button"
                    color="primary"
                    disabled={
                        _.isEmpty(disableReason.reason) ||
                        (_.get(_.find(disableReasons, ['reason', disableReason.reason]), 'label') &&
                            _.isEmpty(disableReason.comments))
                    }
                    onClick={handleDisableAccount}
                >
                    {loc('yes', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(DisableAccountDialog);
