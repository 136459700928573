import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme } from '@material-ui/core/styles';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import TruckForm from './Forms/TruckForm';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography
} from '@material-ui/core';

function TruckTable(props) {
    const { operator, http, onSnackbar, theme } = props;

    const endpoints = {
        getEndPoint: '/trucks/getTrucksCRUD', // url to get all documents
        getEditEndPoint: _id => `/trucks/${_id}/updateTruck`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/trucks/createTruck` // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const [odometerHistoryDialogOpen, setOdometerHistoryDialogOpen] = useState(false);
    const {
        data,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        { key: 'name', header: 'Name' },
        {
            key: 'number',
            header: 'Number'
        },
        {
            key: 'odometer',
            header: 'Odometer(km)',
            formatValue: value => value.toLocaleString()
        },
        { key: 'licensePlate', header: 'License Plate' }
    ];

    const editForm = (
        <TruckForm
            {...selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
        />
    );

    const handleOpenOdometerHistoryDialog = doc => {
        setSelectedDocument(doc);
        setOdometerHistoryDialogOpen(true);
    };

    const renderExtraActions = document => (
        <Tooltip title="Edit" disableFocusListener disableTouchListener>
            <IconButton
                onClick={() => {
                    handleOpenOdometerHistoryDialog(document);
                }}
                data-cy="truck-odometer-history-open-button"
            >
                <Icon>speed</Icon>
            </IconButton>
        </Tooltip>
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={truck => !_.get(truck, 'active', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Enabled Trucks'}
                disabledHeaderText={'Disabled Trucks'}
                loading={loading}
                hideDateFilters
                renderExtraActions={renderExtraActions}
            />
            <Dialog open={odometerHistoryDialogOpen} onClose={() => setOdometerHistoryDialogOpen(false)} fullWidth>
                <DialogTitle>Odometer History - {_.get(selectedDocument, 'name', 'N/A')}</DialogTitle>
                <DialogContent>
                    <List style={{ marginTop: 0, marginBottom: theme.spacing.unit }}>
                        {_.get(selectedDocument, 'odometerHistory', []).map((item, index) => (
                            <>
                                <ListItem
                                    disableGutters
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: theme.spacing.unit,
                                        paddingRight: theme.spacing.unit
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography variant="caption">
                                                {moment(_.get(item, 'date', ''))
                                                    .tz(
                                                        _.get(
                                                            operator,
                                                            'location.timezone',
                                                            process.env.REACT_APP_REGION_TIMEZONE
                                                        )
                                                    )
                                                    .format('dddd[,] MMM D, YYYY h:mm:ss A')}
                                            </Typography>
                                        }
                                    />

                                    <ListItemSecondaryAction
                                        style={{
                                            paddingRight: theme.spacing.unit
                                        }}
                                    >
                                        <Typography variant="caption">{item.odometer} km</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {index < _.get(selectedDocument, 'odometerHistory', []).length - 1 ? (
                                    <Divider style={{ marginBottom: theme.spacing.unit }} />
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOdometerHistoryDialogOpen(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(TruckTable);
