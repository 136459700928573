import React, { useState, useContext } from 'react';
import { Clipboard } from '@capacitor/clipboard';

import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import { IconButton, Link, Badge, Chip } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import CharityActionChips from './CharityActionChips';

import { _charity } from 'std';

import ReferralLinksDialog from 'components/Dialogs/Charity/ReferralLinksDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

function CharityInfoWidget(props) {
    const {
        theme,
        charity,
        elevated,
        customersDonating,
        socialMediaShares,
        onCharityGrowthPlan,
        onSnackbar,
        assignRef,
        onShareDialogOpen,
        shareDialogEnabled,
        onRedirectToPage,
        bulks,
        changingCharities
    } = props;
    const { lang } = useContext(LocalizationContext);
    const [referralLinksDialogOpen, setReferralLinksDialogOpen] = useState(false);

    let charityApprovedStatus, charityFeaturedStatus;

    if (charity.closed) {
        charityApprovedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.grey[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    textTransform: 'capitalize'
                }}
            >
                <Icon
                    style={{
                        color: colors.grey[500]
                    }}
                >
                    cancel
                </Icon>{' '}
                <span data-cy="charity-info-widget-approved-status">{loc('closed', lang)}</span>
            </Typography>
        );
    } else if (charity.approved) {
        charityApprovedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.green[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}
            >
                <Icon
                    style={{
                        color: colors.green[500]
                    }}
                >
                    check_circle
                </Icon>{' '}
                <span data-cy="charity-info-widget-approved-status">{loc('approved', lang)}</span>
            </Typography>
        );
    } else if (!charity.approved && charity.approvalPending) {
        charityApprovedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.orange[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}
            >
                <Icon
                    style={{
                        color: colors.orange[500]
                    }}
                >
                    check_circle
                </Icon>{' '}
                <span data-cy="charity-info-widget-approved-status">{loc('approvalPending', lang)}</span>
            </Typography>
        );
    } else {
        charityApprovedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.red[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}
            >
                <Icon
                    style={{
                        color: colors.red[500]
                    }}
                >
                    highlight_off
                </Icon>{' '}
                <span data-cy="charity-info-widget-approved-status">{loc('notApproved', lang)}</span>
            </Typography>
        );
    }

    if (charity.isFeatured) {
        charityFeaturedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.green[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}
            >
                <span data-cy="charity-info-widget-featured-status">{loc('dashboardMain1', lang)}</span>{' '}
                <Icon
                    style={{
                        color: colors.green[500]
                    }}
                >
                    star
                </Icon>
            </Typography>
        );
    } else {
        charityFeaturedStatus = (
            <Typography
                variant="body1"
                style={{
                    color: colors.grey[500],
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}
            >
                <span data-cy="charity-info-widget-featured-status">{loc('dashboardMain12', lang)}</span>{' '}
                <Icon
                    style={{
                        color: colors.grey[500]
                    }}
                >
                    star_border
                </Icon>
            </Typography>
        );
    }

    let url = !_.isEmpty(charity.url) ? charity.url : charity._id;
    let referralLink = `${process.env.REACT_APP_ORIGIN_URL}/${url}`;

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        onSnackbar(loc('copiedReferraLink', lang));
    };
    return (
        <div
            id="charity-info-widget"
            data-cy="charity-info-widget"
            ref={elem => {
                assignRef(elem);
            }}
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: `100%`,
                marginTop: 'env(safe-area-inset-top)'
            }}
        >
            <div style={{ margin: '0 auto', maxWidth: 500 }}>
                <Paper
                    elevation={elevated ? 4 : 2}
                    style={{
                        width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                        margin: theme.spacing.unit * 2,
                        padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2}px ${theme.spacing.unit *
                            1.5}px ${theme.spacing.unit * 2}px`
                    }}
                >
                    {changingCharities ? (
                        <LoadingScreen />
                    ) : (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {charityApprovedStatus}

                                {charityFeaturedStatus}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant="h5" style={{ marginBottom: theme.spacing.unit }} noWrap>
                                    {charity.name}
                                </Typography>
                            </div>

                            <div style={{ overflow: 'hidden' }}>
                                {charity.approved ? (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginBottom: theme.spacing.unit
                                            }}
                                        >
                                            <CustomWrapper title={loc('dashboardSetting23', lang)}>
                                                <Typography noWrap>
                                                    <Link
                                                        href={referralLink}
                                                        style={{ color: theme.palette.linkColor }}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            handleCopyToClipboard(referralLink)();
                                                        }}
                                                    >
                                                        {referralLink}
                                                    </Link>
                                                </Typography>
                                            </CustomWrapper>
                                            {!_.isEmpty(_charity.getSubdivisions(charity)) && (
                                                <IconButton
                                                    onClick={() => setReferralLinksDialogOpen(true)}
                                                    size="small"
                                                >
                                                    <Badge
                                                        color="primary"
                                                        badgeContent={_charity.getSubdivisions(charity).length + 1}
                                                    >
                                                        <Icon>view_list</Icon>
                                                    </Badge>
                                                </IconButton>
                                            )}
                                        </div>
                                        {/*<Typography>
                                        {loc('dashboardMain4a', lang)}{' '}
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                color: theme.palette.linkColor,
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => onRedirectToPage('charityDonations')}
                                        >
                                            {loc('dashboardMain4b', lang)}
                                        </span>{' '}
                                        {loc('dashboardMain4c', lang)}{' '}
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                color: theme.palette.linkColor,
                                                textDecoration: 'underline'
                                            }}
                                            onClick={onCharityGrowthPlan(true)}
                                        >
                                            {loc('dashboardMain4d', lang)}
                                        </span>{' '}
                                        {loc('dashboardMain4e', lang)}
                                    </Typography>
                                    <Typography
                                        style={{
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            color: theme.palette.linkColor,
                                            marginRight: theme.spacing.unit * 2
                                        }}
                                        onClick={onCharityGrowthPlan(true)}
                                    >
                                        Your Growth Plan:{' '}
                                    </Typography>

                                    <CharityTaskCompletionChip
                                        charity={charity}
                                        style={{ height: 25 }}
                                        setCharityTaskCompletionDialogOpen={setCharityTaskCompletionDialogOpen}
                                    />*/}
                                    </>
                                ) : (
                                    <Typography color="textSecondary">{loc('dashboardMain5', lang)}</Typography>
                                )}
                                {/*
                            {!isWidthUp('md', width) && (
                                <>
                                    <IconButton
                                        style={{ marginRight: 'auto', marginLeft: 'auto', display: 'block' }}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        <Icon>{expanded ? 'expand_less' : 'expand_more'}</Icon>
                                    </IconButton>
                                </>
                            )}

                            <List
                                style={{
                                    paddingTop: expanded ? theme.spacing.unit : 0,
                                    height: expanded ? 'inherit' : 0
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12} lg={6}>
                                        <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                            <Avatar style={{ backgroundColor: colors.green['A700'] }}>
                                                <Icon>people</Icon>
                                            </Avatar>
                                            <ListItemText primary={customersDonating || 0} secondary="Current donors" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                            <Avatar style={{ backgroundColor: colors.lightBlue[500] }}>
                                                <Icon style={{ position: 'relative', left: -1.5 }}>share</Icon>
                                            </Avatar>
                                            <ListItemText
                                                primary={socialMediaShares || 0}
                                                secondary="# of shares"
                                                style={{ paddingRight: 0 }}
                                            />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </List>*/}
                            </div>
                            {/*<div
                            style={{
                                borderLeft: '1px solid ' + theme.palette.text.hint,
                                paddingLeft: theme.spacing.unit
                            }}
                        >
                            <Badge variant="dot" color="error" invisible={!taskHasExpired}>
                                <IconButton onClick={onCharityGrowthPlan(true)}>
                                    <MDIcon path={mdiSproutOutline} size={1} color={theme.palette.text.primary} />
                                </IconButton>
                            </Badge>
                        </div>*/}
                            <CharityActionChips
                                charity={charity}
                                customersDonating={customersDonating}
                                shares={socialMediaShares}
                                onShareDialogOpen={onShareDialogOpen}
                                shareDialogEnabled={shareDialogEnabled}
                                onRedirectToPage={onRedirectToPage}
                                onCharityGrowthPlan={onCharityGrowthPlan}
                                theme={theme}
                                bulks={bulks}
                            />
                        </>
                    )}
                </Paper>
            </div>
            <ReferralLinksDialog
                open={referralLinksDialogOpen}
                onClose={() => setReferralLinksDialogOpen(false)}
                charity={charity}
            />
        </div>
    );
}

export default withTheme()(CharityInfoWidget);
