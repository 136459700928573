import React from 'react';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const AlertDialog = ({ open, handleClose }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Today's Route Has Been Updated</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    There was an update to your route. Please review before continuing.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
