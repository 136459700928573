import React, { useMemo, useRef, useContext } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    withMobileDialog,
    TextField,
    InputAdornment,
    Typography,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    FormHelperText,
    Grow,
    Tooltip,
    CircularProgress
} from '@material-ui/core';

import { getTextInput, getSwitch, getSelect } from '../helperFunctions';
import CurrencyInput from '../Inputs/CurrencyInput';
import LocalizedTextInput from '../Inputs/LocalizedTextInput';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import EligibleExamplesList from 'components/Lists/EligibleExamplesList';
import SubCommoditiesList from 'components/Lists/SubCommoditiesList';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import { COMMODITY_COMPLETION_STRATEGIES } from 'constants.js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CommodityForm(props) {
    const {
        commodity, //NOTE: will be null/undefined if creating a new one
        allFees,
        allRates,
        open,
        onClose,
        onSubmit,
        fullScreen,
        theme,
        loadingRates
    } = props;
    const { lang } = useContext(LocalizationContext);
    const firstRate = _.first(allRates); //all rates should contain all skus for this to work

    const handleSubmit = values => {
        const toSubmit = _.cloneDeep(values);
        toSubmit.iconName = convertToImportName(values.iconName);
        onSubmit(toSubmit);
    };

    const editing = !_.isNil(commodity);

    const allIconNames = useMemo(
        () => Object.keys(allIcons).map(importName => convertImportToIconName(importName)),
        []
    );

    //fixes slowness when dragging color selector, prevents react from rerendering to much,
    //needs to be in a ref so the function isn't recreated every render
    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue('color', e.target.value);
            }
        }, 100)
    );

    const handleFeeChange = e => {
        const fee = e.target.value;
        if (fee === 'none') {
            formik.setFieldValue(e.target.name, null);
            return;
        }

        formik.setFieldValue(e.target.name, fee);
        if (e.target.name === 'preAppliedFee') {
            formik.setFieldValue('preAppliedFeeSingle', fee);
            formik.setFieldValue('preAppliedFeeMultiple', fee);
        }
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(commodity, '_id', undefined),
            disabled: _.get(commodity, 'disabled', false),
            skuType: _.get(commodity, 'skuType', ''),
            units: _.get(commodity, 'units', ''),
            defaultSku: _.get(commodity, 'defaultSku'),
            completionStrategy: _.get(commodity, 'completionStrategy', 'Auto'),
            sizeRatio: _.get(commodity, 'sizeRatio', 1),
            projectionMultiplier: _.get(commodity, 'projectionMultiplier', 1),
            defaultPerUnitAmount: _.get(commodity, 'defaultPerUnitAmount', 1),
            payloadInputName: _.get(commodity, 'payloadInputName', ''),
            payloadHelperText: _.get(commodity, 'payloadHelperText', ''),
            eligibleExamples: _.get(commodity, 'eligibleExamples', []),
            subCommodities: _.get(commodity, 'subCommodities', []),
            color: _.get(commodity, 'color', '#000000'),
            iconName: convertImportToIconName(_.get(commodity, 'iconName', '')),
            preAppliedFee: _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee')),
            preAppliedFeeSingle: _.get(
                commodity,
                'preAppliedFeeSingle._id',
                _.get(
                    commodity,
                    'preAppliedFeeSingle',
                    _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee'))
                )
            ),
            preAppliedFeeMultiple: _.get(
                commodity,
                'preAppliedFeeMultiple._id',
                _.get(
                    commodity,
                    'preAppliedFeeMultiple',
                    _.get(commodity, 'preAppliedFee._id', _.get(commodity, 'preAppliedFee'))
                )
            ),
            preAppliedFeeApply: _.get(commodity, 'preAppliedFeeApply', 'Once'),
            unserviced: _.get(commodity, 'unserviced', false),
            imageRequired: _.get(commodity, 'imageRequired', true),
            subCommodityCustomerDisclaimer: _.get(commodity, 'subCommodityCustomerDisclaimer', ''),
            subCommodityDriverDisclaimer: _.get(commodity, 'subCommodityDriverDisclaimer', ''),
            showOnMap: _.get(commodity, 'showOnMap', false),
            hideFromCustomer: _.get(commodity, 'hideFromCustomer', false),
            hidePrimary: _.get(commodity, 'hidePrimary', false),
            // subCommodityCounterDisclaimer: _.get(commodity, 'subCommodityCounterDisclaimer', '')
            includeContainersInStats: _.get(commodity, 'includeContainersInStats', false),
            showAsDonatedInHistory: _.get(commodity, 'showAsDonatedInHistory', false)
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            skuType: Yup.string().required('You must enter a commodity name.'),
            units: Yup.string().required('You must enter a unit of measure.'),
            defaultSku: !editing
                ? Yup.number().when('completionStrategy', {
                      is: 'Auto',
                      then: Yup.number().required('You must enter a unique number')
                  })
                : Yup.string(),
            defaultPerUnitAmount: Yup.number()
                .required('You must enter a value.')
                .min(1, 'Must be 1 or more'),
            completionStrategy: Yup.string().required('You must select a value'),
            payloadInputName: Yup.string().required('You must enter a value.'),
            payloadHelperText: Yup.string().required('You must enter a value.'),
            color: Yup.string().required('You must enter a color'),
            iconName: Yup.string()
                .transform((value, originalValue) => convertToImportName(originalValue))
                .oneOf(allIconNames.map(iconName => convertToImportName(iconName)), 'Invalid icon name')
                .required('You must enter an icon name'),
            unserviced: Yup.boolean(),
            imageRequired: Yup.boolean(),
            showAsDonatedInHistory: Yup.boolean()
        }),
        onSubmit: handleSubmit
    });

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik);
    };

    return (
        <Dialog open={open} fullScreen={fullScreen}>
            <DialogContent>
                <div style={{ display: 'flex' }}>
                    {editing && getSwitch(theme, 'disabled', 'Disabled', formik)}
                    {!_.get(formik, 'values.disabled') &&
                        getSwitch(theme, 'unserviced', 'Available Unserviced', formik)}
                </div>

                <TextField
                    {...formik.getFieldProps('skuType')}
                    error={formik.touched.skuType && formik.errors.skuType ? true : null}
                    label="Name"
                    margin="normal"
                    variant="outlined"
                    helperText={formik.touched.skuType && formik.errors.skuType ? formik.errors.skuType : null}
                    fullWidth
                    disabled={editing}
                />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        {getTextInput(theme, 'iconName', 'Icon Name', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <MDIcon
                                        path={_.get(allIcons, convertToImportName(formik.values.iconName))}
                                        size={1.5}
                                        color={formik.values.color}
                                    />
                                </InputAdornment>
                            )
                        })}
                        <div style={{ display: 'flex', marginTop: theme.spacing.unit }}>
                            <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            type="color"
                            onChange={handleChangeColorWrapper}
                            value={formik.values.color}
                            onBlur={formik.handleBlur}
                            name="color"
                            error={formik.touched.color && formik.errors.color ? true : null}
                            label="Color"
                            margin="normal"
                            variant="outlined"
                            helperText={formik.touched.color && formik.errors.color ? formik.errors.color : null}
                            fullWidth
                        />
                    </div>
                </div>
                <LocalizedTextInput
                    label="Unit Of Measurement (ex. bag, item, phone)"
                    value={_.get(formik, 'values.units')}
                    onChange={value => formik.setFieldValue('units', value)}
                    style={{ marginTop: theme.spacing.unit * 3 }}
                    touched={_.get(formik, 'touched.units')}
                    error={_.get(formik, 'errors.units')}
                />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        {getTextInput(
                            theme,
                            'sizeRatio',
                            'Bag Size Percent',
                            formik,
                            'number',
                            {},
                            {},
                            '0.1 means 1 unit/item is 10% the size of a large garbage bag'
                        )}
                    </div>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        {getTextInput(
                            theme,
                            'projectionMultiplier',
                            'Equivalent Containers',
                            formik,
                            'number',
                            {},
                            {},
                            '20 means 1 unit/item is worth 20 beverage containers'
                        )}
                    </div>
                    <div style={{ flex: 1 }}>
                        {getTextInput(
                            theme,
                            'defaultPerUnitAmount',
                            'Default Amount',
                            formik,
                            'number',
                            {},
                            {},
                            'Counts to use for unprocessed bags when calculating synthetic containers'
                        )}
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    {getSwitch(theme, 'imageRequired', 'Image required', formik, {}, { fontSize: '95%' })}
                    {getSwitch(theme, 'showOnMap', 'Show on map', formik, {}, { fontSize: '95%' })}
                </div>

                <div style={{ display: 'flex' }}>
                    {getSwitch(
                        theme,
                        'includeContainersInStats',
                        'System Beverage Containers',
                        formik,
                        {},
                        { fontSize: '95%' }
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    {getSwitch(
                        theme,
                        'showAsDonatedInHistory',
                        'Show always as donated',
                        formik,
                        {},
                        { fontSize: '95%' }
                    )}
                </div>
                <TextField
                    {...formik.getFieldProps('completionStrategy')}
                    select
                    error={
                        _.get(formik.touched, 'completionStrategy', false) &&
                        _.get(formik.errors, 'completionStrategy', false)
                            ? true
                            : null
                    }
                    label="Completion Strategy"
                    style={{ marginTop: theme.spacing.unit * 2 }}
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'completionStrategy', false) &&
                        _.get(formik.errors, 'completionStrategy', false) &&
                        _.get(formik.errors, 'completionStrategy', false)
                    }
                    fullWidth
                >
                    {COMMODITY_COMPLETION_STRATEGIES.map(completionStrategy => (
                        <MenuItem key={completionStrategy} value={completionStrategy}>
                            {completionStrategy}
                        </MenuItem>
                    ))}
                </TextField>
                {_.get(formik.values, 'completionStrategy', '') === 'Auto' &&
                    (!editing ? (
                        getTextInput(
                            theme,
                            'defaultSku',
                            'Default Sku (ex. 20010)',
                            formik,
                            'number',
                            {},
                            {},
                            'A new rate will be generated with this sku'
                        )
                    ) : (
                        <div style={{ display: 'flex' }}>
                            <FormControl
                                fullWidth
                                error={
                                    _.get(formik.touched, 'defaultSku', false) &&
                                    _.get(formik.errors, 'defaultSku', false)
                                        ? true
                                        : null
                                }
                                style={{ marginTop: theme.spacing.unit * 2 }}
                            >
                                <InputLabel variant="outlined">Default Sku (ex. 20010)</InputLabel>

                                <Select
                                    {...formik.getFieldProps('defaultSku')}
                                    fullWidth
                                    input={<OutlinedInput labelWidth={150} />}
                                >
                                    {!_.isNil(firstRate) &&
                                        _.get(firstRate, 'rates', []).map(rate => {
                                            if (rate.skuType !== _.get(commodity, 'skuType', '')) {
                                                return null;
                                            } else {
                                                return (
                                                    <MenuItem value={_.get(rate, 'sku')} key={_.get(rate, 'sku')}>
                                                        {/*<ListItemText
                        primary={`${rate.description} ${rate.label && `(${rate.label})`}`}
                        secondary={rate.sku}
                    />*/}
                                                        <Typography inline>
                                                            {rate.description} {rate.label && `(${rate.label})`} -
                                                        </Typography>{' '}
                                                        <Typography color="textSecondary" inline>
                                                            {rate.sku}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                </Select>

                                {_.get(formik.touched, 'defaultSku', false) &&
                                _.get(formik.errors, 'defaultSku', false) ? (
                                    <FormHelperText>{_.get(formik.errors, 'defaultSku', '')}</FormHelperText>
                                ) : (
                                    <FormHelperText>
                                        Sku that will be used to generate counts for Auto, Split Units, and Accept Units
                                        completion methods
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {loadingRates && (
                                <CircularProgress
                                    style={{ marginTop: theme.spacing.unit * 2, marginLeft: theme.spacing.unit }}
                                />
                            )}
                        </div>
                    ))}
                <LocalizedTextInput
                    label="Customer Input Name (ex. General Electronics, Clothing Donation)"
                    value={_.get(formik, 'values.payloadInputName')}
                    onChange={value => formik.setFieldValue('payloadInputName', value)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    touched={_.get(formik, 'touched.payloadInputName')}
                    error={_.get(formik, 'errors.payloadInputName')}
                />
                <LocalizedTextInput
                    label="Customer Input Helper Text (ex. Number of garbage bags)"
                    value={_.get(formik, 'values.payloadHelperText')}
                    onChange={value => formik.setFieldValue('payloadHelperText', value)}
                    style={{ marginTop: theme.spacing.unit * 2 }}
                    touched={_.get(formik, 'touched.payloadHelperText')}
                    error={_.get(formik, 'errors.payloadHelperText')}
                />

                <div style={{ display: 'flex' }}>
                    <Tooltip title="This will hide from customers dialog, not allowing them to enter this commodity">
                        {getSwitch(theme, 'hideFromCustomer', 'Hide from customer', formik, {}, { fontSize: '95%' })}
                    </Tooltip>
                    <Tooltip title="This will hide the first input from all users. It will force users to enter the sub commodities">
                        {getSwitch(theme, 'hidePrimary', 'Hide primary commodity', formik, {}, { fontSize: '95%' })}
                    </Tooltip>
                </div>

                <div style={{ display: 'flex' }}>
                    {formik.values.preAppliedFeeApply === 'Once' &&
                        getSelect(
                            theme,
                            'preAppliedFee',
                            'Fee',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description'
                        )}
                    {formik.values.preAppliedFeeApply === 'Per Item' &&
                        getSelect(
                            theme,
                            'preAppliedFeeSingle',
                            'Fee (Single Unit)',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description'
                        )}
                    {formik.values.preAppliedFeeApply === 'Per Item' &&
                        getSelect(
                            theme,
                            'preAppliedFeeMultiple',
                            'Fee (Multiple Units)',
                            [{ _id: 'none', description: 'None' }, ...allFees],
                            formik,
                            handleFeeChange,
                            '_id',
                            'description',
                            false,
                            false,
                            { marginLeft: theme.spacing.unit }
                        )}
                    <Grow in={!_.isNil(formik.values.preAppliedFee)} style={{ marginLeft: theme.spacing.unit }}>
                        {getSelect(
                            theme,
                            'preAppliedFeeApply',
                            'Apply',
                            ['Once', 'Per Item'],
                            formik,
                            null,
                            null,
                            null,
                            false,
                            _.isNil(formik.values.preAppliedFee)
                        )}
                    </Grow>
                </div>

                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <CustomWrapper title={'Sub-Commodities'} innerDivStyle={{ fontSize: '12px' }}>
                        <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                            <SubCommoditiesList
                                allFees={allFees}
                                allIcons={allIcons}
                                allIconNames={allIconNames}
                                commodity={formik.values}
                                commodityFormik={formik}
                                subCommodities={formik.values.subCommodities}
                                onChange={values => formik.setFieldValue('subCommodities', values)}
                            />
                        </div>
                    </CustomWrapper>
                </div>
                {!_.isEmpty(formik.values.subCommodities) && (
                    <LocalizedTextInput
                        label="Sub-Commodity Customer Disclaimer"
                        value={_.get(formik, 'values.subCommodityCustomerDisclaimer')}
                        onChange={value => formik.setFieldValue('subCommodityCustomerDisclaimer', value)}
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    />
                )}

                {!_.isEmpty(formik.values.subCommodities) && (
                    <LocalizedTextInput
                        label="Sub-Commodity Driver Disclaimer"
                        value={_.get(formik, 'values.subCommodityDriverDisclaimer')}
                        onChange={value => formik.setFieldValue('subCommodityDriverDisclaimer', value)}
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    />
                )}
                {/* {!_.isEmpty(formik.values.subCommodities) && (
                    <LocalizedTextInput
                        label="Sub-Commodity Counter Disclaimer"
                        value={_.get(formik, 'values.subCommodityCounterDisclaimer')}
                        onChange={value => formik.setFieldValue('subCommodityCounterDisclaimer', value)}
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    />
                )} */}
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <CustomWrapper title={'Eligible Examples'} innerDivStyle={{ fontSize: '12px' }}>
                        <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                            <EligibleExamplesList
                                eligibleExamples={formik.values.eligibleExamples}
                                onChange={values => formik.setFieldValue('eligibleExamples', values)}
                            />
                        </div>
                    </CustomWrapper>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="commodity-form-submit">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertToImportName(iconName) {
    if (!iconName.startsWith('mdi')) {
        return _.camelCase(`mdi-${iconName}`);
    } else {
        return _.camelCase(iconName);
    }
}

function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

export default withMobileDialog()(withTheme()(CommodityForm));
