import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { _user } from 'std';
import { formatAsPhoneNumber } from '../../../utils/misc';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import GenericDialog from 'components/Dialogs/GenericDialog';

import * as colors from '@material-ui/core/colors';

import {
    Icon,
    Divider,
    Button,
    Typography,
    TextField,
    FormControl,
    DialogContent,
    DialogActions,
    Dialog,
    MobileStepper,
    Fade,
    LinearProgress,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import { withTheme } from '@material-ui/core/styles';

import InProgress from './InProgress';

import { _pickup } from 'std';

import { deviceHelper } from 'utils/misc';
import pickupHelper from 'helpers/pickupHelper';

import ImageCapture from 'components/ImageUploads/ImageCapture';
import useImageUpload from 'components/ImageUploads/useImageUpload';
// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE);

const STEPS_TITLES = ['Enter Reason', 'Follow Up', 'Capture Bags'];

function MultiStepAbortDialog(props) {
    const {
        http,
        fullScreen,
        theme,
        admin,
        socket,
        open,
        pickupSelected,
        getNextPickup,
        currentLocation,
        reasonsAvailable,
        filterDate,
        onClose,
        onMode,
        handleCenterMapOnPickups,
        onSnackbar,
        setRecalculatingTrips,
        onManuallySetLocation,
        gpsNotWorking,
        abortPickupImageTitle,
        abortPickupImageInstructions
    } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [reason, setReason] = useState('');
    const [explanation, setExplanation] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [followUpRequired, setFollowUpRequired] = useState(false);

    const [abortInfoDialogOpen, setAbortInfoDialogOpen] = useState(false);

    const { imagePreviews, uploadingImage, handleDeleteImage, handleUploadImage, setImagePreviews } = useImageUpload(
        {}
    );

    useEffect(() => {
        const handleBackKeyDown = e => {
            e.preventDefault();

            if (activeStep >= 1) {
                setActiveStep(activeStep - 1);
            } else {
                onClose();
            }
        };

        document.addEventListener('backbutton', handleBackKeyDown, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setActiveStep(0);
        setReason('');
        setExplanation('');
        // resetImages();
        setImagePreviews(pickupHelper.getPickupImageURL(pickupSelected));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupSelected._id]);

    const handleReasonChange = reason => {
        setReason(reason);

        if (reason === 'Could not find / access location' || reason === 'No bags found / Not refundable') {
            setFollowUpRequired(true);
        } else {
            setFollowUpRequired(false);
        }
    };

    const handleSubmit = async () => {
        setInProgress(true);

        const formData = new FormData();
        const reasonSelected = _.find(reasonsAvailable, r => r.short.toLowerCase() === reason.toLowerCase());

        formData.append(
            'form',
            JSON.stringify({
                pickup_id: pickupSelected._id,
                reason: reasonSelected.description, // small sentence relayed to the customer history
                reasonToCustomer: reasonSelected.short, // send to customer
                explanation, // for internal use
                userFault: reasonSelected.userFault,
                receiverLocation: currentLocation,
                images: imagePreviews
            })
        );

        const res = await http.post('/pickups/abortPickup', formData, false, true);

        if (res.ok) {
            onMode('SELECT');
            onSnackbar('Pickup aborted. Recalculating trip...');
            if (!admin) {
                handleCenterMapOnPickups();
                getNextPickup(pickupSelected._id);
            }
            onClose();
            setInProgress(false);
            if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(true);

            socket.emit(
                'pickup-complete',
                {
                    filterDate,
                    timezone: filterDate && filterDate.tz() ? filterDate.tz() : moment.tz.guess()
                },
                success => {
                    if (success) {
                        onSnackbar('Trip recalculation complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                    if (!_.isNil(setRecalculatingTrips)) setRecalculatingTrips(false);
                }
            );

            if (gpsNotWorking && !admin) {
                onManuallySetLocation(pickupSelected.location);
            }
        } else {
            onSnackbar('Unable to complete pickup. Please contact support.', 'error');
            setInProgress(false);
        }
    };

    const customer = _.get(pickupSelected, 'customer');
    const customerPhone = _pickup.getPhoneNumber(pickupSelected, customer);
    const formattedPhone = formatAsPhoneNumber(customerPhone);

    const callCustomer = () => {
        if (!customerPhone) return;
        const phone = customerPhone;
        window.open(`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}`);
    };

    const sendSMSToCustomer = () => {
        if (!customerPhone) return;
        const phone = customerPhone;
        const message = `Hey it’s ${process.env.REACT_APP_BRAND_NAME}, I'm here to grab your recycling.`;

        if (deviceHelper.AndroidCordova()) {
            window.open(`sms:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}?body=${message}`);
        } else {
            window.open(`sms:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}&body=${message}`);
        }
    };

    const steps = [
        <ConfirmDialog
            theme={theme}
            reason={reason}
            explanation={explanation}
            reasonsAvailable={reasonsAvailable}
            inProgress={inProgress}
            onClose={onClose}
            onReason={event => handleReasonChange(event.target.value)}
            onExplanation={event => setExplanation(event.target.value)}
            onNext={() => (followUpRequired ? setActiveStep(1) : setActiveStep(2))}
        />,
        <AbortReminderDialog
            theme={theme}
            customer={customer}
            phone={formattedPhone}
            inProgress={inProgress}
            abortInfoDialogOpen={abortInfoDialogOpen}
            setAbortInfoDialogOpen={setAbortInfoDialogOpen}
            onBack={() => setActiveStep(0)}
            onNext={() => setActiveStep(2)}
            callCustomer={callCustomer}
            sendSMSToCustomer={sendSMSToCustomer}
        />,
        <CameraDialog
            admin={admin}
            // imageURLs={imageURLs}
            imagePreviews={imagePreviews}
            // pickupImages={imagePreviews}
            inProgress={inProgress}
            onSnackbar={onSnackbar}
            onBack={() => (followUpRequired ? setActiveStep(1) : setActiveStep(0))}
            uploadingImage={uploadingImage}
            handleUploadImage={handleUploadImage}
            handleDeleteImage={handleDeleteImage}
            onAbort={() => handleSubmit()}
            abortPickupImageTitle={abortPickupImageTitle}
            abortPickupImageInstructions={abortPickupImageInstructions}
        />
    ];

    return (
        <Dialog
            data-cy="multi-step-dialog"
            fullScreen={fullScreen}
            fullWidth
            open={open}
            onClose={inProgress ? () => undefined : () => onClose()}
        >
            <DialogTitlePrimary closeButtonShown onClose={inProgress ? () => undefined : () => onClose()}>
                {inProgress ? 'In progress...' : STEPS_TITLES[activeStep]}
            </DialogTitlePrimary>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                backButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                style={{ backgroundColor: theme.palette.background.paper }}
            />
            {inProgress ? (
                <DialogContent>
                    <InProgress />
                </DialogContent>
            ) : (
                steps[activeStep]
            )}
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(MultiStepAbortDialog));

function ConfirmDialog(props) {
    const {
        theme,
        reason,
        explanation,
        reasonsAvailable,
        inProgress,
        onClose,
        onReason,
        onExplanation,
        onNext
    } = props;

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText="Why are you aborting?" iconName="message" />
                <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 2 }}>
                    <InputLabel variant="outlined" htmlFor="reason">
                        Reason
                    </InputLabel>

                    <Select
                        data-cy="abort-dialog-select"
                        value={reason}
                        onChange={onReason}
                        input={<OutlinedInput labelWidth={54} name="reason" id="reason" className="select-icon" />}
                    >
                        {reasonsAvailable.map((reason, index) => {
                            return (
                                <MenuItem data-cy={`abort-dialog-li-${index}`} key={reason.short} value={reason.short}>
                                    {reason.short}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="abort-dialog-reason"
                        variant="outlined"
                        multiline
                        label="Explanation"
                        value={explanation}
                        error={_.isEmpty(explanation)}
                        helperText={_.isEmpty(explanation) ? 'Please provide an explanation' : ''}
                        onChange={onExplanation}
                    />
                </FormControl>
                {/* <Typography variant="caption" style={{ color: colors.grey[500] }}>
                        Note: this message will be displayed to the customer.
                    </Typography> */}
            </DialogContent>
            <DialogActions>
                <Button disabled={inProgress} color="primary" onClick={inProgress ? () => undefined : () => onClose()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={_.isEmpty(reason) || _.isEmpty(explanation) || inProgress}
                    onClick={onNext}
                    data-cy="3-pickupdialog-next-button"
                >
                    Next
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}

function AbortReminderDialog(props) {
    const {
        theme,
        inProgress,
        onBack,
        onNext,
        customer,
        phone,
        abortInfoDialogOpen,
        setAbortInfoDialogOpen,
        callCustomer,
        sendSMSToCustomer
    } = props;

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText="Have you done the following?" iconName="message" />
                <Typography style={{ paddingLeft: theme.spacing.unit }}>
                    Before aborting, please follow these steps:
                </Typography>
                <List>
                    <ListItem button onClick={() => setAbortInfoDialogOpen(true)}>
                        <ListItemText primary={`1. Ring the doorbell`} />
                        <Icon style={{ color: colors.grey[700] }}>info</Icon>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem button onClick={callCustomer}>
                        <ListItemText
                            primary={`2. Call the customer - ${_user.getNameFirstNameAndLastInitial(
                                customer
                            )} - ${phone}`}
                        />
                        <Icon style={{ color: colors.grey[700] }}>phone</Icon>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem button onClick={sendSMSToCustomer}>
                        <ListItemText primary="3. If no answer - Text Customer" />
                        <Icon style={{ color: colors.grey[700] }}>message</Icon>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem button>
                        <ListItemText primary="4. If no answer - Wait 5 Minutes" />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem button onClick={callCustomer}>
                        <ListItemText primary="5. If no answer - Call Customer Again" />
                        <Icon style={{ color: colors.grey[700] }}>phone</Icon>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem>
                        <ListItemText secondary="Note: You can press the buttons above to perform the associated action" />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onBack}>
                    Back
                </Button>
                <Button
                    data-cy="abort-dialog-reminder-confirm-button"
                    disabled={inProgress}
                    color="primary"
                    onClick={onNext}
                >
                    Next
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
            <GenericDialog
                open={abortInfoDialogOpen}
                onClose={() => setAbortInfoDialogOpen(false)}
                title="WARNING"
                message="Accessing hallways, recycling rooms, lobbies, elevators, and garages is permitted. 
                    Entering the residence is NOT permitted."
            />
        </>
    );
}

function CameraDialog(props) {
    const {
        admin,
        // imageURLs,
        imagePreviews,
        // pickupImages,
        inProgress,
        onSnackbar,
        onBack,
        uploadingImage,
        handleUploadImage,
        handleDeleteImage,
        onAbort,
        abortPickupImageTitle,
        abortPickupImageInstructions
    } = props;

    return (
        <>
            <DialogContent>
                <ImageCapture
                    uploadingImage={uploadingImage}
                    imageURLs={imagePreviews}
                    handleAddImage={handleUploadImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={
                        <span>
                            {abortPickupImageTitle
                                ? abortPickupImageTitle
                                : 'Upload or take a photo to help provide information for aborting this pickup.'}
                            <br />
                            {abortPickupImageInstructions
                                ? abortPickupImageInstructions.map(item => <li>{item}</li>)
                                : 'This will be displayed to the customer.'}
                            <br />
                            {admin ? ' This step is optional for administrators.' : ''}
                        </span>
                    }
                    onSnackbar={onSnackbar}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onBack}>
                    Back
                </Button>
                <Button
                    data-cy="abort-dialog-abort-button"
                    disabled={
                        inProgress ||
                        uploadingImage ||
                        (!admin && _.isEmpty(imagePreviews) && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE))
                    }
                    color="secondary"
                    onClick={onAbort}
                >
                    Abort
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </>
    );
}
