import React, { Fragment, useState, useContext, useEffect, useMemo } from 'react';

import _ from 'lodash';

import * as terms from 'localizations/terms';
import { COMMERCIAL, BOTTLE_DRIVE, CONDO } from 'constants.js';

import GMapsAutocomplete from 'components/GMapsAutocomplete';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import { Switch } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import {
    Button,
    TextField,
    DialogContentText,
    FormControl,
    DialogContent,
    DialogActions,
    Icon,
    IconButton,
    InputAdornment,
    Fade,
    LinearProgress,
    Grid,
    Typography,
    MenuItem,
    ListItemIcon
} from '@material-ui/core';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import { withTheme } from '@material-ui/core/styles';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { validate } from 'utils/validate';
import { isAUSRegion } from 'utils/misc.js';

const Address = props => {
    const {
        http,
        google,
        admin,
        customer,
        formErrors,
        handleGPSReset,
        handleGPSOverride,
        handleChangeCoordinates,
        pickupIsUnchangeable,
        inProgress,
        formControlStyle,
        toggleHelpDialog,
        services,
        onChange,
        onPastLocationChange,
        onBack,
        onAddressNext,
        onSuggestionSelected,
        pickupForm,
        theme,
        width,
        setMapLocationSelection,
        pendingPickupsByLocation,
        onSelectPickupType,
        onOverrideAlwaysConfirmChange
    } = props;
    const { lang } = useContext(LocalizationContext);

    const [pastLocations, setPastLocations] = useState([]);

    const warnAction = useContext(ConfirmDialogContext);
    const isSmallScreen = isWidthDown('xs', width);

    useEffect(() => {
        (async function loadPastLocations() {
            const res = await http.getJSON(`/users/getPastLocations/${customer._id}`);
            if (res.ok) {
                setPastLocations(res.data.pastLocations || []);
            }
        })();
    }, []);

    const handleDeleteLocation = async place_id => {
        const res = await http.getJSON(`/users/removePastLocation?customer_id=${customer._id}&place_id=${place_id}`);
        if (res.ok) {
            setPastLocations(res.data.pastLocations || pastLocations);
        }
    };

    const handleUnitChange = e => {
        if (!/^[a-zA-Z0-9/-]{0,10}$/.test(e.target.value)) return;

        onChange(e);
    };

    const { location, pickupType, businessName, organizationName } = pickupForm;

    const formContainsErrors =
        _.get(formErrors, 'businessName.fail', false) ||
        _.get(formErrors, 'unitNumber.fail', false) ||
        _.get(formErrors, 'buzzerCode.fail', false) ||
        _.get(formErrors, 'location.fail', false);

    const formattedPastLocations = formatPastLocations(pastLocations, pendingPickupsByLocation, lang);

    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(services, service => service.isMobilePickupService && !service.disabled),
                'subServices',
                []
            ).filter(
                service =>
                    !service.disabled &&
                    !(service.charityLocked && (_.isNil(customer.charities) || _.isEmpty(customer.charities)))
            ),
        [services, customer]
    );
    const selectedSubService = useMemo(() => _.find(mobilePickupSubServices, { pickupType: pickupForm.pickupType }), [
        pickupForm.pickupType,
        mobilePickupSubServices
    ]);

    return (
        <Fragment>
            <DialogContent>
                <CustomFormTitle
                    titleText={loc('addressDialog1', lang)}
                    style={{ marginBottom: theme.spacing.unit }}
                    iconName="not_listed_location"
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        marginBottom: theme.spacing.unit
                    }}
                >
                    {!_.isEmpty(mobilePickupSubServices) && (
                        <TextField
                            onChange={e => {
                                onSelectPickupType(e.target.value, null, true)();
                                if (e.target.value === COMMERCIAL) {
                                    onOverrideAlwaysConfirmChange(false);
                                }
                            }}
                            value={pickupForm.pickupType}
                            fullWidth
                            select
                            data-cy="registration-location-type-select"
                            SelectProps={{
                                renderValue: val => {
                                    const service = _.find(mobilePickupSubServices, { pickupType: val });
                                    const title = _.get(service, `text.${lang}.title`, '');
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                verticalAlign: 'middle',
                                                marginLeft: 14 //render selected pickuptype with extra left margin to align text with other inputs
                                            }}
                                        >
                                            <tr
                                                style={{
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                                    <MDIcon
                                                        path={_.get(allIcons, _.get(service, 'mdiIcon'))}
                                                        size={1}
                                                    />
                                                </td>
                                                <td>{title}</td>
                                            </tr>
                                        </div>
                                    );
                                }
                            }}
                        >
                            {_(mobilePickupSubServices)
                                .map((service, i) => {
                                    const title = _.get(service, `text.${lang}.title`, '');
                                    return (
                                        <MenuItem
                                            value={_.get(service, 'pickupType')}
                                            key={_.get(service, 'pickupType')}
                                            data-cy={`registration-location-type-input-${_.get(service, 'pickupType')}`}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                <tr
                                                    style={{
                                                        verticalAlign: 'middle'
                                                    }}
                                                >
                                                    <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                                        <MDIcon
                                                            path={_.get(allIcons, _.get(service, 'mdiIcon'))}
                                                            size={1}
                                                        />
                                                    </td>
                                                    <td>{title}</td>
                                                </tr>
                                            </div>
                                        </MenuItem>
                                    );
                                })
                                .value()}
                        </TextField>
                    )}
                </div>
                {pickupType === CONDO && (
                    <DialogContentText style={{ color: colors.grey[500], marginTop: 8 }}>
                        {loc('pickupDialogSelect5', lang)}
                    </DialogContentText>
                )}
                <CustomFormTitle
                    titleText={loc('pickupDialog6', lang)}
                    iconName="not_listed_location"
                    noMarginBottom
                    style={{ marginTop: 15 }}
                />
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <GMapsAutocomplete
                        data-cy="pickupdialog-address-input"
                        google={google}
                        disabled={pickupIsUnchangeable}
                        label={loc('address', lang)}
                        placeholder={loc('placeholder1', lang)}
                        selectedValue={location.description}
                        types={['address']}
                        customer={customer}
                        endAdornment={
                            <InputAdornment position="end">
                                {pickupIsUnchangeable ? (
                                    <IconButton
                                        style={{ color: colors.red[500] }}
                                        onClick={toggleHelpDialog('locationUnchangeable')}
                                    >
                                        <Icon>warning</Icon>
                                    </IconButton>
                                ) : (
                                    <>
                                        <IconButton
                                            onClick={toggleHelpDialog('location')}
                                            data-cy="pickupdialog-address-help"
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </>
                                )}
                            </InputAdornment>
                        }
                        onSuggestionSelected={onSuggestionSelected}
                        defaultSuggestions={formattedPastLocations}
                        onPastLocationChange={onPastLocationChange}
                        handleDeleteLocation={handleDeleteLocation}
                    />
                </div>
                {(pickupType === COMMERCIAL || pickupType === CONDO) && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy="pickupdialog-unit-input"
                            name="location.unitNumber"
                            label={loc('labels9', lang)}
                            placeholder={`(${loc('placeholder2', lang)})`}
                            variant="outlined"
                            helperText={formErrors.unitNumber.reason}
                            error={formErrors.unitNumber.fail}
                            value={location.unitNumber || ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleHelpDialog('unitNumber')}
                                            data-cy="pickupdialog-unit-help"
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={e => handleUnitChange(e)}
                        />
                    </FormControl>
                )}

                {pickupType === COMMERCIAL && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy="pickupdialog-business-name-input"
                            name="businessName"
                            label={isAUSRegion() ? loc('pickupDialogSelect8a', lang) : loc('pickupDialogSelect8', lang)}
                            value={businessName || ''}
                            helperText={formErrors.businessName.reason}
                            error={formErrors.businessName.fail}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={toggleHelpDialog('businessName')}
                                            data-cy="pickupdialog-business-name-help"
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={onChange}
                        />
                    </FormControl>
                )}
                {pickupType === BOTTLE_DRIVE && (
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy="pickupdialog-organization-name-input"
                            name="organizationName"
                            label={loc('labels10', lang, { organization: terms.ORGANIZATION_NAME })}
                            value={organizationName || ''}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleHelpDialog('organizationName')}>
                                            <Icon>help</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={onChange}
                        />
                    </FormControl>
                )}
                {pickupType === CONDO && (
                    <>
                        <FormControl fullWidth style={formControlStyle}>
                            <TextField
                                data-cy="pickupdialog-buzzer-input"
                                name="location.buzzerCode"
                                label={loc('pickupDialogSelect3', lang)}
                                placeholder={`(${loc('placeholder2', lang)})`}
                                variant="outlined"
                                helperText={formErrors.buzzerCode.reason}
                                error={formErrors.buzzerCode.fail}
                                value={location.buzzerCode || ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleHelpDialog('buzzerCode')}
                                                data-cy="pickupdialog-buzzer-help"
                                            >
                                                <Icon>help</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={onChange}
                            />
                        </FormControl>
                    </>
                )}
                {admin && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant="caption" style={{ padding: theme.spacing.unit }}>
                                Override GPS coordinates <b>(Admin)</b>
                            </Typography>
                            <Switch
                                checked={_.get(location, 'isCustomGPS', false)}
                                onChange={
                                    _.get(location, 'isCustomGPS', false)
                                        ? () =>
                                              warnAction(async () => {
                                                  await handleGPSReset();
                                              }, "Are you sure you want to revert the coordinates to the address' default coordinates?")
                                        : () => handleGPSOverride()
                                }
                            />
                        </div>
                        {_.get(location, 'isCustomGPS', false) && (
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 5 }}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        name="lat"
                                        label={'Latitude'}
                                        value={location.lat || ''}
                                        onChange={handleChangeCoordinates}
                                        style={{ paddingRight: theme.spacing.unit * 0.75 }}
                                        error={validate(['latCoordinate'], location.lat, lang).fail}
                                        helperText={validate(['latCoordinate'], location.lat, lang).reason}
                                    />
                                </div>
                                <div style={{ flex: 5 }}>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        label={'Longitude'}
                                        variant="outlined"
                                        value={location.lng || ''}
                                        name="lng"
                                        onChange={handleChangeCoordinates}
                                        style={{ paddingleft: theme.spacing.unit * 0.75 }}
                                        error={validate(['lngCoordinate'], location.lng, lang).fail}
                                        helperText={validate(['lngCoordinate'], location.lng, lang).reason}
                                    />
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        marginLeft: theme.spacing.unit / 2,
                                        alignSelf: _.get(formErrors, 'location.fail', false) ? 'auto' : 'center'
                                    }}
                                >
                                    <IconButton
                                        disabled={_.get(formErrors, 'location.fail', false)}
                                        onClick={() => setMapLocationSelection(true)}
                                    >
                                        <Icon>map</Icon>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        {_.get(location, 'isStreet') && (
                            <Typography
                                variant="caption"
                                style={{ padding: theme.spacing.unit, color: colors.red[500] }}
                            >
                                WARNING: The Address selected above is a street address. Make sure that coordinates are
                                correctly overridden if required.
                            </Typography>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" disabled={inProgress} onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    color="primary"
                    disabled={_.isEmpty(location.description) || inProgress || formContainsErrors}
                    onClick={
                        admin && _.get(location, 'isStreet', false) && !_.get(location, 'isCustomGPS', false)
                            ? () =>
                                  warnAction(
                                      onAddressNext,
                                      'This is a street address and the coordinates have not been overridden. Are you sure you would like to continue?'
                                  )
                            : onAddressNext
                    }
                    data-cy="1-pickupdialog-next-button"
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Fragment>
    );
};

export default withWidth()(withTheme()(Address));

function formatPastLocations(pastLocations, pendingPickupsByLocation, lang) {
    let formattedPastLocations = pastLocations.filter(location => _.isNil(location.collectorName));

    formattedPastLocations.forEach(location => {
        // add hasActiveBooking and nextBookingDate
        let pendingPickup = pendingPickupsByLocation[location.description];
        if (!_.isNil(pendingPickup)) {
            location.hasActiveBooking = true;
            if (!_.isNil(pendingPickup.date)) {
                location.nextBookingDate = locDate(pendingPickup.date, 'MMM DD, YYYY', lang);
            }
        }
    });

    formattedPastLocations.sort((a, b) => {
        const aSplit = a.description.toUpperCase().split(' ');
        const bSplit = b.description.toUpperCase().split(' ');

        // Filter numbers incl. 1st 2nd 3rd etc...
        const aParts = aSplit.filter(part => !/[0-9]/.test(part));
        const bParts = bSplit.filter(part => !/[0-9]/.test(part));

        if (_.isEmpty(aParts) || _.isEmpty(bParts)) return 0;

        const aCompare = aParts.join('').replace(/[^A-Z]/gi, '');
        const bCompare = bParts.join('').replace(/[^A-Z]/gi, '');

        return aCompare > bCompare ? 1 : -1;
    });

    return formattedPastLocations;
}
