import React, { useContext } from 'react';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    Icon
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function UserNoteDialog(props) {
    const { open, onSave, onCancel, title, note, onChangeNote, viewOnly, notes } = props;
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} fullWidth onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {!viewOnly && (
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        autoFocus
                        value={note}
                        onChange={onChangeNote}
                        placeholder="Add Note"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton data-cy="user-comments-dialog-save" color="primary" onClick={onSave}>
                                        <Icon>add_circle</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                <table
                    style={{
                        width: '100%'
                    }}
                >
                    <tbody>
                        {(notes || []).map((note, i) => (
                            <tr>
                                <td data-cy={`user-note-${i}-note`}>
                                    <Typography
                                        style={{
                                            display: 'inline-block',
                                            'word-break': 'break-word'
                                        }}
                                        variant="caption"
                                    >
                                        {note.note}
                                    </Typography>
                                </td>

                                <td style={{ width: 'auto' }} data-cy={`user-note-${i}-info`}>
                                    <Typography noWrap style={{ fontSize: '70%' }}>
                                        {note.addedBy}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        style={{
                                            overflow: 'hidden',
                                            fontSize: '70%'
                                        }}
                                    >
                                        {moment(note.dateAdded)
                                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                            .locale('en')
                                            .format('MMM. DD, YYYY h:mm:ss A')}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button data-cy="user-comments-dialog-close" onClick={onCancel}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(UserNoteDialog);
