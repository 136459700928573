import { useState, useEffect } from 'react';

import _ from 'lodash';
import io from 'socket.io-client';
import queryString from 'query-string';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function useDriverSocketIO(location, onSnackbar, http) {
    const [loading, setLoading] = useState(true);
    const [socket, setSocket] = useState(null);
    const [disconnected, setDisconnected] = useState(false); // disconnect forced by the server
    const [socketTrip, setSocketTrip] = useState({});
    const [trip, setTrip] = useState();
    const [alertDriver, setAlertDriver] = useState(false);

    useEffect(() => {
        console.log('%cuseDriverSocketIO: running useEffect', 'color: blue');

        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');

        const socket = io(`${originURL}/pickups?trip_id=${queryString.parse(location.search).trip_id}`, {
            path: `${pathExtension}/socket.io`
        });
        setSocket(socket);

        socket.on('forced-disconnect', () => {
            setDisconnected(true);
        });

        socket.on('trip-update', newTrip => {
            console.log('🌎 trip-update', _.cloneDeep(newTrip));
            setSocketTrip(prev => ({ ...prev, ...newTrip }));
        });

        return () => socket.close();
    }, [location.search]);

    useEffect(() => {
        if (socketTrip && _.has(trip, 'hash') && trip.hash !== socketTrip.hash) {
            setAlertDriver(true);
        }

        setTrip(socketTrip);
        setLoading(false); // will be set to false on first trip-update and unlock the Pickups.js render
    }, [socketTrip]);

    return { loading, socket, disconnected, trip, alertDriver, setAlertDriver };
}

export default useDriverSocketIO;
