import React from 'react';

import { Button, Dialog, Typography, Card, CardActions, CardContent, Icon } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { withTheme } from '@material-ui/core/styles';

function AfterCompleteDialog(props) {
    const { open, afterCompleteDialogTitle, afterCompleteDialogContent, afterCompleteDialogExamples } = props;
    return (
        <Dialog open={open} onClose={props.onClose} maxWidth="sm" fullWidth>
            <Card style={{ width: '100%', backgroundColor: colors.blue[400] }}>
                <CardContent>
                    <div style={{ textAlign: 'center' }}>
                        <Icon style={{ color: '#fff', fontSize: 75, marginBottom: 8 }} fontSize="large">
                            help
                        </Icon>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: '#fff' }}>
                        {afterCompleteDialogTitle.toUpperCase()}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#fff' }}>
                        {afterCompleteDialogContent}
                        {afterCompleteDialogExamples &&
                            afterCompleteDialogExamples.map(example => (
                                <li style={{ margin: 8, marginLeft: 16 }}>{example}</li>
                            ))}
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        style={{ color: '#fff' }}
                        id="aftercompletedialog-close"
                        data-cy="aftercompletedialog-close"
                        onClick={props.onClose}
                    >
                        Dismiss
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
}

export default withTheme()(AfterCompleteDialog);
