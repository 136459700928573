import React, { useContext, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { _user } from 'std';

import * as Yup from 'yup';
import HttpContext from 'utils/contexts/HttpContext';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem
} from '@material-ui/core';
import TimePicker from 'material-ui-pickers/TimePicker';
import { getTextInput } from 'components/CRUDTables/helperFunctions';
import GMapsAutocomplete from 'components/GMapsAutocomplete';

const DEFAULT_START_TIME = moment().toISOString();

const EditDriverDialog = ({ open, handleClose, driver, collector, getDrivers, theme, processors, google }) => {
    const http = useContext(HttpContext);

    const timezone = _.get(collector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);

    const [savingDriver, setSavingDriver] = useState(false);

    const formik = useFormik({
        initialValues: {
            startTime: _.get(driver, 'startTime', DEFAULT_START_TIME),
            driverBagCapacity: _.get(driver, 'driverBagCapacity', 0),
            driverLoadsPerDay: _.get(driver, 'driverLoadsPerDay', 1),
            driverLoadTimeOffset: _.get(driver, 'driverLoadTimeOffset', 8),
            home: _.get(driver, 'home', {}),
            driverDestination: _.get(driver, 'driverDestination._id', collector)
        },
        validationSchema: Yup.object({
            driverBagCapacity: Yup.number()
                .integer('This must be a whole number')
                .min(0, 'This must be positive')
                .required('You must enter a value.'),
            driverLoadsPerDay: Yup.number()
                .integer('This must be a whole number')
                .min(0, 'This must be positive')
                .required('You must enter a value.'),
            driverLoadTimeOffset: Yup.number()
                .min(0, 'This must be positive')
                .required('You must enter a value.'),
            home: Yup.object()
                .required('You must specify a starting location')
                .nullable(),
            driverDestination: Yup.string().required('You must select a destination')
        }),
        onSubmit: async values => {
            setSavingDriver(true);

            const res = await http.post(`/users/${driver._id}/adminUpdate`, values);

            if (res.ok) {
                getDrivers();
                handleClose();
            }

            setSavingDriver(false);
        },
        enableReinitialize: true
    });

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Edit {_user.getNameFull(driver)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={6}>
                        {getTextInput(theme, 'driverBagCapacity', 'Bag Capacity', formik, 'number')}
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Start Time"
                            name="startTime"
                            variant="outlined"
                            value={moment(formik.values.startTime).tz(timezone)}
                            emptyLabel="Please select a time"
                            format="h:mm A"
                            onChange={time => {
                                const hour = time.get('hour');
                                const minute = time.get('minute');
                                formik.setFieldValue(
                                    'startTime',
                                    moment()
                                        .tz(timezone)
                                        .set({ hour, minute })
                                        .toDate()
                                );
                            }}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {getTextInput(theme, 'driverLoadsPerDay', 'Loads Per Day', formik, 'number')}
                    </Grid>
                    <Grid item xs={6}>
                        {getTextInput(theme, 'driverLoadTimeOffset', 'Load Time Offset (hrs)', formik, 'number')}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel variant="outlined">Destination</InputLabel>

                            <Select
                                {...formik.getFieldProps('driverDestination')}
                                fullWidth
                                input={
                                    <OutlinedInput
                                        labelWidth={'destination'.length * theme.spacing.unit}
                                        data-cy={`${_.kebabCase('destination')}-input`}
                                    />
                                }
                            >
                                {processors.map((processor, idx) => (
                                    <MenuItem value={processor._id} key={processor._id}>
                                        {_.get(processor, 'name')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <GMapsAutocomplete
                            onBlur={formik.handleBlur}
                            http={http}
                            google={google}
                            location={{
                                lat: _.get(formik, 'values.home.lat'),
                                lng: _.get(formik, 'values.home.lng')
                            }}
                            label={
                                _.get(formik, 'values.accountType', '') === 'Collector Employee'
                                    ? 'Start Location'
                                    : 'Location'
                            }
                            placeholder="Enter an address"
                            selectedValue={_.get(formik, 'values.home.description', '')}
                            types={['address']}
                            error={{
                                fail: _.get(formik.errors, 'home', false) && _.get(formik.touched, 'home', false),
                                reason: 'You must enter a location'
                            }}
                            style={{ marginTop: theme.spacing.unit }}
                            onSuggestionSelected={({ suggestion, place }) => {
                                formik.setFieldValue('home', {
                                    description: suggestion.description,
                                    place_id: suggestion.place_id,
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                    city: place.address_components.find(ac => ac.types.includes('locality')).long_name,
                                    province: place.address_components.find(ac =>
                                        ac.types.includes('administrative_area_level_1')
                                    ).long_name,
                                    postalCode: _.get(
                                        _.find(place.address_components, component =>
                                            component.types.includes('postal_code')
                                        ),
                                        'short_name',
                                        undefined
                                    )
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            component="span"
                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                            onClick={() => {
                                let destinationInfo = _.find(
                                    processors,
                                    c => c._id.toString() === _.get(formik, 'values.driverDestination', '').toString()
                                );
                                if (destinationInfo) {
                                    formik.setFieldValue('home', {
                                        description: destinationInfo.location.description,
                                        place_id: destinationInfo.location.place_id,
                                        lat: destinationInfo.location.lat,
                                        lng: destinationInfo.location.lng
                                    });
                                }
                            }}
                        >
                            Use Destination Address
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={formik.handleSubmit}
                    disabled={savingDriver}
                    data-cy="driver-save-button"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDriverDialog;
