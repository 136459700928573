import React, { useContext } from 'react';
import _ from 'lodash';
import { _time, _commodity } from 'std';
import moment from 'moment-timezone';

import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import {
    withMobileDialog,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    Grid
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { downloadObjectAsCSV } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

const VolumeReportDialog = ({
    http,
    customer,
    theme,
    fullScreen,
    commodities,
    open,
    onClose,
    reloadCustomer,
    onSnackbar
}) => {
    const { lang } = useContext(LocalizationContext);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: false,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfMonth(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const skuTypesForStats = _.filter(commodities, { includeContainersInStats: true }).map(commodity =>
        _commodity.getSkuType(commodity)
    );

    const handleDownload = async () => {
        const res = await http.postJSON('/bulks/getCompletedBulks', {
            startDate,
            endDate,
            skuTypes: skuTypesForStats,
            customerID: _.get(customer, 'uniqueID', '')
        });

        if (res.ok) {
            const { completedBulks } = res.data;

            let completedBulksGroupedByLocation = _.groupBy(completedBulks, bulk => {
                if (!_.isNil(bulk.pickup)) {
                    return _.get(bulk, 'pickup.location.description', '')
                        .trim()
                        .toLowerCase();
                } else {
                    // for drop and go
                    return _.get(bulk, 'collector.location.description', '')
                        .trim()
                        .toLowerCase();
                }
            });

            const rowSpan = completedBulks.length + 1;

            const customerName = `${_.get(customer, 'name.first')} ${_.get(customer, 'name.last')}`;
            const dateRange = `${moment(startDate).format('MM-DD-YYYY')} to ${moment(endDate).format('MM-DD-YYYY')}`;
            let arrayToConvert = [
                padArray(['Commercial customer report'], rowSpan, ''),
                padArray(['Customer name', customerName], rowSpan, ''),
                padArray(['Date range', dateRange], rowSpan, '')
            ];

            // if more than 1 location, show breakdown;
            const locations = Object.keys(completedBulksGroupedByLocation);
            const hasMultipleLocations = locations.length > 1;

            const depositTaxGroup = _.get(customer, 'depositTaxGroup', null);
            const displayDepositTax = _.get(customer, 'displayDepositTax', false);

            let overallNumOfBins = 0,
                overallPickups = 0,
                overallNumBags = 0,
                overallVolume = 0,
                overallWeight = 0,
                overallValue = 0,
                overallDeposits = 0,
                overallTaxOnDeposits = 0,
                overallFees = 0,
                overallTaxOnFees = 0;

            for (let location in completedBulksGroupedByLocation) {
                let {
                    pickupDatesArr,
                    numBagsArr,
                    numOfBinsArr,
                    totalContainersArr,
                    totalWeightArr,
                    totalValuesArr,
                    depositsArr,
                    depositTaxArr,
                    feesArr,
                    feeTaxArr,
                    materialQuantities,
                    totalNumOfBins,
                    totalPickups,
                    totalNumBags,
                    totalVolume,
                    totalWeight,
                    totalValue,
                    totalDeposits,
                    totalTaxOnDeposits,
                    totalFees,
                    totalTaxOnFees
                } = getReportData(
                    completedBulksGroupedByLocation[location],
                    timezone,
                    rowSpan,
                    location,
                    depositTaxGroup,
                    displayDepositTax
                );

                pickupDatesArr.unshift('Date of pick up');
                numBagsArr.unshift('No. bags');
                numOfBinsArr.unshift('No. bins');
                totalContainersArr.unshift('Total containers');
                totalWeightArr.unshift('Total weight (kg)');
                totalValuesArr.unshift('Value (excl. service fees)');
                depositsArr.unshift('Deposits');
                depositTaxArr.unshift('Deposit Tax');
                feesArr.unshift('Fees');
                feeTaxArr.unshift('Fee Tax');

                const dataForLocation = [
                    padArray([], rowSpan, ''),
                    padArray(['Address', location], rowSpan, ''),
                    pickupDatesArr,
                    numBagsArr,
                    numOfBinsArr,
                    ...materialQuantities,
                    totalContainersArr,
                    totalWeightArr,
                    totalValuesArr,
                    depositsArr,
                    depositTaxArr,
                    feesArr,
                    feeTaxArr,
                    padArray([], rowSpan, ''),
                    padArray(['Total no. bins', totalNumOfBins], rowSpan, ''),
                    padArray(['Total no. pick ups', totalPickups], rowSpan, ''),
                    padArray(['Total no. bags', totalNumBags], rowSpan, ''),
                    padArray(['Total volume', totalVolume], rowSpan, ''),
                    padArray(['Total weight (kg)', totalWeight], rowSpan, ''),
                    padArray(['Total value', formatCents(totalValue)], rowSpan, ''),
                    padArray(['Total deposits', formatCents(totalDeposits)], rowSpan, ''),
                    padArray(['Total deposit tax', formatCents(totalTaxOnDeposits)], rowSpan, ''),
                    padArray(['Total fees', formatCents(totalFees)], rowSpan, ''),
                    padArray(['Total fee tax', formatCents(totalTaxOnFees)], rowSpan, '')
                ];

                if (hasMultipleLocations) {
                    overallNumOfBins += totalNumOfBins;
                    overallPickups += totalPickups;
                    overallNumBags += totalNumBags;
                    overallVolume += totalVolume;
                    overallWeight += totalWeight;
                    overallValue += totalValue;
                    overallDeposits += totalDeposits;
                    overallTaxOnDeposits += totalTaxOnDeposits;
                    overallFees += totalFees;
                    overallTaxOnFees += totalTaxOnFees;
                }

                arrayToConvert = arrayToConvert.concat(dataForLocation);
            }
            if (hasMultipleLocations) {
                const overallData = [
                    padArray([], rowSpan, ''),
                    padArray([], rowSpan, ''),
                    padArray(['Overall Totals'], rowSpan, ''),
                    padArray(['Total no. bins', overallNumOfBins], rowSpan, ''),
                    padArray(['Total no. pick ups', overallPickups], rowSpan, ''),
                    padArray(['Total no. bags', overallNumBags], rowSpan, ''),
                    padArray(['Total volume', overallVolume], rowSpan, ''),
                    padArray(['Total weight (kg)', overallWeight], rowSpan, ''),
                    padArray(['Total value', formatCents(overallValue)], rowSpan, ''),
                    padArray(['Total deposits', formatCents(overallDeposits)], rowSpan, ''),
                    padArray(['Total deposit tax', formatCents(overallTaxOnDeposits)], rowSpan, ''),
                    padArray(['Total fees', formatCents(overallFees)], rowSpan, ''),
                    padArray(['Total fee tax', formatCents(overallTaxOnFees)], rowSpan, '')
                ];

                arrayToConvert = arrayToConvert.concat(overallData);
            }

            downloadCSV(arrayToConvert);
        }
    };

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                // fullWidth
                open={open}
                onClose={onClose}
            >
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    {loc('volumeReport', lang)}
                </DialogTitlePrimary>
                <DialogContent>
                    <DialogContentText
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                    >
                        {loc('volumeReport1', lang)}
                    </DialogContentText>
                    <Grid container spacing={theme.spacing.unit}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        {loc('cancel', lang)}
                    </Button>
                    <Button color="primary" onClick={handleDownload} data-cy="volume-report-dialog-download">
                        {loc('download', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(VolumeReportDialog));

const padArray = (arr, len, fill) => {
    return arr.concat(Array(len).fill(fill)).slice(0, len);
};

function formatCents(cents) {
    return ((cents || 0) / 100).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

const downloadCSV = async arrayToConvert => {
    const date = moment().format('YYYY-MM-DD');
    const fileName = `volume_report_${date}`;
    try {
        await downloadObjectAsCSV(arrayToConvert, fileName, { header: false });
    } catch (err) {
        console.log(err);
    }
};

const getReportData = (bulks, timezone, rowSpan, location, depositTaxGroup, displayDepositTax) => {
    let quantitiesDict = {},
        pickupDatesArr = [],
        numBagsArr = [],
        totalContainersArr = [],
        totalWeightArr = [],
        totalValuesArr = [],
        numOfBinsArr = [],
        depositsArr = [],
        depositTaxArr = [],
        feesArr = [],
        feeTaxArr = [];

    let deposits = 0,
        depositTax = 0,
        fees = 0,
        feeTax = 0;

    // use counts.items for quantities, ledger.lines for dollar values and commoditiesProcessed for bag counts
    bulks.forEach(
        (
            {
                ledger,
                counts,
                commoditiesProcessed,
                commodityAmount,
                datePickedUp,
                customFees,
                commodityUOM = '',
                rates,
                commoditiesProcessedBreakdown
            },
            bulkIdx
        ) => {
            pickupDatesArr.push(
                moment(datePickedUp)
                    .tz(timezone)
                    .format('MM-DD-YYYY')
            );

            // if commodityUOM is bins don't count it again as bags??
            if (!(commodityUOM.toLowerCase() === 'bin' || commodityUOM.toLowerCase().includes('bin'))) {
                numBagsArr.push(commoditiesProcessed || commodityAmount);
            }

            let ratesBySku = _.get(rates, 'rates', []).reduce((ratesObj, rate) => {
                let sku = _.get(rate, 'sku');
                if (sku) {
                    return {
                        ...ratesObj,
                        [sku]: rate
                    };
                }
                return ratesObj;
            }, {});

            const countTotalsForBulk = _(counts)
                .flatMapDeep(count => _.get(count, 'items', []))
                .groupBy(
                    ({ materialType, size, sku }) =>
                        `${
                            _.get(ratesBySku, `${sku}.description`)
                                ? _.get(ratesBySku, `${sku}.description`)
                                : materialType
                        } ${_.isNil(size) || !_.get(ratesBySku, `${sku}.label`) ? '' : size}`
                )
                .map((groupArr, id) => {
                    const countSum = _.sumBy(groupArr, 'quantity');
                    const sku = _.get(groupArr, '0.sku', '');
                    const weight = _.get(ratesBySku, `${sku}.weight`, 0);
                    const weightSum = weight * countSum;

                    return {
                        material: id.trim(),
                        countSum,
                        weightSum
                    };
                })
                .value();

            let depositsBulk = 0,
                depositsTaxBulk = 0,
                feesBulk = 0,
                feesTaxBulk = 0;

            const lines = _.get(ledger, 'lines', []);

            lines.forEach(line => {
                if (line.amount < 0) {
                    depositsBulk += Math.abs(line.amount);
                    deposits += Math.abs(line.amount);
                } else {
                    feesBulk += Math.abs(line.amount);
                    fees += Math.abs(line.amount);
                }
            });
            let depositTaxAmounts = [],
                feeTaxAmounts = [];
            if (!_.isNil(depositTaxGroup) && displayDepositTax) {
                _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
                    let currentAmountForTax = _.find(depositTaxAmounts, { _id: tax._id });
                    if (_.isNil(currentAmountForTax)) {
                        currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                        depositTaxAmounts.push(currentAmountForTax);
                    }
                    const taxType = _.get(tax, 'configuration.type', 'included');
                    if (taxType === 'included') {
                        const beforeTaxAmount = _.round(depositsBulk / (1 + _.get(tax, 'configuration.value', 0)));

                        const taxAmount = depositsBulk - beforeTaxAmount;
                        currentAmountForTax.amount += taxAmount;
                    } else {
                        //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
                    }
                });

                _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
                    let currentAmountForTax = _.find(feeTaxAmounts, { _id: tax._id });
                    if (_.isNil(currentAmountForTax)) {
                        currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                        feeTaxAmounts.push(currentAmountForTax);
                    }
                    const taxType = _.get(tax, 'configuration.type', 'included');
                    if (taxType === 'included') {
                        const beforeTaxAmount = _.round(feesBulk / (1 + _.get(tax, 'configuration.value', 0)));

                        const taxAmount = feesBulk - beforeTaxAmount;
                        currentAmountForTax.amount += taxAmount;
                    } else {
                        //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
                    }
                });
            }
            depositsTaxBulk = depositTaxAmounts.reduce((sum, depositTax) => {
                return sum + _.get(depositTax, 'amount', 0);
            }, 0);
            depositTax += depositsTaxBulk;
            feesTaxBulk = feeTaxAmounts.reduce((sum, tax) => {
                return sum + _.get(tax, 'amount', 0);
            }, 0);
            feeTax += feesTaxBulk;

            let containerTotal = 0,
                valueTotal = 0,
                weightTotal = 0;

            countTotalsForBulk.forEach(({ material, countSum, weightSum }) => {
                const value = Math.abs(
                    _.get(
                        _.find(lines, function(ln) {
                            return ln.description
                                .toLowerCase()
                                .trim()
                                .includes(material.toLowerCase());
                        }),
                        'amount',
                        0
                    )
                );

                containerTotal += countSum;
                valueTotal += value;
                weightTotal += weightSum;

                if (material in quantitiesDict) {
                    quantitiesDict[material].push(countSum);
                } else {
                    let newMaterialQuantitiesArr = new Array(bulkIdx).fill(0);
                    newMaterialQuantitiesArr.push(countSum);
                    quantitiesDict[material] = newMaterialQuantitiesArr;
                }
            });

            const numOfBinsForBulk = getNumOfBinsFromSubCommodities(
                _.filter(commoditiesProcessedBreakdown, c => c.isSubCommodity)
            );
            numOfBinsArr.push(numOfBinsForBulk);
            totalContainersArr.push(containerTotal);
            totalWeightArr.push(weightTotal);
            totalValuesArr.push(valueTotal);
            depositsArr.push(depositsBulk);
            depositTaxArr.push(depositsTaxBulk);
            feesArr.push(feesBulk);
            feeTaxArr.push(feesTaxBulk);

            // fill in the commodities not picked up in this bulk with zero for quantity
            for (var material in quantitiesDict) {
                if (quantitiesDict[material].length <= bulkIdx) {
                    quantitiesDict[material].push(0);
                }
            }
            // const numOfBinsForBulk = getNumOfBinsFromCustomFees(customFees);
            // numOfBinsArr.push(numOfBinsForBulk);
        }
    );

    let materialQuantities = [];
    for (var material in quantitiesDict) {
        materialQuantities.push([material, ...quantitiesDict[material]]);
    }

    const totalPickups = bulks.length;
    const totalNumBags = _.sum(numBagsArr);
    const totalVolume = _.sum(totalContainersArr);
    const totalWeight = _.sum(totalWeightArr);
    const totalValue = _.sum(totalValuesArr);
    const totalNumOfBins = _.sum(numOfBinsArr);

    totalValuesArr = totalValuesArr.map(val => formatCents(val));

    return {
        pickupDatesArr,
        numBagsArr,
        numOfBinsArr,
        totalContainersArr,
        totalWeightArr,
        totalValuesArr,
        depositsArr,
        depositTaxArr,
        feesArr,
        feeTaxArr,
        materialQuantities,
        totalNumOfBins,
        totalPickups,
        totalNumBags,
        totalVolume,
        totalWeight,
        totalValue,
        totalDeposits: deposits,
        totalTaxOnDeposits: depositTax,
        totalFees: fees,
        totalTaxOnFees: feeTax
    };
};

function getNumOfBinsFromCustomFees(customFees = []) {
    let numOfBins = customFees.reduce((sum, fee) => {
        if (fee.binSwapRequired === true) {
            return sum + 1;
        } else {
            return sum;
        }
    }, 0);

    return numOfBins;
}

function getNumOfBinsFromSubCommodities(commoditiesProcessedBreakdown) {
    let numOfBins = 0;
    commoditiesProcessedBreakdown.forEach(c => {
        if (
            c.isSubCommodity &&
            (_.get(c, 'subCommodity.units.en', '').toLowerCase() === 'bin' ||
                _.get(c, 'subCommodity.units.en', '')
                    .toLowerCase()
                    .includes('bin'))
        ) {
            numOfBins += c.amount;
        }
    });
    return numOfBins;
}
