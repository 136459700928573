import { useState, useEffect } from 'react';

import _ from 'lodash';
import io from 'socket.io-client';
import moment from 'moment-timezone';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function useAdminSocketIO() {
    const [loading, setLoading] = useState(true);
    const [socket, setSocket] = useState(null);
    const [disconnected, setDisconnected] = useState(false); // disconnect forced by the server
    const [drivers, setDrivers] = useState([]);
    const [trips, setTrips] = useState([]);
    const [pickups, setPickups] = useState([]);
    const [triplessPickups, setTriplessPickups] = useState([]);
    const [socketDate, setSocketDate] = useState(new Date());
    const [response, setResponse] = useState({});

    useEffect(() => {
        console.log('%cuseAdminSocketIO: running useEffect', 'color: blue');

        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');

        const socket = io(`${originURL}/pickups`, {
            path: `${pathExtension}/socket.io`
        });
        setSocket(socket);

        socket.on('forced-disconnect', () => {
            setDisconnected(true);
        });

        socket.on('location-update', cachedLocations => {
            const cachedDrivers = [];
            for (let _id in cachedLocations) {
                cachedDrivers.push({
                    _id,
                    ...cachedLocations[_id]
                });
            }

            console.log('location-update', cachedDrivers);

            setDrivers(cachedDrivers);
        });

        socket.on('trips-update', response => {
            console.log(`trips-update for ${response.date}`);
            setResponse(response);
        });

        return () => socket.close();
    }, []);

    useEffect(() => {
        if (response) {
            const responseDate = _.get(response, 'date');
            if (responseDate && moment(socketDate).isSame(responseDate, 'day')) {
                setTrips(response.trips);
                setPickups(response.pickups);
                setTriplessPickups(response.pickups.filter(p => _.isNil(p.trip)));
            }
        }
        setLoading(false); // will be set to false on first trips-update and unlock the PickupsAdmin.js render
    }, [response]);

    const clearAll = () => {
        setDrivers([]);
        setTrips([]);
        setPickups([]);
        setTriplessPickups([]);
    };

    return {
        loading,
        socket,
        disconnected,
        trips,
        drivers,
        pickups,
        triplessPickups,
        clearAll,
        setSocketDate
    };
}

export default useAdminSocketIO;
