import React, { useState } from 'react';

import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    Button,
    FormControl,
    Icon,
    IconButton,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    withTheme
} from '@material-ui/core';

import queryString from 'query-string';
import Table from 'components/Table/Table';
import { downloadObjectAsCSV, formatAsCurrency, getCustomerName, getStateFromAddress } from 'utils/misc';
import moment from 'moment-timezone';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import { _time } from 'std';
import { loc } from 'localizations/localizationHandler';
import useTable from 'components/Table/useTable';
import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';
import { useEffect } from 'react';

function HistoryTab({
    theme,
    inProgress,
    lang,
    http,
    onSnackbar,
    setBatchToView,
    setOpenRedemptionDialog,
    setSelectedHistoryRedemptionId
}) {
    const [methodFilter, setMethodFilter] = useState('all');
    const [typeFilter, setTypeFilter] = useState('all');
    const [exportLoading, setExportLoading] = useState(false);

    const [headers, setHeaders] = useState([
        { name: 'Actions', locKey: null },
        { name: 'Account Number', locKey: null },
        { name: 'Name', locKey: null },
        { name: 'Amount', locKey: null },
        { name: 'Type', locKey: null },
        { name: 'Method', locKey: null },
        { name: 'State', locKey: null },
        { name: 'Batch Date', locKey: null },
        { name: 'Batch Number', locKey: null }
    ]);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleWindowSelect,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        loading: tableLoading,
        getData: refreshTable,
        response,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/redemptions/AUS/history?types=${getTypesForTab(typeFilter)}&paymentModels=${getPaymentsModelsForTab(
            methodFilter
        )}`,
        key: 'redemptions',
        headers: headers.map(h => h.name)
    });

    useEffect(() => {
        refreshTable();
    }, [startDate, endDate, timezone, dateWindow]);

    const renderRow = (redemption, filteredHeaders) => {
        let action = (
            <React.Fragment>
                <IconButton
                    color="primary"
                    onClick={async () => {
                        let res = await http.getJSON(`/redemptions/batch/${redemption.batchId}`);
                        if (res.ok) {
                            setBatchToView(res.data.batch);
                            setOpenRedemptionDialog(true);
                            setSelectedHistoryRedemptionId(redemption._id);
                        } else {
                            onSnackbar('Error viewing batch', 'error');
                        }
                    }}
                >
                    <Icon>visibility</Icon>
                </IconButton>
            </React.Fragment>
        );

        let state = getStateFromRedemption(redemption);

        let method = (
            <span
                style={
                    redemption.paymentModel === 'AUS_DIRECT_BANKING'
                        ? { color: theme.palette.primary.main, fontWeight: 500 }
                        : { color: theme.palette.secondary.main, fontWeight: 500 }
                }
            >
                {' '}
                {redemption.paymentModel === 'AUS_DIRECT_BANKING' ? 'Direct Deposit' : loc('redemption5', lang)}
            </span>
        );

        return (
            <TableRow key={redemption._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {action}
                    </TableCell>
                )}
                {filteredHeaders.includes('Account Number') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {_.get(redemption, 'banking.accountNumberMasked', 'N/A')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Name') && (
                    <TableCell
                        style={{
                            paddingLeft: theme.spacing.unit,
                            paddingRight: theme.spacing.unit
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ marginRight: theme.spacing.unit }}>
                                {redemption.type === 'Customer'
                                    ? getCustomerName(redemption.claimant)
                                    : _.get(redemption, 'charity.name', 'N/A')}{' '}
                            </Typography>
                            {_.get(redemption, 'cancelled', false) && (
                                <Tooltip title="Cancelled">
                                    <Icon style={{ color: theme.palette.text.disabled }}>block</Icon>
                                </Tooltip>
                            )}
                        </div>
                    </TableCell>
                )}
                {filteredHeaders.includes('Amount') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {formatAsCurrency(redemption.amount - redemption.fee)}
                    </TableCell>
                )}
                {filteredHeaders.includes('Type') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {redemption.type}
                    </TableCell>
                )}
                {filteredHeaders.includes('Method') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {method}
                    </TableCell>
                )}
                {filteredHeaders.includes('State') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {state}
                    </TableCell>
                )}
                {filteredHeaders.includes('Batch Date') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {moment(_.get(redemption, 'batchDate', 'N/A'))
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Batch Number') && (
                    <TableCell style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}>
                        {_.get(redemption, 'batchNumber', 'N/A')}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const handleDownload = async () => {
        setExportLoading(true);
        const csvRows = [];

        const url = `/redemptions/AUS/history?types=${getTypesForTab(
            typeFilter
        )}&paymentModels=${getPaymentsModelsForTab(methodFilter)}&page_size=${999999999}`;

        const qs = queryString.parse(window.location.search);
        let apiURL = url + `${url.includes('?') ? '&' : '?'}`;
        for (let key in qs) {
            if (qs[key]) {
                const searchKey = key;
                apiURL += `&${searchKey}=${encodeURIComponent(qs[key])}`;
            }
        }

        let res = await http.getJSON(apiURL);

        res.data.redemptions.forEach(redemption => {
            let state = getStateFromRedemption(redemption);

            const newRow = {
                AccountNumber: _.get(redemption, 'banking.accountNumberMasked', 'N/A'),
                Name:
                    redemption.type === 'Customer'
                        ? getCustomerName(redemption.claimant)
                        : _.get(redemption, 'charity.name', 'N/A'),
                Amount: formatAsCurrency(redemption.amount - redemption.fee),
                Type: redemption.type,
                Method: redemption.paymentModel === 'AUS_DIRECT_BANKING' ? 'Direct Deposit' : loc('redemption5', lang),
                State: state,
                BatchDate: moment(_.get(redemption, 'batchDate', 'N/A'))
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('LT MMM Do, YYYY'),
                BatchNumber: _.get(redemption, 'batchNumber', 'N/A')
            };

            csvRows.push(newRow);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `redemption_history_${date}`;
        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
        setExportLoading(false);
    };

    const exportButton = (
        <Button
            disabled={tableLoading || exportLoading}
            color="primary"
            style={{ marginRight: theme.spacing.unit }}
            onClick={handleDownload}
        >
            <Icon>get_app</Icon>
        </Button>
    );

    return (
        <>
            <Grid container spacing={theme.spacing.unit * 2} style={{ padding: theme.spacing.unit * 2 }}>
                <Grid item xs={12}>
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: theme.spacing.unit * 2,
                            height: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DateRangePicker
                                timezone={timezone}
                                startDate={startDate}
                                endDate={endDate}
                                window={dateWindow}
                                disabled={tableLoading || exportLoading}
                                handlePrevious={handleGoBackwards}
                                handleNext={handleGoForwards}
                                handleChangeStartDate={handleChangeStartDate}
                                handleChangeEndDate={handleChangeEndDate}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            <FormControl style={{ marginRight: 16 }}>
                                <Typography>Total Amount: {formatAsCurrency(response.totalAmount)}</Typography>
                                <Typography>Total Redemptions: {totalDataEntries}</Typography>
                            </FormControl>
                            <FormControl>
                                <Select
                                    value={dateWindow}
                                    disabled={tableLoading || exportLoading}
                                    onChange={handleWindowSelect}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                >
                                    <MenuItem value="today">Daily</MenuItem>
                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                    <MenuItem value="allTime">All Time</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <Select
                                    value={typeFilter}
                                    disabled={tableLoading || exportLoading}
                                    onChange={e => setTypeFilter(e.target.value)}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="customer">Customer</MenuItem>
                                    <MenuItem value="charity">Charity</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <Select
                                    value={methodFilter}
                                    disabled={tableLoading || exportLoading}
                                    onChange={e => setMethodFilter(e.target.value)}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="aus direct banking">Direct Deposit</MenuItem>
                                    <MenuItem value="cheque">Cheque</MenuItem>
                                </Select>
                            </FormControl>
                            <StateSelectDropDown loading={tableLoading || exportLoading} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <Table
                dataCy="redemptions-history-table"
                title="Redemption History"
                loading={tableLoading || exportLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={refreshTable}
                cellStyle={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                customAction={exportButton}
            >
                {filteredData &&
                    filteredData.map(data => {
                        return renderRow(data, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(HistoryTab);

function getTypesForTab(typeFilter) {
    switch (typeFilter) {
        case 'all':
            return ['Customer', 'Charity'];
        case 'customer':
            return ['Customer'];
        case 'charity':
            return ['Charity'];
        default:
            return ['Customer', 'Charity'];
    }
}

function getPaymentsModelsForTab(methodFilter) {
    switch (methodFilter) {
        case 'all':
            return ['AUS_DIRECT_BANKING', 'CHEQUE'];
        case 'aus direct banking':
            return ['AUS_DIRECT_BANKING'];
        case 'cheque':
            return ['CHEQUE'];
        default:
            return ['AUS_DIRECT_BANKING', 'CHEQUE'];
    }
}

function getStateFromRedemption(redemption) {
    let state = 'N/A';

    let location = _.get(redemption, 'claimant.location', null);
    if (!_.isNull(location)) {
        if (!_.isNull(_.get(location, 'description', null))) {
            state = getStateFromAddress(_.get(location, 'description'));
        }
    }

    return state;
}
